import React, { useState } from "react";
import { Table } from "../../../../components/Tables/Table";
import {
  getSurveyGradeBackgroundColors,
  getSurveyGradeTextColors,
} from "../../../../utilities/Utils";
import { mySurveyResponseTable } from "../../../../utilities/dummyGraphs/surveyDummy";
import { useDispatch, useSelector } from "react-redux";
import { setSideMenuTablePage } from "../../../../redux/slices/surveySlice";

const SurveyQuestions = ({
  currentrow,
  surveyResponseData,
  searchInputValue,
}) => {
  const dispatch = useDispatch();
  const { page } = useSelector(
    (state) => state.surveysReducer.sideMenu.tableData,
  );

  // const [filteredData, setFilteredData] = useState(mySurveyResponseTable);
  // const [filterInputValue, setFilterInputValue] = useState("");
  const [selected, setSelected] = useState(false);

  const setTablePage = (page) => {
    dispatch(setSideMenuTablePage(page));
  };

  return (
    <div>
      <div
        style={{
          border: "3px solid #F5F5F5",
          boxShadow: "0px 4px 4px 0px #FAFAFA",
          borderRadius: "10px",
          padding: "20px 34px",
        }}
      >
        <div
          // key={surveyQuestionsIndex}
          className="d-flex justify-content-between col-lg-12"
          style={{
            border: "1px solid #DEDEDE",
            borderRadius: "10px",
            padding: "12px 11px",
            // marginBottom: "19px",
          }}
        >
          <div className="d-flex">
            <div
              className="d-flex justify-content-between flex-column align-items-center"
              style={{
                background: getSurveyGradeBackgroundColors(currentrow.grade),
                marginRight: "26px",
                width: "92px",
                padding: "10px 0",
              }}
            >
              <span
                className="d-flex head3-semi-bold mb-2"
                style={{
                  color: getSurveyGradeTextColors(currentrow.grade),
                }}
              >
                {currentrow.grade}
              </span>

              <span
                className="d-flex head6-medium"
                style={{
                  color: getSurveyGradeTextColors(currentrow.grade),
                }}
              >
                {currentrow.pecentage}
              </span>
            </div>
            <div
              className="d-flex justify-content-between flex-column"
              style={{ padding: "14px 0", marginTop: "6px" }}
            >
              <div className="d-flex head5-medium grey9">
                {currentrow.surveyQuestion}
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-between flex-column"
            style={{ padding: "20px 0" }}
          >
            <div className="d-flex caption1 grey9">{`Asked By: ${currentrow.AskedBy}`}</div>
            <span className="d-flex caption1 grey9">{`Created at: ${currentrow.CreatedAt}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyQuestions;
