import { ResponsiveBarCanvas } from "@nivo/bar";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getBarGraphValues } from "../../utilities/GraphUtils";
import dayjs from "dayjs";
import { ResponsiveLine } from "@nivo/line";

// import { addMissingDates } from "../../utilities/dateConversion";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered.

const LineGraphTemp = (props) => {
  const {
    graphData,
    // usersData,
    // groupByGraphKeys,
    // currentFilters,
    // selectedGroupByItem,
    // graphName,
    // selectedGranularity,
    // matchingUsersInGraphData,
    // indexBy,
    // hideDateOnToolTip,

    padding,
    colors,
    axisBottom,
    axisLeft,
    enableGridX,
    enableGridY,
    tooltip,
    minValue,
    maxValue,
    borderRadius,
    margin,
    graphContainerHeight,
    curve,
    xFormatCustom,
  } = props;

  // const getToolTipNames = (value) => {
  //   if (selectedGroupByItem === "users") {
  //     const element = usersData.find((element) => {
  //       // console.log("element.user_id , value", element.user_id, value);
  //       return parseInt(element.user_id) === parseInt(value);
  //     });
  //     // console.log("element", element);
  //     return element?.name;
  //   }
  //   if (
  //     selectedGroupByItem === "source" ||
  //     selectedGroupByItem === "event types"
  //   ) {
  //     return value;
  //   }
  // };

  // --------------------------------- THIS CODE IS ADDING MISSING DATES AND SORTING DATA STARTS ---------------------------------

  // const groupedData = data?.reduce((acc, cur) => {
  // 	const eventTime = cur[`DumpTableMain.eventTime`].split("T")[0];
  // 	// const eventTime = cur["DumpTableMain.eventTime.day"];
  // 	const count = parseInt(cur["DumpTableMain.count"]);
  // 	if (acc[eventTime]) {
  // 		acc[eventTime].count += count;
  // 	} else {
  // 		acc[eventTime] = {
  // 			eventTime,
  // 			count
  // 		};
  // 	}

  // 	return acc;
  // }, {});

  // const result = Object.values(groupedData || {});

  // result.length > 0 && result.reduce(function (hash) {
  // 	return function (p, c) {
  // 		const missingDaysNo = minValue(Date.parse(c.eventTime) - hash.prev) / (1000 * 3600 * 24);
  // 		if (hash.prev && missingDaysNo > 1) {
  // 			for (let i = 1; i < missingDaysNo; i++) {
  // 				const missing = (new Date(hash.prev + i * (1000 * 3600 * 24)));
  // 				const date = new Date(missing);
  // 				const year = date.getFullYear();
  // 				const month = String(date.getMonth() + 1).padStart(2, "0");
  // 				const day = String(date.getDate()).padStart(2, "0");

  // 				const formattedDate = `${year}-${month}-${day}`;
  // 				p.push(formattedDate);
  // 				const newObj = {
  // 					eventTime: formattedDate,
  // 					count: 0
  // 				};
  // 				result.push(newObj);
  // 			}
  // 		}
  // 		hash.prev = Date.parse(c.eventTime);
  // 		return p;
  // 	};
  // }(Object.create(null)), []);

  // result.sort(function (a, b) {
  // 	if (a.eventTime < b.eventTime) {
  // 		return -1;
  // 	}
  // 	if (a.eventTime > b.eventTime) {
  // 		return 1;
  // 	}
  // 	return 0;
  // });

  // --------------------------------- THIS CODE IS ADDING MISSING DATES AND SORTING DATA ENDS ---------------------------------

  // ------------------------------------------------------ SKIPPING LABELS ------------------------------------------------------
  // const valuesToShow = graphData.map((v, i) => i % 2 === 0 ? "" : v["DumpTableMain.eventTime"]);
  // ------------------------------------------------------ SKIPPING LABELS ------------------------------------------------------

  // React.useEffect(() => {
  //   HTMLCanvasElement.prototype.getBBox = function () {
  //     return { width: this.offsetWidth, height: this.offsetHeight };
  //   };
  // }, []);

  console.log("LineGraph", graphData);

  return (
    <div
      style={{
        height: graphContainerHeight ? graphContainerHeight : "266px",
        width: "100%",
      }}
    >
      <ResponsiveLine
        data={graphData}
        margin={margin ? margin : { top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          // stacked: true,
          reverse: false,
        }}
        // fill={true}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        curve={curve !== undefined ? curve : "linear"}
        axisBottom={
          axisBottom !== undefined
            ? axisBottom
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "transportation",
                legendOffset: 36,
                legendPosition: "middle",
              }
        }
        axisLeft={
          axisLeft !== undefined
            ? axisLeft
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "count",
                legendOffset: -40,
                legendPosition: "middle",
              }
        }
        lineWidth={2}
        enablePoints={false}
        pointSize={10}
        // pointColor={"black"}
        pointBorderWidth={2}
        // colors={"black"}
        // colors={"#A790FF"}
        // pointBorderColor={"black"}
        pointLabelYOffset={-12}
        enableArea={true}
        enableGridX={false}
        enableGridY={false}
        // animate={false}
        areaOpacity={0.2}
        // lineWidth={4}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default LineGraphTemp;
