import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetectedUsersMenu from "../../../components/SideMenus/DetectedUser";
import BackgroundBlur from "../../../components/sub-components/Blur";
import { ReactComponent as SlackIcon } from "../../../assets/icons/Slack.svg";

import { Table } from "../../../components/Tables/Table";
import {
  getDetectedUsers,
  setUsersTablePage,
} from "../../../redux/slices/usersSlice";

function DetectedUsers(props) {
  const { inputValue, filters } = props;

  const [currentRow, setCurrentRow] = useState({});
  const [isSideMenu, setIsSideMenu] = useState(false);
  const [isRadioButton, setIsRadioButton] = useState("__0");

  const { data, page } = useSelector((state) => state.usersReducer.tableData);
  const { loading } = useSelector((state) => state.usersReducer);

  const dispatch = useDispatch();

  const columns = [
    {
      key: "username",
      name: "Full Name",
      minWidth: 280,
      // minWidth: 200,
      padding: "0 0 0 32px",
    },
    {
      key: "identifier",
      name: "Platform",
      minWidth: 280,
      accessor: (row) => (
        <div>
          {row.identifier === "Slack" ? <SlackIcon /> : ""}
          <span className="ml-3">{row.identifier}</span>
        </div>
      ),
      // minWidth: 200,
      // SlackIcon
    },
    {
      key: "events",
      name: "Detected Events",
      minWidth: 280,
      // minWidth: 200,
    },
  ];

  const handleSideMenuOpen = () => {
    setIsSideMenu(true);
  };

  const handleSideMenuClose = () => {
    setIsSideMenu(false);
    // setCurrentRow(row);
  };

  const handleMoveToActiveUsers = () => {
    setIsSideMenu(false);
  };

  const setTablePage = (page) => {
    dispatch(setUsersTablePage(page));
  };

  const refreshState = (e) => {
    handleSideMenuClose();
    setIsRadioButton("__0");
  };

  useEffect(() => {
    dispatch(getDetectedUsers());
  }, [dispatch]);

  return (
    <>
      {isSideMenu && <BackgroundBlur closeMenu={refreshState} />}
      <DetectedUsersMenu
        sideMenu={isSideMenu}
        handleMoveToActiveUsers={handleMoveToActiveUsers}
        handleSideMenuClose={handleSideMenuClose}
        isRadioButton={isRadioButton}
        setIsRadioButton={setIsRadioButton}
        refreshState={refreshState}
        // onChangeRadioButton={onChangeRadioButton}
        currentRow={currentRow}
      />

      <Table
        data={data}
        headerBackgroundColor="#F7FBFF"
        columns={columns}
        page={page}
        loading={loading}
        inputValue={inputValue}
        filters={filters}
        setTablePage={setTablePage}
        handleTableRowClick={(row) => {
          setCurrentRow(row);
          handleSideMenuOpen();
        }}
        checkboxSize="24px"
      />
    </>
  );
}

export default DetectedUsers;
