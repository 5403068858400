import React from "react";
import Button from "../../../components/sub-components/Button";
import Field from "../../../components/sub-components/Field";
import arrow_right from "../../../assets/icons/ArrowRight.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import ResetLinkSent from "./ResetLinkSent";
import Footer from "../../../components/sub-components/Footer";
import {
  resetPasswordRequest,
  setForgotPasswordForm,
  setForgotPasswordFormErrors,
} from "../../../redux/slices/authSlice";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.authReducer.loading);
  const form = useSelector((state) => state.authReducer.forgotPasswordForm);
  const formErrors = useSelector(
    (state) => state.authReducer.forgotPasswordFormErrors,
  );
  const isResetPasswordLink = useSelector(
    (state) => state.authReducer.isResetPasswordLink,
  );

  const handleChange = (event) => {
    event.stopPropagation();

    const { name, value } = event.target;

    dispatch(setForgotPasswordForm({ name, value }));
  };

  const handleSubmit = () => {
    if (!form.email) {
      dispatch(
        setForgotPasswordFormErrors({
          name: "email",
          value: "Email is required",
        }),
      );
    }

    if (form.email) {
      dispatch(
        setForgotPasswordFormErrors({
          name: "email",
          value: false,
        }),
      );
    }

    if (form.email) {
      dispatch(resetPasswordRequest(form));
    }
  };

  if (isResetPasswordLink) {
    return <ResetLinkSent />;
  }

  return (
    <>
      <div className="signup">
        <Header />

        <div className="signup-fields">
          <div
            className="row"
            style={{ paddingTop: "150px", textAlign: "left" }}
          >
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <span
                  className="head4 grey8"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  Forgot Password
                </span>
                <img
                  src={arrow_right}
                  style={{ paddingLeft: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ paddingTop: "50px", textAlign: "left" }}
          >
            <div className="col-lg-12">
              <div>
                <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                  Email
                </div>
                <Field
                  inputFieldFull
                  name="email"
                  className={`${
                    formErrors.email ? "border border-danger" : ""
                  }`}
                  value={form.value}
                  onChange={handleChange}
                />
                <span className="ml-2 text-danger">{formErrors.email}</span>
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "30px" }}>
            <div className="d-flex flex-column align-items-center col-lg-12">
              <Button
                buttonFilledFull
                title="Continue"
                loading={loading}
                onClick={handleSubmit}
              />
              <div className="b2 grey8" style={{ paddingTop: "15px" }}>
                Remembered your Password?{" "}
                <Link to="/login" className="s2 primary-color">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="col-lg-12 d-flex flex-row justify-content-center align-items-center"
        style={{ gap: "43.5px", marginTop: "80px" }}
      > */}
      <Footer />
      {/* </div> */}
    </>
  );
}
