import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Button from "../../../components/sub-components/Button";
import Field from "../../../components/sub-components/Field";
import { ReactComponent as Slack } from "../../../assets/icons/Slack.svg";
import { ReactComponent as Google } from "../../../assets/icons/Google.svg";
import Header from "./Header";
import Footer from "../../../components/sub-components/Footer";
import {
  handleLoginFormChange,
  handleLoginSliceCall,
  setLoginFormErrors,
  toggleLoginEyeClick,
  toggleRememberMeCheck,
} from "../../../redux/slices/authSlice";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { providersName } = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.IdentityProvders,
  );
  const form = useSelector((state) => state.authReducer.loginForm);
  const formErrors = useSelector((state) => state.authReducer.loginFormErrors);
  const loading = useSelector((state) => state.authReducer.loading);
  const [isChecked, setIsChecked] = useState(false);

  const countRef = useRef(providersName);
  countRef.current = providersName;

  const handleChange = (target) => {
    dispatch(handleLoginFormChange(target));
  };

  const onChangeRememberMe = (target) => {
    setIsChecked(target.checked);
    dispatch(toggleRememberMeCheck(target.checked));
  };

  const handleSubmit = () => {
    if (!form.email) {
      dispatch(
        setLoginFormErrors({
          name: "email",
          value: "Email is required",
        }),
      );
    }

    if (!form.password) {
      dispatch(
        setLoginFormErrors({
          name: "password",
          value: "Password is required",
        }),
      );
    }

    if (form.email) {
      dispatch(
        setLoginFormErrors({
          name: "email",
          value: false,
        }),
      );
    }

    if (form.password) {
      dispatch(
        setLoginFormErrors({
          name: "password",
          value: false,
        }),
      );
    }

    if (form.email && form.password) {
      dispatch(handleLoginSliceCall(form, history, countRef.current));
    }
  };

  const toggleLoginEyeClickSetter = () => {
    if (form.password) {
      dispatch(toggleLoginEyeClick());
    }
  };

  return (
    <>
      <div className="signup">
        <Header />
        <div className="signup-fields">
          <div className="row" style={{ paddingTop: "7vh", textAlign: "left" }}>
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <span
                  className="head4"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  Login
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "4vh", textAlign: "left" }}>
            <div className="col-lg-12">
              <div>
                <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                  Email <span className="text-danger">*</span>
                </div>
                <Field
                  className={`${
                    formErrors.email ? "border border-danger" : ""
                  }`}
                  inputFieldFull
                  required
                  name="email"
                  value={form.email}
                  onChange={(e) => handleChange(e.target)}
                />
                <span className="text-danger">{formErrors.email}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div
                  className="s1 grey8"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  Password <span className="text-danger">*</span>
                </div>
                <Field
                  passwordFieldFull
                  className={`${
                    formErrors.password ? "border border-danger" : ""
                  }`}
                  name="password"
                  value={form.password}
                  showPassword={form.isShowPassword}
                  onChange={(e) => handleChange(e.target)}
                  onEyeClick={toggleLoginEyeClickSetter}
                />
                <span className="text-danger">{formErrors.password}</span>
              </div>
            </div>
          </div>

          {formErrors.error && (
            <span className="text-danger position-absolute ml-2 mt-2">
              {formErrors.error}
            </span>
          )}

          <div className="row">
            <div
              className="d-flex justify-content-between col-lg-12"
              style={{ paddingTop: formErrors.error ? "30px" : "0" }}
            >
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  id="checkbox-id"
                  name="checkbox-name"
                  onChange={(e) => onChangeRememberMe(e.target, "checkbox")}
                  checked={isChecked}
                />

                <div
                  className="s1 grey8"
                  style={{ paddingLeft: "10px", cursor: "pointer" }}
                  onClick={() =>
                    onChangeRememberMe({ checked: !isChecked }, "checkbox")
                  }
                >
                  Remember me
                </div>
              </div>
              <Link className="b2 grey8" to="/forgot-password">
                Forgot Password?
              </Link>
            </div>
          </div>

          <div className="row mt-1" style={{ paddingTop: "20px" }}>
            <div className="d-flex flex-column align-items-center col-lg-12">
              <Button
                buttonFilledFull
                title="Login"
                onClick={handleSubmit}
                loading={loading}
                style={{ height: "48px" }}
              />
              <div
                className="b2 grey8"
                style={{ paddingTop: "10px", paddingBottom: "20px" }}
              >
                Dont have an account?{" "}
                <Link to="/signup" className="s2 primary-color">
                  Join Now
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-flex align-items-center justify-content-between col-lg-12">
              <hr style={{ border: "2px solid #CED5DB", width: "30%" }} />
              <div className="s2 grey8 d-flex align-items-center">
                Or Login with
              </div>
              <hr style={{ border: "2px solid #CED5DB", width: "30%" }} />
            </div>
          </div>

          <div className="row mt-4 mb-4">
            <div className="col-lg-12 d-flex flex-row justify-content-center align-items-center">
              <Google
                style={{ marginRight: "18px", width: "32px", height: "32px" }}
              />
              <Slack
                style={{ marginLeft: "18px", width: "32px", height: "32px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer fieldFooter />
    </>
  );
}
