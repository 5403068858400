import React, { useState } from "react";
import Field from "../sub-components/Field";
import Button from "../sub-components/Button";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  handleTeamsFormChange,
  handleTeamsGroupData,
} from "../../redux/slices/identityProviderSlice";

export default function CreateTeam(props) {
  const { handleTeamsPopupToggle } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [formError, setFormError] = useState(false);
  const [emptyName, setEmptyName] = useState(false);
  const [firstSpace, setFirstSpace] = useState(false);

  const handleClose = () => {
    setOpen(false);
    handleTeamsPopupToggle();
  };

  const { name = "", description = "" } = useSelector(
    (state) => state.identityProviderReducer.ProviderConnectionInfo.TeamsForm,
  );
  const { teamsData = [] } = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.IdentityProvders,
  );
  const { individualTeamsData = {} } = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.IdentityProvders,
  );

  // Initialize inputValue with clicked team's name, allow edits by the user
  const [inputValue, setInputValue] = useState(individualTeamsData.name || "");
  const [descriptionInput, setDescriptionInput] = useState(description || "");

  const handleChange = (e) => {
    setFormError(false);
    setEmptyName(false);
    setInputValue(e.target.value); // Allow user to edit the team name
    dispatch(handleTeamsFormChange({ name: "name", value: e.target.value }));
  };

  const handleChangeDescription = (e) => {
    setDescriptionInput(e.target.value);
    dispatch(
      handleTeamsFormChange({ name: "description", value: e.target.value }),
    );
  };

  const handleTeamCreated = () => {
    dispatch(handleTeamsFormChange(""));
    const newTeam = { ...individualTeamsData };

    // Ensure name exists
    if (inputValue && inputValue.length > 0) {
      newTeam["name"] = inputValue;
    } else {
      setEmptyName(true);
      return;
    }

    // Update description if provided
    if (description && description.length > 0) {
      newTeam["description"] = description;
    }

    const newTeamsData = [...teamsData];
    const existingTeams = newTeamsData.map((team) => team.name);
    const teamsExists = existingTeams.includes(newTeam.name);

    if (teamsExists) {
      setFormError(true);
      return;
    } else if (inputValue.length === 0) {
      setEmptyName(true);
      return;
    } else if (!inputValue.replace(/\s/g, "").length) {
      setFirstSpace(true);
    } else {
      dispatch(handleTeamsGroupData(newTeam));
      handleTeamsPopupToggle();
    }
    dispatch(handleTeamsFormChange(""));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <>
            <div className="head5" style={{ textAlign: "center" }}>
              Create New Team
            </div>

            {teamsData.length === 0 && (
              <h3 className="s1 mt-3" style={{ textAlign: "center" }}>
                You haven’t created any team, create team to add groups as teams
              </h3>
            )}
          </>

          <div className="row">
            <div
              className="col-lg-12"
              style={{ overflowY: "scroll", maxHeight: "400px" }}
            >
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-lg-12">
                  <div>
                    <div className="s1" style={{ paddingBottom: "10px" }}>
                      Name
                    </div>
                    <Field
                      className={`${
                        formError || emptyName || firstSpace
                          ? "border border-danger"
                          : ""
                      }`}
                      inputFieldFull
                      autoComplete
                      name="name"
                      value={inputValue} // Controlled input for team name
                      onChange={(e) => handleChange(e)}
                    />
                    {formError && (
                      <span className="text-danger">
                        Team already exists, enter a different name
                      </span>
                    )}
                    {firstSpace && (
                      <span className="text-danger">Team name is required</span>
                    )}
                    {emptyName && (
                      <span className="text-danger">Team name is required</span>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="row"
                style={{ paddingTop: "4vh", textAlign: "left" }}
              >
                <div className="col-lg-12">
                  <div>
                    <div className="s2" style={{ paddingBottom: "10px" }}>
                      Description (Optional)
                    </div>
                    <Field
                      inputFieldFull
                      autoComplete
                      name="description"
                      value={descriptionInput} // Controlled input for description
                      onChange={(e) => handleChangeDescription(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center add-team-btn">
              <Button
                buttonFilledMd
                title="Create Team and Add"
                style={{ color: "white", width: "90%" }}
                onClick={() => handleTeamCreated()}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
