import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { useDispatch } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import LinearProgressLoader from "../../../../components/sub-components/LinearProgress";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/Views/Objectives/calendar.svg";
import { ReactComponent as Frame427319066 } from "../../../../assets/icons/Views/Objectives/Frame 427319066.svg";
import {
  deleteObjetive,
  handleCurrentRowToEdit,
  toggleSideMenu,
} from "../../../../redux/slices/ObjectivesSlice";
import { ReactComponent as DotsThreeHorizontal } from "../../../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as Fi_trash } from "../../../../assets/icons/fi_trash.svg";
import { ReactComponent as PencilSimple } from "../../../../assets/icons/PencilSimple.svg";
import DeleteModal from "../../../../components/Modals/DeleteModal";

const TaskItem = (props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
  });
  const { task } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const isButtonActionMenu = Boolean(anchorEl);

  const handleEditObjective = () => {
    handleThreeDotsClose();
    dispatch(toggleSideMenu());
    dispatch(handleCurrentRowToEdit(task));
  };

  const handleDeleteObjective = (e) => {
    handleThreeDotsClose();
    dispatch(deleteObjetive(task.id));
    setOpenModal(false);
  };

  const handleThreeDotsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  const getQuarterForDates = (date) => {
    if (!date) {
      return "";
    }

    const month = dayjs(date).month() + 1;
    let quarter = "";

    if (month >= 1 && month <= 3) {
      quarter = "Q1";
    } else if (month >= 4 && month <= 6) {
      quarter = "Q2";
    } else if (month >= 7 && month <= 9) {
      quarter = "Q3";
    } else if (month >= 10 && month <= 12) {
      quarter = "Q4";
    }

    return quarter;
  };

  return (
    <>
      <DeleteModal
        openModal={openModal}
        handleCloseModal={(e) => {
          e.stopPropagation();
          setOpenModal(false);
        }}
        deleteEvent={(e) => {
          e.stopPropagation();
          handleDeleteObjective(e);
        }}
        text={"Delete Objective"}
      />
      <div
        className="card p-1 mt-3"
        style={{
          border: "1px solid #DEDEDE",
          borderRadius: "10px",
          width: "100%",
          boxShadow: "2px 2px 15px 0px #DEDEDE",
          height: "210px",
        }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingTop: "16px",
            width: "100%",
          }}
          className="d-flex row"
        >
          <div className="col-11 pr-0">
            <div className="mb-1 head6-medium">{task.title}</div>
            <div
              className="body1 mt-3 overflow-auto"
              style={{
                height: "25%",
                maxHeight: "25%",
                minHeight: "25%",
                width: "100%",
              }}
            >
              {task.description}
            </div>
            <div style={{ paddingTop: "24px", paddingBottom: "10px" }}>
              <LinearProgressLoader
                variant="determinate"
                value={task?.overall_current_progress ?? 0}
              />
            </div>

            <div className="d-flex justify-content-between align-items-center row p-0">
              <div className="body2-medium align-items-center col-md-12 col-lg-3 pr-0">
                <span>
                  <CalendarIcon
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",
                      marginBottom: "3px",
                    }}
                  />
                </span>
                <span style={{ color: "#00A926" }}>
                  {getQuarterForDates(task?.start_date) ?? ""}
                </span>
                <span>/</span>
                <span style={{ color: "#EB2D1E" }}>
                  {getQuarterForDates(task?.end_date) ?? ""}
                </span>
              </div>
              <div className="col-md-12 col-lg-5 p-0">
                <div className="d-flex align-items-center justify-content-center">
                  Owner{" "}
                  {task?.ownerprofile?.image_url &&
                  task?.ownerprofile?.image_url !== "pending" ? (
                    <img
                      className="pl-1"
                      src={task?.ownerprofile?.image_url}
                      alt={`${task?.ownerprofile?.first_name} ${task?.ownerprofile?.last_name}`}
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <div className="event-avatar">
                      {task?.ownerprofile?.first_name.charAt(0).toUpperCase()}
                      {task?.ownerprofile?.last_name.charAt(0).toUpperCase()}
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12 col-lg-4 pr-0">
                {task?.overall_current_progress ?? 0}% complete
              </div>
            </div>
          </div>
          <div className="pr-1 col-1">
            <div
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleThreeDotsOpen(e);
              }}
            >
              <DotsThreeHorizontal />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={isButtonActionMenu}
                onClose={(e) => {
                  e.stopPropagation();
                  handleThreeDotsClose();
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditObjective();
                  }}
                >
                  <PencilSimple
                    stroke="#343A40"
                    style={{ width: "16px", height: "16px" }}
                    onClick={(e) => e.preventDefault()}
                  />
                  <span
                    className="s2 grey8 d-flex align-items-center"
                    style={{ marginLeft: "8px" }}
                  >
                    Edit
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(true);
                    handleThreeDotsClose();
                  }}
                >
                  <Fi_trash
                    stroke="#343A40"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <span
                    className="s2 grey8 d-flex align-items-center"
                    style={{ marginLeft: "8px" }}
                  >
                    Delete
                  </span>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskItem;
