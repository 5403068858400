import React, { useState } from "react";
import { useSelector } from "react-redux";
import Company from "../Settings/Company";
import MyProfile from "../Settings/MyProfile";
import Notifications from "./Notifications";
import Billing from "../Company-Setup/Billing";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";

export default function Settings() {
  const { currentTab } = useSelector((state) => state.settingsReducer);

  const [organizationItemSelected, setOrganizationItemSelected] =
    useState("My Profile");
  const [organizationData, setOrganizationData] = useState([]);
  const [organizationItemApplied, setOrganizationItemApplied] =
    // useState("Company");
    useState("My Profile");
  // useState("Notifications");

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [nestedFilterAnchorEl, setNestedFilterAnchorEl] = useState(null);
  // const isNestedFilterationMenuOpen = Boolean(nestedFilterAnchorEl);

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const nestedFilterAnchorEl = React.useRef(null);

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleClickCheckbox = (item) => {
    // const selectedIndex = organizationItemSelected.findIndex((x) => x === item);

    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(organizationItemSelected, item);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(organizationItemSelected.slice(1));
    // } else if (selectedIndex === organizationItemSelected.length - 1) {
    //   newSelected = newSelected.concat(organizationItemSelected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     organizationItemSelected.slice(0, selectedIndex),
    //     organizationItemSelected.slice(selectedIndex + 1),
    //   );
    // }
    console.log("itemitem", item);
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  const handleApplyOrganizationFilter = () => {
    setOrganizationItemApplied(organizationItemSelected);
    handleNestedFilterationMenuClose();

    // const organizationGlobalFilterClone = organizationGlobalFilter;

    // const userFilters = organizationGlobalFilterClone;

    // if (Object.keys(organizationItemSelected).length === 0) {
    //   userFilters.filters = [];
    // } else if (userFilters) {
    //   userFilters.filters = [
    //     {
    //       col: "group_id",
    //       operator: "==",
    //       value: organizationItemSelected.group_id,
    //     },
    //   ];
    // }
  };

  return (
    <>
      <div>
        {/* {currentTab === "1" ? (
        <MyProfile />
      ) : currentTab === "2" ? (
        <Company />
      ) : currentTab === "3" ? (
        <Notifications />
      ) : currentTab === "4" ? (
        <Billing />
      ) : (
        ""
      )} */}
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ marginBottom: "40px", paddingLeft: "34px" }}
        >
          <PopperDropdown
            anchorRef={nestedFilterAnchorEl}
            isDropdownOpen={isNestedFilterationMenuOpen}
            handleToggle={() => handleNestedFilterationMenuOpen()}
            handleClose={() => handleCancelOrganizationFilter()}
            // dropdownDisplayText={capitalizeFirstAndRemoveLastTwoLetters(
            //   granularitySelected,
            // )}
            dropdownDisplayText="Users"
            customRenderFieldChildren={
              <div
                className="d-flex align-items-center head4-semi-bold"
                style={{ cursor: "pointer" }}
              >
                {organizationItemApplied}{" "}
                <CaretDown stroke="black" stroke-width="3" className="ml-2" />
              </div>
            }
            // menuItems={["Users", "Event Types", "Sources"]}
            dropdownWithSearchAndCheckbox
            dropdownData={["My Profile", "Company", "Notifications"]}
            handleClickCheckbox={handleClickCheckbox}
            handleApply={handleApplyOrganizationFilter}
            handleCancel={handleCancelOrganizationFilter}
            dropdownItemsSelected={organizationItemSelected}
            keyToAccess="none"
            // handleOptionSelected={handleGroupBySelected}
            // activeMenuItem={"Users"}
          />
        </div>
      </div>
      {organizationItemApplied === "My Profile" ? (
        <MyProfile />
      ) : organizationItemApplied === "Company" ? (
        <Company />
      ) : organizationItemApplied === "Notifications" ? (
        <Notifications />
      ) : (
        ""
      )}
    </>
  );
}
