/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import BpCheckbox from "../sub-components/Checkbox";
import { ReactComponent as CheckIcon } from "../../assets/icons/Views/Objectives/Check.svg";

import Field from "../../components/sub-components/Field";

const DropDown = (props) => {
  let prev = "";
  let duplicateNameFlag = false;

  const [dropDownData, setDropDownData] = useState([...props.dropDownData]);

  useEffect(() => {
    setDropDownData([...props.dropDownData]);
  }, [props.dropDownData.length]);

  if (props.editUserDropDownSortAndFilter) {
    return (
      <div
        className="add-members-team-menu-card"
        style={{
          position: "absolute",
          opacity: props.isFieldActive ? 1 : 0,
          zIndex: props.isFieldActive ? 30 : -50,
          transition: "all 0.4s ease",
          visibility: !props.isFieldActive && "hidden",
          height: props.isFieldActive ? "396px" : "100px",
          bottom: "0",
          left: "0px",
          width: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            padding: "16px 12px 12px 12px",
            zIndex: "20",
            width: "100%",
          }}
        >
          <Field
            inputFieldFullSearchIcon
            placeholder="Search Cohorts"
            value={props.searchValue}
            onChange={(e) => {
              props.setSearchValue(e.target.value);
              // handleActionMenu("open");
            }}
            // onChange={() => setIsTeamsMenu(true)}
            style={{
              height: "40px",
              width: "100%",
              borderRight: "1px solid #e9ecef",
              borderRadius: "10px",
              color: "#6C757D",
              paddingLeft: "52px",
              margin: "0",
            }}
            // onClick={() => setIsTeamsMenu(true)}
          />
        </div>
        <div
          style={{
            height: "100%",
            margin: "0 16px",
            marginTop: "60px",
            overflowY: "scroll",
            display: props.isFieldActive ? "block" : "none",
            zIndex: "15",
          }}
        >
          {!props.isSearchMatch && (
            <span
              className="s2 grey8"
              style={{
                position: "absolute",
                margin: "0 auto",
                top: "25%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              No Matching Results
            </span>
          )}

          {dropDownData.length &&
            dropDownData
              ?.sort((a, b) => a[props.sortBy].localeCompare(b[props.sortBy]))
              .map((team) => {
                const userNameSubStr = team[props.dataToShow]
                  .toLowerCase()
                  .trim()
                  .substring(0, props.searchValue.toLowerCase().length);
                const isSearch =
                  userNameSubStr === props.searchValue.toLowerCase();

                const filterFirstLetter = team[props.dataToShow][0];
                if (prev === filterFirstLetter) {
                  duplicateNameFlag = true;
                } else {
                  duplicateNameFlag = false;
                  prev = filterFirstLetter;
                }

                const isFirstLetter =
                  prev.toLowerCase() === props.searchValue.toLowerCase()[0];
                const isItemSelected = props.isSelected(team);

                return (
                  <div key={team.uniqueKey}>
                    {!duplicateNameFlag && !isFirstLetter && isSearch && (
                      <span
                        className="s2 grey6"
                        style={{
                          display: "flex",
                          margin: "16px 0 6px 0",
                          color: "#6C757D",
                        }}
                      >
                        {prev.toUpperCase()}
                      </span>
                    )}

                    {!duplicateNameFlag &&
                      isFirstLetter &&
                      props.isSearchMatch &&
                      isSearch && (
                        <>
                          <span
                            className="s2 grey6"
                            style={{
                              display: "flex",
                              margin: "16px 0 6px 0",
                              color: "#6C757D",
                            }}
                          >
                            {prev.toUpperCase()}
                          </span>
                        </>
                      )}

                    {isSearch && (
                      <div key={team.uniqueKey}>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          onClick={() => props.handleClickCheckbox(team)}
                        >
                          <span className="s2 grey8">
                            {team[props.dataToShow]}
                            {/* {user.first_name.trim() + " " + user.last_name.trim()}
								{" - "} 
								{user.identifiers.map(identifier => identifier.is_primary && identifier.email )} */}
                          </span>
                          <BpCheckbox
                            checked={isItemSelected}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            width={20}
                            height={20}
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              paddingRight: "4px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
        <div
          style={{
            position: "relative",
            bottom: "0px",
            left: 0,
            borderTop: "1px solid #E9ECEF",
          }}
        >
          <div
            style={{
              boxShadow: "0px 6px 16px rgba(163, 163, 163, 0.12)",
              alignItems: "flex-end",
              flexDirection: "column",
              width: "100%",
              maxHeight: "55px",
              height: "55px",
              backgroundColor: "#FFFFFF",
              marginRight: "16px",
              borderBottom: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="d-flex" style={{ marginRight: "16px" }}>
              <span
                className="s2 grey6"
                style={{ marginRight: "20px", cursor: "pointer" }}
                onClick={() => {
                  props.setIsFieldActive(!props.isFieldActive);
                  // handleActionMenu();
                  props.setSelected([]);
                }}
              >
                Cancel
              </span>
              <span
                className="s2 primary-color"
                style={{ cursor: "pointer" }}
                // onClick={() => handleAddMembers()}
              >
                Add Members
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.bottomDropdown) {
    return (
      <div
        className="add-members-team-menu-card"
        style={{
          position: "absolute",
          opacity: props.isFieldActive ? 1 : 0,
          zIndex: props.isFieldActive ? 30 : -50,
          transition: "all 0.4s ease",
          visibility: !props.isFieldActive && "hidden",
          // height: props.isFieldActive ? "396px" : "100px",
          height: "100%",
          minHeight: "240px",
          top: "50px",
          left: "0px",
          width: "100%",
        }}
      >
        {/* <div
          style={{
            position: "absolute",
            padding: "16px 12px 12px 12px",
            zIndex: "20",
            width: "100%",
          }}
        >
          <Field
            inputFieldFullSearchIcon
            placeholder="Search Cohorts"
            value={props.searchValue}
            onChange={(e) => {
              props.setSearchValue(e.target.value); // will need this on parent search
            }}
            style={{
              height: "40px",
              width: "100%",
              borderRight: "1px solid #e9ecef",
              borderRadius: "10px",
              color: "#6C757D",
              paddingLeft: "52px",
              margin: "0",
            }}
          />
        </div> */}
        <div
          style={{
            height: "100%",
            minHeight: "100%",
            margin: "0 16px",
            // marginTop: "60px",
            overflowY: "scroll",
            display: props.isFieldActive ? "block" : "none",
            zIndex: "15",
          }}
        >
          {!props.isSearchMatch && (
            <span
              className="s2 grey8"
              style={{
                position: "absolute",
                margin: "0 auto",
                top: "25%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              No Matching Results
            </span>
          )}

          {props.dropDownData.map((team) => {
            const userNameSubStr = team[props.dataToShow]
              .toLowerCase()
              .trim()
              .substring(0, props.searchValue.toLowerCase().length);
            const isSearch = userNameSubStr === props.searchValue.toLowerCase();

            // const filterFirstLetter = team[props.dataToShow][0];
            // if (prev === filterFirstLetter) {
            //   duplicateNameFlag = true;
            // } else {
            //   duplicateNameFlag = false;
            //   prev = filterFirstLetter;
            // }

            const isFirstLetter =
              prev.toLowerCase() === props.searchValue.toLowerCase()[0];
            const isItemSelected = props.isSelected(team);

            return (
              <div key={team.uniqueKey}>
                {!duplicateNameFlag && !isFirstLetter && isSearch && (
                  <span
                    className="s2 grey6"
                    style={{
                      display: "flex",
                      margin: "16px 0 6px 0",
                      color: "#6C757D",
                    }}
                  >
                    {prev.toUpperCase()}
                  </span>
                )}

                {!duplicateNameFlag &&
                  isFirstLetter &&
                  props.isSearchMatch &&
                  isSearch && (
                    <>
                      <span
                        className="s2 grey6"
                        style={{
                          display: "flex",
                          margin: "16px 0 6px 0",
                          color: "#6C757D",
                        }}
                      >
                        {prev.toUpperCase()}
                      </span>
                    </>
                  )}

                {isSearch && (
                  <div key={team.uniqueKey}>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      onClick={() => props.handleClickCheckbox(team)}
                      style={{ height: "30px", cursor: "pointer" }}
                    >
                      <span className="s2 grey8">
                        {team[props.dataToShow]}
                        {/* {user.first_name.trim() + " " + user.last_name.trim()}
								{" - "} 
								{user.identifiers.map(identifier => identifier.is_primary && identifier.email )} */}
                      </span>
                      {isItemSelected && <CheckIcon style={{ margin: "0" }} />}
                      {/* <BpCheckbox
                        checked={isItemSelected}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        width={20}
                        height={20}
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingRight: "4px",
                        }}
                      /> */}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default DropDown;
