import React from "react";
import styled from "@emotion/styled";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#BFBFBF",
    // backgroundColor:
    //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#9300FF",

    // backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
function LinearProgressLoader(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant={props.variant} value={props.value} />
    </Box>
  );
}
export default LinearProgressLoader;
