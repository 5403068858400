import React, { useEffect, useState } from "react";
import Button from "../../../components/sub-components/Button";
import Field from "../../../components/sub-components/Field";
import arrow_right from "../../../assets/icons/ArrowRight.svg";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import LinkExpired from "./LinkExpired";
import FullPageLoading from "../../../components/Loading/FullPageLoading";
import { useHistory } from "react-router";
import Footer from "../../../components/sub-components/Footer";
import {
  resetPassword,
  setResetPasswordForm,
  setResetPasswordFormErrors,
  validateResetPasswordToken,
} from "../../../redux/slices/authSlice";

export default function ResetPassword(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const form = useSelector((state) => state.authReducer.resetPasswordForm);
  const formErrors = useSelector(
    (state) => state.authReducer.resetPasswordFormErrors,
  );
  const loading = useSelector((state) => state.authReducer.loading);
  const isValidateToken = useSelector(
    (state) => state.authReducer.isResetPasswordTokenValidated,
  );

  const handleChange = (event) => {
    event.stopPropagation();

    const { name, value } = event.target;

    dispatch(
      setResetPasswordForm({
        name,
        value,
      }),
    );
  };

  const handleSubmit = () => {
    validate();
    if (
      form.password &&
      form.confirmPassword &&
      form.password === form.confirmPassword
    ) {
      dispatch(
        resetPassword(
          {
            token: props.match.params.token,
            password: form.password,
          },
          history,
        ),
      );
    }
  };

  const validate = () => {
    if (!form.password) {
      dispatch(
        setResetPasswordFormErrors({
          name: "password",
          value: "Password is Required",
        }),
      );
    }
    if (!form.confirmPassword) {
      dispatch(
        setResetPasswordFormErrors({
          name: "confirmPassword",
          value: "Confirm Password is Required",
        }),
      );
    }
    if (form.password) {
      dispatch(
        setResetPasswordFormErrors({
          name: "password",
          value: false,
        }),
      );
    }
    if (form.confirmPassword) {
      dispatch(
        setResetPasswordFormErrors({
          name: "confirmPassword",
          value: false,
        }),
      );
    }

    if (form.password && form.confirmPassword) {
      if (form.password !== form.confirmPassword) {
        dispatch(
          setResetPasswordFormErrors({
            name: "confirmPassword",
            value: "Password and Confirm Password must be same",
          }),
        );
      }
    }
  };

  useEffect(() => {
    dispatch(validateResetPasswordToken(props.match.params.token));
  }, [props.match.params.token]);

  if (!isValidateToken) {
    return <FullPageLoading />;
  }

  if (isValidateToken === "false") {
    return <LinkExpired />;
  }

  if (isValidateToken) {
    return (
      <div className="signup">
        <Header />

        <div className="signup-fields">
          <div
            className="row"
            style={{ paddingTop: "120px", textAlign: "left" }}
          >
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <span
                  className="head4 grey8"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  Reset Password
                </span>
                <img
                  src={arrow_right}
                  style={{ paddingLeft: "20px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ paddingTop: "70px", textAlign: "left" }}
          >
            <div className="col-lg-12">
              <div>
                <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                  Password
                  <span className="float-right">
                    10 characters minimum. Special Characters
                  </span>
                </div>
                <Field
                  className={`${
                    formErrors.password ? "border border-danger" : ""
                  }`}
                  name="password"
                  showPassword={showPassword}
                  value={form.password}
                  passwordFieldFull
                  onEyeClick={() => {
                    if (form.password) setShowPassword(!showPassword);
                  }}
                  onChange={handleChange}
                />
                <span className="ml-1 text-danger">{formErrors.password}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div
                  className="s1 grey8"
                  style={{ paddingTop: "15px", paddingBottom: "10px" }}
                >
                  Confirm Password
                </div>
                <Field
                  className={`${
                    formErrors.confirmPassword ? "border border-danger" : ""
                  }`}
                  name="confirmPassword"
                  showPassword={showConfirmPassword}
                  value={form.confirmPassword}
                  passwordFieldFull
                  onEyeClick={() => {
                    if (form.confirmPassword)
                      setShowConfirmPassword(!showConfirmPassword);
                  }}
                  onChange={handleChange}
                />
                <span className="ml-1 text-danger">
                  {formErrors.confirmPassword}
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "50px" }}>
            <div className="col-lg-12">
              <Button
                buttonFilledFull
                title="Continue"
                loading={loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
          <Footer fieldFooter />
        </div>
      </div>
    );
  }
}
