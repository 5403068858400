import React, { useEffect, useState } from "react";
import Field from "../../../components/sub-components/Field";
import Button from "../../../components/sub-components/Button";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../components/sub-components/ImageUploader";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import {
  editFormUsers,
  getFormUsers,
  getUsersDepartments,
  getUsersDesignations,
  getUsersEmail,
  setFormData,
} from "../../../redux/slices/settingsSlice";

function MyProfile() {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrlToUpload, setImageUrlToUpload] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentRowImage, setCurrentRowImage] = useState(null);
  const { formData, loading } = useSelector((state) => state.settingsReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { managerData } = useSelector((state) => state.settingsReducer);

  const [organizationItemSelected, setOrganizationItemSelected] =
    useState("My Profile");
  const [organizationData, setOrganizationData] = useState([]);
  const [organizationItemApplied, setOrganizationItemApplied] =
    useState("My Profile");

  // const [anchorEl, setAnchorEl] = useState(null);
  // const [nestedFilterAnchorEl, setNestedFilterAnchorEl] = useState(null);
  // const isNestedFilterationMenuOpen = Boolean(nestedFilterAnchorEl);

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);
  const nestedFilterAnchorEl = React.useRef(null);

  const handleChange = (event) => {
    setDisabled(false);
    dispatch(setFormData({ [event.target.name]: event.target.value }));
  };

  const toggleDepartmentDropDown = (event) => {
    setDisabled(false);
    let cloneFormData = { ...formData };
    cloneFormData = {
      department_id: event.id,
      departments: { id: event.id, name: event.name },
    };
    dispatch(setFormData(cloneFormData));
  };

  const toggleDesignationsDropDown = (event) => {
    setDisabled(false);
    let cloneFormData = { ...formData };
    cloneFormData = {
      designation_id: event.id,
      designations: { id: event.id, name: event.name },
    };
    dispatch(setFormData(cloneFormData));
  };

  const handleSubmit = () => {
    setDisabled(true);
    const cloneFormData = { ...formData };
    cloneFormData["image_url"] =
      formData?.image_url && imageUrlToUpload === null
        ? formData?.image_url
        : imageUrlToUpload;
    dispatch(editFormUsers(user?.userId, cloneFormData));
  };

  const handleNestedFilterationMenuOpen = () => {
    setIsNestedFilterationMenuOpen((prevOpen) => !prevOpen);
  };
  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleClickCheckbox = (item) => {
    // const selectedIndex = organizationItemSelected.findIndex((x) => x === item);

    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(organizationItemSelected, item);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(organizationItemSelected.slice(1));
    // } else if (selectedIndex === organizationItemSelected.length - 1) {
    //   newSelected = newSelected.concat(organizationItemSelected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     organizationItemSelected.slice(0, selectedIndex),
    //     organizationItemSelected.slice(selectedIndex + 1),
    //   );
    // }
    console.log("itemitem", item);
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  const handleApplyOrganizationFilter = () => {
    setOrganizationItemApplied(organizationItemSelected);
    handleNestedFilterationMenuClose();

    // const organizationGlobalFilterClone = organizationGlobalFilter;

    // const userFilters = organizationGlobalFilterClone;

    // if (Object.keys(organizationItemSelected).length === 0) {
    //   userFilters.filters = [];
    // } else if (userFilters) {
    //   userFilters.filters = [
    //     {
    //       col: "group_id",
    //       operator: "==",
    //       value: organizationItemSelected.group_id,
    //     },
    //   ];
    // }
  };

  useEffect(() => {
    dispatch(getFormUsers(user?.userId));
    dispatch(getUsersEmail(user?.userId));
    dispatch(getUsersDepartments());
    dispatch(getUsersDesignations());
  }, [dispatch, user?.userId]);

  useEffect(() => {
    if (imageUrlToUpload !== null) {
      setLoadingImage(false);
      setDisabled(false);
    }
  }, [imageUrlToUpload]);

  return (
    <div>
      <div
        className="page-body-new d-flex justify-content-between"
        style={{ padding: "0px 40px 0px 29px" }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ paddingLeft: "8px" }}>
            <h1 className="head6 grey8" style={{ lineHeight: "30px" }}>
              Personal Information
            </h1>
            <p className="b2 grey6" style={{ lineHeight: "21px" }}>
              Information that is related to your profile and might be synced
              through your identity provider
            </p>
          </div>
          <div className="row" style={{ marginTop: "32px" }}>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                First name
              </div>
              <Field
                style={{ background: "#E9ECEF" }}
                value={formData?.first_name}
                name="first_name"
                onChange={(e) => handleChange(e)}
                inputFieldFull
                disabled={true}
              />
            </div>

            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Last name
              </div>
              <Field
                style={{ background: "#E9ECEF" }}
                value={formData?.last_name}
                name="last_name"
                onChange={(e) => handleChange(e)}
                inputFieldFull
                disabled={true}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Department
              </div>
              <Field
                options={formData?.departmentDropdown}
                name="department_id"
                value={formData?.departments?.name}
                onClick={(e) => toggleDepartmentDropDown(e)}
                objectValueToRender="name"
                inputBackground="#FFFFFF"
                fieldDropdownFull
                readOnly
                // settings
              />
            </div>

            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Job Title
              </div>
              <Field
                options={formData?.designationDropdown}
                name="designation_id"
                value={formData?.designations?.name}
                onClick={(e) => toggleDesignationsDropDown(e)}
                objectValueToRender="name"
                inputBackground="#FFFFFF"
                fieldDropdownFull
                readOnly
                // settings
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Employee ID
              </div>
              <div style={{ width: "100%" }}>
                <Field
                  style={{ background: "#E9ECEF" }}
                  name="employee_id"
                  value={formData?.employee_id}
                  onChange={(e) => handleChange(e)}
                  inputFieldFull
                  disabled={true}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Location
              </div>
              <Field
                style={{ background: "#E9ECEF" }}
                name="address"
                value={formData?.address}
                onChange={(e) => handleChange(e)}
                inputFieldFull
                disabled={true}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Email
              </div>
              <Field
                style={{ background: "#E9ECEF" }}
                className="grey6"
                disabled={true}
                value={formData?.email}
                inputFieldFull
              />
            </div>

            {/* <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Manager
              </div>
              <Field
                style={{ background: "#E9ECEF" }}
                className="grey6"
                disabled={true}
                value={
                  managerData
                    ? managerData.first_name + " " + managerData.last_name
                    : " "
                }
                inputFieldFull
              />
            </div> */}

            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Password
              </div>
              <Field
                style={{ background: "#E9ECEF" }}
                className="grey6"
                disabled={true}
                inputFieldFull
              />
            </div>
          </div>

          <div
            className="s1 primary-color"
            style={{ paddingTop: "25px", width: "160px", marginLeft: "10px" }}
          >
            <Button
              disabled={disabled}
              loading={loading}
              onClick={handleSubmit}
              buttonFilledFull
              title="Edit"
            />
          </div>
        </div>

        <div
          className="d-flex flex-column"
          style={{
            marginTop: "90px",
            width: "300px",
            height: "100%",
            textAlign: "left",
          }}
        >
          <div
            className="s1"
            style={{ marginLeft: "40px", paddingBottom: "10px" }}
          >
            Profile Image
          </div>
          <ImageUploader
            loadingImage={loadingImage}
            setImageUrlToUpload={setImageUrlToUpload}
            setLoadingImage={setLoadingImage}
            postImageURL={"v1/teams/upload"}
            data={formData?.image_url}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            currentRowImage={currentRowImage}
            setCurrentRowImage={setCurrentRowImage}
          />
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
