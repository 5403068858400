import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActiveUsers from "./ActiveUsers";
import DetectedUsers from "./DetectedUsers";
import ArchiveUsers from "./ArchiveUsers";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import Field from "../../../components/sub-components/Field";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import Teams from "../Teams/Teams";
import Cohorts from "./Cohorts";
import Events from "../Events/Events";
import Button from "../../../components/sub-components/Button";
import Departments from "./Departments/Departments";
import {
  handleCreateTeamButtonClick,
  setTeamsTablePage,
  toggleAddMenu,
} from "../../../redux/slices/teamsSlice";
import {
  setTableSelectedRows,
  setUsersTablePage,
  usersMovedToActiveSuccessAlert,
  usersMovedToArchiveSuccessAlert,
} from "../../../redux/slices/usersSlice";
import { toggleSideMenu } from "../../../redux/slices/cohortsSlice";
import {
  resetFormData,
  setCurrentRowEventsSlice,
  setEventsTablePage,
  toggleEventMenuOpen,
  updateCurrentScreen,
} from "../../../redux/slices/eventsSlice";
import { toggleDepartmentsSideMenu } from "../../../redux/slices/departmentSlice";

const initialUserState = { id: 0, name: "All Teams" };
const initialViewState = { id: 1, name: "Teams" };
const initialUserStateTesting = { id: 2, name: "Active Users" };

const People = () => {
  const dispatch = useDispatch();
  const { currentTab } = useSelector((state) => state.usersReducer);
  const { data: teamsData } = useSelector(
    (state) => state.teamsReducer.tableData,
  );
  const { data: lifeEventsData } = useSelector(
    (state) => state.eventsReducer.tableData,
  );

  const { cohortsData } = useSelector(
    (state) => state.cohortsReducer.tableData,
  );

  const [filterColumn, setFilterColumn] = useState("");
  const [filterInputValue, setFilterInputValue] = useState("");
  const [filters, setFilters] = useState({});
  const [userApplied, setUserApplied] = useState({ id: 0, name: "All Teams" });
  const [currentViewApplied, setCurrentViewApplied] =
    useState(initialViewState);
  const [organizationItemSelected, setOrganizationItemSelected] =
    useState(initialViewState);

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    useState(false);
  const [isCurrentViewMenuOpen, setIsCurrentViewMenuOpen] = useState(false);

  const nestedFilterAnchorEl = useRef(null);
  const currentViewAnchorEl = useRef(null);

  useEffect(() => {
    restartFilterationState();
  }, [currentTab]);

  const restartFilterationState = () => {
    setFilterColumn("");
    setFilterInputValue("");
    setFilters({});
    dispatch(setUsersTablePage(1));
    dispatch(setTableSelectedRows([]));
    // setUserSelected("Active Users");
    if (currentTab !== "2") {
      dispatch(usersMovedToActiveSuccessAlert());
    }

    if (currentTab !== "1") {
      dispatch(usersMovedToArchiveSuccessAlert());
    }
  };

  const handleUserTypeApply = (obj) => {
    setUserApplied(obj);
    if (organizationItemSelected.name === "Teams") {
      dispatch(setTeamsTablePage(1));
    }
    if (organizationItemSelected.name === "Life Events") {
      dispatch(setEventsTablePage(1));
    }
    setIsNestedFilterationMenuOpen(false);
  };

  const handleCurrentViewFilterApply = (item) => {
    if (item.name === "Users") {
      setUserApplied(initialUserStateTesting);
    } else if (item.name === "Life Events") {
      setUserApplied({ id: 0, name: "All Life Events" });
    } else if (item.name === "Cohorts") {
      setUserApplied({ id: 0, name: "All Cohorts" });
    } else {
      setUserApplied(initialUserState);
    }
    setOrganizationItemSelected(item);
    setCurrentViewApplied(item);
    setIsCurrentViewMenuOpen(false);
  };

  const handleCreateTeamButton = () => {
    dispatch(handleCreateTeamButtonClick());
    dispatch(toggleAddMenu());
  };

  const handleEventsSideMenuOpen = () => {
    dispatch(resetFormData());
    dispatch(setCurrentRowEventsSlice({})); //do we really need to do this?
    dispatch(updateCurrentScreen(1));
    dispatch(toggleEventMenuOpen());
  };

  const handleCohortsSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  const handleDepartmentSideMenu = () => {
    dispatch(toggleDepartmentsSideMenu());
  };

  const renderView = () => {
    switch (currentViewApplied.name) {
      case "Teams":
        return (
          <Teams
            inputValue={filterInputValue}
            filters={filters}
            teamAppliedFilter={
              JSON.stringify(userApplied) === JSON.stringify(initialUserState)
                ? []
                : userApplied
            }
          />
        );
      case "Users":
        switch (userApplied.name) {
          case "Active Users":
            return (
              <ActiveUsers inputValue={filterInputValue} filters={filters} />
            );
          case "Archived Users":
            return (
              <ArchiveUsers inputValue={filterInputValue} filters={filters} />
            );
          case "Detected Users":
            return (
              <DetectedUsers inputValue={filterInputValue} filters={filters} />
            );
          default:
            return null;
        }
      case "Cohorts":
        return <Cohorts />;
      case "Life Events":
        return <Events />;
      case "Departments":
        return <Departments inputValue={filterInputValue} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ padding: "0 44px", marginBottom: "50px" }}>
        <div className="d-flex align-items-center justify-content-between">
          <PopperDropdown
            anchorRef={currentViewAnchorEl}
            isDropdownOpen={isCurrentViewMenuOpen}
            handleToggle={() => setIsCurrentViewMenuOpen((prev) => !prev)}
            handleClose={() => setIsCurrentViewMenuOpen(false)}
            dropdownDisplayText="Users"
            customRenderFieldChildren={
              <div
                className="d-flex align-items-center head4-semi-bold"
                style={{ cursor: "pointer" }}
              >
                {currentViewApplied.name}
                <CaretDown stroke="black" strokeWidth="3" className="ml-2" />
              </div>
            }
            dropdownWithSearchAndCheckbox
            dropdownData={[
              { id: 1, name: "Teams" },
              { id: 2, name: "Users" },
              { id: 3, name: "Cohorts" },
              { id: 4, name: "Life Events" },
              { id: 5, name: "Departments" },
            ]}
            handleApply={handleCurrentViewFilterApply}
            dropdownItemsSelected={organizationItemSelected}
            keyToAccess="name"
          />
          {["Teams", "Cohorts", "Life Events", "Departments"].includes(
            currentViewApplied.name,
          ) && (
            <Button
              buttonAddtoTeam
              title={currentViewApplied.name.slice(0, -1)}
              onClick={
                currentViewApplied.name === "Teams"
                  ? handleCreateTeamButton
                  : currentViewApplied.name === "Cohorts"
                    ? handleCohortsSideMenu
                    : currentViewApplied.name === "Life Events"
                      ? handleEventsSideMenuOpen
                      : handleDepartmentSideMenu
              }
              style={{
                boxShadow: "none",
                background: "#9300FF",
                padding: "0 36px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
      </div>
      <div className="page-body-new">
        <div
          style={{
            border: "1px solid #F5F5F5",
            paddingLeft: "27px",
            paddingRight: "27px",
            borderRadius: "10px",
            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
          className="w-100"
        >
          <div
            className="d-flex align-items-center"
            style={{ padding: "20px 0" }}
          >
            {currentViewApplied.name !== "Departments" && (
              <PopperDropdown
                anchorRef={nestedFilterAnchorEl}
                isDropdownOpen={isNestedFilterationMenuOpen}
                handleToggle={() =>
                  setIsNestedFilterationMenuOpen((prev) => !prev)
                }
                handleClose={() => setIsNestedFilterationMenuOpen(false)}
                dropdownDisplayText="Users"
                customRenderFieldChildren={
                  <div
                    className="d-flex align-items-center body1"
                    style={{
                      cursor: "pointer",
                      border: "1px solid #9300FF",
                      padding: "10px 12px",
                      borderRadius: "10px",
                      marginRight: "16px",
                    }}
                  >
                    {userApplied.name}
                    <CaretDown
                      stroke="black"
                      strokeWidth="2"
                      className="ml-2"
                    />
                  </div>
                }
                dropdownWithSearchAndCheckbox
                dropdownData={
                  currentViewApplied.name === "Teams"
                    ? [{ id: 0, name: "All Teams" }, ...teamsData]
                    : currentViewApplied.name === "Life Events"
                      ? lifeEventsData
                      : currentViewApplied.name === "Cohorts"
                        ? cohortsData
                        : [
                            { id: 1, name: "Archived Users" },
                            { id: 2, name: "Active Users" },
                            { id: 3, name: "Detected Users" },
                          ]
                }
                handleApply={handleUserTypeApply}
                dropdownItemsSelected={userApplied}
                searchTerm={filterInputValue}
                searchBy="name"
                keyToAccess="name"
              />
            )}
            <Field
              inputFieldFullSearchIcon
              placeholder={`Search ${filterColumn}`}
              value={filterInputValue}
              onChange={(e) => setFilterInputValue(e.target.value)}
              style={{
                // width: "100%",
                height: "40px",
                paddingLeft: "52px",
                borderRight: "1px solid #e9ecef",
                borderRadius: "10px",
                background: "white",
              }}
            />
          </div>
          {renderView()}
        </div>
      </div>
    </>
  );
};

export default People;
