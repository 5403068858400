import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CreateEventsNew from "./CreateEventsNew";
import {
  getAllLifeEventsData,
  handleCreateEventFailure,
  handleCreateEventSuccess,
  handleUpdateEventRequest,
} from "../../../redux/slices/eventsSlice";

export default function EventsMenu() {
  const dispatch = useDispatch();

  const {
    eventCreationSuccess,
    eventCreationFailure,
    updateEventSuccess,
    updateEventFailure,
  } = useSelector((state) => state.eventsReducer);

  useEffect(() => {
    dispatch(getAllLifeEventsData());
  }, []);

  useEffect(() => {
    if (eventCreationSuccess) {
      setTimeout(() => {
        dispatch(handleCreateEventSuccess());
      }, 4000);
    }

    if (eventCreationFailure) {
      setTimeout(() => {
        dispatch(handleCreateEventFailure());
      }, 4000);
    }
  }, [eventCreationSuccess, eventCreationFailure]);

  useEffect(() => {
    if (updateEventSuccess) {
      setTimeout(() => {
        dispatch(handleUpdateEventRequest());
      }, 4000);
    }

    if (updateEventFailure) {
      setTimeout(() => {
        dispatch(handleUpdateEventRequest());
      }, 4000);
    }
  }, [updateEventSuccess, updateEventFailure]);

  return <CreateEventsNew />;
}
