import React from "react";
import { ReactComponent as Logo } from "../../../assets/icons/Logo/Crewnetics.svg";

export default function Header() {
  return (
    <div className="row mt-5">
      <div className="col-lg-12">
        <Logo className="header-logo" />
      </div>
    </div>
  );
}
