import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import AddCard from "../SideMenus/AddCard";
import Button from "../sub-components/Button";
import { StripeApiKey } from "../../utilities/Constants";
import { useDispatch, useSelector } from "react-redux";
import PaymentCards from "../SideMenus/PaymentCards";
import BackgroundBlur from "../sub-components/Blur";
import DelayedSkeletonLoader from "../sub-components/DelayedSkeletonLoader";
import {
  handleSideMenu,
  listStripePaymentMethods,
  setDefaultPaymentMethod,
  setIsPaymentMethodExpired,
  togglePaymentMethodExpiredSideMenu,
} from "../../redux/slices/billingSlice";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

const PaymentMethod = (props) => {
  const { mediaQuery, getScreenWidth } = props;

  const stripePromise = loadStripe(StripeApiKey);

  const {
    isSideMenu,
    stripeCardsList,
    IsAddNewCard,
    defaultPaymentMethod,
    isPaymentMethodExpired,
    isPaymentMethodExpiredSideMenu,
    paymentMethodLoader,
  } = useSelector((state) => state.billingsReducer);
  const userProfile = useSelector((state) => state.authReducer.userProfile);

  const getDefaultPaymentMethod = () => {
    const { default_payment_method } =
      stripeCardsList[0].customer.invoice_settings;

    const defaultCard = stripeCardsList.find(
      ({ id }) => id === default_payment_method,
    );
    dispatch(setDefaultPaymentMethod(defaultCard));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listStripePaymentMethods());
  }, []);

  // CARD EXPIRED LOGIC
  useEffect(() => {
    if (defaultPaymentMethod) {
      const expireMonth = defaultPaymentMethod.card.exp_month;
      const expireYear = defaultPaymentMethod.card.exp_year;
      const thisMonth = new Date().toLocaleString().split(",")[0].split("/")[0];
      const thisYear = new Date().toLocaleString().split(",")[0].split("/")[2];

      if (expireYear < thisYear) {
        dispatch(setIsPaymentMethodExpired());
        dispatch(togglePaymentMethodExpiredSideMenu());
      } else if (expireYear <= thisYear && expireMonth <= thisMonth) {
        dispatch(setIsPaymentMethodExpired());
        dispatch(togglePaymentMethodExpiredSideMenu());
      }
    }
  }, [defaultPaymentMethod]);

  useEffect(() => {
    if (stripeCardsList?.length > 0 && defaultPaymentMethod === "") {
      getDefaultPaymentMethod();
    }
  }, [stripeCardsList]);

  return (
    <div
      style={{ marginTop: getScreenWidth() === "DesktopFull" && "32px" }}
      className="col-lg-12 col-sm-6"
    >
      {stripeCardsList.length === 0 || IsAddNewCard ? (
        <Elements stripe={stripePromise}>
          <AddCard
            // handleSideMenuClose={handleSideMenuClose}
            sideMenu={isSideMenu}
          />
        </Elements>
      ) : (
        <PaymentCards />
      )}

      {isPaymentMethodExpired && (
        <>
          <Elements stripe={stripePromise}>
            <AddCard
              // handleSideMenuClose={handleSideMenuClose}
              sideMenu={isSideMenu}
            />
          </Elements>
          {isPaymentMethodExpiredSideMenu && <BackgroundBlur />}
        </>
      )}
      <span className="head6 grey8">Payment Method</span>

      <div
        className="d-flex flex-column align-items-center"
        style={{
          // height: mediaQuery.width < "992" ? "356px" : "312px",
          marginTop: "11px",
          borderRadius: "10px",
          background: "#f8f8f8",
          padding:
            stripeCardsList.length === 0
              ? "24px"
              : getScreenWidth() === "DesktopFull"
                ? "20px 32px 24px 24px"
                : "24px",
          minWidth: "220px",
          justifyContent: stripeCardsList.length === 0 ? "center" : "",
          minHeight: "224px",
        }}
      >
        {paymentMethodLoader && stripeCardsList.length === 0 ? (
          <div className="row d-flex mt-2 w-100" style={{ display: "flex" }}>
            <div style={{ display: "flex", flex: 1, maxWidth: "242px" }}>
              <DelayedSkeletonLoader type="card" />
            </div>

            <div style={{ display: "flex", flex: 1, paddingLeft: "24px" }}>
              <div style={{ width: "100%", paddingTop: "20px" }}>
                <DelayedSkeletonLoader
                  type="text-md"
                  style={{
                    width: "70%",
                    maxWidth: "320px",
                    marginBottom: "20px",
                  }}
                />
                <DelayedSkeletonLoader
                  type="text-md"
                  style={{
                    width: "70%",
                    maxWidth: "320px",
                    marginBottom: "20px",
                  }}
                />
                <DelayedSkeletonLoader
                  type="text-md"
                  style={{
                    width: "70%",
                    maxWidth: "320px",
                    marginBottom: "20px",
                  }}
                />
                <DelayedSkeletonLoader
                  type="text-md"
                  style={{
                    width: "90%",
                    maxWidth: "320px",
                    marginBottom: "20px",
                  }}
                />
              </div>
            </div>
          </div>
        ) : !paymentMethodLoader && stripeCardsList.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="s1 grey8 mb-2">No Payment Method Detected</span>
            <Button
              onClick={() => dispatch(handleSideMenu())}
              title="Add Card"
              typography={"b0-medium grey1"}
              buttonFilledFull
              style={{
                width: " 108px",
                height: "40px",
                marginTop: "5px",
                fontWeight: "400",
              }}
            />
          </div>
        ) : (
          <>
            <div className="row d-flex justify-content-between w-100">
              {getScreenWidth() === "DesktopFull" ? (
                <div>
                  <span className="head6" style={{ color: "#000000" }}>
                    Cards
                  </span>
                </div>
              ) : (
                <span className="b2 grey6">Billing Details</span>
              )}
              <div className="d-flex align-items-center">
                <span className="b2 primary-color">Manage</span>
              </div>
            </div>

            <div className="row d-flex mt-2 w-100">
              <div>
                <Card
                  number={`**** **** **** ${defaultPaymentMethod?.card?.last4}`}
                  name={`${userProfile.first_name} ${userProfile.last_name}`}
                  expiry={`${defaultPaymentMethod?.card?.exp_month} ${defaultPaymentMethod?.card?.exp_year}`}
                  formData={null}
                  preview
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-end w-100"
              style={{
                marginTop: getScreenWidth() !== "DesktopFull" && "32px",
              }}
            >
              <Button
                onClick={() =>
                  isPaymentMethodExpired
                    ? dispatch(togglePaymentMethodExpiredSideMenu())
                    : dispatch(handleSideMenu())
                }
                title="Add New Card"
                className="b2 grey0"
                buttonFilledFull
                typography="s1 grey1"
                style={{
                  width: getScreenWidth() === "DesktopFull" ? "180px" : "100%",
                  height: "40px",
                  // marginTop: "5px",
                  fontWeight: "400",
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentMethod;
