import React from "react";
import { useRouteMatch } from "react-router-dom";
import Header from "../components/sub-components/Header";
import { getExcludedPathsForHeader } from "../utilities/Utils";

export const HeaderDash = ({ handleMainMenu }) => {
  const isExcludedMatch = useRouteMatch({
    path: getExcludedPathsForHeader(),
    exact: true,
  });

  const pathName = window.location.pathname;

  const getHeader = () => {
    if (["/billing", "/cohorts"].includes(pathName)) {
      return "header0";
    }
    if (["/teams"].includes(pathName)) {
      return "TeamHeader";
    }
    // the new design
    if (["/dashboard"].includes(pathName)) {
      // pathName = `My ${window.location.pathname}`;
      return "newHeader";
    }
    if ("/users" === pathName) {
      return "header3";
    }
    // if ("/settings" === pathName) {
    //   return "header4";
    // }
    if ("/events" === pathName) {
      return "header5";
    }
    return "404";
  };
  const value = getHeader();

  return isExcludedMatch ? null : (
    <>
      {pathName === "/users" ? (
        // <Header
        //   headerType={"header3"}
        //   title={pathName}
        //   tab1="Active Users"
        //   tab2="Archived Users"
        //   tab3="Detected Users"
        //   style={{ height: "152px", zIndex: "50" }}
        //   handleMainMenu={handleMainMenu}
        //   users
        // />
        <></>
      ) : pathName === "/events" ? (
        <Header
          headerType={"header5"}
          title={pathName}
          tab1="My Events"
          style={{ height: "152px", zIndex: "50" }}
          handleMainMenu={handleMainMenu}
        />
      ) : // : pathName === "/settings" ? (
      //   <Header
      //     headerType={"header4"}
      //     title={pathName}
      //     tab1="My Profile"
      //     tab2="Company"
      //     tab3="Notifications"
      //     tab4="Billing"
      //     style={{ height: "152px", zIndex: "50" }}
      //     handleMainMenu={handleMainMenu}
      //   />
      // )
      pathName === "/dashboard" ? (
        <></>
      ) : // <Header
      //   headerType={value}
      //   title={"/My Dashboard"}
      //   handleMainMenu={handleMainMenu}
      // />
      value !== "404" ? (
        <Header
          headerType={value}
          title={pathName}
          handleMainMenu={handleMainMenu}
          // temp style
          style={{
            filter: "drop-shadow(0px 6px 16px rgba(163, 163, 163, 0.12))",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export const HeaderNew = () => {
  // const isExcludedMatch = useRouteMatch({
  //   path: [
  //     "/",
  //     "/login",
  //     "/signup",
  //     "/forgot-password",
  //     "/activation",
  //     "/activation/:id",
  //     "/confirm-plan",
  //     "/join-organization",
  //   ],
  //   exact: true,
  // });

  const pathName = window.location.pathname;

  return (
    <>
      <Header
        headerType={"newHeader"}
        title={pathName === "/dashboard" ? "/Analytics" : pathName}
        //  handleMainMenu={handleMainMenu}
      />
    </>
  );
};

// export default HeaderDash;
