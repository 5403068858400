import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  Chip,
  ClickAwayListener,
  Avatar,
} from "@mui/material";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import BpCheckbox from "../../components/sub-components/Checkbox";
import { ReactComponent as MinusCheckbox } from "../../assets/icons/Views/Objectives/MinusCheckbox.svg";
import { ReactComponent as CheckboxIcon } from "../../assets/icons/Views/Objectives/Checkbox.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/Views/Objectives/X.svg";
import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";

const TreeViewSelect = ({
  nodes,
  label,
  styleProps,
  error,
  helperText,
  appliedItems,
  setAppliedItems,
  disabled,
  showSingleItem,
  objectivesChecked,
}) => {
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleClick = () => {
    setOpenDropdown((prev) => !prev);
  };

  const handleClose = () => {
    setOpenDropdown(false);
  };

  const handleCheck = (newChecked, targetNode) => {
    if (showSingleItem) {
      // For single select, only allow one item to be checked at a time
      setChecked([targetNode.value]);
      setSelectedItems([{ value: targetNode.value, label: targetNode.label }]);
    } else {
      // For multi-select, proceed as usual
      setChecked(newChecked);
      const selectedNodes = nodesToItems(nodes, newChecked);
      setSelectedItems(selectedNodes);
    }
  };

  const handleApply = () => {
    handleClose();
    setAppliedItems(selectedItems);
  };

  const handleDelete = (itemToDelete) => {
    const newChecked = checked.filter((value) => value !== itemToDelete.value);
    setChecked(newChecked);
    handleCheck(newChecked);
    const selectedNodes = nodesToItems(nodes, newChecked);
    setAppliedItems(selectedNodes);
  };

  const getAllNodeValues = (nodes) => {
    const values = [];
    const addValues = (nodes) => {
      nodes.forEach((node) => {
        if (node.children) {
          // If the node has children, recursively add its children's values instead
          addValues(node.children);
        } else {
          // If no children, it's a leaf node, add its value
          values.push(node.value);
        }
      });
    };
    addValues(nodes);
    return values;
  };

  const handleSelectAll = () => {
    const allValues = getAllNodeValues(nodes);
    setChecked(allValues);
    const allSelectedItems = nodesToItems(nodes, allValues);
    setSelectedItems(allSelectedItems);
  };

  const handleReset = () => {
    setChecked([]);
    setSelectedItems([]);
  };

  const nodesToItems = (nodes, checkedValues) => {
    const items = [];
    const findItems = (nodes) => {
      nodes.forEach((node) => {
        if (checkedValues.includes(node.value)) {
          items.push({ value: node.value, label: node.label });
        }
        if (node.children) {
          findItems(node.children);
        }
      });
    };
    findItems(nodes);
    return items;
  };

  useEffect(() => {
    if (objectivesChecked?.length) {
      setChecked(objectivesChecked);
    }
  }, [objectivesChecked]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <FormControl
        variant="outlined"
        fullWidth
        style={styleProps}
        error={error}
      >
        <TextField
          fullWidth
          label={label}
          value=""
          onClick={!disabled ? handleClick : null}
          className="mt-2"
          sx={{
            "& .MuiInputBase-input": {
              // height: "48px",
              cursor: "pointer",
            },
            "& .MuiInputBase-root": {
              borderRadius: "10px",
              height: "48px",
              cursor: "pointer",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#DEDEDE",
              "&:hover": {
                borderColor: "#b1b1b1",
              },
              "&.Mui-focused": {
                borderColor: "#b1b1b1",
              },
            },
            width: "100%",
          }}
          disabled={disabled}
          InputProps={{
            readOnly: true,
            startAdornment: !showSingleItem ? (
              appliedItems?.map((item) => (
                <Chip
                  key={item.value}
                  label={item.label}
                  // Changed to handle click on the whole chip for delete
                  className="body2 mr-2"
                  onClick={(e) => e.stopPropagation()}
                  avatar={
                    <Avatar
                      style={{
                        width: 24,
                        height: 32,
                        backgroundColor: "transparent",
                        cursor: disabled ? "not-allowed" : "pointer",
                      }}
                      onClick={() => !disabled && handleDelete(item)}
                    >
                      <CloseIcon
                        style={{
                          color: "#1976d2",
                        }}
                      />
                      <div
                        style={{
                          borderRight: "1px solid #BAD9FB",
                          height: "100%",
                          marginLeft: "6px",
                        }}
                      />
                    </Avatar>
                  }
                  style={{
                    marginRight: "5px",
                    height: "30px",
                    borderRadius: "6px",
                    backgroundColor: "#EDF5FE", // Light blue background
                    border: "1px solid #90caf9", // Lighter blue border
                    color: "#317FF6", // Dark blue text
                    zIndex: 1,
                    cursor: disabled ? "not-allowed" : "pointer",
                  }}
                />
              ))
            ) : (
              <span className="w-100">
                {appliedItems?.length > 0 && appliedItems[0]?.label}
              </span>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <CaretDown stroke="#343A40" style={{ transition: "" }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: openDropdown ? "100%" : "0", // Use ternary to toggle height
            maxHeight: "300px",
            border: openDropdown ? "1px solid #E4E2E4" : "none",
            overflow: "hidden", // Contains the children's overflow
          }}
        >
          <div
            style={{
              flex: "1", // Makes this div take up all available space
              overflowY: "auto", // Only this part scrolls
            }}
          >
            <CheckboxTree
              nodes={nodes}
              checked={checked}
              expanded={expanded}
              onCheck={handleCheck}
              onExpand={setExpanded}
              expandDisabled={disabled}
              disabled={disabled}
              icons={{
                check: (
                  <BpCheckbox
                    checked={true}
                    indeterminate={false}
                    width={20}
                    height={20}
                    style={{ padding: 0 }}
                  />
                ),
                uncheck: (
                  <CheckboxIcon style={{ width: "16px", height: "16px" }} />
                ),
                halfCheck: (
                  <MinusCheckbox
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                  />
                ),
                expandClose: (
                  <span className="rct-icon rct-icon-expand-close" />
                ),
                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
              }}
            />
          </div>
          <div
            className="d-flex justify-content-between"
            style={{
              borderTop: "1px solid #E4E2E4",
              padding: "12px 16px",
            }}
          >
            <div className="d-flex">
              <div
                className="body2-medium primary-color"
                onClick={handleSelectAll}
                style={{ cursor: "pointer", marginRight: "16px" }}
              >
                Select All
              </div>
              <div
                className="body2-medium primary-color"
                onClick={handleReset}
                style={{ cursor: "pointer" }}
              >
                Reset
              </div>
            </div>
            <div className="d-flex">
              <div
                onClick={handleApply}
                className="body2-medium primary-color"
                style={{ cursor: "pointer" }}
              >
                Apply
              </div>
            </div>
          </div>
        </div>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </ClickAwayListener>
  );
};

export default TreeViewSelect;
