import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { showSnackbar } from "./authSlice";

const initialState = {
  currentTab: "1",
  usersMovedToActiveSuccess: false,
  cohortsData: [],
  isMultipleRowsSelected: false,
  attributeSchema: {},
  loading: false,
  tableData: {
    cohortsData: [],
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
  },
  sideMenu: {
    isSideMenuOpen: false,
    tableData: {
      usersData: [],
      rowsData: [],
      rowsPerPage: 5,
      page: 1,
      selected: [],
    },
  },
};

const cohortsSlice = createSlice({
  name: "cohorts",
  initialState,
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setGroupsData(state, action) {
      state.tableData.cohortsData = action.payload;
    },
    setRowsData(state, action) {
      state.tableData.rowsData = action.payload;
    },
    setTablePage(state, action) {
      state.tableData.page = action.payload;
    },
    setSideMenuTablePage(state, action) {
      state.sideMenu.tableData.page = action.payload;
    },
    setTableSelectedRows(state, action) {
      state.tableData.selected = action.payload;
    },
    setMultipleSelected(state, action) {
      state.isMultipleRowsSelected = action.payload;
    },
    setIsLoading(state) {
      state.loading = !state.loading;
    },
    toggleSideMenu(state) {
      state.sideMenu.isSideMenuOpen = !state.sideMenu.isSideMenuOpen;
    },
    handleNewCohortList(state, action) {
      state.tableData.cohortsData.push(action.payload);
    },
    handleCohortsData(state, action) {
      state.tableData.cohortsData = action.payload;
    },
    setCurrentCohortData(state, action) {
      state.sideMenu.tableData.usersData = action.payload;
    },
    setSelectedCohorts(state, action) {
      state.sideMenu.tableData.selected = action.payload;
    },
    setAttributeSchema(state, action) {
      state.attributeSchema = action.payload;
    },
  },
});

export const getAttributeSchema = () => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: "v1/cohorts/attribute/schema",
        method: "Get",
      });

      dispatch(setAttributeSchema(request.data));
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const handleNewCohort = (data) => {
  return async (dispatch) => {
    try {
      // dispatch({
      // 	type: SET_IS_LOADING,
      // });

      const request = await secure_instance.request({
        url: "v1/cohorts/",
        method: "Post",
        data: data,
      });

      dispatch(handleNewCohortList(request.data));
      dispatch(
        showSnackbar({
          message: "Cohort created successfully",
          severity: "success",
        }),
      );
      // dispatch({
      // 	type: SET_IS_LOADING,
      // });

      // dispatch(toggleSideMenu());
      // return request.data;
      // setIntegrationsData(response);
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      dispatch(
        showSnackbar({
          message: "Failed to create Cohort",
          severity: "error",
        }),
      );
      console.log("error", e);
    }
  };
};

export const handleDeleteCohortSlice = (ids) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: "/v1/cohorts/",
        method: "DELETE",
        data: ids,
      });
      dispatch(
        showSnackbar({
          message: "Cohort deleted successfully",
          severity: "success",
        }),
      );
    } catch (e) {
      dispatch(
        showSnackbar({
          message: "Failed to delete Cohort",
          severity: "error",
        }),
      );
      console.log(e);
    }
  };
};

export const handleEditCohortSlice = (currentId, cohortObj, cohortsData) => {
  return async (dispatch) => {
    dispatch(setIsLoading());
    try {
      const request = await secure_instance.request({
        url: `/v1/cohorts/${currentId}`,
        method: "PATCH",
        data: cohortObj,
      });

      const newCohorts = cohortsData.map((cohort) => {
        if (cohort.id === currentId) {
          return request.data;
        }

        return cohort;
      });

      dispatch(handleCohortsData(newCohorts));
      dispatch(setIsLoading());
      dispatch(toggleSideMenu());
      dispatch(
        showSnackbar({
          message: "Cohort edited successfully",
          severity: "success",
        }),
      );
    } catch (e) {
      dispatch(
        showSnackbar({
          message: "Failed to edit Cohort",
          severity: "error",
        }),
      );
      console.log(e);
    }
  };
};

// export const handleMoveToActiveUsers = (userIds,filteredArchiveUsers) => {
// 	return async (dispatch) => {
// 		try {
// 			await secure_instance.request({
// 				url: "v1/user_profile/unarchive/users",
// 				method: "Post",
// 				data: userIds,
// 			});

// 			dispatch({
// 				type: REMOVE_FROM_ARCHIVE_USERS,
// 				payload: filteredArchiveUsers,
// 			});
// 			dispatch({
// 				type: USERS_MOVED_TO_ACTIVE_SUCCESS,
// 			});
// 		} catch (e) {
// 			// --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
// 			console.log("error", e);
// 		}
// 	};
// };

// export const handleUndoArchiveUsers = (userIds,filteredArchiveUsers) => {
// 	return async (dispatch) => {
// 		try {
// 			await secure_instance.request({
// 				url: "v1/user_profile/archive/users",
// 				method: "Post",
// 				data: userIds,
// 			});

// 			dispatch({
// 				type: UNDO_REMOVED_FROM_ARCHIVE_USERS,
// 				payload: filteredArchiveUsers,
// 			});
// 			console.log("filteredArchiveUsers INSIDE ACTIONS",filteredArchiveUsers);
// 			dispatch({
// 				type: USERS_MOVED_TO_ACTIVE_SUCCESS,
// 			});
// 		} catch (e) {
// 			// --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
// 			console.log("error", e);
// 		}
// 	};
// };

export const {
  setCurrentTab,
  setGroupsData,
  setRowsData,
  setTablePage,
  setSideMenuTablePage,
  setTableSelectedRows,
  setMultipleSelected,
  setIsLoading,
  toggleSideMenu,
  handleNewCohortList,
  handleCohortsData,
  setCurrentCohortData,
  setSelectedCohorts,
  setAttributeSchema,
} = cohortsSlice.actions;

export default cohortsSlice.reducer;
