import React from "react";
import { ReactComponent as Process } from "../../../assets/icons/Process-signup.svg";
import { ReactComponent as Arrow_right } from "../../../assets/icons/ArrowRight.svg";
import Field from "../../../components/sub-components/Field";
import Button from "../../../components/sub-components/Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Login/Header";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import CircularStatic from "../../../components/sub-components/CircularProcess";
import Footer from "../../../components/sub-components/Footer";
import {
  handleRegisterCallFormChange,
  handleRegisterSliceCall,
  toggleConfirmRegisterEyeClick,
  toggleRegisterEyeClick,
  validateRegisterForm,
} from "../../../redux/slices/authSlice";
export default function Signup() {
  const dispatch = useDispatch();

  const form = useSelector((state) => state.authReducer.registerForm);
  const errors = useSelector((state) => state.authReducer.registerFormErrors);
  const loading = useSelector((state) => state.authReducer.loading);

  const mediaQuery = useWindowDimensions();
  const handleChange = (target) => {
    dispatch(handleRegisterCallFormChange(target));
  };

  const handleRegisterEyeClick = () => {
    if (form.password) {
      dispatch(toggleRegisterEyeClick());
    }
  };

  const handleConfirmRegisterEyeClick = () => {
    if (form.confirmPassword) {
      dispatch(toggleConfirmRegisterEyeClick());
    }
  };

  const handleSubmit = () => {
    if (!form.firstName) {
      dispatch(
        validateRegisterForm({
          name: "firstName",
          value: "First name is required",
        }),
      );
    }
    if (!form.lastName) {
      dispatch(
        validateRegisterForm({
          name: "lastName",
          value: "Last name is required",
        }),
      );
    }
    if (!form.email) {
      dispatch(
        validateRegisterForm({
          name: "email",
          value: "Email is required",
        }),
      );
    }
    if (!form.password) {
      dispatch(
        validateRegisterForm({
          name: "password",
          value: "Password is required",
        }),
      );
    }
    if (!form.confirmPassword) {
      dispatch(
        validateRegisterForm({
          name: "confirmPassword",
          value: "Confirm password is required",
        }),
      );
    }
    if (!form.companyName) {
      dispatch(
        validateRegisterForm({
          name: "companyName",
          value: "Company name is required",
        }),
      );
    }
    if (form.firstName) {
      dispatch(
        validateRegisterForm({
          name: "firstName",
          value: false,
        }),
      );
    }
    if (form.lastName) {
      dispatch(
        validateRegisterForm({
          name: "lastName",
          value: false,
        }),
      );
    }
    if (form.email) {
      dispatch(
        validateRegisterForm({
          name: "email",
          value: false,
        }),
      );
    }
    if (form.password) {
      dispatch(
        validateRegisterForm({
          name: "password",
          value: false,
        }),
      );
    }
    if (form.confirmPassword) {
      dispatch(
        validateRegisterForm({
          name: "confirmPassword",
          value: false,
        }),
      );
    }
    if (form.companyName) {
      dispatch(
        validateRegisterForm({
          name: "companyName",
          value: false,
        }),
      );
    }
    if (form.password && form.confirmPassword) {
      if (form.password !== form.confirmPassword) {
        dispatch(
          validateRegisterForm({
            name: "confirmPassword",
            value: "Confirm password must be same as password",
          }),
        );
      }
    }

    if (
      form.firstName &&
      form.lastName &&
      form.companyName &&
      form.email &&
      form.confirmPassword &&
      form.password &&
      form.password === form.confirmPassword
    ) {
      dispatch(handleRegisterSliceCall(form));
    }
  };

  return (
    <>
      <div className="signup">
        <Header />

        {/* ------------------MOBILE------------------ */}
        {mediaQuery.width > 425 ? (
          <div className="row">
            <div className="col-lg-12">
              <Process style={{ marginTop: "60px" }} className="process-logo" />
            </div>
          </div>
        ) : (
          <div
            className="row mt-5"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CircularStatic currentStep={1} totalSteps={3} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                marginLeft: "10px",
              }}
            >
              <h6 style={{ marginBottom: "0" }}>Sign Up</h6>
              <span
                className="b0-medium grey8"
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.4px",
                }}
              >
                Next Step: Confirm Plan
              </span>
            </div>
          </div>
        )}

        <div className="signup-fields">
          <div className="row signup-header">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <span
                  className={mediaQuery.width > 768 ? "head4" : "head5"}
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  Create Account
                </span>
                {mediaQuery.width > 768 && (
                  <Arrow_right
                    style={{ marginLeft: "20px", cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className="row"
            style={{ paddingTop: "40px", textAlign: "left" }}
          >
            <div className="col-lg-12">
              <div>
                <div className="s1" style={{ paddingBottom: "10px" }}>
                  First Name <span className="text-danger">*</span>
                </div>
                <Field
                  inputFieldFull
                  required
                  name="firstName"
                  value={form.firstName}
                  onChange={(e) => handleChange(e.target)}
                  onError={(e) => console.log(e)}
                  style={{
                    border: errors.firstName ? "1px solid #ff0000" : "",
                  }}
                />
                <span className="text-danger">{errors.firstName}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div
                  className="s1"
                  style={{ paddingTop: "25px", paddingBottom: "10px" }}
                >
                  Last Name <span className="text-danger">*</span>
                </div>
                <Field
                  inputFieldFull
                  name="lastName"
                  value={form.lastName}
                  onChange={(e) => handleChange(e.target)}
                  style={{ border: errors.lastName ? "1px solid #ff0000" : "" }}
                />
                <span className="text-danger">{errors.lastName}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div
                  className="s1"
                  style={{ paddingTop: "25px", paddingBottom: "10px" }}
                >
                  Company Name <span className="text-danger">*</span>
                </div>
                <Field
                  inputFieldFull
                  name="companyName"
                  value={form.companyName}
                  onChange={(e) => handleChange(e.target)}
                  style={{
                    border: errors.companyName ? "1px solid #ff0000" : "",
                  }}
                />
                <span className="text-danger">{errors.companyName}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="d-flex justify-content-between">
                  <div
                    className="s1"
                    style={{ paddingTop: "25px", paddingBottom: "10px" }}
                  >
                    Email <span className="text-danger">*</span>
                  </div>
                  <div
                    className="s1 grey6"
                    style={{ paddingTop: "25px", paddingBottom: "10px" }}
                  >
                    Business email only
                  </div>
                </div>
                <Field
                  inputFieldFull
                  name="email"
                  value={form.email}
                  onChange={(e) => handleChange(e.target)}
                  style={{ border: errors.email ? "1px solid #ff0000" : "" }}
                />
                <span className="text-danger">{errors.email}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="d-flex justify-content-between">
                  <div
                    className="s1"
                    style={{ paddingTop: "25px", paddingBottom: "10px" }}
                  >
                    Password <span className="text-danger">*</span>
                  </div>
                  <div
                    className="s1 password-description"
                    style={{ paddingTop: "25px", paddingBottom: "10px" }}
                  >
                    10 characters minimum. Special Characters
                  </div>
                </div>
                <Field
                  passwordFieldFull
                  name="password"
                  value={form.password}
                  onChange={(e) => handleChange(e.target)}
                  onEyeClick={handleRegisterEyeClick}
                  showPassword={form.isShowPassword}
                  style={{
                    border: errors.password ? "1px solid #ff0000" : "",
                    borderRadius: "10px",
                  }}
                />
                <span className="text-danger">{errors.password}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div>
                <div
                  className="s1"
                  style={{ paddingTop: "25px", paddingBottom: "10px" }}
                >
                  Confirm Password <span className="text-danger">*</span>
                </div>
                <Field
                  passwordFieldFull
                  name="confirmPassword"
                  value={form.confirmPassword}
                  onChange={(e) => handleChange(e.target)}
                  onEyeClick={handleConfirmRegisterEyeClick}
                  showPassword={form.isShowConfirmPassword}
                  style={{
                    border: errors.confirmPassword ? "1px solid #ff0000" : "",
                    borderRadius: "10px",
                  }}
                />
                <span className="text-danger">{errors.confirmPassword}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="s1 d-flex align-items-baseline pt-4">
                <Field
                  inputFieldCheckbox
                  name="allowUsers"
                  checked={form.allowUsers}
                  onChange={(e) =>
                    handleChange({
                      name: e.target.name,
                      value: e.target.checked,
                    })
                  }
                />
                <span className="ml-2 b1">
                  Allow all users with{" "}
                  <span className="b1 primary-color">
                    {form?.email?.split("@")[1]
                      ? `@${form?.email?.split("@")[1]}`
                      : ""}
                  </span>{" "}
                  domain to join the organisation automatically
                </span>
              </div>
            </div>
          </div>

          <div className="row" style={{ paddingTop: "40px" }}>
            <div className="col-lg-12">
              <Button
                buttonFilledFull
                title="Register"
                onClick={handleSubmit}
                loading={loading}
              />
              <div
                className="b2"
                style={{ textAlign: "center", paddingTop: "15px" }}
              >
                Already a member?{" "}
                <Link to="/" className="b2 primary-color">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer fieldFooter />
    </>
  );
}
