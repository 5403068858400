import React, { useEffect, useState } from "react";
import Field from "../../components/sub-components/Field";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import Switch from "../sub-components/Switch";
import { useDispatch, useSelector } from "react-redux";
import { secure_instance } from "../../axios/axios-config";
import { ReactComponent as DotsThreeHorizontal } from "../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as PencilSimple } from "../../assets/icons/PencilSimple.svg";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import { MenuItem } from "@mui/material";
import DropDown from "../DropDowns/DropDown";
import { StyledMenu } from "../Menus/Menu";
import Tab from "../sub-components/Tab";
import Tabv2 from "../sub-components/Tabv2";
import SideBarSurveys from "./Tabs/Surveys";
import SidebarObjectives from "./Tabs/Objectives";
import AnalyticsSideBar from "./Tabs/Analytics";
import { fetchTeamsData } from "../../redux/slices/teamsSlice";
import { toggleLoading } from "../../redux/slices/usersSlice";

export default function EditUser(props) {
  const { isSideMenuOpen, currentrow, setCurrentrow } = props;
  const [isEmployee, setIsEmployee] = useState(false);
  const [isCohortWatcher, setIsCohortWatcher] = useState(false);
  const [isTeamWatcher, setIsTeamWatcher] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTeamsMenu, setIsTeamsMenu] = useState(false);
  const [isCohortsMenu, setIsCohortsMenu] = useState(false);
  const [isSearchMatch, setIsSearchMatch] = useState(true);
  const [cohortsData, setCohortsData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditable, setIsEditable] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [isMoveToArchive, setIsMoveToArchive] = React.useState(false);
  const [isUnArchive, setIsUnArchive] = React.useState(false);
  const [isEditableState, setIsEditableState] = useState(false);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedTab, setSelectedTab] = useState({ id: 1 });

  const tabs = [
    { id: 1, title: "Details" },
    { id: 2, title: "Analytics" },
    { id: 3, title: "Surveys" },
    { id: 4, title: "Objectives" },
    // Add more tabs as needed
  ];

  const isActionMenu = Boolean(anchorEl);

  const dispatch = useDispatch();

  const { teamsData } = useSelector((state) => state.teamsReducer);
  const { loading } = useSelector((state) => state.usersReducer);

  console.log("teams data ", teamsData);

  const onChangeSwitch = (e, id) => {
    if (id === "employee") {
      setIsEmployee(e);
    }
    switch (id) {
      case "employee":
        setIsEmployee(e);
        break;
      case "cohort_watcher":
        setIsCohortWatcher(e);
        break;
      case "team_watcher":
        setIsTeamWatcher(e);
        break;
      case "admin":
        setIsAdmin(e);
        break;
      default:
        return;
    }
  };

  const handleTeamsDropDown = () => {
    setIsCohortsMenu(false);
    if (teamsData?.length === 0) {
      dispatch(fetchTeamsData());
    }
    setIsTeamsMenu(!isTeamsMenu);
  };
  const handleActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCohortsDropDown = async () => {
    setIsTeamsMenu(false);
    if (cohortsData.length === 0) {
      try {
        const request = await secure_instance.request({
          url: "/v1/cohorts/",
          method: "GET",
        });

        setCohortsData(request.data);
      } catch (e) {
        console.log(e);
      }
    }

    setIsCohortsMenu(!isCohortsMenu);
  };

  const isSelected = (user) => {
    const ans = selected.findIndex((x) => x.id === user.id);
    return ans !== -1;
  };

  const handleClickCheckbox = (team) => {
    const selectedIndex = selected.findIndex((x) => x.id === team.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, team);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const refreshState = () => {
    setIsEmployee(false);
    setIsCohortWatcher(false);
    setIsTeamWatcher(false);
    setIsAdmin(false);
    setIsTeamsMenu(false);
    setIsCohortsMenu(false);
    setIsSearchMatch(true);
    setIsEditable(false);
    setSearchValue("");
    setSelected([]);
    setIsUnArchive(false);
    setIsMoveToArchive(false);
    if (loading) {
      dispatch(toggleLoading());
    }
  };

  const getPreviousViewIcon = () => {
    if (isMoveToArchive) {
      return (
        <Arrow_left
          stroke="#343A40"
          strokeWidth="2"
          style={{ margin: "0px 20px 3px 0", width: "24px", cursor: "pointer" }}
          onClick={() =>
            isMoveToArchive
              ? setIsMoveToArchive(false)
              : isUnArchive && setIsUnArchive(false)
          }
        />
      );
    } else {
      return (
        <Close
          stroke="#343A40"
          strokeWidth="2"
          style={{ margin: "0px 20px 3px 0", width: "24px", cursor: "pointer" }}
          onClick={() => props.handleSideMenu()}
        />
      );
    }
  };

  const handleTab1Clicked = () => {
    setTab1(true);
    setTab2(false);
    // setSideMenuTablePage(0);
  };

  const handleTab2Clicked = () => {
    setTab1(false);
    setTab2(true);
    // setSideMenuTablePage(0);
  };

  const handleTabChange = (index) => {
    setSelectedTab(tabs[index]);
    // Your tab change logic here
  };

  const getSidebarHeaderView = () => {
    return (
      <span className="head5 grey8">
        {getPreviousViewIcon()}
        {currentrow.first_name + " " + currentrow.last_name}
      </span>
    );
  };

  useEffect(() => {
    if (!isCohortWatcher) {
      setIsCohortsMenu(false);
    }
    if (!isTeamWatcher) {
      setIsTeamsMenu(false);
    }
  }, [isCohortWatcher, isTeamWatcher]);

  useEffect(() => {
    if (Object.keys(currentrow).length === 0) {
      setIsMoveToArchive(false);
    }
    if (!isSideMenuOpen) {
      refreshState();
    }
  }, [currentrow, isSideMenuOpen]);

  useEffect(() => {
    if (props.archiveUser) {
      setIsUnArchive(true);
    }
  }, [props.archiveUser]);

  return (
    <div
      className={`side-menu-box ${
        isSideMenuOpen === true ? "open border" : "side-menu-box border"
      }`}
    >
      {selectedTab.id === 1 && (
        <>
          <div className="d-flex justify-content-between">
            <span className="head5 grey8">
              {getPreviousViewIcon()}
              {props.archiveUser
                ? "Unarchive User"
                : props.activeUser && isMoveToArchive
                  ? "Archive User"
                  : "Edit User"}
            </span>

            {isEditable ? (
              <div
                className="d-flex align-items-center flex-row-reverse"
                style={{ width: "fit-content" }}
              >
                <div className="integration-menu-buttons">
                  <Button
                    buttonFilledFull
                    Unarchive
                    User
                    title={"Save Changes"}
                    style={{
                      color: "white",
                      height: "40px",
                      marginTop: "5px",
                      fontWeight: "400",
                    }}
                    // loading={loading}
                    onClick={() => {
                      setIsEditable(false);
                      setIsTeamsMenu(false);
                      setIsCohortsMenu(false);
                    }}
                  />
                </div>
                <div
                  style={{ paddingRight: "15px" }}
                  className="integration-menu-buttons"
                >
                  <Button
                    buttonHollowLg
                    title={"Cancel"}
                    typography={
                      props.archiveUser
                        ? "s1 grey6"
                        : props.activeUser && "s1 primary-color"
                    }
                    style={{ height: "40px" }}
                    // For now we are resfreshing all the states, but when this data is coming
                    // from backend, we would reset it to it's initial state that was fetched
                    onClick={() => refreshState()}
                  />
                </div>
              </div>
            ) : isMoveToArchive || isUnArchive ? (
              <div
                className="d-flex align-items-center flex-row-reverse"
                style={{ width: "fit-content" }}
              >
                <div className="integration-menu-buttons">
                  <Button
                    typography={"b0-medium grey1"}
                    buttonFilledFull
                    Unarchive
                    User
                    title={"Confirm"}
                    style={{
                      color: "white",
                      height: "40px",
                      marginTop: "5px",
                      fontWeight: "400",
                    }}
                    loading={loading}
                    onClick={() => props.handleArchiveUsers()}
                  />
                </div>
                <div
                  style={{ paddingRight: "15px" }}
                  className="integration-menu-buttons"
                >
                  <Button
                    buttonHollowLg
                    title={"Cancel"}
                    typography={"s1 grey6"}
                    style={{ height: "40px" }}
                    onClick={() =>
                      props.archiveUser
                        ? setIsUnArchive(false)
                        : props.activeUser && setIsMoveToArchive(false)
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center flex-row-reverse">
                  <div
                    style={{ cursor: "pointer", width: "40px", height: "40px" }}
                  >
                    <DotsThreeHorizontal onClick={handleActionMenu} />
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={isActionMenu}
                      onClose={handleActionMenuClose}
                    >
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          handleActionMenuClose();
                          props.activeUser && setIsMoveToArchive(true);
                          props.archiveUser && setIsUnArchive(true);
                        }}
                      >
                        <span
                          className="s2 grey8 d-flex align-items-center"
                          style={{ marginLeft: "8px" }}
                        >
                          {props.activeUser ? "Archive" : "Unarchive"}
                        </span>
                      </MenuItem>
                    </StyledMenu>
                  </div>
                  <div style={{ marginRight: "16px" }}>
                    <PencilSimple
                      style={{
                        width: "32px",
                        height: "32px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsEditable(true);
                        handleActionMenuClose();
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          {isMoveToArchive && (
            <>
              <span
                className="head6 grey8"
                style={{ marginTop: "24px", marginBottom: "16px" }}
              >
                Move to Archived Users?
              </span>
              <span className="s1 grey8">
                You are about to move this user to archived user. Are you sure
                you want to continue?
              </span>
              <div className="top-border" style={{ marginTop: "24px" }} />
            </>
          )}

          {isUnArchive && (
            <>
              <span
                className="head6 grey8"
                style={{ marginTop: "24px", marginBottom: "16px" }}
              >
                Move to Active Users?
              </span>
              <span className="s1 grey8">
                You are about to move{" "}
                {currentrow?.first_name + " " + currentrow?.last_name} to active
                user. Are you sure you want to continue?
              </span>
              <div className="top-border" style={{ marginTop: "24px" }} />
            </>
          )}
        </>
      )}

      {/* <div
        className={`tags d-flex ${
          !(isEditableState || !isRowClicked) && "disableField"
        }`}
        style={{ marginTop: "24px" }}
      >
        <div
          className={`${
            tab1 && (isEditableState || !isRowClicked) ? "b0-medium" : "b1"
          } d-flex justify-content-center 
							${tab1 && (isEditableState || !isRowClicked) ? "grey8" : "grey6"}`}
          style={{
            borderBottom:
              tab1 && (isEditableState || !isRowClicked)
                ? "4px solid #7B2CBF"
                : "4px solid #E9ECEF",
            padding: "8px",
            width: "279px",
            cursor: "pointer",
          }}
          onClick={handleTab1Clicked}
        >
          {"Details"}
        </div>
        <div
          className={`${
            tab2 && (isEditableState || !isRowClicked) ? "b0-medium" : "b1"
          } d-flex justify-content-center 
							${tab2 && (isEditableState || !isRowClicked) ? "grey8" : "grey6"}`}
          style={{
            borderBottom:
              tab2 && (isEditableState || !isRowClicked)
                ? "4px solid #7B2CBF"
                : "4px solid #E9ECEF",
            padding: "8px",
            width: "279px",
            cursor: "pointer",
          }}
          onClick={handleTab2Clicked}
        >
          <div style={{ position: "relative" }}>
            <span>{"Users"}</span>
          </div>
        </div>
      </div> */}

      {console.log("activeTab", activeTab)}
      {console.log("468 teamsDatateamsData", teamsData)}

      {/* <Tab
        tab4
        setActiveTab={setActiveTab}
        title="gf"
        tabs={["tab1", "tab1", "tab1", "tab1"]}
      /> */}

      {selectedTab.id !== 1 && getSidebarHeaderView()}
      {/* {getPreviousViewIcon()} */}

      {props.activeUser && !isUnArchive && !isMoveToArchive && (
        <Tabv2
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
        />
      )}
      {console.log("selectedTabselectedTab", selectedTab)}
      {selectedTab.id === 1 && (
        <>
          <div
            className="d-flex flex-column"
            style={{
              marginTop: "32px",
              height: "fit-content",
              textAlign: "start",
            }}
          >
            <span className="head6 grey9">User’s Details</span>

            <div className="row" style={{ margin: "16px 0px 0px 0px" }}>
              <div className="col-lg-6 p-0" style={{ marginRight: "0px" }}>
                <div style={{ paddingBottom: "10px" }} className="s2 grey8">
                  First Name
                </div>
                <div style={{ marginRight: "32px" }}>
                  <Field
                    inputFieldFullReadOnly
                    noDropDown
                    typography="b1"
                    value={currentrow.first_name}
                    style={{ color: "#6C757D" }}
                  />
                </div>

                <div style={{ padding: "10px 0px" }} className="s2 grey8">
                  Last Name
                </div>
                <div style={{ marginRight: "32px" }}>
                  <Field
                    inputFieldFullReadOnly
                    noDropDown
                    typography="b1"
                    value={currentrow.last_name}
                    style={{ color: "#6C757D" }}
                  />
                </div>

                <div style={{ padding: "10px 0px" }} className="s2 grey8">
                  Department
                </div>
                <div style={{ marginRight: "32px" }}>
                  <Field
                    inputFieldFullReadOnly
                    noDropDown
                    typography="b1"
                    value={currentrow.department}
                    style={{ color: "#6C757D" }}
                  />
                </div>
              </div>

              <div className="col-lg-6 p-0">
                <div style={{ paddingBottom: "10px" }} className="s2 grey8">
                  Job Title
                </div>
                <Field
                  inputFieldFullReadOnly
                  noDropDown
                  typography="b1"
                  value={currentrow.title}
                  style={{ color: "#6C757D" }}
                />
                <div style={{ padding: "10px 0px" }} className="s2 grey8">
                  Employee ID
                </div>
                <Field
                  inputFieldFullReadOnly
                  noDropDown
                  typography="b1"
                  value={currentrow.employee_id}
                />
                <div style={{ padding: "10px 0px" }} className="s2 grey8">
                  Manager
                </div>
                <Field
                  inputFieldFullReadOnly
                  noDropDown
                  typography="b1"
                  value={currentrow.manager_name}
                  style={{ color: "#6C757D" }}
                />
              </div>

              <div className="col-lg-6 p-0">
                <div style={{ padding: "10px 0px" }} className="s2 grey8">
                  Office
                </div>
                <div style={{ marginRight: "32px" }}>
                  <Field inputFieldFullReadOnly noDropDown typography="b1" />
                </div>
              </div>
            </div>
          </div>

          {!isMoveToArchive && !isUnArchive && (
            <div className="top-border" style={{ margin: "24px 0" }} />
          )}

          {!isMoveToArchive && !isUnArchive && (
            <div className="d-flex flex-column" style={{ textAlign: "start" }}>
              <span className="head6 grey9" style={{ marginBottom: "16px" }}>
                Permissions
              </span>

              <div
                className="d-flex w-100 justify-content-between"
                style={{
                  height: "56px",
                  padding: "16px 30px",
                  border: "1px solid #DEE2E6",
                  borderBottom: "none",
                  borderRadius: "10px",
                  borderBottomLeftRadius: "0",
                  borderBottomRightRadius: "0",
                }}
              >
                <div className="s2 grey8 w-50" style={{ marginRight: "48px" }}>
                  Role(s) in the System
                </div>
                <div className="s2 grey8 w-50">Teams / Cohorts</div>
              </div>
              <div
                className="sync-menu-options-container"
                style={{
                  minHeight: "325px",
                  pointerEvents: !isEditable && "none",
                  background: "#FFFFFF",
                  padding: "0px",
                  border: "1px solid #DEE2E6",
                  borderTopRightRadius: "0",
                  borderTopLeftRadius: "0",
                  borderTop: "none",
                }}
              >
                <div className="w-100">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      borderTop: "1px solid #DEE2E6",
                      borderBottom: "none",
                      height: "80px",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ flex: 1, margin: "0 24px" }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between align-items-center w-50"
                        style={{
                          minWidth: "296px",
                          width: "100%",
                          background: "#FFFFFF",
                          border: "1px solid #E9ECEF",
                          borderRadius: "10px",
                          height: "48px",
                          padding: "12px 16px 12px 20px",
                          marginRight: "48px",
                        }}
                      >
                        <div className="s1 grey8">Employee</div>
                        <Switch
                          id="employee"
                          isEditable={isEditable}
                          checked={isEmployee}
                          onChange={onChangeSwitch}
                        />
                      </div>
                      <div className="w-50">
                        <Field
                          placeholder="Search Employee"
                          fieldDropdownFull
                          noDropDown
                          inputBackground={isEmployee ? "#FFFFFF" : "#F8F9FA"}
                          readOnly
                          inputStyle={{ height: "48px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      borderTop: "1px solid #F8F9FA",
                      borderBottom: "none",
                      height: "80px",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ flex: 1, margin: "0 24px" }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between align-items-center w-50"
                        style={{
                          marginRight: "48px",
                          minWidth: "296px",
                          width: "100%",
                          background: "#FFFFFF",
                          border: "1px solid #E9ECEF",
                          borderRadius: "10px",
                          height: "48px",
                          padding: "12px 16px 12px 20px",
                        }}
                      >
                        <div className="s1 grey8">Cohort Watcher</div>
                        <Switch
                          id="cohort_watcher"
                          isEditable={isEditable}
                          checked={isCohortWatcher}
                          onChange={onChangeSwitch}
                        />
                      </div>

                      <div className="w-50">
                        <div
                          style={{ position: "relative", border: "none" }}
                          className="teams-menu-container"
                        >
                          <div
                            onClick={() =>
                              isCohortWatcher && handleCohortsDropDown()
                            }
                          >
                            <Field
                              fieldDropdownFull
                              placeholder="Search Cohorts"
                              disabled={!isCohortWatcher}
                              readOnly
                              inputBackground={
                                isCohortWatcher ? "#FFFFFF" : "#F8F9FA"
                              }
                              style={
                                {
                                  // backgroundColor: isCohortWatcher && "white",borderRadius:"10px",
                                  // color:"#6C757D",cursor:"pointer"
                                }
                              }
                              inputStyle={{ height: "48px" }}
                            />
                          </div>

                          <DropDown
                            isFieldActive={isCohortsMenu}
                            setIsFieldActive={setIsCohortsMenu}
                            isSearchMatch={isSearchMatch}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            dropDownData={cohortsData}
                            isSelected={isSelected}
                            handleClickCheckbox={handleClickCheckbox}
                            setSelected={setSelected}
                            uniqueKey="id"
                            dataToShow="name"
                            sortBy="name"
                            editUserDropDownSortAndFilter
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      borderTop: "1px solid #F8F9FA",
                      borderBottom: "none",
                      height: "80px",
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{ flex: 1, margin: "0 24px" }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between align-items-center w-50"
                        style={{
                          minWidth: "296px",
                          width: "100%",
                          background: "#FFFFFF",
                          border: "1px solid #E9ECEF",
                          borderRadius: "10px",
                          height: "48px",
                          padding: "12px 16px 12px 20px",
                          marginRight: "48px",
                        }}
                      >
                        <div className="s1 grey8">Team Watcher</div>
                        <Switch
                          id="team_watcher"
                          isEditable={isEditable}
                          checked={isTeamWatcher}
                          onChange={onChangeSwitch}
                        />
                      </div>
                      <div className="w-50">
                        <div
                          style={{ position: "relative", border: "none" }}
                          className="teams-menu-container"
                        >
                          <div
                            onClick={() =>
                              isTeamWatcher && handleTeamsDropDown()
                            }
                          >
                            <Field
                              fieldDropdownFull
                              placeholder="Search Teams"
                              disabled={!isTeamWatcher}
                              readOnly
                              inputBackground={
                                isTeamWatcher ? "#FFFFFF" : "#F8F9FA"
                              }
                              style={{
                                height: "48px",
                                // width: "100%", background: isTeamWatcher && "white",borderRadius:"10px",
                                // color:"#6C757D"
                              }}
                              inputStyle={{ height: "48px", width: "100%" }}
                              onClick={() => handleTeamsDropDown()}
                            />
                          </div>

                          <DropDown
                            isFieldActive={isTeamsMenu}
                            setIsFieldActive={setIsTeamsMenu}
                            isSearchMatch={isSearchMatch}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            dropDownData={teamsData}
                            isSelected={isSelected}
                            handleClickCheckbox={handleClickCheckbox}
                            setSelected={setSelected}
                            uniqueKey="id"
                            dataToShow="name"
                            sortBy="name"
                            editUserDropDownSortAndFilter
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ borderTop: "1px solid #F8F9FA", height: "80px" }}
                  >
                    <div
                      className="d-flex"
                      style={{ flex: 1, margin: "0 24px" }}
                    >
                      <div
                        className="d-flex flex-row justify-content-between align-items-center w-50"
                        style={{
                          minWidth: "296px",
                          width: "100%",
                          background: "#FFFFFF",
                          border: "1px solid #E9ECEF",
                          borderRadius: "10px",
                          height: "48px",
                          padding: "12px 16px 12px 20px",
                          marginRight: "48px",
                        }}
                      >
                        <div className="s1 grey8">Admin</div>
                        <Switch
                          id="admin"
                          isEditable={isEditable}
                          checked={isAdmin}
                          onChange={onChangeSwitch}
                        />
                      </div>
                      <div className="w-50">
                        <Field
                          fieldDropdownFull
                          noDropDown
                          inputBackground={isAdmin ? "#FFFFFF" : "#F8F9FA"}
                          placeholder="Search Admin"
                          readOnly
                          inputStyle={{ height: "48px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* 
    {props.activeUser && 
      selectedTab.id === 2 && <AnalyticsSideBar />
      selectedTab.id === 3 && (
        <div>
          <SideBarSurveys />
        </div>
      )
      selectedTab.id === 4 && <SidebarObjectives />
    } */}
      {/* {props.activeUser && */}
      {(selectedTab.id === 2 && <AnalyticsSideBar />) ||
        (selectedTab.id === 3 && (
          <div>
            <SideBarSurveys />
          </div>
        )) ||
        (selectedTab.id === 4 && <SidebarObjectives />)}
      {/* } */}
    </div>
  );
}
