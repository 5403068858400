import React, { useState } from "react";

export default function Switch({
  id,
  name,
  checked,
  onChange,
  isEditable,
  onDetectedUsersChange,
  ...props
}) {
  // const [isChecked, setisChecked] = useState(false);

  // const handleSwitch = (e) => {
  //   console.log(e.target.checked);
  //   console.log(e.target.id);
  //   setisChecked(!isChecked);
  // };
  if (props.detectedUsers) {
    return (
      <div>
        <label className="switch">
          <input
            type="checkbox"
            id={"detected-users"}
            checked={checked}
            onChange={(e) => onDetectedUsersChange(e.target.checked)}
          />
          <input
            type="checkbox"
            name={name}
            id={id}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
          />
          <span className="slider round" />
        </label>
      </div>
    );
  }

  if (props.billing) {
    return (
      <div>
        <label className="switch" style={{ margin: 0 }}>
          <input
            type="checkbox"
            id={id}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
          />
          <input
            type="checkbox"
            name={name}
            id={id}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
          />
          <span className="slider round" />
        </label>
      </div>
    );
  }
  return (
    <div>
      <label className="switch" style={{ marginBottom: "0px" }}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked, id)}
        />
        <input
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={`slider round ${isEditable === false && "disabled"}`}>
          <div className="sliderAction" />
        </span>
      </label>
    </div>
  );
}
