import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { getCookie, setCookie } from "../../utilities/Utils";

const initialState = {
  currentTab: "1",
  loading: false,
  isSideMenu: false,
  formData: {},
  managerData: "",
  data: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setFormData(state, action) {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    setManagerData(state, action) {
      state.managerData = {
        ...state.managerData,
        ...action.payload,
      };
    },
    setDepartmentData(state, action) {
      state.formData.departmentDropdown = action.payload;
    },
    setDesignationData(state, action) {
      state.formData.designationDropdown = action.payload;
    },
    setLoadingStart(state) {
      state.loading = true;
    },
    setLoadingEnd(state) {
      state.loading = false;
    },
    setLoadingFailed(state) {
      state.loading = false;
    },
  },
});

export const getFormUsers = (id) => {
  return async (dispatch) => {
    try {
      const request1 = await secure_instance.request({
        url: `/v1/user_profile/${id}`,
        method: "GET",
      });

      dispatch(setFormData(request1.data));

      if (request1.data.manager_id) {
        // Second API call to fetch manager data
        const request2 = await secure_instance.request({
          url: `/v1/user_profile/${request1.data.manager_id}`,
          method: "GET",
        });
        dispatch(setManagerData(request2.data));
      }
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const getUsersEmail = (id) => {
  return async (dispatch) => {
    try {
      const email = getCookie("email");
      console.log("emailemail", email);
      if (!email) {
        const request = await secure_instance.request({
          url: `/v1/users/${id}`,
          method: "GET",
        });
        setCookie("email", request.data.email, 1);
        dispatch(setFormData(request.data));
      } else {
        dispatch(setFormData({ email: email }));
      }
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const getUsersDepartments = () => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: "/v1/departments/",
        method: "GET",
      });
      dispatch(setDepartmentData(request.data));
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const getUsersDesignations = () => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: "/v1/designations/",
        method: "GET",
      });
      dispatch(setDesignationData(request.data));
    } catch (e) {
      console.log("error", e);
    }
  };
};

export const editFormUsers = (id, formData) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingStart());
      await secure_instance.request({
        url: `/v1/user_profile/${id}`,
        method: "PATCH",
        data: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          ...(formData.department_id !== null && {
            department_id: formData.department_id,
          }),
          ...(formData.designation_id !== null && {
            designation_id: formData.designation_id,
          }),
          employee_id: parseInt(formData.employee_id),
          address: formData.address,
          image_url: formData.image_url,
        },
      });
      dispatch(setLoadingEnd());
    } catch (e) {
      dispatch(setLoadingFailed());
      console.log("error", e);
    }
  };
};

export const {
  setCurrentTab,
  setFormData,
  setManagerData,
  setDepartmentData,
  setDesignationData,
  setLoadingStart,
  setLoadingEnd,
  setLoadingFailed,
} = settingsSlice.actions;

export default settingsSlice.reducer;
