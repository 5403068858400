import React from "react";
import { Modal } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../assets/icons/DeleteRedModalIcon.svg";
import { ReactComponent as Close } from "../../../src/assets/icons/X.svg";
import CustomBox from "./CustomBox";

const DeleteModal = ({ openModal, handleCloseModal, deleteEvent, text }) => {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox>
        <div className="row">
          <div className="col-2">
            <DeleteIcon className="mr-auto" />
          </div>
          <div className="col-10">
            <div className="d-flex justify-content-between">
              <div className="head4 mb-1 text-left">{text}</div>
              <Close
                stroke="#343A40"
                strokeWidth="2"
                style={{
                  margin: "0px 20px 3px 0",
                  width: "24px",
                  cursor: "pointer",
                }}
                onClick={handleCloseModal}
              />
            </div>
            <div
              className="s1 grey8 mr-2"
              style={{ paddingTop: "3px", textAlign: "start" }}
            >
              Are you sure you want to delete this? This action cannot be
              undone.
            </div>
          </div>
        </div>

        <div className="w-100 d-flex justify-content-end ">
          <button
            type="button"
            className="btn btn-light"
            style={{ borderColor: "grey" }}
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger ml-2"
            onClick={(e) => deleteEvent(e)}
          >
            Delete
          </button>
        </div>
      </CustomBox>
    </Modal>
  );
};

export default DeleteModal;
