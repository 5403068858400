import { alpha, Toolbar, Tooltip, Typography } from "@mui/material";
import React from "react";

const TableToolbar = (props) => {
  const {
    numSelected,
    totalRows,
    checkboxRequired,
    toolbarText,
    toolbarImage,
  } = props;
  const headerCalc = () => {
    return `${numSelected} / ${totalRows} selected`;
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
      style={{
        background: numSelected > 0 && "#F4F2F9",
        borderBottom: "1px solid #E9ECEF",
        height: "80px",
        padding: "16px",
        paddingRight: "48px",
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <span className="head6 primary-color">{headerCalc()}</span>
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <>
            <div
              className="align-items-center head6 primary-color"
              onClick={() => props.handleToolbarAction()}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100vw",
                cursor: "pointer",
              }}
            >
              {/* {(props.activeUsers || props.archiveUsers || props.cohorts || props.teams || props.teamsSideMenu) ?
								numSelected === totalRows ? 
									toolbarText[1] 
									:
									toolbarText[0]
								:
								toolbarText
							} */}
              <span className="mr-1">{toolbarImage}</span>
              <span>
                {numSelected === totalRows ? toolbarText[1] : toolbarText[0]}
              </span>
            </div>
          </>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default TableToolbar;
