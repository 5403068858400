import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/sub-components/Button";
import Footer from "../../../components/sub-components/Footer";
import { getCookie } from "../../../utilities/Utils";
import Header from "../Login/Header";
import { resendActivationLink } from "../../../redux/slices/authSlice";

export default function LinkExpired() {
  const dispatch = useDispatch();

  const handleResendLink = () => {
    dispatch(resendActivationLink(getCookie("email")));
  };

  return (
    <>
      <div className="signup">
        <div className="row mt-5">
          <div className="col-lg-12">
            <Header />
          </div>
        </div>

        <div
          className="d-flex flex-column align-items-center"
          style={{ textAlign: "center" }}
        >
          <div
            className="head3"
            style={{ paddingTop: "130px", color: "#7B2CBF" }}
          >
            Link Expired
          </div>
          <div
            className="head6"
            style={{ paddingTop: "30px", paddingBottom: "40px" }}
          >
            It seems that this link has expired. Please generate a new link.
          </div>

          <div className="col-lg-5 col-md-12">
            <Button
              buttonFilledFull
              title="Resend Link"
              onClick={handleResendLink}
            />
          </div>
        </div>
      </div>
      <div
        className="col-lg-12 d-flex flex-row justify-content-center align-items-center"
        style={{ gap: "43.5px", marginTop: "180px" }}
      >
        <Footer />
      </div>
    </>
  );
}
