// import React from "react";
// import Switch from "../../../components/sub-components/Switch";

// function Notifications() {
//   return (
//     <>
//       <div className="row ml-2">
//         <div className="col-lg-6">
//           <div className="mb-3">
//             <span className="d-flex head6-medium grey8 mb-2">Email Alerts</span>
//             <span className="d-flex body1 grey6">
//               Notification Emails that you would like to receive from us
//             </span>
//           </div>

//           <div style={{ background: "#FAFAFA", padding: "16px 24px" }}>
//             <div className="d-flex justify-content-between mb-4">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">
//                   Channel Activity
//                 </span>
//                 <span className="d-flex body2 grey6">
//                   Receive an email notification whenever there is activity in
//                   your Channel
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//             <div className="d-flex justify-content-between mb-4">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">New Member</span>
//                 <span className="d-flex body2 grey6">
//                   Receive an email notification whenever a new member joins
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//             <div className="d-flex justify-content-between mb-4">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">Member Leaves</span>
//                 <span className="d-flex body2 grey6">
//                   Receive an email notification whenever a member leaves
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//             <div className="d-flex justify-content-between">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">Lorem Ipsum</span>
//                 <span
//                   className="d-flex body2 grey6 pr-4"
//                   style={{ lineHeight: "22px" }}
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
//                   tristique sed a varius gravida justo velit ac pellentesque.
//                   Viverra proin netus morbi quis.
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="col-lg-6">
//           <div className="mb-3">
//             <span className="d-flex head6-medium grey8 mb-2">Team Alerts</span>
//             <span className="d-flex body1 grey6">
//               Notification Emails that you would like to receive from us
//             </span>
//           </div>

//           <div style={{ background: "#FAFAFA", padding: "16px 24px" }}>
//             <div className="d-flex justify-content-between mb-4">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">
//                   Channel Activity
//                 </span>
//                 <span className="d-flex body2 grey6">
//                   Receive an email notification whenever there is activity in
//                   your Channel
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//             <div className="d-flex justify-content-between mb-4">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">New Member</span>
//                 <span className="d-flex body2 grey6">
//                   Receive an email notification whenever a new member joins
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//             <div className="d-flex justify-content-between mb-4">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">Member Leaves</span>
//                 <span className="d-flex body2 grey6">
//                   Receive an email notification whenever a member leaves
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//             <div className="d-flex justify-content-between">
//               <div>
//                 <span className="d-flex body1 grey8 mb-1">Lorem Ipsum</span>
//                 <span
//                   className="d-flex body2 grey6 pr-4"
//                   style={{ lineHeight: "22px" }}
//                 >
//                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
//                   tristique sed a varius gravida justo velit ac pellentesque.
//                   Viverra proin netus morbi quis.
//                 </span>
//               </div>

//               <div>
//                 <Switch />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-lg-6">Cohort Alerts</div>
//         <div className="col-lg-6">Billing Alerts</div>
//       </div>
//     </>
//   );
// }

// export default Notifications;

import React, { useState } from "react";
import Switch from "../../../components/sub-components/Switch";

function NotificationItem({
  title,
  description,
  id,
  name,
  checked,
  onChange,
  isEditable,
}) {
  return (
    <div className="d-flex justify-content-between mb-4">
      {console.log("checked", checked)}
      <div>
        <span className="d-flex body1 grey8 mb-1">{title}</span>
        <span className="d-flex body2 grey6">{description}</span>
      </div>
      <div>
        <Switch
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          isEditable={isEditable}
        />
      </div>
    </div>
  );
}

function NotificationGroup({ title, children, description }) {
  return (
    <div className="mb-3">
      <span className="d-flex head6-medium grey8 mb-2">{title}</span>
      <span className="d-flex body1 grey6 mb-3">{description}</span>
      <div
        style={{
          background: "#FAFAFA",
          padding: "16px 24px",
          minHeight: "288px",
          maxHeight: "288px",
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Notifications() {
  //   channelActivityChecked
  // handleChannelActivityChange
  // isEditable

  const [channelActivityChecked, setChannelActivityChecked] = useState(false);
  // const [handleChannelActivityChange, setfirst] = useState(second)
  const [isEditable, setIsEditable] = useState(true);

  const [channelActivity, setChannelActivity] = useState(false);
  const [addsToTeam, setAddsToTeam] = useState(false);
  const [newMember, setNewMember] = useState(false);
  const [removesFromTeam, setRemovesFromTeam] = useState(false);
  const [privilegeForTeamAlerts, setPrivilegeForTeamAlerts] = useState(false);
  const [privilegeForCohortAlerts, setPrivilegeForCohortAlerts] =
    useState(false);
  // const [privilege, setPrivilege] = useState(false);
  // const [privilege, setPrivilege] = useState(false);
  const [memberLeaves, setMemberLeaves] = useState(false);
  const [loremIpsum, setloremIpsum] = useState(false);
  const [changesPrivilege, setChangesPrivilege] = useState(false);
  const [addsToCohort, setAddsToCohort] = useState(false);
  const [upcomingPayments, setUpcomingPayments] = useState(false);
  const [removesFromCohort, setRemovesFromCohort] = useState(false);
  const [chargebacks, setChargebacks] = useState(false);

  const onChangeSwitch = (e, id) => {
    console.log("eeeee", e);
    switch (id) {
      case "channel_activity":
        setChannelActivity(e);
        break;
      case "adds_to_team":
        setAddsToTeam(e);
        break;
      case "new_member":
        setNewMember(e);
        break;
      case "removes_from_team":
        setRemovesFromTeam(e);
        break;
      case "member_leaves":
        setMemberLeaves(e);
        break;
      case "lorem_ipsum":
        setloremIpsum(e);
        break;
      case "adds_you_to_a_team":
        setAddsToTeam(e);
        break;
      case "removes_you_from_a_team":
        setRemovesFromTeam(e);
        break;
      case "changes_your_privilege_for_team_alerts":
        setPrivilegeForTeamAlerts(e);
        break;
      case "adds_you_to_a_cohort":
        setAddsToCohort(e);
        break;
      case "removes_you_from_a_cohort":
        setRemovesFromCohort(e);
        break;
      case "changes_your_privilege_for_cohort_alerts":
        setPrivilegeForCohortAlerts(e);
        break;
      // case "changes_your_privilege":
      //   setAddsToCohort(e);
      //   break;
      case "upcoming_payments":
        setUpcomingPayments(e);
        break;
      case "chargebacks":
        setChargebacks(e);
        break;
      default:
        return;
    }
  };

  return (
    <>
      {/* <div className="container"> */}
      <div className="row ml-2 m-0">
        <div className="col-lg-6">
          <NotificationGroup
            title="Email Alerts"
            description="Notification Emails that you would like to receive from us"
          >
            <NotificationItem
              title="Channel Activity"
              description="Receive an email notification whenever there is activity in your Channel"
              id="channel_activity"
              name="channel_activity"
              checked={channelActivity}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />

            <NotificationItem
              title="New Member"
              description="Receive an email notification whenever a new member joins"
              id="new_member"
              name="new_member"
              checked={newMember}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />
            <NotificationItem
              title="Member Leaves"
              description="Receive an email notification whenever a member leaves"
              id="member_leaves"
              name="member_leaves"
              checked={memberLeaves}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />
            <NotificationItem
              title="Lorem Ipsum"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tristique sed a varius gravida justo velit ac pellentesque. Viverra proin netus morbi quis."
              id="lorem_ipsum"
              name="lorem_ipsum"
              checked={loremIpsum}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />
          </NotificationGroup>
        </div>
        <div className="col-lg-6">
          <NotificationGroup
            title="Team Alerts"
            description={
              "Notifications that alert you whenever there is activity in your teams"
            }
          >
            <NotificationItem
              title="Adds you to a Team"
              description="Receive a notification whenever someone adds you to a Team"
              id="adds_you_to_a_team"
              name="adds_you_to_a_team"
              checked={addsToTeam}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />
            <NotificationItem
              title="Removes you from a Team"
              description="Receive a notification whenever someone removes you from a Team"
              id="removes_you_from_a_team"
              name="removes_you_from_a_team"
              checked={removesFromTeam}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />{" "}
            <NotificationItem
              title="Changes your Privilege"
              description="Receive a notification whenever your Team privileges are changed"
              id="changes_your_privilege_for_team_alerts"
              name="changes_your_privilege_for_team_alerts"
              checked={privilegeForTeamAlerts}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />{" "}
          </NotificationGroup>
        </div>
      </div>
      {/* </div> */}
      <div className="row ml-2 m-0">
        <div className="col-lg-6">
          <NotificationGroup
            title="Cohort Alerts"
            description={
              "Notifications that alert you whenever there is activity in your cohorts"
            }
          >
            <NotificationItem
              title="Adds you to a Cohort"
              description="Receive a notification whenever someone adds you to a Cohort"
              id="adds_you_to_a_cohort"
              name="adds_you_to_a_cohort"
              checked={addsToCohort}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />
            <NotificationItem
              title="Removes you from a Cohort"
              description="Receive a notification whenever someone removes you from a Cohort"
              id="removes_you_from_a_cohort"
              name="removes_you_from_a_cohort"
              checked={removesFromCohort}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />{" "}
            <NotificationItem
              title="Changes your Privilege"
              description="Receive a notification whenever your Cohort privileges are changed"
              id="changes_your_privilege_for_cohort_alerts"
              name="changes_your_privilege_for_cohort_alerts"
              checked={privilegeForCohortAlerts}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />{" "}
          </NotificationGroup>
        </div>{" "}
        <div className="col-lg-6">
          <NotificationGroup
            title="Billing Alerts"
            description={
              "Notifications that alert you about your billing and payment"
            }
          >
            <NotificationItem
              title="Upcoming Payments"
              description="Receive a notification whenever you have an upcoming payment"
              id="upcoming_payments"
              name="upcoming_payments"
              checked={upcomingPayments}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />
            <NotificationItem
              title="Chargebacks"
              description="Receive a notification whenever you have a chargeback or your card declines"
              id="chargebacks"
              name="chargebacks"
              checked={chargebacks}
              onChange={onChangeSwitch}
              isEditable={isEditable}
            />{" "}
          </NotificationGroup>
        </div>
      </div>
    </>
  );
}

export default Notifications;
