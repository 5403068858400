import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { showSnackbar } from "./authSlice";

const initialState = {
  teamsData: [],
  manually_selected_teams: [],
  groupsData: [],
  new_teams: [{}],
  showEditMenu: false,
  showAddMenu: false,
  teamCreationSuccess: false,
  teamCreationFailure: false,
  teamEditSuccess: false,
  teamEditFailure: false,
  teamDeletedSuccess: false,
  isMultipleRowsSelected: false,
  isTeamCreatedButtonClick: false,
  loading: false,
  tableData: {
    currentViewApplied: {
      id: 1,
      name: "Teams",
    },
    data: [],
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
    isMultipleRowsSelected: false,
    tableLoading: false,
  },
  sideMenu: {
    isSideMenuOpen: false,
    tableData: {
      usersData: [],
      rowsData: [],
      rowsPerPage: 5,
      page: 1,
      selected: [],
      sideMenuTableLoading: false,
    },
  },
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    handleTeamsData(state, action) {
      state.tableData.data = action.payload;
    },
    saveFetchTeamsData(state, action) {
      state.teamsData = action.payload;
    },
    handleUpdateTeamsData(state, action) {
      state.teamsData.push(action.payload);
    },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    toggleTableLoading(state) {
      state.tableData.tableLoading = !state.tableData.tableLoading;
    },
    toggleSideMenuTableLoading(state) {
      state.sideMenu.tableData.sideMenuTableLoading =
        !state.sideMenu.tableData.sideMenuTableLoading;
    },
    handleGroupsData(state, action) {
      state.groupsData = action.payload;
    },
    handleIndividualTeamSelect(state, action) {
      state.manually_selected_teams = action.payload;
    },
    handleNewTeamData(state, action) {
      state.new_teams.push(action.payload);
    },
    toggleEditMenu(state) {
      state.showEditMenu = !state.showEditMenu;
    },
    toggleAddMenu(state) {
      state.showAddMenu = !state.showAddMenu;
    },
    handleTeamCreatedSuccess(state) {
      state.teamCreationSuccess = !state.teamCreationSuccess;
    },
    handleTeamCreatedFailure(state) {
      state.teamCreationFailure = !state.teamCreationFailure;
    },
    handleTeamEditedSuccess(state) {
      state.teamEditSuccess = !state.teamEditSuccess;
    },
    handleTeamEditedFailure(state) {
      state.teamEditFailure = !state.teamEditFailure;
    },
    handleTeamDeletedSuccess(state) {
      state.teamDeletedSuccess = !state.teamDeletedSuccess;
    },
    handleCreateTeamButtonClick(state) {
      state.isTeamCreatedButtonClick = !state.isTeamCreatedButtonClick;
    },
    setMultipleSelectedSlice(state, action) {
      state.isMultipleRowsSelected = action.payload;
    },
    setTeamsTablePage(state, action) {
      state.tableData.page = action.payload;
    },
    setTeamsSideMenuTablePage(state, action) {
      state.sideMenu.tableData.page = action.payload;
    },
    setSideMenuTableRowsPerPage(state, action) {
      state.sideMenu.tableData.rowsPerPage = action.payload;
    },
    setTableSelectedRows(state, action) {
      state.tableData.selected = action.payload;
    },
    handleCurrentViewApplied(state, action) {
      state.tableData.currentViewApplied = action.payload;
    },
  },
});

//-- Async action creators
export const getTeamsData = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/teams/`,
        method: "Get",
      });
      dispatch(handleTeamsData(request.data));
      dispatch(toggleTableLoading());
    } catch (e) {
      console.log(e);
    }
  };
};

export const getNewTeamsData = (status) => {
  return async (dispatch) => {
    try {
      // dispatch(toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/teams/`,
        method: "Get",
      });
      dispatch(handleTeamsData(request.data));
      if (status === "created") {
        dispatch(
          showSnackbar({
            message: "Team created successfully!",
            severity: "success",
          }),
        );
      }
      if (status === "edited") {
        dispatch(
          showSnackbar({
            message: "Team edited successfully!",
            severity: "success",
          }),
        );
      }
      dispatch(toggleAddMenu());
      dispatch(toggleLoading());
    } catch (e) {
      if (status === "created") {
        dispatch(
          showSnackbar({
            message: "Failed to create team!",
            severity: "error",
          }),
        );
      }
      if (status === "edited") {
        dispatch(
          showSnackbar({
            message: "Failed to edit team!",
            severity: "error",
          }),
        );
      }
      console.log(e);
    }
  };
};

export const fetchTeamsData = () => {
  return async (dispatch) => {
    try {
      // dispatch(toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/teams/`,
        method: "Get",
      });
      dispatch(saveFetchTeamsData(request.data));
      // dispatch(toggleTableLoading());
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateTeamsData = (data) => {
  return async (dispatch) => {
    try {
      dispatch(handleTeamsData(data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getGroupsData = () => {
  return async (dispatch) => {
    try {
      // dispatch(toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/groups/?type=default`,
        method: "Get",
      });
      dispatch(handleGroupsData(request.data));
      // dispatch(toggleTableLoading());
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteTeamCall = (data) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: `v1/teams/`,
        method: "DELETE",
        data: data,
      });
      dispatch(
        showSnackbar({
          message: "Team deleted successfully!",
          severity: "success",
        }),
      );
      dispatch(handleTeamDeletedSuccess());
    } catch (e) {
      dispatch(
        showSnackbar({
          message: "Failed to delete team!",
          severity: "error",
        }),
      );
      console.log(e);
    }
  };
};

export const handleCreateTeam = (data, teamType) => {
  return async (dispatch) => {
    try {
      dispatch(toggleLoading());

      await secure_instance.request({
        url: teamType === "auto" ? "v1/teams/" : "v1/groups/",
        method: "POST",
        data: data,
      });

      dispatch(getNewTeamsData("created"));
    } catch (e) {
      dispatch(
        showSnackbar({
          message: "Failed to create team!",
          severity: "error",
        }),
      );
      console.log(e);
      // dispatch(handleTeamCreatedFailure());
    }
  };
};

export const handleUpdateTeamData = (data, id, teamType) => {
  return async (dispatch) => {
    try {
      dispatch(toggleLoading());

      await secure_instance.request({
        url: teamType === "auto" ? `v1/teams/${id}` : `v1/groups/${id}`,
        method: "PATCH",
        data: data,
      });

      // dispatch(toggleLoading());
      dispatch(getNewTeamsData("edited"));
    } catch (e) {
      console.log(e);
      dispatch(handleTeamEditedFailure());
    }
  };
};

export const {
  handleTeamsData,
  saveFetchTeamsData,
  handleUpdateTeamsData,
  toggleLoading,
  toggleTableLoading,
  toggleSideMenuTableLoading,
  handleGroupsData,
  handleIndividualTeamSelect,
  handleNewTeamData,
  toggleEditMenu,
  toggleAddMenu,
  handleTeamCreatedSuccess,
  handleTeamCreatedFailure,
  handleTeamEditedSuccess,
  handleTeamEditedFailure,
  handleTeamDeletedSuccess,
  handleCreateTeamButtonClick,
  setMultipleSelectedSlice,
  setTeamsTablePage,
  setTeamsSideMenuTablePage,
  setSideMenuTableRowsPerPage,
  setTableSelectedRows,
  handleCurrentViewApplied,
} = teamsSlice.actions;

export default teamsSlice.reducer;
