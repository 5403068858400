import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ReactComponent as Camera } from "../../assets/icons/Camera.svg";
import { secure_instance } from "../../axios/axios-config";

const ImageUploader = (props) => {
  const {
    data,
    isSideMenuOpen,
    setLoadingImage,
    setImageUrlToUpload,
    isRowClicked,
    currentRowToEdit,
    postImageURL,
    style,
    selectedImage,
    setSelectedImage,
    currentRowImage,
    setCurrentRowImage,
  } = props;

  const handleSelectedImage = async (e) => {
    e.preventDefault();

    setLoadingImage(true);
    setSelectedImage(e.target.files[0]);

    const formData = new FormData(); // pass in the form
    formData.append("image", e.target.files[0]);

    try {
      const response = await secure_instance.request({
        url: postImageURL,
        method: "Post",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

      setImageUrlToUpload(response.data.data);
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }

    e.target.value = "";
  };

  // required when image uploader is inside a side menu
  useEffect(() => {
    if (isSideMenuOpen === false) {
      setSelectedImage(null);
      setCurrentRowImage(null);
    }
  }, [isSideMenuOpen]);

  // required when side menu is opened with a table row
  useEffect(() => {
    if (isRowClicked) {
      if (currentRowToEdit.image_url !== null && selectedImage === null) {
        setCurrentRowImage(currentRowToEdit.image_url);
      }
    }
  }, [isRowClicked]);

  useEffect(() => {
    if (
      data !== null &&
      selectedImage === null &&
      (currentRowImage === null || currentRowImage === undefined)
    ) {
      setCurrentRowImage(data);
    }
  }, [data]);

  return (
    <div
      className="d-flex"
      style={{ justifyContent: "flex-end", flexDirection: "column" }}
    >
      {/* ,flexDirection:"column !important",justifyContent:"flex-end !important",
				flexWrap:"wrap" */}
      <div className="d-flex" style={{ ...style, justifyContent: "flex-end" }}>
        <label
          htmlFor="file-input"
          style={{
            position: "relative",
            background: "#F8F9FA",
            border: "1px solid #E9ECEF",
            padding: "3px 6px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            maxWidth: "300px",
          }}
        >
          {props.loadingImage && (
            <>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(108, 117, 125, 0.1)",
                  backdropFilter: "blur(1px)",
                  zIndex: 2,
                }}
              />

              <Spinner
                style={{ position: "absolute", top: "90px", left: "90px" }}
                className="loading"
                animation="grow"
                as="span"
                variant="dark"
              />
            </>
          )}

          {selectedImage || currentRowImage ? (
            <img
              width="198"
              height="210"
              style={{ objectFit: "contain" }}
              src={
                (isRowClicked || data) && selectedImage === null
                  ? currentRowImage
                  : selectedImage && URL.createObjectURL(selectedImage)
              }
              alt=""
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                cursor: "pointer",
                width: "198px",
                height: "210px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CloudUploadIcon
                  style={{ color: "#7B2CBF", fontSize: "100px" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="s2 grey8">Upload Profile Picture</span>
              </div>
            </div>
          )}

          <div
            style={{
              position: "absolute",
              bottom: "-9px",
              right: "-5px",
              width: "30px",
              height: "30px",
              background: "#F8F9FA",
              border: "1px solid #E9ECEF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30px",
              zIndex: "10",
            }}
          >
            <Camera
              style={{ width: "15px", height: "15px", cursor: "pointer" }}
              stroke="#7B2CBF"
              fill="#7B2CBF"
            />
          </div>
        </label>

        <input
          style={{ width: "50px", display: "none" }}
          onChange={(event) => handleSelectedImage(event)}
          id="file-input"
          type="file"
        />
      </div>
      <div className="d-flex" style={{ justifyContent: "flex-end" }}>
        {props.loadingImage && <h3 className="s2 grey8"></h3>}
      </div>
    </div>
  );
};

export default ImageUploader;
