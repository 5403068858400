import React from "react";
import CircularLoader from "./CircularLoader";
import BackgroundBlur from "../sub-components/Blur";

const LoadingAlert = () => {
  return (
    <>
      <div className="d-flex justify-content-center loader-popup">
        <div className="popup-loader-content">
          <CircularLoader />
          <h3>Please wait...</h3>
          <div className="justify-content-center">
            <h5>
              Groups and Users are autosyncing.
              <br />
              This wont take more than few seconds.
            </h5>
          </div>
        </div>
      </div>
      <BackgroundBlur />
    </>
  );
};

export default LoadingAlert;
