import React from "react";

const Skeleton = ({ type }) => {
  return (
    <div className="skeleton">
      <div className={type}> </div>
    </div>
  );
};

const SkeletonProduct = (props) => {
  return (
    <div className="skeleton-wrapper" style={{ ...props.style }}>
      <Skeleton type={props.type} />
      <div className="shimmer-wrapper">
        <div
          className={props.type === "bar" ? "graph-shimmer" : "shimmer"}
        ></div>
      </div>
    </div>
  );
};
export default SkeletonProduct;
