/* eslint-disable no-mixed-spaces-and-tabs */
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactComponent as Close } from "../../../assets/icons/X.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none !important",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

export default function CustomModal(props) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ ...props.style }}>
          <div>
            <Close
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 18,
                right: 15,
                margin: "2px 10px 5px 0",
                width: "24px",
              }}
              stroke="#343A40"
              strokeWidth="3"
              onClick={props.handleModalClose}
            />
            {props.users ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  className="head6 primary-color"
                  style={{ marginBottom: "16px" }}
                >
                  {props.activeUsers && "Move to Archived Users?"}
                  {props.archiveUsers && "Move to Active Users?"}
                </span>

                <span
                  className="s1 grey8"
                  style={{ marginBottom: "27px", flexWrap: "wrap" }}
                >
                  {props.activeUsers &&
                    "You are about to move this user to archived users. Would you like to continue?"}

                  {props.archiveUsers &&
                    `You are about to move ‘lauren Ipsum’ to active users as ‘lauren Ipsum’. 
                                    Are you sure you want to continue?`}
                </span>

                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    width: "100%",
                    borderTop: "1px solid #E9ECEF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "18px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="s1 grey8"
                      onClick={() => props.setIsModal(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="s1 primary-color"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleModalConfirmation(false)}
                    >
                      Confirm
                    </div>
                  </div>
                </div>
                {/* <Button title="Continue" className="b2" buttonFilledFull style={{ color: "white", minWidth:" 135px",	height: "40px",marginTop:"5px",fontWeight:"400",maxWidth:"135px" }}	// onClick={() => props.setIsSubscriptionCancelConfirm(true)}
                            	/>

                            	<div className="s1 primary-color" style={{cursor:"pointer",marginTop:"34px"}} 
                            		// onClick={handleClose}
                            	>
                        I don’t want to cancel my subscription
                            	</div> */}
              </div>
            ) : (
              props.cohorts && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span
                    className="head6 primary-color"
                    style={{ marginBottom: "16px" }}
                  >
                    {"Delete Cohort"}
                  </span>

                  <span
                    className="s1 grey8"
                    style={{ marginBottom: "27px", flexWrap: "wrap" }}
                  >
                    {
                      "You are about to delete this cohort. Would you like to continue?"
                    }
                  </span>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      width: "100%",
                      borderTop: "1px solid #E9ECEF",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="s1 grey8"
                        onClick={() => props.setIsModal(false)}
                      >
                        Cancel
                      </div>
                      <div
                        className="s1 primary-color"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.handleModalConfirmation(false)}
                      >
                        Confirm
                      </div>
                    </div>
                  </div>
                  {/* <Button title="Continue" className="b2" buttonFilledFull style={{ color: "white", minWidth:" 135px",	height: "40px",marginTop:"5px",fontWeight:"400",maxWidth:"135px" }}	// onClick={() => props.setIsSubscriptionCancelConfirm(true)}
                        />

                        <div className="s1 primary-color" style={{cursor:"pointer",marginTop:"34px"}} 
                            // onClick={handleClose}
                        >
                I don’t want to cancel my subscription
                        </div> */}
                </div>
              )
            )}

            {props.teams && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  className="head6 primary-color"
                  style={{ marginBottom: "16px" }}
                >
                  {/* {"Delete Cohort"}  */}
                  {props.heading}
                </span>

                <span
                  className="s1 grey8"
                  style={{ marginBottom: "27px", flexWrap: "wrap" }}
                >
                  {props.text}
                  {/* {"You are about to delete this cohort. Would you like to continue?"} */}
                </span>

                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    width: "100%",
                    borderTop: "1px solid #E9ECEF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "18px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="s1 grey8"
                      onClick={() => props.setIsModal(false)}
                    >
                      {props.leftButton}
                    </div>
                    <div
                      className="s1 primary-color"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleModalConfirmation(false)}
                    >
                      {props.rightButton}
                      {/* Confirm */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* {props.activeUsers &&
							<div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
								<span className="head6 primary-color" style={{ marginBottom: "16px" }}>
									Move to Active Users?
								</span>

								<span className="s1 grey8" style={{ marginBottom: "27px", flexWrap: "wrap" }}>
									You are about to move ‘lauren Ipsum’ to active users as ‘lauren Ipsum’.
									Are you sure you want to continue?
								</span>

								<div style={{ position: "absolute", bottom: "20px", width: "100%", borderTop: "1px solid #E9ECEF" }}>
									<div style={{
										display: "flex", justifyContent: "space-around", alignItems: "center",
										marginTop: "18px", cursor: "pointer"
									}}>
										<div className="s1 grey8" onClick={() => props.setIsModal(false)}>
											Cancel
										</div>
										<div className="s1 primary-color" style={{ cursor: "pointer" }}
											onClick={() => props.setIsModal(false)}
										>
											Yes, Continue
										</div>
									</div>
								</div>
							</div>
						} */}
            {/* 
						{props.identityProvidersTeamsModal && 
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style} className="remove-teams-modal">
								<Typography id="modal-modal-title" variant="h6" component="h2" style={{fontFamily:"Poppins",fontWeight:"500",}}>
							Remove from team
								</Typography>
								<Typography id="modal-modal-content" style={{fontFamily:"Poppins",fontWeight:"400",fontSize:"13px",marginTop:"10px"}}>
							Are you sure you want to remove group {`"${props.groupName}"`} as a team {`"${props.teamName}"`}
								</Typography>
								<Button buttonFilledMd title= "Remove Team"  
									style={{color:"white",width: "213px",margin:"15px 0"}}
									onClick={() => props.removePopUpHandler()}	
								/>
								<Button buttonHollowMd title="Cancel" style={{width: "213px"}} onClick={handleClose} />
							</Box>
						</Modal>
						} */}
            {props.identityProvidersTeamsModal && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px 0",
                }}
              >
                <span className="head5 grey9" style={{ marginBottom: "16px" }}>
                  {/* {"Delete Cohort"}  */}
                  {props.heading}
                </span>

                <span className="s1 grey7" style={{ flexWrap: "wrap" }}>
                  {props.text}
                  {/* {"You are about to delete this cohort. Would you like to continue?"} */}
                </span>

                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    width: "100%",
                    borderTop: "1px solid #E9ECEF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "18px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="s1 grey8"
                      onClick={() => props.handleModalClose()}
                    >
                      {props.leftButton}
                    </div>
                    <div
                      className="s1 primary-color"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.removePopUpHandler()}
                    >
                      {props.rightButton}
                      {/* Confirm */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
