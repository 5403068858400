import React from "react";
import warning from "../../assets/icons/Warning.svg";

export default function Alerts(props) {
  if (props.billings) {
    return (
      <div
        className="d-flex align-items-center h6 grey0"
        style={{
          backgroundColor: "#EE6055",
          paddingLeft: "28px",
          height: "92px",
          marginBottom: "0px",
        }}
      >
        <img style={{ paddingRight: "15px" }} src={warning} />
        <span className="head6 grey0">
          Alert: Your payment method has expired. Please
          <span
            className="primary-color"
            onClick={() => props.handleExpiredCardSideMenu()}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Click here{" "}
          </span>
          to add a new payment method.
        </span>
      </div>
    );
  }
}
