import { combineReducers } from "redux";

//Add Additional Reducers Here
import authSlice from "./slices/authSlice";
import teamsSlice from "./slices/teamsSlice";
import tableSlice from "./slices/tableSlice";
import integrationsSlice from "./slices/integrationsSlice";
import usersSlice from "./slices/usersSlice";
import cohortsSlice from "./slices/cohortsSlice";
import settingsSlice from "./slices/settingsSlice";
import companySlice from "./slices/companySlice";
import menuSlice from "./slices/menuSlice";
import eventsSlice from "./slices/eventsSlice";
import notificationsSlice from "./slices/notificationsSlice";
import ObjectivesSlice from "./slices/ObjectivesSlice";
import dashboardSlice from "./slices/dashboardSlice";
import identityProviderSlice from "./slices/identityProviderSlice";
import billingSlice from "./slices/billingSlice";
import departmentSlice from "./slices/departmentSlice";
import surveySlice from "./slices/surveySlice";

const allReducers = combineReducers({
  authReducer: authSlice,
  identityProviderReducer: identityProviderSlice,
  integrationsReducer: integrationsSlice,
  usersReducer: usersSlice,
  cohortsReducer: cohortsSlice,
  tableReducer: tableSlice,
  billingsReducer: billingSlice,
  teamsReducer: teamsSlice,
  settingsReducer: settingsSlice,
  companyReducer: companySlice,
  dashboardReducer: dashboardSlice,
  menuReducer: menuSlice,
  eventsReducer: eventsSlice,
  notificationsReducer: notificationsSlice,
  objectivesReducer: ObjectivesSlice,
  departmentsReducer: departmentSlice,
  surveysReducer: surveySlice,
});

export default allReducers;
