import React from "react";
import { capitalizeFirstLetter } from "./Utils";
import { ReactComponent as Grid } from "../assets/icons/Sidebar/grid.svg";
import { ReactComponent as GitBranch } from "../assets/icons/Sidebar/git-branch.svg";
import { ReactComponent as Integrations } from "../assets/icons/Sidebar/integrations.svg";
import { ReactComponent as Users } from "../assets/icons/Sidebar/users.svg";
import { ReactComponent as Vector } from "../assets/icons/Sidebar/Vector.svg";
import { ReactComponent as WpfSurvey } from "../assets/icons/Sidebar/wpf_survey.svg";
import { ReactComponent as BillingIcon } from "../assets/icons/Sidebar/billing.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/Sidebar/settings.svg";

// export const StripeApiKey = "pk_test_HerFpGS9a4wwWEa3emfBETfv"; //.env->STRIPE_PUBLIC_KEY
export const StripeApiKey =
  "pk_test_51LDXgtI06i6syYeJwBiJd3Qack9zTVcEqwRF4fAVuflbUdO9ksCKGIV60g6Bzif2DsU4bwK6isqQXM5pUdErd00m007nlSCW2a"; //.env->STRIPE_PUBLIC_KEY
export const GoogleMapApiKey = "AIzaSyAkdHshh-y0TpVSWElszzzBlVWf3Nl4218"; //GOOGLE_MAP_API_KEY
export const ownerRoutes = [{ path: "/confirm-plan" }];

export const BOARD_SECTIONS = {
  "not started": "Not Started",
  "in progress": "In progress",
  completed: "Completed",
};

export const USERS_COLUMNS = [
  {
    key: "full_name",
    name: "Name",
    minWidth: 280,
  },
  {
    key: "primary_email",
    name: "Email",
    minWidth: 280,
  },
  {
    key: "title",
    name: "Job Title",
    minWidth: 280,
  },
  {
    key: "role",
    name: "Privilege(s)",
    minWidth: 280,
  },
];

export const TEAMS_COLUMNS = [
  {
    key: "name",
    name: "Full Name",
    // align:"center",
    minWidth: 250,
  },
  {
    key: "description",
    name: "Description",
    minWidth: 300,
  },
  {
    key: "sync_type",
    name: "Type",
    accessor: (row) => (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          width: "65px",
          background:
            row?.team_group?.length && row?.team_group[0]?.sync_type === "auto"
              ? "#E6FCEB"
              : "#F4E6FF",
          padding: "6px",
          borderRadius: "10px",
        }}
      >
        <span
          style={{
            color:
              row?.team_group?.length &&
              row?.team_group[0]?.sync_type === "auto"
                ? "#00A926"
                : "#6E00BF",
          }}
          className="body2"
        >
          {capitalizeFirstLetter(
            row?.team_group?.length && row?.team_group[0]?.sync_type,
          )}
        </span>
      </div>
    ),
    minWidth: 100,
  },
  {
    key: "members",
    name: "Members",
    accessor: (row) => (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginLeft: "16px" }}
        >
          {row?.team_group?.length &&
          row?.team_group[0]?.group_members?.length > 0 ? (
            row?.team_group.length &&
            row?.team_group[0]?.group_members.slice(0, 3).map((member) => {
              // member.user_profile.image_url
              return member?.user_profile.image_url !== "" ? (
                <img
                  className="event-avatar-image"
                  style={{ marginLeft: "-16px" }}
                  src={member?.user_profile.image_url}
                  alt={`${member?.user_profile.first_name} ${member?.user_profile.last_name}`}
                />
              ) : (
                <div className="event-avatar" style={{ marginLeft: "-16px" }}>
                  {member?.user_profile.first_name.charAt(0).toUpperCase()}
                  {member?.user_profile.last_name.charAt(0).toUpperCase()}
                </div>
              );
            })
          ) : (
            <span className="body1 grey6">0</span>
          )}
        </div>
        {row?.team_group?.length &&
          row?.team_group[0]?.group_members.length - 3 > 0 && (
            <span className="ml-2 body1 grey6">
              {"+"}
              {row?.team_group?.length &&
                row?.team_group[0]?.group_members.length - 3}
            </span>
          )}
      </>
    ),
    minWidth: 150,
  },
];

export const SIDEBARITEMS = [
  {
    name: "Analytics",
    route: "/dashboard",
    image: <Grid />,
    stroke: "white", // Add stroke property here
  },
  {
    name: "Objectives",
    route: "objectives",
    image: <Vector />,

    stroke: "white",
  },
  {
    name: "Surveys",
    route: "/surveys",
    image: <WpfSurvey />,

    fill: "#56585A",
  },
  {
    name: "People",
    route: "people",
    image: <Users />,
    stroke: "white",
  },
  {
    name: "Integrations",
    route: "integrations",
    image: <Integrations />,

    // stroke: "#56585A",
  },
  {
    name: "Identity Providers",
    route: "identity-providers-list",
    image: <GitBranch />,
    stroke: "white",
  },
  {
    name: "Billing",
    route: "billing",
    image: <BillingIcon />,
    stroke: "white",
  },
  {
    name: "Settings",
    route: "settings",
    image: <SettingsIcon />,
    stroke: "white",
  },
];
