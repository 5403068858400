import React from "react";
// import process from "../../../assets/icons/Process.svg";
import Button from "../../../components/sub-components/Button";
import Header from "./Header";
import { useHistory } from "react-router";
import Footer from "../../../components/sub-components/Footer";

export default function LinkExpired() {
  const history = useHistory();

  const handleResendLink = () => {
    history.push("/forgot-password");
  };

  return (
    <>
      <div className="signup">
        <Header />
        <div
          className="d-flex flex-column align-items-center"
          style={{ textAlign: "center" }}
        >
          <div
            className="head3"
            style={{ paddingTop: "130px", color: "#7B2CBF" }}
          >
            Link Expired
          </div>
          <div
            className="head6"
            style={{ paddingTop: "30px", paddingBottom: "40px" }}
          >
            It seems that this link has expired. Please generate a new link.
          </div>

          <div style={{ width: "180px" }}>
            <Button
              buttonFilledFull
              title="Resend Link"
              onClick={handleResendLink}
            />
          </div>
        </div>
      </div>
      <div
        className="col-lg-12 d-flex flex-row justify-content-center align-items-center"
        style={{ gap: "43.5px", marginTop: "180px" }}
      >
        <Footer />
      </div>
    </>
  );
}
