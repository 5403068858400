import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../assets/styles/App.scss";
import ProtectedRoute from "./ProtectedRoute";
import Signup from "./pages/SignUp-Flow/Signup";
import JoinOrganization from "./pages/SignUp-Flow/JoinOrganization";
import AccountActivated from "./pages/SignUp-Flow/AccountActivated";
import Login from "./pages/Login/Login";
import BasicInfo from "./pages/Company-Setup/BasicInfo";
import IdentityProvidersList from "./pages/Company-Setup/IdentityProviders";
import ConfirmPlan from "./pages/SignUp-Flow/ConfirmPlan";
import Error404 from "./pages/Error404";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import Teams from "./pages/Teams/Teams";
import Dashboard from "./pages/Dashboard/Dashboard";
import Integrations from "./pages/Company-Setup/Integrations";
import ActivationPending from "./pages/SignUp-Flow/ActivationPending";
import Billing from "./pages/Company-Setup/Billing";
import Settings from "./pages/Settings/Settings";
import { useDispatch, useSelector } from "react-redux";
import LinkExpired from "./pages/Login/LinkExpired";
import FlashIconMenu from "../components/Menus/FlashIconMenu";
import Events from "./pages/Events/Events";
import Objectives from "./pages/Objectives/Objectives";
import Surveys from "./pages/Surveys/Surveys";
import { toggleFlashNotificationsMenu } from "../redux/slices/menuSlice";
import { Snackbar } from "../components/Toast/Toast";
import { hideSnackbar } from "../redux/slices/authSlice";
import Cohorts from "./pages/People/Cohorts";
import People from "./pages/People/People";
import SetPassword from "./pages/Login/SetPassword";

function App() {
  const dispatch = useDispatch();
  const menuRef = useRef(null);

  useEffect(() => {
    // Function to handle mousedown events outside of the menu
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        dispatch(toggleFlashNotificationsMenu(false));
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const { isFlashNotificationsMenuOpen } = useSelector(
    (state) => state.menuReducer,
  );

  const { snackbar } = useSelector((state) => state.authReducer);

  const handleCloseSnackbar = () => {
    dispatch(hideSnackbar());
  };
  return (
    <>
      <Router>
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
        />
        <div
          ref={menuRef}
          style={{ position: "absolute", top: 90, right: 50 }}
          className={`flash-notification-menu-container ${
            isFlashNotificationsMenuOpen
              ? "open"
              : "flash-notification-menu-container"
          }`}
        >
          <FlashIconMenu showMenu={isFlashNotificationsMenuOpen} />
        </div>

        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route path="/login/:token" component={ResetPassword} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/set-password/:token" component={SetPassword} />
          <Route path="/basic-info" component={BasicInfo} />
          <ProtectedRoute path="/teams" component={Teams} />
          <ProtectedRoute path="/cohorts" component={Cohorts} />
          <Route path="/signup" component={Signup} />
          <Route path="/activation/:token" component={AccountActivated} />
          <Route path="/activation" component={ActivationPending} />
          <Route path="/confirm-plan" component={ConfirmPlan} />
          <Route path="/link-expired" component={LinkExpired} />
          <ProtectedRoute
            path="/join-organization"
            component={JoinOrganization}
          />
          <ProtectedRoute path="/events" component={Events} />
          <ProtectedRoute path="/dashboard" component={Dashboard} />
          <ProtectedRoute path="/people" component={People} />
          <ProtectedRoute path="/objectives" component={Objectives} />
          <ProtectedRoute path="/surveys" component={Surveys} />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/integrations" component={Integrations} />
          <ProtectedRoute
            path="/identity-providers-list"
            component={IdentityProvidersList}
          />
          <ProtectedRoute path="/billing" component={Billing} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
