import { Box } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  top: "50%",
  outline: "none",
  borderRadius: "10px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  maxWidth: "611px",
  minWidth: "328px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const CustomBox = ({ children }) => {
  return (
    <Box sx={style} className="d-flex align-items-center col-sm-12">
      {children}
    </Box>
  );
};

export default CustomBox;
