import React, { useState, useEffect } from "react";
import { Table } from "../../../components/Tables/Table";
import { useSelector, useDispatch } from "react-redux";
import EventsMenu from "../../../components/SideMenus/Events/EventsMenu";
import Modal from "@mui/material/Modal";
import BackgroundBlur from "../../../components/sub-components/Blur";
import { formatDate } from "../../../utilities/dateConversion";
import {
  deleteLifeEvents,
  getAllLifeEvents,
  selectEventType,
  setCurrentRowEventsSlice,
  setEventsTablePage,
  setIsEditEvents,
  setMultipleSelectedEventsSlice,
  setTableSelectedRows,
  toggleAddMenu,
  toggleEventMenuClose,
  toggleEventMenuOpen,
  updateCurrentScreen,
  updateEventsData,
} from "../../../redux/slices/eventsSlice";
import { setUserProfile } from "../../../redux/slices/authSlice";
import CustomBox from "../../../components/Modals/CustomBox";

export default function Events() {
  const dispatch = useDispatch();
  const { isEventSideMenuOpen, showEditMenu, showAddMenu, formData } =
    useSelector((state) => state.eventsReducer);
  const { isMultipleRowsSelected, selected, data, page, tableLoading } =
    useSelector((state) => state.eventsReducer.tableData);

  const { userProfile } = useSelector((state) => state.authReducer);

  const [inputValue, setInputValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentrow, setCurrentrow] = React.useState({});

  const columns = [
    {
      key: "event_name",
      name: "Title",
      // minWidth: 210,
    },
    {
      key: "description",
      name: "Description",
      minWidth: 261,
    },
    {
      key: "start_date",
      name: "Date",
      accessor: (row) => (
        <div>
          {formatDate(row.start_date) + " - " + formatDate(row.end_date)}
        </div>
      ),
      // minWidth: 241,
    },
    {
      key: "involves",
      accessor: (row) => (
        <>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ marginLeft: "16px" }}
          >
            {row?.categories?.event_type === "personal" ? (
              <>
                {userProfile?.image_url ? (
                  <img
                    className="event-avatar-image"
                    src={userProfile?.image_url}
                    alt={`${userProfile?.first_name} ${userProfile?.last_name}`}
                  />
                ) : (
                  <div className="event-avatar">
                    {userProfile?.first_name?.charAt(0)?.toUpperCase()}
                    {userProfile?.last_name?.charAt(0)?.toUpperCase()}
                  </div>
                )}
              </>
            ) : (
              <>
                {row.life_event_mappings.slice(0, 3).map((data, index) => (
                  <div key={index}>
                    {data?.user_profiles?.image_url !== "" ? (
                      <img
                        className="event-avatar-image"
                        style={{ marginLeft: "-16px" }}
                        src={data?.user_profiles.image_url}
                        alt={`${data?.user_profiles.first_name} ${data?.user_profiles.last_name}`}
                      />
                    ) : (
                      <div
                        className="event-avatar"
                        style={{ marginLeft: "-16px" }}
                      >
                        {data?.user_profiles.first_name.charAt(0).toUpperCase()}
                        {data?.user_profiles.last_name.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                ))}
                {row.life_event_mappings.length - 3 > 0 && (
                  <span className="ml-2 body1 grey6">
                    {"+"}
                    {row.life_event_mappings.length - 3}
                  </span>
                )}
              </>
            )}
          </div>
        </>
      ),

      name: "Users",
      // minWidth: 220,
    },
  ];

  const handleEventsSideMenuOpen = () => {
    dispatch(setCurrentRowEventsSlice({}));
    dispatch(updateCurrentScreen());
    dispatch(toggleEventMenuOpen());
  };

  const handleEventsSideMenuClose = () => {
    dispatch(toggleEventMenuClose());
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleCurrentRow = (row) => {
    editMenu();
    dispatch(setCurrentRowEventsSlice(row));
    dispatch(setIsEditEvents(true));
  };

  const editMenu = () => {
    dispatch(updateCurrentScreen(3));
    dispatch(toggleEventMenuOpen());
    handleActionMenuClose();
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const setTablePage = (data) => {
    dispatch(setEventsTablePage(data));
  };

  const setMultipleSelected = (boolean) => {
    dispatch(setMultipleSelectedEventsSlice(boolean));
  };

  const setSelected = (data) => {
    dispatch(setTableSelectedRows(data));
  };

  const deleteEvent = () => {
    // const eventsDataCloned = data.map((a) => ({ ...a }));

    if (showAddMenu) {
      const map = { events: [{ id: formData.id }] };
      dispatch(deleteLifeEvents(map));
      handleCloseModal();
      // const filteredTeams = eventsDataCloned.filter(
      //   (obj) => obj.id !== formData.id,
      // );
      // dispatch(updateEventsData(filteredTeams));  not the interal defination of the function
      dispatch(toggleAddMenu());
    }
    if (!showAddMenu) {
      const map = { events: selected.map((obj) => ({ id: obj.id })) };
      dispatch(deleteLifeEvents(map));
      handleCloseModal();
      // const filteredTeams = eventsDataCloned.filter(
      //   (obj) => !selected.some((sel) => sel.id === obj.id),
      // );
      // dispatch(updateEventsData(filteredTeams));  not the interal defination of the function
    }
    setSelected([]);
  };

  useEffect(() => {
    dispatch(getAllLifeEvents());
  }, []);

  useEffect(() => {
    const storedUserProfile = localStorage.getItem("userProfile");
    if (storedUserProfile) {
      dispatch(setUserProfile(JSON.parse(storedUserProfile)));
    }
  }, []);

  useEffect(() => {
    if (!isEventSideMenuOpen) {
      dispatch(setIsEditEvents(false));
      dispatch(selectEventType(""));
    }
  }, [isEventSideMenuOpen]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBox>
          <span className="head6 primary-color">Delete Event</span>

          <span className="s1 grey8" style={{ paddingTop: "3px" }}>
            Are you sure you want to delete selected Event(s)?
          </span>

          <div
            style={{
              position: "absolute",
              bottom: "20px",
              width: "100%",
              borderTop: "1px solid #E9ECEF",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "18px",
                cursor: "pointer",
              }}
            >
              <div className="s1 grey8" onClick={handleCloseModal}>
                Cancel
              </div>
              <div
                className="s1 primary-color"
                style={{ cursor: "pointer" }}
                onClick={() => deleteEvent()}
              >
                Yes, delete
              </div>
            </div>
          </div>
        </CustomBox>
      </Modal>

      <div
        className={`side-menu-box ${
          isEventSideMenuOpen ? "open border" : "side-menu-box border"
        }`}
      >
        <EventsMenu
          currentRowToEdit={currentrow}
          deleteTeam={handleOpenModal}
          setCurrentRowToEdit={setCurrentrow}
        />
      </div>
      <div className="flex-column">
        {/* <div> */}
        {isEventSideMenuOpen && (
          <BackgroundBlur events closeMenu={handleEventsSideMenuOpen} />
        )}
        {isEventSideMenuOpen && (
          <BackgroundBlur events closeMenu={handleEventsSideMenuClose} />
        )}
        <Table
          data={data}
          headerBackgroundColor="#F7FBFF"
          columns={columns}
          loading={tableLoading && !showAddMenu && !showEditMenu}
          page={page}
          inputValue={inputValue}
          setTablePage={setTablePage}
          selected={selected}
          setSelected={setSelected}
          sortBy="id"
          handleTableRowClick={handleCurrentRow}
          anchorEl={anchorEl}
          setMultipleSelected={setMultipleSelected}
          handleToolbarAction={handleOpenModal}
          isMultipleRowsSelected={isMultipleRowsSelected}
          toolbarText={["Delete Selected Events", "Delete All Events"]}
          checkboxSize="24px"
          checkboxRequired
          setCurrentrow={setCurrentrow}
        />
      </div>
    </>
  );
}
