import React from "react";
import Header from "../../../components/sub-components/Header";
import def from "../../../assets/icons/Default Image 2.svg";
import Field from "../../../components/sub-components/Field";
import Button from "../../../components/sub-components/Button";

export default function BasicInfo() {
  return (
    <div>
      <Header header3 title="Company Setup" />

      <div className="page-body ">
        <div
          className="d-flex flex-column "
          style={{ width: "300px", height: "100%", textAlign: "left" }}
        >
          <div className="s1" style={{ paddingBottom: "10px" }}>
            Company Logo
          </div>
          <img src={def} style={{ cursor: "pointer", width: "80%" }} />
        </div>

        <div style={{ marginLeft: "", width: "100%" }}>
          <div className="row">
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Company Name
              </div>
              <Field inputFieldFull />
            </div>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Address
              </div>
              <Field inputFieldFull />
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                State
              </div>
              <Field fieldDropdownFull />
            </div>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Country
              </div>
              <div style={{ width: "100%" }}>
                <Field fieldDropdownFull />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Phone
              </div>
              <Field inputFieldFull />
            </div>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Zip Code
              </div>
              <Field inputFieldFull />
            </div>
          </div>

          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Website URL
              </div>
              <Field inputFieldFull />
            </div>
          </div>

          <div style={{ paddingTop: "25px", width: "140px" }}>
            <Button buttonFilledFull title="Save" />
          </div>
        </div>
      </div>
    </div>
  );
}
