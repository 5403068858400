/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import { ReactComponent as Process } from "../../../assets/icons/Process-confirm-plan.svg";
import Field from "../../../components/sub-components/Field";
import Button from "../../../components/sub-components/Button";
import { useDispatch, useSelector } from "react-redux";
import Packages from "./Packages";
import PaymentInfo from "./PaymentInfo";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Redirect } from "react-router";
import { StripeApiKey } from "../../../utilities/Constants";
import Header from "../Login/Header";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import CircularStatic from "../../../components/sub-components/CircularProcess";
import {
  checkCouponDetails,
  getStripePricingsSliceCall,
  getSubscriptionPackagesSliceCall,
  getTenantSubscriptionSliceCall,
  handleCouponCodeChange,
  removeCoupon,
  setStripePriceId,
  toggleModal,
} from "../../../redux/slices/authSlice";
import Footer from "../../../components/sub-components/Footer";

export default function ConfirmPlan() {
  const stripePromise = loadStripe(StripeApiKey);

  const dispatch = useDispatch();
  const [monthly, setMonthly] = useState(true);
  const [yearly, setYearly] = useState(false);
  const [starter, setStarter] = useState(true);
  const [professional, setProfessional] = useState(false);
  const [price, setPrice] = useState(null);
  const [plan, setPlan] = useState(null);
  const [applyCoupon, setApplyCoupon] = useState(false);

  const user = useSelector((state) => state.authReducer.user);
  const subscriptionPackages = useSelector(
    (state) => state.authReducer.subscriptionPackages,
  );

  const isPackagesModal = useSelector(
    (state) => state.authReducer.isPackagesModal,
  );

  const subscription = useSelector((state) => state.authReducer.subscription);
  const { stripePricings } = useSelector((state) => state.authReducer);
  const isSubscribed = useSelector((state) => state.authReducer.isSubscribed);
  const couponCode = useSelector((state) => state.authReducer.couponCode);
  const tenantSubscription = useSelector(
    (state) => state.authReducer.tenantSubscription,
  );
  const couponResult = useSelector((state) => state.authReducer.couponResult);

  const mediaQuery = useWindowDimensions();

  const handleYearlyClicked = () => {
    setYearly(true);
    setMonthly(false);
  };

  const handleMonthlyClicked = () => {
    setMonthly(true);
    setYearly(false);
  };

  const handleChangePlanClicked = (selected) => {
    if (selected === "Starter") {
      setStarter(true);
      setProfessional(false);
    } else {
      setStarter(false);
      setProfessional(true);
    }
  };

  const handleOpenModal = () => {
    dispatch(toggleModal());
  };

  const handleSubmit = () => {
    let stripePriceId = null;
    // let packageId = null;
    if (starter) {
      if (monthly) {
        stripePriceId = stripePricings[0]?.stripe_pricings[0].id;
      } else {
        stripePriceId = stripePricings[0]?.stripe_pricings[1].id;
      }
      // packageId = 1;
    } else if (professional) {
      if (monthly) {
        stripePriceId = stripePricings[1]?.stripe_pricings[0].id;
      } else {
        stripePriceId = stripePricings[1]?.stripe_pricings[1].id;
      }
      // packageId = 2;
    }

    dispatch(setStripePriceId({ stripePriceId }));
  };

  const handleCouponCodeChangeSetter = (target) => {
    dispatch(handleCouponCodeChange(target));
  };

  const handleCheckCouponDetails = () => {
    if (couponResult.isCouponCodeApplied) {
      dispatch(removeCoupon());
    } else {
      dispatch(checkCouponDetails(couponCode));
    }
  };

  useEffect(() => {
    if (monthly) {
      if (starter) {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[0]?.package_details?.prices?.monthly *
                couponResult?.percentOff) /
              100
            }/mo`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[0]?.package_details?.prices?.monthly -
              couponResult?.amountOff / 100
            }/mo`,
          );
        } else {
          setPrice(
            `$${stripePricings[0]?.stripe_pricings[0]?.price_per_unit}/mo`,
          );
        }
      } else if (professional) {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[1]?.package_details?.prices?.monthly *
                couponResult?.percentOff) /
              100
            }/mo`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[1]?.package_details?.prices?.monthly -
              couponResult?.amountOff / 100
            }/mo`,
          );
        } else {
          setPrice(
            `$${stripePricings[1]?.stripe_pricings[0]?.price_per_unit}/mo`,
          );
        }
      }
    } else if (yearly) {
      if (starter) {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[0]?.package_details?.prices?.yearly *
                couponResult?.percentOff) /
              100
            }/year`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[0]?.package_details?.prices?.yearly -
              couponResult?.amountOff / 100
            }/year`,
          );
        } else {
          setPrice(
            `$${stripePricings[0]?.stripe_pricings[1]?.price_per_unit}/year`,
          );
        }
      } else if (professional) {
        if (couponResult?.percentOff) {
          setPrice(
            `$${
              (subscriptionPackages[1]?.package_details?.prices?.yearly *
                couponResult?.percentOff) /
              100
            }/year`,
          );
        } else if (couponResult?.amountOff) {
          setPrice(
            `$${
              subscriptionPackages[1]?.package_details?.prices?.yearly -
              couponResult?.amountOff / 100
            }/year`,
          );
        } else {
          setPrice(
            `$${stripePricings[1]?.stripe_pricings[1]?.price_per_unit}/year`,
          );
        }
      }
    }

    if (starter) {
      setPlan(`${stripePricings[0]?.name} Plan`);
    } else if (professional) {
      setPlan(`${stripePricings[1]?.name} Plan`);
    }
  }, [
    subscriptionPackages,
    stripePricings,
    monthly,
    yearly,
    starter,
    professional,
    couponResult,
  ]);

  useEffect(() => {
    dispatch(getSubscriptionPackagesSliceCall());
    dispatch(getStripePricingsSliceCall());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTenantSubscriptionSliceCall(user.tenantId));
  }, [dispatch, user]);

  if (subscription?.stripePriceId && !isSubscribed && !tenantSubscription) {
    return (
      <Elements stripe={stripePromise}>
        <PaymentInfo />
      </Elements>
    );
  } else if (isSubscribed || tenantSubscription) {
    return <Redirect to="/join-organization" />;
  }

  return (
    <div className="signup">
      <Header />

      {mediaQuery.width > 425 ? (
        <div className="row">
          <div className="col-lg-12">
            <Process style={{ marginTop: "60px" }} className="process-logo" />
          </div>
        </div>
      ) : (
        <div
          className="row mt-5"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <CircularStatic currentStep={2} totalSteps={3} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              marginLeft: "10px",
            }}
          >
            <h6 className="s1 grey8" style={{ marginBottom: "0" }}>
              Confirm Plan
            </h6>
            <span
              className="s1 grey8"
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: "0.4px",
              }}
            >
              Next Step: Enter payment info
            </span>
          </div>
        </div>
      )}

      <div className="signup-fields">
        <div className="row" style={{ paddingTop: "60px", textAlign: "left" }}>
          <div className="col-lg-12">
            <div className="d-flex align-items-center">
              <span
                className="head4"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Confirm Plan
              </span>
            </div>
          </div>
        </div>

        <Packages
          show={isPackagesModal}
          plan={plan}
          packages={stripePricings}
          // packages={subscriptionPackages}
          monthly={`${monthly}`}
          onChange={(selected) => handleChangePlanClicked(selected)}
          mediaQuery={mediaQuery}
        />

        <div className="row">
          <div className="col-lg-12 d-flex flex-row">
            <div
              className="b1"
              style={{
                borderBottom: monthly ? "4px solid #7B2CBF" : "",
                padding: "10px 30px",
                cursor: "pointer",
              }}
              onClick={handleMonthlyClicked}
            >
              Monthly
            </div>
            <div
              className="b1"
              style={{
                borderBottom: yearly ? "4px solid #7B2CBF" : "",
                padding: "10px 30px",
                cursor: "pointer",
              }}
              onClick={handleYearlyClicked}
            >
              Yearly
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "45px", paddingBottom: "8px" }}
        >
          <div className="col-lg-12 d-flex flex-row justify-content-between">
            <div className="head5">{plan}</div>
            <div className="s1">{price}</div>
          </div>
        </div>

        <div
          className="b1"
          onClick={handleOpenModal}
          style={{ color: "#7B2CBF", cursor: "pointer" }}
        >
          Change Plan
        </div>

        <hr
          style={{
            border: "2px solid #E9ECEF",
            width: "100%",
            marginBottom: "25px",
            marginTop: "25px",
          }}
        />

        <div
          className="row"
          style={{ paddingTop: "45px", paddingBottom: "10px" }}
        >
          <div className="col-lg-12 d-flex flex-row justify-content-between">
            <div className="head5">Total Payment</div>
            <div className="s1">{price}</div>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingTop: "45px", paddingBottom: "10px" }}
        >
          <div className="col-lg-12 d-flex flex-row justify-content-between">
            <div className="head5">Payment Due Today</div>
            <div className="s1">$0.00</div>
          </div>
        </div>

        <div
          className="d-flex flex-row-reverse s1"
          onClick={() => setApplyCoupon(!applyCoupon)}
          style={{
            paddingBottom: "10px",
            color: "#7B2CBF",
            cursor: "pointer",
          }}
        >
          Apply Coupon Code
        </div>
        {applyCoupon && (
          <Field
            style={{ float: "right", marginBottom: "30px" }}
            inputFieldApplyCoupon
            placeholder="Coupon Code"
            disabled={!couponCode}
            name="couponCode"
            value={couponCode}
            applied={couponResult.isCouponCodeApplied}
            errorText={couponResult.couponErrorText}
            onChange={(e) => handleCouponCodeChangeSetter(e.target)}
            onClick={handleCheckCouponDetails}
          />
        )}

        <div style={{ marginTop: "24px" }}>
          <Button buttonFilledFull title="Continue" onClick={handleSubmit} />
        </div>

        <div className="s1" style={{ paddingTop: "15px", textAlign: "center" }}>
          Next Payment: $29.99 on 18/09/2021
        </div>

        <Footer />
        {/* 
        <div
          className="d-flex justify-content-around"
          style={{ paddingTop: "100px" }}
        >
          <div className="s2 grey8">Privacy Policy</div>
          <div className="s2 grey8">Help</div>
          <div className="s2 grey8">Terms and Conditions</div>
        </div> */}
      </div>
    </div>
  );
}
