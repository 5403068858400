import { styled, alpha } from "@mui/material/styles";
import React from "react";
import { Menu } from "@mui/material";

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    // These props can be sent from parent component as it is, disabled here due to positioning issues
    // anchorOrigin={{
    //   vertical: props.anchorOriginvertical,
    //   horizontal: props.anchorOriginhorizontal,
    // }}
    // transformOrigin={{
    //   vertical: props.transformOriginvertical,
    //   horizontal: props.transformOriginhorizontal,
    // }}
    PaperProps={{
      style: {
        ...props.paperStyle,
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 2px 10px rgba(163, 163, 163, 0.06)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
