import React from "react";

export default function Footer(props) {
  return (
    // <div
    //   className="footer-container fixed-bottom d-flex justify-content-center align-items-center"
    //   style={{ gap: "43.5px", height: "100px" }}
    // >
    <div
      className="footer-container sticky-bottom d-flex justify-content-center align-items-center"
      style={{ gap: "43.5px", height: "100px" }}
    >
      <div className="s2 grey8">Privacy Policy</div>
      <div className="s2 grey8">Help</div>
      <div className="s2 grey8">Terms and Conditions</div>
    </div>
  );
}
