import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardGraph from "./DashboardGraph";
import { generateQueryForLineGraph } from "../../utilities/CubeUtils";
import { getGraphGranularityMappings } from "../../utilities/Utils";
import { secure_instance } from "../../axios/axios-config";

const ActiveTimePerTeam = () => {
  // const dispatch = useDispatch();
  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );
  const [selectedGranularity, setSelectedGranularity] = useState("monthly");
  const [selectedGroupByOption, setSelectedGroupByOption] = useState("teams");
  const cumulativeGraphAnchorRef = useRef(null);
  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);

  const groupByAnchorEl = useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = useState(false);
  const [teamDataForMapping, setTeamDataForMapping] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const generateQuery = (selectedGranularity, groupByOption) => {
    const query = generateQueryForLineGraph(
      dashboardGlobalFilters,
      organizationGlobalFilter,
      getGraphGranularityMappings(selectedGranularity),
      groupByOption,
    );
    return query;
  };

  const getGraphAPIData = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/graph/multiline",
        method: "Post",
        data: query,
      });
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  const getGraphData = async (query) => {
    // const { graph, addition_data } = await getGraphAPIData(query);
    setIsGraphLoading(true);
    const response = await getGraphAPIData(query);

    if (!response?.graph) return;

    // const transformedGraph = graph[0].data
    //   .filter((item) => item.data.length > 0)
    //   .map((item) => ({
    //     data: item.data,
    //     id: item.key,
    //   }));

    // const { graph, addition_data } = await getGraphDataWithChMaestro(query);
    // console.log("graph trigger", graph);
    const { graph, addition_data } = response;

    const transformedGraph = graph
      .filter((item) => item.data.length > 0)
      .map((item) => ({
        // status: item.status,
        // data: item.data.map((innerItem) => ({
        //   // event_type: innerItem.event_type,
        //   y: innerItem.y,
        //   x: innerItem.x,
        //   group_id: item.group_id,
        // })),
        //   event_type: item.event_type,
        // y: item.y,
        //     x: item.x,
        data: item.data,
        id: item.key,

        // id: item.key, // You can use a different logic to generate unique IDs if needed
        // group_id: item.group_id, // You can use a different logic to generate unique IDs if needed
        // color: getRandomColor(),
      }));
    setTeamDataForMapping(addition_data);
    console.log("addition_data", addition_data);

    console.log("transformedGraph--", transformedGraph);
    setIsGraphLoading(false);
    return transformedGraph;
  };

  const fetchGraphData = async () => {
    // setIsGraphLoading(true);
    const query = generateQuery(selectedGranularity, selectedGroupByOption);
    const graphData = await getGraphData(query);
    setGraphData(graphData ?? []);
    // setIsGraphLoading(false);
  };

  const handleGranularityOptionSelected = async (value) => {
    setSelectedGranularity(value);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(value, selectedGroupByOption);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  const handleGroupByOptionSelected = async (value) => {
    setSelectedGroupByOption(value);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(selectedGranularity, value);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  // Fetch data when component first loads
  useEffect(() => {
    fetchGraphData();
  }, []);

  // Fetch data on trigger refetch
  useEffect(() => {
    if (isTriggerRefetch) {
      fetchGraphData();
    }
  }, [isTriggerRefetch, selectedGranularity]);

  return (
    <DashboardGraph
      title="Active Time Per Team"
      isTriggerRefetch={isTriggerRefetch}
      dashboardGlobalFilters={dashboardGlobalFilters}
      isGraphLoading={isGraphLoading}
      graphData={graphData}
      setGraphData={setGraphData}
      getGraphData={getGraphData}
      generateQuery={generateQuery}
      selectedGranularity={selectedGranularity}
      setSelectedGranularity={setSelectedGranularity}
      teamDataForMapping={teamDataForMapping}
      cumulativeGraphAnchorRef={cumulativeGraphAnchorRef}
      handleGenericMenuOpen={handleGenericMenuOpen}
      handleGenericMenuClose={handleGenericMenuClose}
      handleGranularityOptionSelected={handleGranularityOptionSelected}
      handleGroupByOptionSelected={handleGroupByOptionSelected}
      groupByAnchorEl={groupByAnchorEl}
      isGroupByMenuOpen={isGroupByMenuOpen}
      setIsGroupByMenuOpen={setIsGroupByMenuOpen}
      isCumulativeGraphDropDownOpen={isCumulativeGraphDropDownOpen}
      setCumulativeGraphDropDownOpen={setCumulativeGraphDropDownOpen}
      groupByMenuItems={["Teams"]}
      selectedGroupByOption={selectedGroupByOption}
      ActiveTimePerTeam
    />
  );
};
export default ActiveTimePerTeam;
