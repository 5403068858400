import React, { useEffect, useState } from "react";
import { ReactComponent as Process } from "../../../assets/icons/Process-payment-info.svg";
import { ReactComponent as Arrow_right } from "../../../assets/icons/ArrowRight.svg";
import Field from "../../../components/sub-components/Field";
import Button from "../../../components/sub-components/Button";
import {
  CardNumberElement,
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import Geocode from "react-geocode";
import { GoogleMapApiKey } from "../../../utilities/Constants";
import Header from "../Login/Header";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import CircularStatic from "../../../components/sub-components/CircularProcess";
import {
  createStripePaymentMethod,
  handleStripeFormChange,
  handleStripeFormSelectChange,
  validateStripeForm,
} from "../../../redux/slices/authSlice";
import { getData } from "country-list";
import CustomSelect from "../../../components/sub-components/Select";

export default function PaymentInfo() {
  Geocode.setApiKey(GoogleMapApiKey);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const form = useSelector((state) => state.authReducer.stripePaymentForm);
  const subscription = useSelector((state) => state.authReducer.subscription);
  const { stripePricings } = useSelector((state) => state.authReducer);
  const errors = useSelector(
    (state) => state.authReducer.stripePaymentFormErrors,
  );
  const user = useSelector((state) => state.authReducer.user);
  const loading = useSelector((state) => state.authReducer.loading);
  const [country, setCountry] = useState(null);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    if (countriesList.length === 0) {
      setCountriesList(getData());
    }
  }, []);

  const mediaQuery = useWindowDimensions();

  const cardNumberStyle = {
    style: {
      base: {
        fontSize: "17px",
        "::placeholder": {
          color: "#f8f9fa",
        },
        lineHeight: "45px",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: "17px",
        "::placeholder": {
          color: "#f8f9fa",
        },
        lineHeight: "45px",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const validateForm = () => {
    if (!form.name) {
      dispatch(
        validateStripeForm({
          name: "name",
          value: "Name is required",
        }),
      );
    }
    if (!form.address) {
      dispatch(
        validateStripeForm({
          name: "address",
          value: "Address is required",
        }),
      );
    }
    if (!country) {
      dispatch(
        validateStripeForm({
          name: "country",
          value: "Country is required",
        }),
      );
    }
    if (!form.postalCode) {
      dispatch(
        validateStripeForm({
          name: "postalCode",
          value: "Postal Code is required",
        }),
      );
    }
    if (!form.city) {
      dispatch(
        validateStripeForm({
          name: "city",
          value: "City is required",
        }),
      );
    }
    if (!form.state) {
      dispatch(
        validateStripeForm({
          name: "state",
          value: "State / Province is required",
        }),
      );
    }
    if (form.name) {
      dispatch(
        validateStripeForm({
          name: "name",
          value: false,
        }),
      );
    }
    if (form.address) {
      dispatch(
        validateStripeForm({
          name: "address",
          value: false,
        }),
      );
    }
    if (country) {
      dispatch(
        validateStripeForm({
          name: "country",
          value: false,
        }),
      );
    }
    if (form.postalCode) {
      dispatch(
        validateStripeForm({
          name: "postalCode",
          value: false,
        }),
      );
    }
  };

  const handleSubmit = () => {
    validateForm();

    if (
      form.name &&
      form.address &&
      country &&
      form.postalCode &&
      form.city &&
      form.state &&
      !errors.cardNumber &&
      !errors.cardExpiry &&
      !errors.cardCVC
    ) {
      dispatch(
        createStripePaymentMethod(
          stripe,
          elements,
          CardNumberElement,
          form,
          country,
          subscription,
          stripePricings,
          user.tenantId,
        ),
      );
    }
  };

  const handleChange = (target) => {
    dispatch(handleStripeFormChange(target));
  };

  const handleSelectChange = (selected) => {
    console.log("selected>>>>>>>.", selected);

    dispatch(handleStripeFormSelectChange(selected));
  };

  const handleAddressChange = (address) => {
    handleChange({ name: "address", value: address });
  };

  const handleAddressSelect = (address) => {
    Geocode.fromAddress(address)
      .then((response) => {
        let city = "",
          state = "",
          streetNumber = "",
          postalCode = "",
          route = "";
        const country = { label: "", value: "" };

        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "street_number":
                streetNumber =
                  response.results[0].address_components[i].long_name;
                break;
              case "route":
                route = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalCode =
                  response.results[0].address_components[i].long_name;
                break;
              case "country":
                country.label =
                  response.results[0].address_components[i].long_name;
                country.value =
                  response.results[0].address_components[i].short_name;
                break;
            }
          }
        }
        handleChange({ name: "address", value: streetNumber + " " + route });
        handleChange({ name: "city", value: city });
        handleChange({ name: "state", value: state });
        handleChange({ name: "postalCode", value: postalCode });
        handleSelectChange(country);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="signup">
      <Header />
      {mediaQuery.width > 425 ? (
        <div className="row">
          <div className="col-lg-12">
            <Process style={{ marginTop: "60px" }} className="process-logo" />
          </div>
        </div>
      ) : (
        <div
          className="row mt-5"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <CircularStatic currentStep={3} totalSteps={3} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              marginLeft: "10px",
            }}
          >
            <h6 style={{ marginBottom: "0" }}>Payment Info</h6>
            <span
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                letterSpacing: "0.4px",
                color: "#000000",
              }}
            >
              Hurray! This is the final step
            </span>
          </div>
        </div>
      )}

      <div className="signup-fields">
        <div className="row" style={{ paddingTop: "70px", textAlign: "left" }}>
          <div className="col-lg-12">
            <div className="d-flex align-items-center">
              <span
                className="head4 grey8"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Payment Info
              </span>
              <Arrow_right style={{ marginLeft: "20px", cursor: "pointer" }} />
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingTop: "40px", textAlign: "left" }}>
          <div className="col-lg-12">
            <div>
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Cardholder Name <span className="text-danger">*</span>
              </div>
              <Field
                inputFieldFull
                name="name"
                value={form.name}
                onChange={(e) => handleChange(e.target)}
                style={{ border: errors.name ? "1px solid #ff0000" : "" }}
              />
              <span className="text-danger">{errors.name}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                Card Number <span className="text-danger">*</span>
              </div>
              <CardNumberElement
                className="stripe-input-field-full"
                options={cardNumberStyle}
                onChange={(e) => {
                  if (e?.error) {
                    dispatch(
                      validateStripeForm({
                        name: "cardNumber",
                        value: e?.error?.message,
                      }),
                    );
                  } else {
                    dispatch(
                      validateStripeForm({
                        name: "cardNumber",
                        value: false,
                      }),
                    );
                  }
                }}
              />
              <span className="text-danger">{errors.cardNumber}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                Expiry (MM/YY) <span className="text-danger">*</span>
              </div>
              <CardExpiryElement
                className="stripe-input-field-full"
                options={cardStyle}
                onChange={(e) => {
                  if (e?.error) {
                    dispatch(
                      validateStripeForm({
                        name: "cardExpiry",
                        value: e?.error?.message,
                      }),
                    );
                  } else {
                    dispatch(
                      validateStripeForm({
                        name: "cardExpiry",
                        value: false,
                      }),
                    );
                  }
                }}
              />
              <span className="text-danger">{errors.cardExpiry}</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                CVC <span className="text-danger">*</span>
              </div>
              <CardCvcElement
                className="stripe-input-field-full"
                options={cardStyle}
                onChange={(e) => {
                  if (e?.error) {
                    dispatch(
                      validateStripeForm({
                        name: "cardCVC",
                        value: e?.error?.message,
                      }),
                    );
                  } else {
                    dispatch(
                      validateStripeForm({
                        name: "cardCVC",
                        value: false,
                      }),
                    );
                  }
                }}
              />
              <span className="text-danger">{errors.cardCVC}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                Address <span className="text-danger">*</span>
              </div>
              <PlacesAutocomplete
                value={form.address}
                onSelect={handleAddressSelect}
                onChange={handleAddressChange}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div
                    style={{
                      border: errors.address ? "1px solid #ff0000" : "",
                      borderRadius: errors.address ? "10px" : "",
                    }}
                  >
                    <input
                      {...getInputProps({
                        className: "input-field-full",
                      })}
                    />
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        width: "95%",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      {/* {loading && <div>Loading...</div>} */}
                      {suggestions.map((suggestion, index) => {
                        return (
                          <div
                            className="input-field-full-list-address"
                            key={index}
                            {...getSuggestionItemProps(suggestion)}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <span className="text-danger">{errors.address}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                City <span className="text-danger">*</span>
              </div>
              <Field
                inputFieldFull
                name="city"
                value={form.city}
                onChange={(e) => handleChange(e.target)}
                style={{ border: errors.city ? "1px solid #ff0000" : "" }}
              />
              <span className="text-danger">{errors.city}</span>
            </div>
          </div>
          <div className="col-lg-6">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                State / Province <span className="text-danger">*</span>
              </div>
              <Field
                inputFieldFull
                name="state"
                value={form.state}
                onChange={(e) => handleChange(e.target)}
                style={{ border: errors.state ? "1px solid #ff0000" : "" }}
              />
              <span className="text-danger">{errors.state}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div
              className="s1 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              Country <span className="text-danger">*</span>
            </div>

            <CustomSelect
              items={countriesList?.map((country) => {
                return {
                  value: country.code,
                  label: country.name,
                };
              })}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              menuMaxHeight={"330px"}
              error={errors.country}
            />
            <span className="text-danger">{errors.country}</span>
          </div>
          <div className="col-lg-6">
            <div>
              <div
                className="s1 grey8"
                style={{ paddingTop: "25px", paddingBottom: "10px" }}
              >
                Postal/Zip Code <span className="text-danger">*</span>
              </div>
              <Field
                inputFieldFull
                name="postalCode"
                value={form.postalCode}
                onChange={(e) => handleChange(e.target)}
                style={{ border: errors.postalCode ? "1px solid #ff0000" : "" }}
              />
              <span className="text-danger">{errors.postalCode}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="remember-check"
                name="markAsBusinessAddress"
                style={{ marginTop: "25px", marginBottom: "10px" }}
                checked={form.markAsBusinessAddress}
                onChange={(e) =>
                  handleChange({
                    name: "markAsBusinessAddress",
                    value: e.target.checked,
                  })
                }
              />
              <div
                className="b2 grey8"
                style={{
                  paddingTop: "25px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                }}
              >
                Mark as business address
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={
            mediaQuery.width > 425
              ? { paddingTop: "58px" }
              : { paddingTop: "48px" }
          }
        >
          <div className="col-lg-12">
            <Button
              buttonFilledFull
              title="Start 30 Day Free Trial"
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
        <span
          className="s1 grey6 mt-1"
          style={{ display: "inline-flex", textAlign: "center" }}
        >
          After 30 days trial, you’ll be charged according to your chosen plan
        </span>

        <div
          className="d-flex justify-content-around"
          style={{ paddingTop: "100px" }}
        >
          <div className="s2 grey8">Privacy Policy</div>
          <div className="s2 grey8">Help</div>
          <div className="s2 grey8">Terms and Conditions</div>
        </div>
      </div>
    </div>
  );
}
