import React, { useState, useEffect } from "react";
import { ReactComponent as Close } from "../../../../assets/icons/X.svg";
import { ReactComponent as Arrow_left } from "../../../../assets/icons/ArrowLeft.svg";

import dayjs from "dayjs";
import Field from "../../../../components/sub-components/Field";
import SurveyAnalyticsOverview from "../SurveyAnalyticsOverview";
import { DateRangePicker } from "../../../../components/DatePickers/DateRangePicker";
import { mySurveyResponseTable } from "../../../../utilities/dummyGraphs/surveyDummy";
import {
  getSurveyGradeBackgroundColors,
  getSurveyGradeTextColors,
} from "../../../../utilities/Utils";
import SurveyQuestions from "./SurveyQuestions";
import SurveyResponse from "./SurveyResponse";
import { useSelector } from "react-redux";

function SurveySideMenu({
  currentSurveyOpened,
  isCurrentSurveyOpened,
  currentrow,
}) {
  const { mySurveysData } = useSelector((state) => state.surveysReducer);

  const [usersData, setUsersData] = useState([]);

  const [filterInputValue, setFilterInputValue] = useState("");
  const [filteredData, setFilteredData] = useState(mySurveyResponseTable);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [isCurrentSurvey, setIsCurrentSurvey] = useState({});

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  const resetForm = () => {
    // setIsEditEventCategory(false);
  };

  const renderIcon = () => {
    const commonIconProps = {
      stroke: "#343A40",
      strokeWidth: "2",
      style: {
        margin: "0px 20px 3px 0",
        width: "24px",
        cursor: "pointer",
      },
    };

    if (
      Object.keys(isCurrentSurvey).length === 0 &&
      isCurrentSurvey.constructor === Object
    ) {
      return <Close {...commonIconProps} onClick={() => {}} />;
    } else {
      return (
        <Arrow_left
          stroke="#343A40"
          style={{ margin: "2px 20px 5px 0", width: "24px" }}
          onClick={() => setIsCurrentSurvey({})}
        />
      );
    }
  };

  useEffect(() => {
    filterData();
  }, [startDate, endDate, filterInputValue]);

  const filterData = () => {
    let filtered = mySurveyResponseTable;

    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      filtered = filtered.filter((item) => {
        const itemDate = dayjs(item.response_submitted);
        return itemDate.isAfter(start) && itemDate.isBefore(end);
      });
    }

    if (filterInputValue) {
      filtered = filtered.filter((item) => {
        return item.full_name
          .toLowerCase()
          .includes(filterInputValue.toLowerCase());
      });
    }

    const dateFormattedData = filtered.map((item) => ({
      ...item,
      response_submitted: dayjs(item.response_submitted).format("YYYY-MM-DD"),
    }));

    setFilteredData(dateFormattedData);
  };

  return (
    <>
      {/* {!isCurrentSurveyOpened ? ( */}
      <div>
        <div>
          <div className="d-flex justify-content-between">
            <span
              className="head4 grey8 d-flex align-items-center"
              style={{ fontWeight: "400" }}
            >
              {renderIcon()}
              <span className="head4">My Surveys</span>
              {currentSurveyOpened.surveyName}
            </span>
          </div>
          {Object.keys(isCurrentSurvey).length === 0 && (
            <SurveyAnalyticsOverview
              graphName={currentSurveyOpened.surveyName}
              usersData={usersData}
              indexBy={"user_id"}
              graphType="productivityGraph"
              productivityGraph
              organizationItemSelected={{}}
            />
          )}
          {Object.keys(isCurrentSurvey).length === 0 &&
          isCurrentSurvey.constructor === Object ? (
            <>
              <div
                className="row d-flex justify-content-between"
                style={{ marginTop: "32px" }}
              >
                <div className="d-flex align-items-center flex-row">
                  <Field
                    inputFieldFullSearchIcon
                    placeholder={`Search`}
                    value={filterInputValue}
                    onChange={(e) => setFilterInputValue(e.target.value)}
                    style={{
                      width: "100%",
                      height: "40px",
                      paddingLeft: "52px",
                      borderRight: "1px solid #e9ecef",
                      borderRadius: "10px",
                      background: "white",
                    }}
                  />
                </div>

                <div
                  className="d-flex align-items-center"
                  style={{
                    height: "40px",
                    background: "#FFF",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                    border: "1px solid #E9ECEF",
                    borderRadius: "10px",
                    padding: "6px 12px",
                    cursor: "pointer",
                  }}
                >
                  <DateRangePicker
                    value={{ from: startDate, to: endDate }}
                    fetchDatesData={filterData}
                    setIsDateMenuOpen={setIsDateMenuOpen}
                    isDateMenuOpen={isDateMenuOpen}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </div>

              <div style={{ marginTop: "24px" }} className="row">
                {mySurveysData.map((survey, index) => {
                  return (
                    <div
                      key={index}
                      className="col-lg-12"
                      style={{
                        border: "3px solid #F5F5F5",
                        boxShadow: "0px 4px 4px 0px #FAFAFA",
                        marginBottom: "24px",
                      }}
                    >
                      {/* <div className="head6-medium" style={{ margin: "19px 0" }}>
                    {survey.category}
                  </div> */}
                      <div
                        className="d-flex justify-content-between col-lg-12"
                        style={{
                          border: "1px solid #DEDEDE",
                          borderRadius: "10px",
                          padding: "12px 11px",
                          // marginBottom: "19px",
                          maxHeight: "110px",
                          margin: "12px 0",
                        }}
                      >
                        <div className="d-flex">
                          <div
                            className="d-flex justify-content-between flex-column align-items-center"
                            style={{
                              background:
                                getSurveyGradeBackgroundColors("default"), // Placeholder
                              marginRight: "26px",
                              width: "92px",
                              padding: "10px 0",
                            }}
                          >
                            <span
                              className="d-flex head3-semi-bold mb-2"
                              style={{
                                color: getSurveyGradeTextColors("default"), // Placeholder
                              }}
                            >
                              Grade {/* Placeholder */}
                            </span>

                            <span
                              className="d-flex head6-medium"
                              style={{
                                color: getSurveyGradeTextColors("default"), // Placeholder
                              }}
                            >
                              Percentage {/* Placeholder */}
                            </span>
                          </div>
                          <div
                            className="d-flex justify-content-between flex-column"
                            style={{ padding: "14px 0" }}
                          >
                            <div className="d-flex head5-medium grey9">
                              {survey.question}
                            </div>
                            <span
                              className="d-flex primary-color caption1"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => setIsCurrentSurvey(survey)}
                            >
                              {"See Responses"}
                            </span>
                          </div>
                        </div>

                        <div
                          className="d-flex justify-content-between flex-column"
                          style={{ padding: "20px 0" }}
                        >
                          <div className="d-flex caption1 grey9">{`Asked By: ${survey.user_id}`}</div>
                          <span className="d-flex caption1 grey9">{`Created at: ${survey.start_date}`}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  borderTop: "1px solid #BFBFBF",
                  margin: "32px -48px",
                }}
              />
              <SurveyQuestions currentrow={currentrow} />
              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  marginBottom: "24px",
                  marginTop: "32px",
                  // padding: "0 10px",
                }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ padding: "20px 0" }}
                >
                  <Field
                    inputFieldFullSearchIcon
                    placeholder={`Search`}
                    value={filterInputValue}
                    onChange={(e) => setFilterInputValue(e.target.value)}
                    style={{
                      width: "100%",
                      height: "40px",
                      paddingLeft: "52px",
                      borderRight: "1px solid #DEDEDE",
                      borderRadius: "10px",
                    }}
                  />
                </div>

                <div
                  className="d-flex align-items-center"
                  style={{
                    height: "36px",
                    background: "#F8F9FA",
                    border: "1px solid #E9ECEF",
                    borderRadius: "10px",
                    padding: "6px",
                    cursor: "pointer",
                  }}
                >
                  <DateRangePicker
                    value={{ from: startDate, to: endDate }}
                    fetchDatesData={fetchDatesData}
                    setIsDateMenuOpen={setIsDateMenuOpen}
                    isDateMenuOpen={isDateMenuOpen}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>
              </div>
              <SurveyResponse
                surveyResponseData={filteredData}
                searchInputValue={filterInputValue}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SurveySideMenu;
