import React from "react";

const Tabv2 = ({ tabs, selectedTab, onTabChange }) => {
  const handleTabChange = (index) => {
    // Your tab change logic here
    onTabChange(index);
  };

  return (
    <div
      className="d-flex"
      style={{ width: "700px", marginBottom: "32px", marginTop: "12px" }}
    >
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => handleTabChange(index)}
          className={`tab-sm ${
            selectedTab.id === tab.id ? "b0-medium grey8" : "b1 grey6"
          }`}
          style={{
            borderBottom:
              selectedTab.id === tab.id
                ? "4px solid #9300FF"
                : "4px solid #EBEBEB",
            // marginRight: "20px",
          }}
        >
          <span>{tab.title || `Tab ${index + 1}`}</span>
        </div>
      ))}
    </div>
  );
};

export default Tabv2;
