import React, { useEffect, useState } from "react";
import { ReactComponent as List } from "../../assets/icons/List.svg";
// import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";
// import { ReactComponent as MagnifyingGlass } from "../../assets/icons/MagnifyingGlass.svg";
import caret_down from "../../assets/icons/CaretDown.svg";
import Bell from "../../assets/icons/Bell.svg";
import { ReactComponent as User_icon } from "../../assets/icons/UserCircle.svg";
import Frame from "../../assets/icons/Frame 693.svg";
import { ReactComponent as FlashIcon } from "../../assets/icons/Lightning.svg";
import { ReactComponent as FlashIconEmpty } from "../../assets/icons/Lightning-outlined.svg";
// import { ReactComponent as UserImage } from "../../assets/icons/Mask Group.svg";
import Tab from "./Tab";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "../../redux/slices/settingsSlice";
import { toggleFlashNotificationsMenu } from "../../redux/slices/menuSlice";
import { setUsersCurrentTab } from "../../redux/slices/usersSlice";

export default function Header(props) {
  // var header_tabs = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 },];
  const [isMenu, setIsMenu] = useState(false);
  const [showSearch, setIsShowSearch] = useState(false);
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);

  const dispatch = useDispatch();

  const { isFlashNotificationsMenuOpen } = useSelector(
    (state) => state.menuReducer,
  );
  const [notif, setNotif] = useState(false);
  // const { isNewNotificationsReceived } = useSelector((state) => state.notificationsReducer);

  // const isNewNotificationsReceived = JSON.parse(localStorage.getItem("isNewNotifications"));

  useEffect(() => {
    const isNewNotificationsReceived = JSON.parse(
      window.localStorage.getItem("isNewNotifications"),
    );
    if (isNewNotificationsReceived) {
      // if recerived, setnotif => true
      setNotif(true);
    } else {
      // else add a listener since it would probably be triggered.
      // but what in case it isn't received
      window.addEventListener("newNotification", () => {
        console.log("Change to local storage!");
        setNotif(true);
      });
    }

    //   return () => {
    // 	second
    //   }
  }, []);

  // console.log({isNewNotificationsReceived});

  const handleClickFlashIcon = () => {
    dispatch(toggleFlashNotificationsMenu(!isFlashNotificationsMenuOpen));
    localStorage.setItem("isNewNotifications", false);
    setNotif(false);
    // dispatch(toggleNewNotifications(false));
  };

  // useEffect(() => {
  // 	setCollapsed(JSON.parse(localStorage.getItem('todos')));
  // }, [localStorage.getItem('isNewNotifications')]);

  const handleTab1Clicked = () => {
    setTab1(true);
    setTab2(false);
    setTab3(false);
    setTab4(false);
    // Needs to be refactor**********************
    dispatch(setUsersCurrentTab("1"));
    dispatch(setCurrentTab("1"));
  };

  const handleTab2Clicked = () => {
    setTab2(true);
    setTab1(false);
    setTab3(false);
    setTab4(false);
    dispatch(setUsersCurrentTab("2"));
    dispatch(setCurrentTab("2"));
  };

  const handleTab3Clicked = () => {
    setTab3(true);
    setTab1(false);
    setTab2(false);
    setTab4(false);
    dispatch(setUsersCurrentTab("3"));
    dispatch(setCurrentTab("3"));
  };

  const handleTab4Clicked = () => {
    setTab4(true);
    setTab1(false);
    setTab2(false);
    setTab3(false);
    dispatch(setUsersCurrentTab("4"));
    dispatch(setCurrentTab("4"));
  };

  const toggleSearch = () => {
    setIsShowSearch(!showSearch);
  };

  const getCurrentPage = (str) => {
    // return window.location.pathname.split("/")[1];
    const converted = str
      .split("/")[1]
      .replace(/(-|^)([^-]?)/g, function (_, prep, letter) {
        return (prep && " ") + letter.toUpperCase();
      });
    return converted;
  };

  // $("#inpt_search").on('focus', function () {
  // 	$(this).parent('label').addClass('active');
  // });

  // $("#inpt_search").on('blur', function () {
  // 	if($(this).val().length == 0)
  // 		$(this).parent('label').removeClass('active');
  // });
  // console.log("props.title",getCurrentPage);

  // const [selectedTab, setSelectedTab] = useState(header_tabs[0]);

  // function handleTabChange(index){
  // 	setSelectedTab(header_tabs[index]);
  // }

  if (props.headerType === "header0") {
    return (
      <div
        className="header"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title">
          <div className="d-flex align-items-center">
            <List
              fill="#343A40"
              stroke="#343A40"
              // onClick={() => props.handleMainMenu(!isMenu)}
              style={{
                cursor: "pointer",
              }}
            />
            <span className="head5 mb-0 ml-3">
              {getCurrentPage(props.title) || "Header"}
            </span>
          </div>

          <div className="user-icon-header d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span className="s1 pl-2 pr-4">Full Name</span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.headerType === "TeamHeader") {
    return (
      <div
        className="TeamHeader"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title">
          <div className="d-flex align-items-center justify-content-center">
            <div style={{ display: "flex", cursor: "pointer" }}>
              <span
                style={{ cursor: "pointer" }}
                // onClick={() => props.handleMainMenu(!isMenu)}
              >
                <List
                  fill="none"
                  stroke="#343A40"
                  // onClick={() => setView("list")}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            <div className="mb-0 ml-4 head5 grey8">
              {getCurrentPage(props.title) || "Header"}
            </div>
          </div>

          <div className="user-icon-header d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span
              className="s1"
              style={{ marginLeft: "22px", marginRight: "22px" }}
            >
              Jaffar Hussain
            </span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
            <div style={{ marginLeft: "50px", marginRight: "50px" }}>
              <img src={Frame} />
            </div>
            <div>
              <img src={Bell} />
            </div>
          </div>
        </div>
        {/* <div className='tags b1'>
					<Tab tabSm title='All Teams' />
				</div> */}
      </div>
    );
  }

  if (props.headerType === "newHeader") {
    return (
      <div
        className="TeamHeader"
        style={{ ...props.style, borderBottom: "2px solid #E9ECEF" }}
        onClick={props.onClick}
      >
        <div className="newHeaderContainer">
          <div className="d-flex align-items-center justify-content-center">
            <div className="head1-bold grey9">
              {getCurrentPage(props.title) || "Header"}
            </div>
          </div>
          <div className="user-icon-header d-flex flex-row-reverse align-items-center justify-content-between">
            <div style={{ position: "relative" }}>
              <div
                style={{
                  // margin: "0 32px",
                  cursor: "pointer",
                  pointerEvents: isFlashNotificationsMenuOpen && "none",
                }}
                onClick={() => handleClickFlashIcon()}
              >
                {notif ? <FlashIcon /> : <FlashIconEmpty />}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "0px 36px",
            borderBottom: "1px solid #ABABAB",
            // outlineOffset: "-20px",
          }}
        />
      </div>
    );
  }

  if (props.headerType === "header1") {
    return (
      <div
        className="header"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title">
          <div className="d-flex align-items-center">
            <div>
              <List
                fill="none"
                stroke="#343A40"
                // onClick={() => setView("list")}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <span
              className="head5"
              style={{ paddingLeft: "50px", marginBottom: "0px" }}
            >
              {getCurrentPage(props.title) || "Header"}
            </span>
          </div>

          <div className="d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span
              className="s1"
              style={{ paddingRight: "20px", paddingLeft: "10px" }}
            >
              Name
            </span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
          </div>
        </div>
        <div className="tags">
          <Tab tabSm title="All Teams" />
        </div>
      </div>
    );
  }

  if (props.headerType === "header2") {
    return (
      <div
        className="header"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title">
          <div className="d-flex align-items-center">
            <div>
              <List
                fill="none"
                stroke="#343A40"
                // onClick={() => setView("list")}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <span
              className="head5"
              style={{ paddingLeft: "50px", marginBottom: "0px" }}
            >
              {getCurrentPage(props.title) || "Header"}
            </span>
          </div>

          <div className="d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span
              className="s1"
              style={{ paddingRight: "20px", paddingLeft: "10px" }}
            >
              Name
            </span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags">
            <div
              className="b1"
              style={{
                borderBottom: tab1 ? "4px solid #7B2CBF" : "",
                padding: "10px 30px",
                cursor: "pointer",
              }}
              onClick={handleTab1Clicked}
            >
              {props.tab1 || "Tab1"}
            </div>
            <div
              className="b1"
              style={{
                borderBottom: tab2 ? "4px solid #7B2CBF" : "",
                padding: "10px 30px",
                cursor: "pointer",
              }}
              onClick={handleTab2Clicked}
            >
              {props.tab2 || "Tab2"}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.headerType === "header3") {
    return (
      <div
        className="header"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title" style={{ ...props.tabStyles }}>
          <div
            className="d-flex align-items-center"
            style={{ margin: "40px 0 36px 0" }}
          >
            <div>
              <List
                fill="none"
                stroke="#343A40"
                // onClick={() => setView("list")}
                // onClick={() => props.handleMainMenu(!isMenu)}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <span
              className="head5"
              style={{ paddingLeft: "32px", marginBottom: "0px" }}
            >
              {getCurrentPage(props.title) || "Header"}
            </span>
          </div>

          <div className="user-icon-header d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span
              className="s1"
              style={{ marginLeft: "22px", marginRight: "22px" }}
            >
              Jaffar Hussain
            </span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
            <div style={{ marginLeft: "50px", marginRight: "50px" }}>
              <img src={Frame} />
            </div>
            <div>
              <img src={Bell} />
            </div>
          </div>
        </div>
        <div className="tags">
          <div
            className="b1"
            style={{
              borderBottom: tab1 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab1Clicked}
          >
            {props.tab1 || "Tab1"}
          </div>
          <div
            className="b1"
            style={{
              borderBottom: tab2 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab2Clicked}
          >
            {props.tab2 || "Tab2"}
          </div>
          <div
            className="b1"
            style={{
              borderBottom: tab3 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab3Clicked}
          >
            {props.tab3 || "Tab3"}
          </div>
        </div>
      </div>
    );
  }

  if (props.headerType === "header4") {
    return (
      <div
        className="header"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title" style={{ ...props.tabStyles }}>
          <div
            className="d-flex align-items-center"
            style={{ margin: "40px 0 36px 0" }}
          >
            <div>
              <List
                fill="none"
                stroke="#343A40"
                // onClick={() => props.handleMainMenu(!isMenu)}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <span
              className="head5"
              style={{ paddingLeft: "32px", marginBottom: "0px" }}
            >
              {getCurrentPage(props.title) || "Header"}
            </span>
          </div>

          <div className="user-icon-header d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span
              className="s1"
              style={{ marginLeft: "22px", marginRight: "22px" }}
            >
              Jaffar Hussain
            </span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
            <div style={{ marginLeft: "50px", marginRight: "50px" }}>
              <img src={Frame} />
            </div>
            <div>
              <img src={Bell} />
            </div>
          </div>
        </div>
        <div className="tags">
          <div
            className="b1"
            style={{
              borderBottom: tab1 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab1Clicked}
          >
            {props.tab1 || "Tab1"}
          </div>
          <div
            className="b1"
            style={{
              borderBottom: tab2 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab2Clicked}
          >
            {props.tab2 || "Tab2"}
          </div>
          <div
            className="b1"
            style={{
              borderBottom: tab3 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab3Clicked}
          >
            {props.tab3 || "Tab3"}
          </div>
          <div
            className="b1"
            style={{
              borderBottom: tab4 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab4Clicked}
          >
            {props.tab4 || "Tab4"}
          </div>
        </div>
      </div>
    );
  }

  if (props.headerType === "header5") {
    return (
      <div
        className="header"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <div className="title" style={{ ...props.tabStyles }}>
          <div
            className="d-flex align-items-center"
            style={{ margin: "40px 0 36px 0" }}
          >
            <div>
              <List
                fill="none"
                stroke="#343A40"
                // onClick={() => setView("list")}
                // onClick={() => props.handleMainMenu(!isMenu)}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            <span
              className="head5"
              style={{ paddingLeft: "32px", marginBottom: "0px" }}
            >
              {getCurrentPage(props.title) || "Header"}
            </span>
          </div>

          <div className="user-icon-header d-flex flex-row-reverse align-items-center">
            <div>
              <img src={caret_down} style={{ cursor: "pointer" }} />
            </div>
            <span
              className="s1"
              style={{ marginLeft: "22px", marginRight: "22px" }}
            >
              Jaffar Hussain
            </span>
            <div>
              {/* <img src={user_icon} /> */}
              <User_icon />
            </div>
            <div style={{ marginLeft: "50px", marginRight: "50px" }}>
              <img src={Frame} />
            </div>
            <div>
              <img src={Bell} />
            </div>
          </div>
        </div>
        <div className="tags">
          <div
            className="b1"
            style={{
              borderBottom: tab1 ? "4px solid #7B2CBF" : "",
              padding: "10px 30px",
              cursor: "pointer",
            }}
            onClick={handleTab1Clicked}
          >
            {props.tab1 || "Tab1"}
          </div>
        </div>
      </div>
    );
  }
}
