import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../../components/sub-components/Footer";
import Header from "./Header";

export default function ResetLinkSent() {
  const form = useSelector((state) => state.authReducer.forgotPasswordForm);

  return (
    <div className="signup">
      <Header />

      <div className="signup-fields">
        <div className="row">
          <div className="col-lg-12">
            <div className="reset-link">
              <div
                className="head3 primary-color"
                style={{ paddingBottom: "10px" }}
              >
                Reset Link Sent
              </div>
              <div className="b1 grey7">
                A reset link has been sent to{" "}
                <span className="b1 primary-color">{form.email}</span> Please
                check your email to reset your password.
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
