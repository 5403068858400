/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";
import { ReactComponent as TempImg } from "../../assets/icons/temp_img.svg";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from "@mui/material";
import BpCheckbox from "../sub-components/Checkbox";
import { EventSourcePolyfill } from "event-source-polyfill";
import { secure_instance } from "../../axios/axios-config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
import dayjs from "dayjs";
import baseURL from "../../utilities/BaseURL";
import { getExcludedPathsForHeader } from "../../utilities/Utils";
import { fakeNotif } from "../../utilities/Faker/FlashNotificationsDummy";
import { SurveyComponent } from "../../views/pages/Surveys/FlashMenuSurvey";

const getRandomPastelColor = () => {
  const hue = Math.floor(Math.random() * 360); // Random hue
  const saturation = Math.floor(Math.random() * 30) + 70; // Low saturation (70-100)
  const lightness = Math.floor(Math.random() * 30) + 70; // High lightness (70-100)
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Returns an HSL color
};

const isValidRoute = (path, excludedPaths) => {
  return !excludedPaths.some((excludedPath) => {
    return path === excludedPath;
  });
};

// ----------------------  Will export this component to a differnet file later ----------------------

export default function FlashIconMenu(props) {
  const { showMenu } = props;
  const location = useLocation();
  const isExcludedMatch = isValidRoute(
    location.pathname,
    getExcludedPathsForHeader(),
  );

  // const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authReducer);
  const [notifications, setNotifications] = useState([]);
  const { isFlashNotificationsMenuOpen } = useSelector(
    (state) => state.menuReducer,
  );
  const isFlashNotificationsMenuOpenRef = useRef(isFlashNotificationsMenuOpen);

  useEffect(() => {
    if (user.accessToken !== null) {
      const events = new EventSourcePolyfill(`${baseURL}/v1/server_events`, {
        headers: {
          Authorization: `B ${user.accessToken}`,
        },
        heartbeatTimeout: 10 * 60 * 1000,
      });
      events.addEventListener("newevent", (event) => {
        // dispatch(toggleNewNotifications(true));
        // localStorage.setItem("isNewNotifications", true);
        if (!isFlashNotificationsMenuOpenRef.current) {
          window.localStorage.setItem("isNewNotifications", true);
          window.dispatchEvent(new Event("newNotification"));
        } else {
          window.localStorage.setItem("isNewNotifications", false);
        }
        // setNotifications([...notifications,...JSON.parse(event.data)]);
        // setNotifications([...notifications, JSON.parse(event.data)]);
        setNotifications((prevNotifications) => [
          JSON.parse(event.data),
          ...prevNotifications,
        ]);
      });
    }
  }, [user.accessToken]);

  // Update the reference whenever the value changes in the Redux store
  useEffect(async () => {
    if (user.accessToken !== null) {
      try {
        const response = await secure_instance.request({
          url: "v1/server_events/push_notif",
          method: "Get",
        });
        setNotifications(response.data);
      } catch (e) {
        // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
        console.log("error", e);
      }
    }
  }, [user.accessToken]);

  useEffect(() => {
    isFlashNotificationsMenuOpenRef.current = isFlashNotificationsMenuOpen;
  }, [isFlashNotificationsMenuOpen]);

  const [groupByAnchorEl, setGroupByAnchorEl] = useState(null);
  const [notificationTypeSelected, setNotificationTypeSelected] = useState([
    "events-only",
  ]);

  const [surveyEvents, setSurveyEvents] = useState([]);
  const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);

  // const [listening, setListening] = useState(false);

  const { accessToken } = useSelector((state) => state.authReducer.user);

  const isgroupByMenu = Boolean(groupByAnchorEl);

  const anchorRef = React.useRef(null);

  const handleSurveyResponse = async (surverId, label) => {
    try {
      await secure_instance.request({
        url: `v1/survey_response/`,
        method: "POST",
        data: {
          triggered_survey_id: surverId,
          response: label,
        },
      });
      setCurrentSurveyIndex((prevIndex) => prevIndex + 1);
    } catch (e) {
      alert("Something went wrong");
      console.log(e);
    }
  };

  const handleNotificationMenuOpen = (event) => {
    setGroupByAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setGroupByAnchorEl(null);
  };

  const handleClickNotification = async (notification) => {
    // Check if the notification is already read
    if (!notification.read) {
      // Create a copy of the notifications array
      const updatedNotifications = [...notifications];

      // Find the index of the clicked notification in the copied array
      const index = updatedNotifications.findIndex(
        (n) => n.id === notification.id,
      );

      if (index !== -1) {
        // Update the 'read' property of the clicked notification to true
        updatedNotifications[index] = { ...notification, read: true };

        // Set the updated notifications state with the 'read' property changed
        setNotifications(updatedNotifications);
      }

      try {
        await secure_instance.request({
          url: "v1/server_events/push_notif",
          method: "Patch",
          data: [updatedNotifications[index]],
        });
      } catch (e) {
        // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
        console.log("error", e);
      }
    }
  };

  const handleClickCheckbox = (item) => {
    const selectedIndex = notificationTypeSelected.findIndex((x) => x === item);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(notificationTypeSelected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(notificationTypeSelected.slice(1));
    } else if (selectedIndex === notificationTypeSelected.length - 1) {
      newSelected = newSelected.concat(notificationTypeSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        notificationTypeSelected.slice(0, selectedIndex),
        notificationTypeSelected.slice(selectedIndex + 1),
      );
    }
    setNotificationTypeSelected(newSelected);
  };

  const isSelected = (name) => notificationTypeSelected?.indexOf(name) !== -1;

  useEffect(() => {
    if (user.accessToken !== null) {
      const fetchData = async () => {
        try {
          const response = await secure_instance.request({
            url: "v1/triggered_surveys",
            method: "Get",
          });
          const sortedData = response.data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
          );

          setSurveyEvents(sortedData);
        } catch (e) {
          throw new Error("Failed to get Triggered Surveys");
          // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
          // console.log("error", e);
        }
      };

      // fetchData();

      // Event source request for real-time changes
      const events = new EventSourcePolyfill(`${baseURL}/v1/survey_events`, {
        headers: {
          Authorization: `B ${accessToken}`,
        },
        heartbeatTimeout: 10 * 60 * 1000,
      });

      events.addEventListener("survey_triggered", (event) => {
        console.log("eventevent => ", event);
        // const parsedData = JSON.parse(event.data);
        const newSurvey = JSON.parse(event.data);
        console.log("newSurvey => ", newSurvey);
        // newSurvey.survey = newSurvey?.survey[0];
        // setSurveyEvents((prevSurveys) => [...prevSurveys, newSurvey]);
        setSurveys((prevSurveys) => {
          const updatedSurveys = [
            ...prevSurveys,
            { ...newSurvey, color: getRandomPastelColor() },
          ];
          // Sort the updated surveys array in descending order based on "created_at"
          //   (a, b) => new Date(b.created_at) - new Date(a.created_at)
          //   oldest surveys are displayed first
          updatedSurveys.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at),
          );

          if (!isFlashNotificationsMenuOpenRef.current) {
            window.localStorage.setItem("isNewNotifications", true);
            window.dispatchEvent(new Event("newNotification"));
          } else {
            window.localStorage.setItem("isNewNotifications", false);
          }

          return updatedSurveys;
        });
        // const { event_name, survey } = parsedData;
        // setSurveyEvents(parsedData);
        // console.log("parsedData:", parsedData);
      });
      // const eventSource = new EventSource("your-event-source-api-url");
      // eventSource.onmessage = (event) => {};

      // setListening(true);
    }
  }, [user.accessToken]);

  const [surveys, setSurveys] = useState([]);

  const handleSubmit = (surverId, label) => {
    handleSurveyResponse(surverId, label);
    setSurveys((prevSurveys) => prevSurveys.slice(1));
  };

  const surveysToShow = surveys;

  return (
    isExcludedMatch && (
      <>
        <div
          className={`survey-stack ${showMenu ? "open" : "closing"}`}
          style={{ marginTop: `${surveys.length * 20}px` }}
        >
          {surveysToShow.map((survey, index) => (
            <SurveyComponent
              key={survey.id}
              survey={survey}
              onSubmit={handleSubmit}
              surveyLength={surveys.length}
              index={index + 1}
              surveyCount={surveys.length}
              color={survey.color}
              style={{
                zIndex: surveys.length - index,
                transform: `translateY(-${index * 20}px) translateX(${index * 0}px) rotate(-${index * 8}deg)`,
                filter: index !== 0 && `blur(${4}px)`,
                transition: showMenu
                  ? `transform 350ms ease, opacity 350ms ease, visibility 0s linear ${index * 100}ms`
                  : `opacity 150ms ease, visibility 0s linear 150ms`, // No transform transition on close
              }}
            />
          ))}
        </div>

        <div
          className={`flash-menu-box ${showMenu ? "open" : ""}`}
          onClick={() => {
            // setShowMenu(false); // Ensure you close the menu properly
          }}
          style={{
            border: "1px solid #BFBFBF",
            boxShadow: "0px 4px 4px 0px #00000040",
            marginTop:
              surveys.length === 0 ? 0 : `${surveys.length * 22 + 300}px`,
            // marginTop: surveys.length === 0 ? 0 : "300px",
            maxHeight:
              surveys.length === 0 ? "80vh" : `${550 - surveys.length * 30}px`, // --------------------------- IF SURVEYS ARE NOT AVAILABLE, USE THIS HEIGHT
          }}
        >
          <div
            className="row"
            style={{
              background: "#FFFFFF",
            }}
          >
            <div className="col-12">
              <div
                className="d-flex justify-content-between"
                // style={{ margin: "22px 32px 22px 38px" }}
                style={{ padding: "24px 6px 14px 6px" }}
              >
                <div
                  className="d-flex align-items-center head6 grey8"
                  style={{ fontWeight: "600" }}
                  // onClick={() => setSurveyTest()}
                >
                  Alerts
                </div>
                {/* <div>
                            Events Only
                        </div> */}

                <ClickAwayListener onClickAway={handleNotificationMenuClose}>
                  <div className="d-flex align-items-center">
                    {/* Group By Dropdown */}
                    <div
                      className="d-flex align-items-center"
                      style={{
                        background: "#F8F9FA",
                        position: "relative",
                        border: "1px solid #E9ECEF",
                        borderRadius: "10px",
                        padding: "8px 16px",
                        width: "100%",
                        height: "37px",
                        cursor: "pointer",
                      }}
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={open ? "composition-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleNotificationMenuOpen}
                    >
                      <span
                        className="grey8 s2 mr-2"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {"Events Only"}
                      </span>
                      <CaretDown stroke="#343A40" stroke-width="1.5" />
                    </div>

                    <Popper
                      open={isgroupByMenu}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: "right top",
                          }}
                        >
                          {/* <Paper> */}
                          <div>
                            <ClickAwayListener
                              onClickAway={handleNotificationMenuClose}
                            >
                              <MenuList
                                // autoFocusItem={isgroupByMenu}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  width: "244px",
                                  border: "1px solid #DEE2E6",
                                  borderRadius: "10px",
                                }}
                                // onKeyDown={handleListKeyDown}
                              >
                                <div className="s2 grey9 ml-3">{"Select"}</div>
                                <div
                                  style={{
                                    borderBottom: "2px solid #DEE2E6",
                                    width: "96%",
                                    marginLeft: "12px",
                                    marginBottom: "8px",
                                    // margin:"12px"
                                  }}
                                />
                                <MenuItem
                                  id="events-only"
                                  style={{ padding: "0 12px" }}
                                  onClick={(e) =>
                                    handleClickCheckbox("events-only")
                                  }
                                >
                                  <span>
                                    <BpCheckbox
                                      checked={isSelected("events-only")}
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                      }}
                                      width={20}
                                      height={20}
                                      style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                      }}
                                    />
                                  </span>
                                  <span className={"s2 grey6"}>
                                    Events Only
                                  </span>
                                </MenuItem>
                                <MenuItem
                                  id="insights-only"
                                  style={{ padding: "0 12px" }}
                                  onClick={(e) =>
                                    handleClickCheckbox("insights-only")
                                  }
                                >
                                  <span>
                                    <BpCheckbox
                                      checked={isSelected("insights-only")}
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                      }}
                                      width={20}
                                      height={20}
                                      style={{
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                      }}
                                    />
                                  </span>
                                  <span className={"s2 grey6"}>
                                    Insights Only
                                  </span>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </div>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </ClickAwayListener>
              </div>

              {notifications.length > 0 ? (
                notifications.map((notification, i) => (
                  <div
                    key={i}
                    className="d-flex mb-3"
                    style={{
                      background:
                        !notification.read && "rgba(115, 174, 243, 0.3)",
                      border: "1px solid #EBDEF7",
                      margin: "0 38px",
                      padding: "15px 49px 32px 16px",
                      borderRadius: "10px",
                    }}
                    onClick={() => handleClickNotification(notification)}
                  >
                    <div>
                      {notification.properties.userProfile.image_url ? (
                        <img
                          src={notification?.properties?.userProfile?.image_url}
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "100px",
                            border: "2px solid #212529",
                          }}
                        />
                      ) : (
                        <TempImg />
                      )}
                    </div>
                    <div style={{ marginLeft: "16px" }}>
                      <div className="b0-medium grey6 notification-activity mb-1">
                        {/* <span className="grey8">userId: {notification.user_id} </span>
                                sent an event for {notification.event_body} */}
                        {/* <span className="grey8"> ‘#{notification.event}’</span> */}
                        <span className="grey8">
                          {notification.preview_event}
                        </span>
                      </div>

                      <div className="d-flex" style={{ marginTop: "10px" }}>
                        {/* {notification?.properties?.source === "google" ? (
                      <GoogleIcon
                        style={{
                          marginRight: "6px",
                          maxWidth: "16px",
                          maxHeight: "16px",
                        }}
                      />
                    ) : (
                      <SlackIcon style={{ marginRight: "6px" }} />
                    )} */}
                        <div className="caption grey6">
                          {dayjs(notification.time_stamp).format(
                            "D MMM, YYYY [at] h:mm A",
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center body1-medium grey6"
                  style={{ height: "40vh" }}
                >
                  {" "}
                  {"No Notifications Yet"}{" "}
                </div>
              )}

              {/* {fakeNotif.map((notification, i) => (
                <div
                  key={i}
                  className="d-flex mb-3"
                  style={{
                    background: "#FFFFFF", // Set the background to white
                    border: "1px solid #BFBFBF",
                    boxShadow: "0px 6px 16px 0px #00000012",
                    borderRadius: "20px", // Rounded corners
                    padding: "15px 20px", // Adjust padding to fit the design
                    margin: "10px 0", // Add margin between cards
                    cursor: "pointer", // Change cursor to pointer for interactivity
                  }}
                  onClick={() => handleClickNotification(notification)}
                >
                  <div className="mr-2">
                    <TempImg />
                  </div>
                  <div>
                    <div className="d-flex align-items-center">
                      <div
                        className="b0-medium"
                        style={{ color: "#7E57C2", fontWeight: "bold" }}
                      >
                        Objective
                      </div>
                      <div
                        className="caption ml-2"
                        style={{ color: "#9E9E9E" }}
                      >
                        {dayjs(notification.time_stamp).format(
                          "D MMM, YYYY [at] h:mm A",
                        )}
                      </div>
                    </div>
                    <div
                      className="mt-1"
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                    >
                      {notification.preview_event}
                    </div>
                    <div style={{ color: "#9E9E9E", fontSize: "14px" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </>
    )
  );
}
