import React, { useEffect, useState } from "react";
import SkeletonProduct from "./Skeleton";

const DelayedSkeletonLoader = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 750);

    return () => clearTimeout(timer);
  });

  return (
    showSpinner && <SkeletonProduct type={props.type} style={props.style} />
  );
};

export default DelayedSkeletonLoader;
