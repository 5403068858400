import React, { useState, memo } from "react";
import LineGraphTemp from "../../../components/Analytics/LineGraphTemp";
import { lineGraphDataSingle } from "../../../utilities/dummyGraphs/LineGraphData";

function SurveyAnalytics(props) {
  const { graphName, graphType, organizationItemSelected } = props;

  const FILTERS = ["All", "Performance", "Well being", "Happiness"];
  const [activeFilter, setActiveFilter] = useState("All");

  return (
    <div className="w-100" style={{ padding: "27px 0" }}>
      <div
        style={{
          height: "398px",
          border: "1px solid #C6C6C6",
          borderRadius: "8px",
          background: "#FFFFFF",
          boxShadow: "2px 2px 15px 0px #E5D5F2",
          // paddingBottom: "8px",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "24px 32px 0 32px",
            // borderBottom: "1px solid #E9ECEF",
            // height: "86px",
          }}
        >
          <div className="s1 d-flex align-items-center">{graphName}</div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "16px" }}
          >
            {FILTERS.map((filter) => (
              <button
                key={filter}
                onClick={() => setActiveFilter(filter)}
                className={`${activeFilter === filter ? "body2-semi-bold" : "body2"}`}
                style={{
                  padding: "10px 20px",
                  borderRadius: "10px",
                  border: `1px solid ${activeFilter === filter ? "#7B2CBF" : "#757575"}`,
                  backgroundColor:
                    activeFilter === filter ? "#9300FF" : "white",
                  color: activeFilter === filter ? "white" : "#757575",
                  cursor: "pointer",
                  outline: "none",
                }}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>

        <LineGraphTemp
          graphData={lineGraphDataSingle}
          margin={{ top: 50, right: 0, bottom: 25, left: 60 }}
          graphContainerHeight={"306px"}
          curve={"natural"}
        />
      </div>
      {/* </div > */}
    </div>
  );
}

export default memo(SurveyAnalytics);
