import React from "react";
import { Spinner } from "react-bootstrap";
import BackgroundBlur from "../sub-components/Blur";

export default function FullPageLoading(props) {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        // backgroundColor: "rgb(253, 253, 253)",
        minHeight: props.height || "66vh",
        ...props.style,
      }}
    >
      {/* <Spinner animation='grow' variant='secondary' /> */}
      <span className="full-loader"></span>
      <BackgroundBlur loader backgroundColor="#f8f9fa" />
    </div>
  );
}
