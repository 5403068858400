import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { showSnackbar } from "./authSlice";

const initialState = {
  events: [],
  eventType: "",
  showEditMenu: false,
  showAddMenu: false,
  deletedEvent: null,
  eventCategory: "",
  personalCards: [],
  sharedCards: [],
  selectedCardId: "",
  eventCreationSuccess: false,
  eventCreationFailure: false,
  updateEventSuccess: false,
  updateEventFailure: false,
  isTeamCreatedButtonClick: false,
  eventDeletedSuccess: false,
  currentScreen: 1,
  formData: {
    event_name: "",
    description: "",
    start_date: null,
    end_date: null,
    img_url: "",
    category_id: null,
  },
  tableData: {
    data: [],
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
    isMultipleRowsSelected: false,
    tableLoading: false,
  },
  sideMenu: {
    isSideMenuOpen: false,
    tableData: {
      usersData: [],
      rowsData: [],
      rowsPerPage: 5,
      page: 1,
      selected: [],
      sideMenuTableLoading: false,
    },
  },
  isLoading: false,
  error: null,
  isEventSideMenuOpen: false,
  isEditEvents: false,
  loading: false,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    toggleEditMenu: (state) => {
      state.showEditMenu = !state.showEditMenu;
    },
    toggleAddMenu: (state) => {
      state.showAddMenu = !state.showAddMenu;
    },
    handleEventDeletedSuccess: (state) => {
      state.eventDeletedSuccess = !state.eventDeletedSuccess;
    },
    handleCreateEventButtonClick: (state) => {
      state.isTeamCreatedButtonClick = !state.isTeamCreatedButtonClick;
    },
    getAllLifeEvents: (state, action) => {
      state.tableData.data = action.payload;
    },
    setTableSelectedRows: (state, action) => {
      state.tableData.selected = action.payload;
    },
    setMultipleSelectedEventsSlice: (state, action) => {
      state.tableData.isMultipleRowsSelected = action.payload;
    },
    updateCurrentScreen: (state, action) => {
      state.currentScreen = action.payload;
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
    handleCreateEventRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    handleCreateEventSuccess: (state) => {
      state.eventCreationSuccess = !state.eventCreationSuccess;
      state.isLoading = false;
      state.error = null;
    },
    handleCreateEventFailure: (state, action) => {
      state.eventCreationFailure = !state.eventCreationFailure;
      state.isLoading = false;
      state.error = action.payload;
    },
    handleUpdateEventRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    handleUpdateEventSuccess: (state) => {
      state.updateEventSuccess = !state.updateEventSuccess;
      state.isLoading = false;
      state.error = null;
    },
    handleUpdateEventFailure: (state, action) => {
      state.updateEventFailure = !state.updateEventFailure;
      state.isLoading = false;
      state.error = action.payload;
    },
    getAllCategories: (state, action) => {
      state.events = action.payload;
    },
    setPersonalCards: (state, action) => {
      state.personalCards = action.payload;
    },
    setSharedCards: (state, action) => {
      state.sharedCards = action.payload;
    },
    selectEventType: (state, action) => {
      state.eventType = action.payload;
    },
    selectEventCategory: (state, action) => {
      state.formData.category_id = action.payload;
      state.eventCategory = action.payload;
    },
    toggleTableLoading: (state) => {
      state.tableData.tableLoading = !state.tableData.tableLoading;
    },
    toggleSideMenuTableLoading: (state) => {
      state.sideMenu.tableData.sideMenuTableLoading =
        !state.sideMenu.tableData.sideMenuTableLoading;
    },
    setEventsTablePage: (state, action) => {
      state.tableData.page = action.payload;
    },
    setEventsSideMenuTablePage: (state, action) => {
      state.sideMenu.tableData.page = action.payload;
    },
    setSideMenuTableRowsPerPage: (state, action) => {
      state.sideMenu.tableData.rowsPerPage = action.payload;
    },
    setCurrentRowEventsSlice: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setIsEditEvents: (state, action) => {
      state.isEditEvents = action.payload;
    },
    toggleEventMenuOpen: (state) => {
      state.isEventSideMenuOpen = true;
    },
    toggleEventMenuClose: (state) => {
      state.isEventSideMenuOpen = false;
    },
    toggleEventLoading: (state) => {
      state.loading = true;
    },
    incrementView: (state) => {
      state.currentScreen++;
    },
    decrementView: (state) => {
      state.currentScreen--;
    },
    resetEventType: (state) => {
      state.eventType = "";
    },
    resetEventCategory: (state) => {
      state.eventCategory = "";
    },
  },
});

export const updateEventsData = (data) => {
  return async (dispatch) => {
    try {
      //   not implemented in original reducer
      //   dispatch({
      //     type: HANDLE_EVENT_DATA,
      //     payload: data,
      //   });
    } catch (e) {
      //
    }
  };
};

export const getAllLifeEventsData = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/life_events`,
        method: "GET",
      });
      dispatch(getAllLifeEvents(request.data));
      dispatch(toggleTableLoading());
    } catch (e) {
      console.error(e);
    }
  };
};

// export const getAllNewLifeEventsData = () => {
// 	return async (dispatch) => {
// 		try {
// 			dispatch(toggleTableLoading());
// 			const request = await secure_instance.request({
// 				url: `v1/life_events`,
// 				method: "GET",
// 			});
// 			dispatch(getAllLifeEvents(request.data));
// 			dispatch(toggleTableLoading());
// 		}
// 		catch (e) {
// 			console.error(e);
// 		}
// 	};
// };

export const getNewEventsData = (status) => {
  return async (dispatch) => {
    try {
      dispatch(toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/life_events`,
        method: "GET",
      });

      dispatch(getAllLifeEvents(request.data));
      dispatch(toggleTableLoading());

      if (status === "created") {
        // dispatch(handleCreateEventSuccess("created"));
        // Success alert for event creation
        dispatch(
          showSnackbar({
            message: "Event created successfully",
            severity: "success",
          }),
        );
      }
      if (status === "edited") {
        // dispatch(handleUpdateEventSuccess("edited"));
        // Success alert for event editing
        dispatch(
          showSnackbar({
            message: "Event updated successfully",
            severity: "success",
          }),
        );
      }
    } catch (e) {
      console.error(e);
      // Failure alert
      dispatch(
        showSnackbar({
          message: "Failed to load events",
          severity: "error",
        }),
      );
    }
  };
};

export const getAllCategoriesData = () => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: `v1/life_event_cats`,
        method: "GET",
      });
      dispatch(getAllCategories(request.data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const handleCreateIdentifier = (formData, userIds) => {
  return async (dispatch) => {
    dispatch(handleCreateEventRequest());
    try {
      formData["life_event_mappings"] = userIds;

      await secure_instance.request({
        url: "/v1/life_events/",
        method: "POST",
        data: formData,
      });
      dispatch(getNewEventsData("created"));
    } catch (error) {
      dispatch(
        showSnackbar({
          message: "Failed to create events",
          severity: "error",
        }),
      );
      // dispatch(handleCreateEventFailure(error.message));
    }
  };
};

export const updateLifeEvents = (eventId, formData, userIds) => {
  return async (dispatch) => {
    try {
      formData["life_event_mappings"] = userIds;

      await secure_instance.request({
        url: `v1/life_events/${eventId}`,
        method: "PATCH",
        data: formData,
      });
      dispatch(getNewEventsData("edited"));
    } catch (error) {
      dispatch(
        showSnackbar({
          message: "Failed to update events",
          severity: "error",
        }),
      );
      // dispatch(handleUpdateEventFailure(error.message));
    }
  };
};

export const deleteLifeEvents = (data) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: `v1/life_events/`,
        method: "DELETE",
        data: data,
      });

      dispatch(handleEventDeletedSuccess());
      dispatch(getNewEventsData());

      // Success alert for event deletion
      dispatch(
        showSnackbar({
          message: "Event deleted successfully",
          severity: "success",
        }),
      );
    } catch (e) {
      console.log(e);

      // Failure alert
      dispatch(
        showSnackbar({
          message: "Failed to delete event",
          severity: "error",
        }),
      );
    }
  };
};

export const deleteSingleLifeEvents = (id, data) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: `v1/life_events/${id}`,
        method: "DELETE",
        data: data,
      });
      // not implemented in original reducer
      //   dispatch({
      //     type: HANDLE_DELETE_EVENT,
      //     payload: request.data,
      //   });

      dispatch(handleEventDeletedSuccess());
      dispatch(getNewEventsData());
    } catch (e) {
      console.log(e);
    }
  };
};

export const {
  toggleEditMenu,
  toggleAddMenu,
  handleEventDeletedSuccess,
  handleCreateEventButtonClick,
  getAllLifeEvents,
  setTableSelectedRows,
  setMultipleSelectedEventsSlice,
  updateCurrentScreen,
  resetFormData,
  handleCreateEventRequest,
  handleCreateEventSuccess,
  handleCreateEventFailure,
  handleUpdateEventRequest,
  handleUpdateEventSuccess,
  handleUpdateEventFailure,
  getAllCategories,
  setPersonalCards,
  setSharedCards,
  selectEventType,
  selectEventCategory,
  toggleTableLoading,
  toggleSideMenuTableLoading,
  setEventsTablePage,
  setEventsSideMenuTablePage,
  setSideMenuTableRowsPerPage,
  setCurrentRowEventsSlice,
  setIsEditEvents,
  toggleEventMenuOpen,
  toggleEventMenuClose,
  toggleEventLoading,
  incrementView,
  decrementView,
  resetEventType,
  resetEventCategory,
} = eventsSlice.actions;

export default eventsSlice.reducer;
