import {
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  TableFooter,
} from "@mui/material";
import React from "react";
import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";
import { ReactComponent as CaretLeft } from "../../assets/icons/CaretLeft.svg";
import { ReactComponent as CaretRight } from "../../assets/icons/CaretRight.svg";

const TablePagination = (props) => {
  const {
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleChangePageCustomPagination,
    renderPage,
    rows,
    isRowEmpty,
    backgroundColor,
  } = props;

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];

  //   // Generate page numbers for the loop
  //   for (
  //     let i = Math.max(0, renderPage - 1);
  //     i <= Math.min(totalPages - 1, renderPage + 2);
  //     i++
  //   ) {
  //     pageNumbers.push(i);
  //   }

  //   return pageNumbers.map((page) => (
  //     <td key={page}>
  //       <span
  //         className={`b2 grey6 ${page === renderPage && "highlighted"}`}
  //         onClick={() => handleChangePage(page)}
  //       >
  //         {page + 1}
  //       </span>
  //     </td>
  //   ));
  // };
  // const renderPageNumbers = () => {
  //   const pageNumbers = [];

  //   // Calculate the startPage and endPage to ensure three pages are displayed
  //   let startPage, endPage;

  //   if (totalPages <= 3) {
  //     startPage = 0;
  //     endPage = totalPages - 1;
  //   } else {
  //     startPage = Math.max(0, Math.min(renderPage, totalPages - 3));
  //     endPage = Math.min(startPage + 2, totalPages - 1);
  //   }

  //   for (let i = startPage; i <= endPage; i++) {
  //     pageNumbers.push(i);
  //   }

  //   return pageNumbers.map((page) => (
  //     <td key={page}>
  //       <span
  //         className={`b2 grey6`}
  //         style={{ background: page === renderPage && "red" }}
  //         onClick={() => handleChangePage(page)}
  //       >
  //         {page + 1}
  //       </span>
  //     </td>
  //   ));
  // };
  const totalPages = Math.ceil(rows.length / rowsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];

    // Calculate the startPage and endPage to ensure three pages are displayed
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 0;
      endPage = totalPages - 1;
    } else {
      // Calculate the center page to ensure it's always displayed in the middle
      const centerPage = Math.max(1, Math.min(renderPage + 1, totalPages - 2));

      startPage = centerPage - 1;
      endPage = centerPage + 1;

      // Adjust startPage and endPage when they exceed the boundaries
      if (endPage === totalPages - 1) {
        startPage = totalPages - 3;
      } else if (startPage === 0) {
        endPage = 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((page) => (
      <td key={page}>
        <span
          className={`b2 grey6 ${page === renderPage && "highlighted"}`}
          style={{ background: page === renderPage && "red" }}
          onClick={() => handleChangePage(page)}
        >
          {page + 1}
        </span>
      </td>
    ));
  };

  return (
    <table className="w-100 noSelect footer">
      {/* Using table tag above to prevent validateDOMNesting error */}
      <TableFooter
        sx={{
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 32px",
          borderBottom: "1px solid #DEE2E6",
          borderTop: !isRowEmpty ? "1px solid #DEE2E6" : "0",
          backgroundColor: backgroundColor,
        }}
      >
        <tr>
          <td>
            <span className="b2 grey8 mr-2">Row per page</span>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              IconComponent={(props) => (
                <CaretDown
                  {...props}
                  stroke="#343A40"
                  style={{ top: "6px", right: "7px" }}
                />
              )}
              style={{ width: "66px", height: "32px", paddingLeft: "1px" }}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#7b2cbf",
                },
              }}
            >
              {[5, 10, 25]
                .sort((a, b) => a - b)
                .map((menuItem) => {
                  return (
                    <MenuItem value={menuItem} key={menuItem}>
                      <span className="s2 grey8">{menuItem}</span>
                    </MenuItem>
                  );
                })}
            </Select>
          </td>
        </tr>

        <>
          <tr>
            {/* <td>
              <CaretLeft
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleChangePage(renderPage - 1)}
              />
            </td> */}
            <td>
              {/* <input
                readOnly
                value={renderPage + 1}
                className="b2 grey8"
                style={{
                  outline: "none",
                  width: "22px",
                  height: "22px",
                  background: "none",
                  border: "2px solid #E9ECEF",
                  borderRadius: "4px",
                  textAlign: "center",
                  pointerEvents: "none",
                }}
              /> */}
              <Pagination
                count={Math.ceil(rows.length / rowsPerPage)}
                defaultPage={renderPage}
                boundaryCount={0}
                siblingCount={1}
                page={renderPage}
                onChange={handleChangePageCustomPagination}
                shape="rounded"
                // color="secondary"
                variant="outlined"
                // renderItem={(item) => (
                //   <PaginationItem
                //     slots={{ previous: "Previous", next: "Next" }}
                //     {...item}
                //   />
                // )}
                // MuiButtonBase-root
                // MuiPaginationItem-root
                // MuiPaginationItem-sizeMedium
                // MuiPaginationItem-outlined
                // MuiPaginationItem-rounded
                // Mui-selected
                // MuiPaginationItem-page
                // css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root
                sx={{
                  "& .MuiPagination-ul li": {
                    marginBottom: "0",
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#F4E6FF",
                    border: "1px solid #BFBFBF",
                    // color: "#343A40",
                    // border: "none",
                    "&:hover, &:focus": {
                      // color: "white",
                      backgroundColor: "#fae6ff",
                    },
                  },
                  "& .MuiPaginationItem-root": {
                    // backgroundColor: "red",
                    color: "#343A40",
                    border: "none",
                    minWidth: "24px",
                    height: "24px",
                    // padding: "0",
                  },
                }}
                // sx={{ color: "red" }}
              />
            </td>
            {/* <td>
              <span
                className="b2 grey6"
                style={{ margin: "0 10px", fontSize: "14px", color: "#6C757D" }}
              >
                /
              </span>
            </td> */}
            {/* console.log({renderPage}) */}
            {/* {renderPageNumbers()} */}
            {/* <td>
              <span
              >
                {renderPage > 0 && renderPage}
              </span>
            </td>
            <td
              style={{
                background: renderPage === 0 && "red",
                width: "20px",
                height: "20px",
              }}
            >
              {renderPage + 1}
            </td>
            <td>{renderPage + 2}</td>
            <td>{renderPage === 0 && renderPage + 3}</td>
            {console.log(
              "Math.ceil(rows.length / rowsPerPage)",
              Math.ceil(rows.length / rowsPerPage),
            )} */}
            {/* <td>
              <span className="b2 grey6">{`${
                Math.ceil(rows.length / rowsPerPage) === 0
                  ? 1
                  : Math.ceil(rows.length / rowsPerPage)
              }`}</span>
            </td> */}

            {/* <td>
              <CaretRight
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleChangePage(renderPage + 1)}
              />
            </td> */}
          </tr>
        </>
      </TableFooter>
    </table>
  );
};

export default TablePagination;
