/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import Field from "../../components/sub-components/Field";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus.svg";
import { ReactComponent as DotsThreeHorizontal } from "../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as Fi_trash } from "../../assets/icons/fi_trash.svg";
import { ReactComponent as PencilSimple } from "../../assets/icons/PencilSimple.svg";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import { Menu, MenuItem } from "@mui/material";
import { secure_instance } from "../../axios/axios-config";
import { Table } from "../Tables/Table";
import { StyledMenu } from "../Menus/Menu";
import {
  handleEditCohortSlice,
  handleNewCohort,
  setCurrentCohortData,
  setSelectedCohorts,
  setSideMenuTablePage,
  toggleSideMenu,
} from "../../redux/slices/cohortsSlice";

const columns = [
  {
    key: "name",
    // numeric: false,
    // disablePadding: true,
    name: "Name",
    minWidth: 300,
    padding: "0 0 0 32px",
    accessor: (row) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          className="s1"
          style={{
            marginRight: "16px",
            minWidth: "37px",
            background: "#495057",
            color: "#F8F9FA",
            minHeight: "37px",
            borderRadius: "100px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {row?.user_profile.first_name.substring(0, 1)}
          {row?.user_profile.last_name.substring(0, 1)}
        </span>
        <span className="b1 grey8">
          {row?.user_profile.first_name + " " + row?.user_profile.last_name}
        </span>
      </div>
    ),
  },
  // ASK THE BACKEND PEEPS TO SEND DEPARTMENT FROM BACKEND
  {
    key: "department",
    // numeric: false,
    // disablePadding: true,
    name: "Department",
    minWidth: 270,
    accessor: (row) => (
      <span className="b1 grey8">{row?.user_profile.department}</span>
    ),
  },
];

export default function CohortSideMenu(props) {
  const { currentrow, setCurrentrow } = props;
  const [isCohortFiltersExist, setIsCohortFiltersExist] = useState(false);
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [cohortTitle, setCohortTitle] = useState("");
  const [cohortDescription, setCohortDescription] = useState("");
  const [fieldId, setFieldId] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [usersSearch, setUsersSearch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDropDown, setShowDropDown] = useState({
    menu: null,
    click: 0,
    rowId: 0,
  });
  const [isDeleteCohortView, setIsDeleteCohortView] = useState(false);

  const cohortFilterInitialState = {
    id: fieldId,
    attribute: "",
    condition: "",
    value: "",
  };

  const [cohortFilters, setCohortFilters] = useState([
    cohortFilterInitialState,
  ]);
  const [match, setMatch] = useState("");

  const isActionMenu = Boolean(anchorEl);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.authReducer);

  const { cohortsData } = useSelector(
    (state) => state.cohortsReducer.tableData,
  );

  const { page, usersData } = useSelector(
    (state) => state.cohortsReducer.sideMenu.tableData,
  );
  const { isSideMenuOpen } = useSelector(
    (state) => state.cohortsReducer.sideMenu,
  );

  const { attributeSchema, loading } = useSelector(
    (state) => state.cohortsReducer,
  );

  const handleIncrementId = () => {
    if (isEditable) {
      setFieldId(fieldId + 1);
    }
  };

  const handleDropDownMenu = (id, rowId) => {
    setShowDropDown({
      menu: true,
      click: id + rowId,
    });

    if (showDropDown.click === id + rowId && !(showDropDown.menu === null)) {
      setShowDropDown({
        menu: null,
        click: id,
      });
    }
  };

  const handleAddCohortFilter = () => {
    const newCohortFilter = { ...cohortFilterInitialState, id: fieldId };
    setCohortFilters([...cohortFilters, newCohortFilter]);
  };

  const handleRemoveCohortFilter = (filterToRemove) => {
    setCohortFilters(cohortFilters.filter((item) => item !== filterToRemove));
  };

  const handleDropDownSelected = (data, id, currentField) => {
    if (Object.prototype.hasOwnProperty.call(currentField, id)) {
      const newArr = cohortFilters.map((object) => {
        if (object === currentField) {
          if (id === "attribute") {
            return {
              ...object,
              [id]: attributeSchema[data]?.attribute,
              value: "",
              condition: "",
            };
          }
          if (
            id === "value" &&
            Object.prototype.hasOwnProperty.call(data, "id")
          ) {
            return { ...object, [id]: data.id };
          }
          if (id === "value" && currentField.attribute === "gender") {
            return { ...object, [id]: data };
          }
          if (id === "condition") {
            if (data.operator === "Like") {
              return { ...object, [id]: data.operator, value: "" };
            }
            return { ...object, [id]: data.operator };
          }
          return { ...object, [id]: data };
        }
        return object;
      });
      setCohortFilters(newArr);
    }
  };

  const handleCohortInputValue = (value, currentField) => {
    const newArr = cohortFilters.map((object) => {
      if (object === currentField) {
        return { ...object, ["value"]: value };
      }
      return object;
    });
    setCohortFilters(newArr);
  };

  const transformData = () => {
    return cohortFilters.map((filter) => ({
      attribute: filter.attribute.toLowerCase(),
      condition: filter.condition,
      value: filter.value,
    }));
  };

  const handleCreateCohort = () => {
    const filtersTransformation = cohortFilters.map((filter) => {
      return {
        attribute: filter.attribute,
        condition: filter.condition,
        value: filter.value,
      };
    });
    const operations = { operations: filtersTransformation };

    const matchCondition = match.toLowerCase();

    const cohortObj = {
      name: cohortTitle,
      tenant_id: user.tenantId,
      logic:
        matchCondition === "any" || matchCondition === "or"
          ? "or"
          : matchCondition === "all" || matchCondition === "and"
            ? "and"
            : "",
      description: cohortDescription,
      filters: operations,
    };

    dispatch(handleNewCohort(cohortObj));
    dispatch(toggleSideMenu());
    dispatch(setSelectedCohorts([]));
    setTablePage(1);
  };

  const refreshStates = () => {
    setIsRowClicked(false);
    setCohortFilters([cohortFilterInitialState]);
    setCurrentrow({});
    setFieldId(0);
    setMatch("");
    setCohortTitle("");
    setCohortDescription("");
    dispatch(setCurrentCohortData([]));
    setTab1(true);
    setTab2(false);
    setIsEditable(false);
    setIsDeleteCohortView(false);
    setTablePage(1);
  };

  const handleTab1Clicked = () => {
    setTab1(true);
    setTab2(false);
  };

  const handleTab2Clicked = () => {
    setTab1(false);
    setTab2(true);
  };

  const handleActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleUsersSearch = (value) => {
    {
      isEditable ? setUsersSearch(value) : setUsersSearch(null);
    }
  };

  const setTablePage = (data) => {
    dispatch(setSideMenuTablePage(data));
  };

  const getCurrentCohortData = async () => {
    try {
      const request = await secure_instance.request({
        url: `/v1/cohorts/${currentrow.id}`,
        method: "GET",
      });

      dispatch(setCurrentCohortData(request.data));
    } catch (e) {
      // console.log(e);
    }
  };

  const handleEditCohort = async () => {
    const cohortFiltersWithoutIds = transformData();
    const operations = { operations: cohortFiltersWithoutIds };

    const matchCondition = match.toLowerCase();

    const cohortObj = {
      name: cohortTitle,
      tenant_id: user.tenantId,
      logic:
        matchCondition === "any" || matchCondition === "or"
          ? "or"
          : matchCondition === "all" || matchCondition === "and"
            ? "and"
            : "",
      description: cohortDescription,
      filters: operations,
    };
    dispatch(handleEditCohortSlice(currentrow.id, cohortObj, cohortsData));
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getCohortAttribute = (attributeFilter) => {
    const convertAttribute =
      attributeFilter === "department_id"
        ? "Department"
        : attributeFilter === "designation_id"
          ? "Designation"
          : attributeFilter === "dob"
            ? "Age"
            : capitalizeFirstLetter(attributeFilter);

    if (Object.keys(currentrow).length > 0 && isRowClicked) {
      Object.entries(attributeSchema).find((attribute) => {
        if (attribute[0] === convertAttribute) {
          return attribute;
        }
      });
    }
    return convertAttribute;
  };

  const getCohortValue = (currentFilter) => {
    let valueToReturn = "";
    Object.entries(attributeSchema).find((attribute) => {
      if (attribute[1].attribute === currentFilter.attribute) {
        if (
          currentFilter.attribute === "department_id" ||
          currentFilter.attribute === "designation_id"
        ) {
          // INSTEAD COMPARE HERE, IF THE ID IS THERE, COMPARE IT
          if (attribute[1].attribute === currentFilter.attribute) {
            const valueOperatorMap = attribute[1].values.find(
              (value) => value.id === currentFilter.value,
            );
            valueToReturn = valueOperatorMap?.name;
          }
        }
        if (currentFilter.attribute === "gender") {
          const valueOperatorMap = attribute[1].values.find(
            (value) => value === currentFilter?.value,
          );
          if (valueOperatorMap) {
            // IN CASE OF IDS, RETURN NAME ELSE valueOperatorMap
            valueToReturn = valueOperatorMap;
          }
        } else {
          const valueOperatorMap = attribute[1].values.find(
            (value) => value[attribute[1]?.attribute] === currentFilter?.value,
          );
          if (valueOperatorMap) {
            // IN CASE OF IDS, RETURN NAME ELSE valueOperatorMap
            valueToReturn = valueOperatorMap[attribute[1]?.attribute];
          }
        }
      }
    });
    return valueToReturn;
  };

  const getConditionOptions = (cohortFilter) => {
    const conditionOptions = [];
    // console.log("conditionOptions:", conditionOptions);

    Object.entries(attributeSchema).find((attribute) => {
      if (attribute[1].attribute === cohortFilter.attribute) {
        attribute[1].conditions.map((condition) => {
          conditionOptions.push(condition);
        });
      }
    });
    return conditionOptions;
  };

  const getConditionValues = (currentFilter) => {
    let conditionValue = "";
    Object.entries(attributeSchema).forEach((attribute) => {
      if (attribute[1].attribute === currentFilter.attribute) {
        const valueOperatorMap = attribute[1].conditions.find(
          (condition) => condition.operator === currentFilter.condition,
        );
        conditionValue = valueOperatorMap?.condition;
      }
    });
    return conditionValue;
  };

  const getValuesOptions = (currentFilter) => {
    const valueOptions = [];
    // Map the attribute schema and get value according to selected attribute
    Object.entries(attributeSchema).map((attribute) => {
      if (attribute[1].attribute === currentFilter.attribute) {
        attribute[1].values.forEach((value) => {
          if (value !== null && value[attribute[1].attribute] !== null) {
            if (Object.prototype.hasOwnProperty.call(value, "id")) {
              valueOptions.push(value);
            } else if (
              Object.prototype.hasOwnProperty.call(
                value,
                attribute[1].attribute,
              )
            ) {
              valueOptions.push(value[attribute[1].attribute]);
            } else {
              valueOptions.push(value);
            }
          }
        });
      }
    });
    return valueOptions;
  };

  const getMatchValue = (match) => {
    match = match.toLowerCase();
    const convertValue =
      match === "or" || match === "any"
        ? "Any"
        : match === "and" || match === "all"
          ? "All"
          : "";
    const capitalized = capitalizeFirstLetter(convertValue);
    return capitalized;
  };

  const disableOverlay = () => {
    return (
      <div
        style={{
          position: "absolute",
          // left: 0,
          // bottom:0,
          width: "100%",
          padding: "10px",
          height: "100%",
          backgroundColor: "rgb(248,249,250,0.6)",
          borderRadius: "10px",
          zIndex: 5,
        }}
      />
    );
  };

  useEffect(() => {
    if (fieldId > 0) {
      handleAddCohortFilter();
    }
  }, [fieldId]);

  useEffect(() => {
    const isFilter = cohortFilters.every((cohort) => {
      return (
        cohort.attribute.length > 0 &&
        cohort.condition.length > 0 &&
        (cohort.value.length > 0 || cohort.value !== "")
      );
    });
    if (isFilter) {
      setIsCohortFiltersExist(true);
    }
    if (!isFilter) {
      setIsCohortFiltersExist(false);
    }
  }, [cohortFilters]);

  useEffect(() => {
    if (Object.keys(currentrow).length === 0) {
      setIsRowClicked(false);
      setIsEditable(true);
    }
    if (Object.keys(currentrow).length > 0) {
      setIsEditable(false);
      setIsRowClicked(true);
      setCohortTitle(currentrow.name);
      setCohortDescription(currentrow.description);
      setCohortFilters(currentrow.filters.operations);
      setMatch(currentrow.logic);
    }
  }, [currentrow]);

  useEffect(() => {
    if (isSideMenuOpen === true) {
      document.body.style.overflow = "hidden";
      Object.keys(currentrow).length > 0 && getCurrentCohortData();
    } else if (isSideMenuOpen === false) {
      document.body.style.overflow = "unset";
      refreshStates();
    }
  }, [isSideMenuOpen]);

  return (
    <div
      className={`side-menu-box ${
        isSideMenuOpen === true ? "open border" : "side-menu-box border"
      }`}
      style={{ paddingTop: "48px" }}
      onClick={() => setShowDropDown({ menu: null, click: 0 })}
    >
      <div className="d-flex justify-content-between">
        <span className="head5 grey8">
          {isDeleteCohortView ? (
            <Arrow_left
              stroke="#343A40"
              style={{ margin: "2px 20px 5px 0", width: "24px" }}
              onClick={() => setIsDeleteCohortView(false)}
            />
          ) : (
            <Close
              stroke="#343A40"
              strokeWidth="2"
              style={{
                margin: "0px 20px 3px 0",
                width: "24px",
                cursor: "pointer",
              }}
              onClick={() => dispatch(toggleSideMenu())}
            />
          )}
          {isRowClicked ? "Name of Cohort" : "Cohort"}
        </span>

        {!isRowClicked ? (
          <div
            className="d-flex align-items-center flex-row-reverse"
            style={{ width: "fit-content" }}
          >
            <div className="integration-menu-buttons">
              <Button
                typography={"b0-medium grey1"}
                buttonFilledFull
                title="Create Cohort"
                style={{ height: "40px", marginTop: "5px", fontWeight: "400" }}
                loading={loading}
                disabled={
                  cohortTitle.length === 0 ||
                  !isCohortFiltersExist ||
                  match.length === 0
                }
                onClick={() => handleCreateCohort()}
              />
            </div>
            <div
              style={{ paddingRight: "15px" }}
              className="integration-menu-buttons"
            >
              <Button
                typography={"s1 grey6"}
                buttonHollowLg
                title="Cancel"
                className="s1 grey6"
                style={{ height: "40px", marginTop: "5px" }}
                onClick={() => dispatch(toggleSideMenu())}
              />
            </div>
          </div>
        ) : !isEditable && !isDeleteCohortView ? (
          <>
            <div className="d-flex align-items-center flex-row-reverse">
              <DotsThreeHorizontal onClick={handleActionMenu} />
              <div style={{ marginRight: "16px" }}>
                <PencilSimple
                  style={{ width: "32px", height: "32px", cursor: "pointer" }}
                  onClick={() => {
                    setIsEditable(true);
                    handleActionMenuClose();
                  }}
                />
              </div>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={isActionMenu}
                onClose={handleActionMenuClose}
              >
                <MenuItem
                  disableRipple
                  onClick={() => {
                    setIsDeleteCohortView(true);
                    handleActionMenuClose();
                  }}
                >
                  <Fi_trash
                    stroke="#343A40"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <span
                    className="s2 grey8 d-flex align-items-center"
                    style={{ marginLeft: "8px" }}
                  >
                    Delete
                  </span>
                </MenuItem>
              </StyledMenu>
            </div>
          </>
        ) : (
          <div
            className="d-flex align-items-center flex-row-reverse"
            style={{ width: "fit-content" }}
          >
            <div className="integration-menu-buttons">
              <Button
                buttonFilledFull
                title={isDeleteCohortView ? "Confirm" : "Save Changes"}
                className="s1 grey1"
                style={{ height: "40px", marginTop: "5px", fontWeight: "400" }}
                loading={loading}
                disabled={
                  cohortTitle.length === 0 ||
                  !isCohortFiltersExist ||
                  match.length === 0
                }
                onClick={
                  isDeleteCohortView
                    ? () => props.handleDeleteCohort()
                    : () => handleEditCohort()
                }
              />
            </div>
            <div
              style={{ paddingRight: "15px" }}
              className="integration-menu-buttons"
            >
              <Button
                buttonHollowLg
                title="Cancel"
                className="s1 grey6"
                style={{ height: "40px", marginTop: "5px" }}
                onClick={
                  isDeleteCohortView
                    ? () => setIsDeleteCohortView(false)
                    : () => setIsEditable(false)
                }
              />
            </div>
          </div>
        )}
      </div>
      {isRowClicked && !isDeleteCohortView && (
        <div className="tags d-flex" style={{ marginTop: "24px" }}>
          <div
            className={`b1 d-flex justify-content-center ${
              tab1 ? "grey8" : "grey6"
            }`}
            style={{
              borderBottom: tab1 ? "4px solid #7B2CBF" : "",
              padding: "8px",
              width: "279px",
              cursor: "pointer",
            }}
            onClick={handleTab1Clicked}
          >
            {"Details"}
          </div>
          <div
            className={`b1 d-flex justify-content-center ${
              tab2 ? "grey8" : "grey6"
            }`}
            style={{
              // color:
              borderBottom: tab2 ? "4px solid #7B2CBF" : "",
              padding: "8px",
              width: "279px",
              cursor: "pointer",
            }}
            onClick={handleTab2Clicked}
          >
            {"Users"}
          </div>
        </div>
      )}
      {tab1 && !isDeleteCohortView ? (
        <>
          <div
            className="d-flex flex-column"
            style={{
              height: "fit-content",
              textAlign: "start",
              marginTop: "30px",
              borderBottom: "2px solid #E9ECEF",
            }}
          >
            <span className={`head6 ${isEditable ? "grey8" : "grey6"}`}>
              Cohort Details
            </span>
            <div className="row" style={{ margin: "30px 0px 20px 0px" }}>
              <div className="col-lg-12 p-0" style={{ marginRight: "0px" }}>
                <div
                  style={{ paddingBottom: "10px" }}
                  className={`s2 ${isEditable ? "grey8" : "grey6"}`}
                >
                  Title
                </div>
                <div>
                  <Field
                    inputFieldFull
                    name="title"
                    placeholder="Name of cohort"
                    className={`b1 ${isEditable ? "grey8" : "grey6"}`}
                    disabled={!isEditable && isRowClicked}
                    style={{ background: isEditable && "white" }}
                    value={
                      isRowClicked && cohortTitle === "" ? "" : cohortTitle
                    }
                    onChange={(e) => {
                      setCohortTitle(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div
                className="col-lg-12 p-0"
                style={{ marginRight: "0px", marginTop: "16px" }}
              >
                <div
                  style={{ paddingBottom: "10px" }}
                  className={`s2 ${isEditable ? "grey8" : "grey6"}`}
                >
                  Description
                </div>
                <div style={{}}>
                  <Field
                    inputFieldFull
                    autoComplete
                    name="description"
                    placeholder="Description"
                    className={`b1 ${isEditable ? "grey8" : "grey6"}`}
                    disabled={!isEditable}
                    style={{ background: isEditable && "white" }}
                    value={
                      isRowClicked && cohortDescription === ""
                        ? ""
                        : cohortDescription
                    }
                    onChange={(e) => {
                      setCohortDescription(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <span
            className={`head6 ${isEditable ? "grey9" : "grey6"}`}
            style={{ marginTop: "24px" }}
          >
            Attributes
          </span>

          <div
            className="d-flex align-items-center"
            style={{ marginTop: "16px" }}
            onClick={(e) => e.stopPropagation()}
          >
            <span className={`s2 ${isEditable ? "grey8" : "grey6"}`}>
              Match
            </span>
            <Field
              style={{
                maxWidth: "144px",
                margin: "0 8px",
                pointerEvents: !isEditable && "none",
              }}
              inputBackground={isEditable ? "#FFFFFF" : "#F8F9FA"}
              fieldDropdownFull
              options={isEditable ? ["All", "Any"] : []}
              id="match"
              rowId="match"
              readOnly
              value={getMatchValue(match)}
              placeholder="All"
              typography={`s1 grey5-placeHolderColor ${
                isEditable ? "grey8" : "grey6"
              }`}
              className={`s1 ${
                isEditable ? "grey8-placeHolderColor" : "grey6-placeHolderColor"
              }`}
              handleDropDownMenu={handleDropDownMenu}
              showDropDown={showDropDown}
              showMultipleDropDown
              onClick={(option) => {
                setMatch(option);
              }}
            />
            <span className={`s2 ${isEditable ? "grey8" : "grey6"}`}>
              attributes
            </span>
          </div>

          <div className="row" style={{ margin: "24px 0px 8px 0px" }}>
            {cohortFilters.map((cohortFilter, index) => {
              const rowId = cohortFilter.id
                ? cohortFilter.id
                : "_" + index + "_";
              return (
                <div
                  key={index}
                  className="d-flex align-items-center col-lg-12 p-0"
                  style={{ marginRight: "0px", marginBottom: "24px" }}
                >
                  <div
                    style={{ marginRight: "8px", minWidth: "36%" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      style={{ paddingBottom: "8px" }}
                      className={`s2 ${isEditable ? "grey7" : "grey6"}`}
                    >
                      Attribute Type
                    </div>

                    <Field
                      inputBackground={isEditable ? "#FFFFFF" : "#F8F9FA"}
                      fieldDropdownFull
                      placeholder="Select Attribute"
                      typography={`s1 grey5-placeHolderColor ${
                        isEditable ? "grey8" : "grey6"
                      }`}
                      id="attribute"
                      rowId={rowId}
                      style={{ pointerEvents: !isEditable && "none" }}
                      options={isEditable ? Object.keys(attributeSchema) : []}
                      readOnly
                      value={getCohortAttribute(cohortFilter.attribute)}
                      handleDropDownMenu={handleDropDownMenu}
                      showMultipleDropDown
                      showDropDown={showDropDown}
                      onClick={(option, id) =>
                        handleDropDownSelected(option, id, cohortFilter)
                      }
                    />
                  </div>

                  <div
                    style={{
                      marginRight: "8px",
                      maxWidth: "154px",
                      minWidth: "20.3%",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      style={{ paddingBottom: "8px" }}
                      className={`s2 ${isEditable ? "grey7" : "grey6"}`}
                    >
                      Condition
                    </div>

                    <Field
                      inputBackground={isEditable ? "#FFFFFF" : "#F8F9FA"}
                      fieldDropdownFullCohorts
                      noDropDown
                      placeholder="select"
                      style={{ pointerEvents: !isEditable && "none" }}
                      id="condition"
                      rowId={rowId}
                      typography={`s1 grey5-placeHolderColor ${
                        isEditable ? "grey8" : "grey6"
                      }`}
                      options={getConditionOptions(cohortFilter)}
                      conditionsDropDown
                      readOnly
                      value={
                        cohortFilter.condition &&
                        getConditionValues(cohortFilter)
                      }
                      handleDropDownMenu={handleDropDownMenu}
                      showMultipleDropDown
                      showDropDown={showDropDown}
                      onClick={(option, id) =>
                        handleDropDownSelected(option, id, cohortFilter)
                      }
                    />
                  </div>

                  <div
                    style={{ minWidth: "36%" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      style={{ paddingBottom: "8px" }}
                      className={`s2 ${isEditable ? "grey7" : "grey6"}`}
                    >
                      Value
                    </div>

                    {cohortFilter.attribute === "dob" ? (
                      <div style={{ display: "flex" }}>
                        <Field
                          inputFieldFullNumber
                          id="value"
                          onChange={(e) =>
                            handleCohortInputValue(
                              Number(e.target.value) > 0
                                ? Number(e.target.value)
                                : "",
                              cohortFilter,
                            )
                          }
                          value={cohortFilter.value}
                          style={{
                            pointerEvents: !isEditable && "none",
                            background: isEditable
                              ? "#FFFFFF"
                              : "rgb(248, 249, 250)",
                          }}
                        />
                      </div>
                    ) : cohortFilter.condition === "Like" ? (
                      <div style={{ display: "flex" }}>
                        <Field
                          inputFieldFull
                          id="value"
                          onChange={(e) =>
                            handleCohortInputValue(e.target.value, cohortFilter)
                          }
                          value={cohortFilter.value}
                          style={{
                            pointerEvents: !isEditable && "none",
                            background: isEditable
                              ? "#FFFFFF"
                              : "rgb(248, 249, 250)",
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <Field
                          inputBackground={isEditable ? "#FFFFFF" : "#F8F9FA"}
                          options={getValuesOptions(cohortFilter)}
                          valuesDropDown={
                            cohortFilter.attribute === "designation_id" ||
                            cohortFilter.attribute === "department_id"
                          }
                          fieldDropdownFullCohorts
                          placeholder="Select Attribute"
                          id="value"
                          rowId={rowId}
                          typography={`s1 grey5-placeHolderColor ${
                            isEditable ? "grey8" : "grey6"
                          }`}
                          style={{ pointerEvents: !isEditable && "none" }}
                          showMultipleDropDown
                          readOnly
                          value={
                            cohortFilter.value && getCohortValue(cohortFilter)
                          }
                          handleDropDownMenu={handleDropDownMenu}
                          showDropDown={showDropDown}
                          onClick={(option, id) => {
                            handleDropDownSelected(option, id, cohortFilter);
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      marginLeft: "24px",
                      width: "24px",
                      height: "24px",
                    }}
                  >
                    <Close
                      fill={isEditable ? "#343A40" : "#DEE2E6"}
                      stroke={isEditable ? "#343A40" : "#DEE2E6"}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginTop: "13px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        isEditable && handleRemoveCohortFilter(cohortFilter)
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="d-flex align-items-center"
            style={{ cursor: "pointer", width: "192px", marginTop: "0px" }}
            onClick={handleIncrementId}
          >
            <PlusIcon
              fill={isEditable ? "#7B2CBF" : "#ADB5BD"}
              stroke={isEditable ? "#7B2CBF" : "#ADB5BD"}
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            <span
              className="s1"
              style={{ color: isEditable ? "#7B2CBF" : "#ADB5BD" }}
            >
              Add another filter
            </span>
          </div>
        </>
      ) : (
        <>
          {!isDeleteCohortView && (
            <>
              <div
                style={{
                  margin: "48px 0 20px 0",
                  border: "1px solid #E9ECEF",
                  borderRadius: "10px",
                  background: isEditable ? "#FFFFFF" : "#F8F9FA",
                }}
              >
                <Field
                  inputFieldFullExpand
                  placeholder="Search"
                  disabled={!isEditable && isRowClicked}
                  detectedUserIcon
                  style={{
                    background: isEditable && "white",
                    height: "48px",
                    borderRadius: "10px",
                  }}
                  value={usersSearch}
                  onChange={(e) => {
                    handleUsersSearch(e.target.value);
                  }}
                  inputStyle={{
                    color: "#6C757D",
                    border: "none",
                    height: "48px",
                    background: isEditable && "#FFFFFF",
                    pointerEvents: !isEditable && "none",
                  }}
                />
              </div>

              <div style={{ position: "relative" }}>
                {!isEditable && disableOverlay()}
                <Table
                  data={usersData.length > 0 && usersData[0].cohort_mapping}
                  inputValue={usersSearch}
                  columns={columns}
                  page={page}
                  setTablePage={setTablePage}
                  // cohortsSideMenu
                  isEditable={isEditable}
                  background={isEditable ? "#FFFFFF" : "#F8F9FA"}
                  emptyState="User not found"
                  // displayAvatar
                />
              </div>
            </>
          )}

          {isDeleteCohortView && (
            <>
              <span
                className="head6 grey8"
                style={{ margin: "24px 0px 16px 0" }}
              >
                Delete Cohort
              </span>
              <span className="s1 grey8">
                You are about to delete this cohort. Would you like to continue?
              </span>
            </>
          )}
        </>
      )}
    </div>
  );
}
