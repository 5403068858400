import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";

const initialState = {
  ProviderConnectionInfo: {
    loading: false,
    tableLoader: false,
    hamburgerSideMenu: false,
    IdentityProvders: {
      id: 0,
      providersName: "",
      connected: false,
      autoSyncSwitch: true,
      autoSyncGroupsSwitch: true,
      enableAutoSync: false,
      syncUsersData: [],
      manuallySelectedUsers: [],
      groupsData: [],
      individualTeamsData: {
        tenant_id: 0,
        name: "", // dispatch on create teams
        team_groups: [], // group_id here
      },
      teamsData: [],
    },
    TeamsForm: {
      name: "",
      description: "",
    },
    isTeamsModal: false,
  },
  sideMenuTableData: {
    data: [],
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
    isMultipleRowsSelected: false,
  },
  showSyncMenu: false,
};

export const handleConnectionStatus = createAsyncThunk(
  "teams/handleConnectionStatus",
  async (isLogin, { rejectWithValue }) => {
    try {
      const gauthReq = await secure_instance.request({
        url: "v1/gauth/connection",
        method: "GET",
      });

      console.log("gauthReqgauthReq", gauthReq);

      if (gauthReq.data) {
        return { status: gauthReq.data, providerName: "GSuite" };
      }
    } catch (e) {
      return rejectWithValue(e.response?.data?.message || e.message);
    }

    try {
      const AzureReq = await secure_instance.request({
        url: "v1/mauth/connection",
        method: "GET",
      });

      if (AzureReq.data) {
        return { status: AzureReq.data, providerName: "Microsoft Azure" };
      }
    } catch (e) {
      return rejectWithValue(e.response?.data?.message || e.message);
    }
  },
);

export const checkCurrentProviderStatus = createAsyncThunk(
  "providers/checkCurrentProviderStatus",
  async (providersName, { rejectWithValue }) => {
    try {
      const request = await secure_instance.request({
        url:
          providersName === "GSuite"
            ? "v1/gauth/connection"
            : providersName === "Microsoft Azure" && "v1/mauth/connection",
        method: "GET",
      });

      if (!request) {
        throw new Error("Invalid provider name");
      }
      return request.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  },
);

export const getAutoSyncUsers = createAsyncThunk(
  "identityProviders/toggleAutoSync",
  async (providersName, { dispatch }) => {
    try {
      dispatch(identityProvidersSlice.actions.setTableLoading(true)); // Correctly reference action
      const url =
        providersName === "GSuite"
          ? "v1/gauth/users"
          : providersName === "Microsoft Azure"
            ? "v1/mauth/users"
            : null;
      const response = await secure_instance.request({ url, method: "Get" });
      dispatch(identityProvidersSlice.actions.setTableLoading(false)); // Correctly reference action
      return {
        syncUsersData: response.data,
      };
    } catch (error) {
      dispatch(identityProvidersSlice.actions.setTableLoading(false)); // Correctly reference action
      console.error("Error toggling auto sync:", error);
    }
  },
);

export const handleSyncUsers = createAsyncThunk(
  "identityProviders/handleSyncUsers",
  async (providersName, { rejectWithValue }) => {
    try {
      const url =
        providersName === "GSuite"
          ? "v1/gauth/auto-sync"
          : "v1/mauth/auto-sync";
      const response = await secure_instance.request({
        url,
        method: "Get",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  },
);

export const getGroupsSyncData = createAsyncThunk(
  "identityProviders/getGroupsSyncData",
  async (history, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: `v1/groups/?type=all`,
        method: "Get",
      });
      // history.push("/integrations");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  },
);

export const handleManualSyncUsers = createAsyncThunk(
  "identityProviders/handleManualSyncUsers",
  async ({ map, providersName, history }, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: providersName === "GSuite" ? "v1/gauth/users" : "v1/mauth/users",
        method: "Post",
        data: map,
      });
      history.push(`integrations`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  },
);

export const handleTeamsGroupPost = createAsyncThunk(
  "teams/handleTeamsGroupPost",
  async ({ data, history }, { rejectWithValue }) => {
    try {
      console.log("datadata", data);
      const request = await secure_instance.request({
        url: "v1/teams/",
        method: "POST",
        data: data,
      });
      if (request.data.length > 0) {
        history.push(`integrations`);
      }

      return request.data;
    } catch (e) {
      return rejectWithValue(e.response.data.message);
    }
  },
);

const identityProvidersSlice = createSlice({
  name: "identityProviders",
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      state.ProviderConnectionInfo.hamburgerSideMenu = action.payload;
    },
    toggleSyncMenu: (state, action) => {
      state.showSyncMenu = !state.showSyncMenu;
    },
    setManualSyncUsers(state, action) {
      state.ProviderConnectionInfo.IdentityProvders.syncUsersData =
        action.payload;
    },
    toggleTableLoading(state) {
      state.ProviderConnectionInfo.tableLoader =
        !state.ProviderConnectionInfo.tableLoader;
    },
    setTableLoading(state, action) {
      state.ProviderConnectionInfo.tableLoader = action.payload;
    },
    toggleAutoSyncButton(state, action) {
      // state.tableLoader = action.payload;
      console.log("action.payloadaction.payload", action.payload);
      state.ProviderConnectionInfo.IdentityProvders.autoSyncSwitch =
        action.payload.autoSyncEnabled;
    },
    handleSyncUsersEnd(state) {
      state.ProviderConnectionInfo.loading = false;
    },
    handleIndividualTeamGroupData: (state, action) => {
      state.ProviderConnectionInfo.IdentityProvders.individualTeamsData =
        action.payload;
    },
    handleTeamsGroupData: (state, action) => {
      state.ProviderConnectionInfo.IdentityProvders.teamsData.push(
        action.payload,
      );
    },
    handleFilteredTeamsGroupData: (state, action) => {
      state.ProviderConnectionInfo.IdentityProvders.teamsData = action.payload;
    },
    toggleAutoSyncGroupsButton: (state) => {
      state.ProviderConnectionInfo.IdentityProvders.autoSyncGroupsSwitch =
        !state.ProviderConnectionInfo.IdentityProvders.autoSyncGroupsSwitch;
    },
    handleTeamsPopup: (state) => {
      state.ProviderConnectionInfo.isTeamsModal =
        !state.ProviderConnectionInfo.isTeamsModal;
    },
    handleTeamsFormChange: (state, action) => {
      if (action.payload === "") {
        state.ProviderConnectionInfo.TeamsForm = {}; // Reset to initial form state
      } else {
        const { name, value } = action.payload;
        state.ProviderConnectionInfo.TeamsForm[name] = value; // Update specific form field
      }
    },
    handleCancelSetup: (state) => {
      state.ProviderConnectionInfo.IdentityProvders.autoSyncSwitch =
        initialState.ProviderConnectionInfo.IdentityProvders.autoSyncSwitch;
      state.ProviderConnectionInfo.IdentityProvders.autoSyncGroupsSwitch =
        initialState.ProviderConnectionInfo.IdentityProvders.autoSyncGroupsSwitch;
      state.ProviderConnectionInfo.IdentityProvders.individualTeamsData =
        initialState.ProviderConnectionInfo.IdentityProvders.individualTeamsData;
      state.ProviderConnectionInfo.IdentityProvders.teamsData =
        initialState.ProviderConnectionInfo.IdentityProvders.teamsData;
    },
    setTablePage: (state, action) => {
      state.sideMenuTableData.page = action.payload;
    },
    // clearTeamsForm: (state) => {
    //   state.ProviderConnectionInfo.TeamsForm = {}; // Clear the form
    // },
    // getAutoSyncUsers(state, action) {
    //   state.autoSync = action.payload;
    // },
    // Additional actions can be added here
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleConnectionStatus.pending, (state) => {
        state.ProviderConnectionInfo.loading = true;
      })
      .addCase(handleConnectionStatus.fulfilled, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
        const { providerName, status } = action.payload;
        console.log(providerName, status);
        // state.ProviderConnectionInfo.IdentityProviders = {
        // ...state.ProviderConnectionInfo.IdentityProviders,
        //
        state.ProviderConnectionInfo.IdentityProvders.providersName =
          providerName;
        state.ProviderConnectionInfo.IdentityProvders.connected = true;
        state.ProviderConnectionInfo.IdentityProvders.id = status.id;
        state.ProviderConnectionInfo.IdentityProvders.autoSync =
          status.auto_sync;
        // providersName: providerName,
        // connected: true,
        // id: status.id,
        // autoSync: status.auto_sync,
        // };
      })
      .addCase(handleConnectionStatus.rejected, (state) => {
        state.ProviderConnectionInfo.loading = false;
      })
      .addCase(checkCurrentProviderStatus.pending, (state) => {
        state.ProviderConnectionInfo.loading = true;
        state.error = null;
      })
      .addCase(checkCurrentProviderStatus.fulfilled, (state, action) => {
        if (state.ProviderConnectionInfo.IdentityProvders.autoSync) {
          state.ProviderConnectionInfo.loading = false;
        }
        const { id, auto_sync } = action.payload;
        state.ProviderConnectionInfo.IdentityProvders.connected = true;
        state.ProviderConnectionInfo.IdentityProvders.id = id;
        state.ProviderConnectionInfo.IdentityProvders.autoSync = auto_sync;
      })
      .addCase(checkCurrentProviderStatus.rejected, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
        state.error = action.payload;
      })
      .addCase(getAutoSyncUsers.fulfilled, (state, action) => {
        state.ProviderConnectionInfo.IdentityProvders.syncUsersData =
          action.payload?.syncUsersData;
      })
      .addCase(handleSyncUsers.pending, (state) => {
        state.ProviderConnectionInfo.loading = true;
      })
      .addCase(handleSyncUsers.fulfilled, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
        state.ProviderConnectionInfo.IdentityProvders.enableAutoSync =
          action.payload.enable_auto_sync;
      })
      .addCase(handleSyncUsers.rejected, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
        state.error = action.payload;
      })
      .addCase(handleManualSyncUsers.pending, (state) => {
        state.ProviderConnectionInfo.loading = true;
      })
      .addCase(handleManualSyncUsers.fulfilled, (state) => {
        state.ProviderConnectionInfo.loading = false;
      })
      .addCase(handleManualSyncUsers.rejected, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
        state.error = action.payload;
      })
      .addCase(getGroupsSyncData.pending, (state) => {
        state.ProviderConnectionInfo.loading = true;
      })
      .addCase(getGroupsSyncData.fulfilled, (state, action) => {
        // state.groupsData = action.payload;
        state.ProviderConnectionInfo.IdentityProvders.groupsData =
          action.payload;
        state.ProviderConnectionInfo.loading = false;
      })
      .addCase(getGroupsSyncData.rejected, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
        state.error = action.payload;
      })
      .addCase(handleTeamsGroupPost.pending, (state) => {
        state.ProviderConnectionInfo.loading = true;
      })
      .addCase(handleTeamsGroupPost.fulfilled, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
      })
      .addCase(handleTeamsGroupPost.rejected, (state, action) => {
        state.ProviderConnectionInfo.loading = false;
      });
  },
});

export const {
  toggleMenu,
  toggleSyncMenu,
  setManualSyncUsers,
  toggleTableLoading,
  setTableLoading,
  handleSyncUsersEnd,
  handleIndividualTeamGroupData,
  handleTeamsGroupData,
  handleFilteredTeamsGroupData,
  toggleAutoSyncGroupsButton,
  handleTeamsPopup,
  handleTeamsFormChange,
  handleCancelSetup,
  setTablePage,
  toggleAutoSyncButton,
  // getAutoSyncUsers,
} = identityProvidersSlice.actions;
export default identityProvidersSlice.reducer;
