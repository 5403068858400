import React from "react";
import Button from "../sub-components/Button";
import { ReactComponent as Icon } from "../../assets/icons/Chats.svg";

export default function BillingCard(props) {
  if (props.free) {
    return (
      <div className="billing-card">
        <div className="head5">Free</div>
        <div
          className={`head3 ${props.selected ? "grey0" : "grey9"}`}
          style={{ paddingTop: "30px" }}
        >
          $0
        </div>
        <div className="b2" style={{ paddingTop: "10px" }}>
          user / month
        </div>
        <div className="s1" style={{ paddingTop: "30px" }}>
          1 user
        </div>
        <div
          className="s1"
          style={{ paddingTop: "10px", paddingBottom: "70px" }}
        >
          1 integration
        </div>
        <Button buttonFilledMd title="Current Plan" />
      </div>
    );
  } else if (props.starter) {
    return (
      <div
        className="billing-card"
        onClick={props.onClick}
        style={{
          ...props.style,
          background: props.selected ? "#7B2CBF" : "white",
        }}
      >
        <div className={`head5 ${props.selected ? "grey0" : "grey9"}`}>
          Starter
        </div>
        <div
          className={`head3 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "30px",
          }}
        >
          ${props.price}
        </div>
        <div
          className={`b2 ${props.selected ? "grey0" : "grey7"}`}
          style={{
            paddingTop: "10px",
          }}
        >
          user / {props.time}
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "30px",
          }}
        >
          {`${props.package.allowed_users} users`}
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "10px",
          }}
        >
          {`${props.package.allowed_integrations} integrations`}
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "10px",
            paddingBottom: "40px",
          }}
        >
          Destinations
        </div>
        {props.billing && !props.currentPlan && (
          <Button
            style={{ marginTop: "50px", width: "150px" }}
            buttonHollowMd
            title="Choose Plan"
          />
        )}
        {props.currentPlan && (
          <Button
            style={{ marginTop: "50px" }}
            buttonPlanFilledMd
            title="Current Plan"
          />
        )}
      </div>
    );
  } else if (props.professional) {
    return (
      <div
        className="billing-card"
        style={{
          ...props.style,
          background: props.selected ? "#7B2CBF" : "white",
        }}
        onClick={props.onClick}
      >
        <div className={`head5 ${props.selected ? "grey0" : "grey9"}`}>
          Professional
        </div>
        <div
          className={`head3 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "30px",
          }}
        >
          ${props.price}
        </div>
        <div
          className={`b2 ${props.selected ? "grey0" : "grey7"}`}
          style={{
            paddingTop: "10px",
          }}
        >
          user / {props.time}
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "30px",
          }}
        >
          {`${props.package.allowed_users} users`}
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "10px",
          }}
        >
          {`${props.package.allowed_integrations} integrations`}
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{
            paddingTop: "10px",
            paddingBottom: "40px",
          }}
        >
          Destinations
        </div>
        {props.billing && !props.currentPlan && (
          <Button
            typography={`b0-medium ${props.selected ? "grey0" : "grey8"}`}
            style={{ marginTop: "50px", width: "150px" }}
            buttonHollowMd
            title="Choose Plan"
          />
        )}
        {props.currentPlan && (
          <Button
            buttonPlanFilledMd
            title="Current Plan"
            style={{ marginTop: "50px" }}
          />
        )}
      </div>
    );
  } else if (props.enterprise) {
    return (
      <div className="billing-card" style={{ ...props.style }}>
        <div className={`head5 ${props.selected ? "grey0" : "grey9"}`}>
          Enterprise
        </div>
        <Icon style={{ paddingBottom: "0px", paddingTop: "20px" }} />
        <div
          className={`b2 ${props.selected ? "grey0" : "primary-color"}`}
          style={{ paddingTop: "10px" }}
        >
          Contact Sales
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{ paddingTop: "30px" }}
        >
          As per package
        </div>
        <div
          className={`s1 ${props.selected ? "grey0" : "grey9"}`}
          style={{ paddingTop: "10px" }}
        >
          As per package
        </div>
        <div
          className="s1"
          style={{ paddingTop: "10px", paddingBottom: "10px" }}
        >
          Destinations
        </div>
        {props.currentPlan && (
          <Button buttonPlanFilledMd title="Current Plan" />
        )}
      </div>
    );
  }
}
