/* eslint-disable linebreak-style */
/* eslint-disable indent */
import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

function CircularProgressLabel(props) {
  const { totalSteps, currentStep } = props;

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        style={{ color: "#ADB5BD" }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        style={{ color: "#7B2CBF" }}
        size={40}
        // value={70}
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${currentStep}/${totalSteps}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function CircularStatic(props) {
  const { totalSteps, currentStep } = props;
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    setProgress(Math.round((currentStep / totalSteps) * 100));
  });

  return (
    <CircularProgressLabel
      totalSteps={totalSteps}
      currentStep={currentStep}
      value={progress}
    />
  );
}
