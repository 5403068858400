import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { secure_instance } from "../../../axios/axios-config";
import Button from "../../../components/sub-components/Button";
import { ReactComponent as CohortIllustration } from "../../../assets/icons/Cohorts.svg";
import CohortSideMenu from "../../../components/SideMenus/Cohorts";
import BackgroundBlur from "../../../components/sub-components/Blur";
import FullPageLoading from "../../../components/Loading/FullPageLoading";
import CustomModal from "../../../components/Custom/Modal/Modal";
import { Table } from "../../../components/Tables/Table";
import { setTableSelectedRows } from "../../../redux/slices/teamsSlice";
import {
  getAttributeSchema,
  handleCohortsData,
  handleDeleteCohortSlice,
  setMultipleSelected,
  setTablePage,
  toggleSideMenu,
} from "../../../redux/slices/cohortsSlice";

const columns = [
  {
    key: "name",
    // numeric: false,
    // disablePadding: true,
    name: "Title",
    // minWidth: 200,
    padding: "0 0 0 22px",
  },
  {
    key: "description",
    // numeric: false,
    // disablePadding: true,
    name: "Description",
    minWidth: 200,
  },
  {
    key: "users",
    // numeric: true,
    align: "center",
    name: "Users",
    // minWidth: 150,
  },
];

function Cohorts() {
  // ------------- STATES FOR FILTERATION COMPONENT START -------------
  const [inputValue, setInputValue] = useState("");
  const [filters, setFilters] = useState({});
  // ------------- STATES FOR FILTERATION COMPONENT END -------------

  const { page, selected, cohortsData } = useSelector(
    (state) => state.cohortsReducer.tableData,
  );
  const { isSideMenuOpen } = useSelector(
    (state) => state.cohortsReducer.sideMenu,
  );
  const [isCohortsData, setIsCohortsData] = useState(false);
  const [currentrow, setCurrentrow] = useState({});
  const [isModal, setIsModal] = useState(false);
  const dispatch = useDispatch();

  const getCohorts = async () => {
    try {
      const request = await secure_instance.request({
        url: "/v1/cohorts/",
        method: "GET",
      });
      dispatch(handleCohortsData(request.data));
      setIsCohortsData(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  const handleTableRowClick = (row) => {
    dispatch(toggleSideMenu());
    setCurrentrow(row);
  };

  const handleToolbarAction = () => {
    setIsModal(!isModal);
  };

  const handleModalClose = () => {
    setIsModal(!isModal);
  };

  const handleModalConfirmation = () => {
    setIsModal(!isModal);
    handleDeleteCohort();
  };

  const handleDeleteCohort = async () => {
    let cohortsToDelete = [];
    if (selected.length > 0) {
      cohortsToDelete = selected.map((cohorts) => cohorts.id);
    }
    if (Object.keys(currentrow).length > 0) {
      cohortsToDelete = [currentrow.id];
    }

    const filteredCohorts = cohortsData.filter(
      (cohorts) => !cohortsToDelete.includes(cohorts.id),
    );

    dispatch(handleDeleteCohortSlice(cohortsToDelete));
    if (isSideMenuOpen) {
      dispatch(toggleSideMenu());
    }
    dispatch(handleCohortsData(filteredCohorts));
    dispatch(setTableSelectedRows([]));
  };

  const setTablePageSetter = (data) => {
    dispatch(setTablePage(data));
  };

  const setMultipleSelectedSetter = (boolean) => {
    dispatch(setMultipleSelected(boolean));
  };

  const setSelected = (data) => {
    dispatch(setTableSelectedRows(data));
  };

  useEffect(() => {
    getCohorts();
    dispatch(getAttributeSchema());
  }, []);

  return (
    <div>
      <CustomModal
        style={{ width: "500px", height: "209px" }}
        open={isModal}
        setIsModal={setIsModal}
        handleModalClose={handleModalClose}
        handleModalConfirmation={handleModalConfirmation}
        cohorts
      />

      <CohortSideMenu
        isSideMenuOpen={isSideMenuOpen}
        currentrow={currentrow}
        handleSideMenu={handleSideMenu}
        setCurrentrow={setCurrentrow}
        handleToolbarAction={handleToolbarAction}
        handleDeleteCohort={handleDeleteCohort}
        selectedCohorts={selected}
      />
      {isSideMenuOpen && <BackgroundBlur closeMenu={handleSideMenu} />}

      <div
        className="flex-column d-flex align-items-center"
        style={{
          padding: cohortsData.length === 0 && "0",
          // height:
          //   !isCohortsData && cohortsData.length === 0
          //     ? "70vh"
          //     : "calc(100vh - 122px)",
        }}
      >
        {!isCohortsData && cohortsData.length === 0 ? (
          <div className="w-100">
            <FullPageLoading />
          </div>
        ) : cohortsData.length > 0 ? (
          <>
            {/* TABLE NEEDS TO BE FINALIZED, WHEN AVATAR IS ADDED INSIDE TITLE ( PADDDING ) */}
            <Table
              data={cohortsData}
              headerBackgroundColor="#F7FBFF"
              inputValue={inputValue}
              columns={columns}
              filters={filters}
              page={page}
              setMultipleSelected={setMultipleSelectedSetter}
              setSelected={setSelected}
              handleSideMenu={handleSideMenu}
              handleTableRowClick={handleTableRowClick}
              // isMultipleRowsSelected={isMultipleRowsSelected}
              setCurrentrow={setCurrentrow}
              handleToolbarAction={handleToolbarAction}
              toolbarText={["Delete Selected Cohorts", "Delete All Cohorts"]}
              setTablePage={setTablePageSetter}
              selected={selected}
              checkboxRequired
              checkboxSize="24px"
              // displayAvatar
            />
          </>
        ) : (
          <div>
            <div
              style={{
                // border: "1px solid #E9ECEF",
                // boxShadow: "0px 6px 16px rgba(163, 163, 163, 0.12)",
                background: "#FFFFFF",
                width: "1176px",
                maxWidth: "65vw",
                maxHeight: "690px",
                height: "auto",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* use different image size for mobile */}
                <div style={{ padding: "80px 0 48px 0" }}>
                  <CohortIllustration />
                </div>
                <div className="head5 grey9"> You’ve no Cohorts present! </div>
                <div
                  className="s1 grey7"
                  style={{ maxWidth: "668px", padding: "16px 0 48px 0" }}
                >
                  Set up your internal data to create customised cohorts. Once
                  created, you can organise data accordingly and see how it
                  works
                </div>

                <Button
                  buttonFilledFull
                  typography={"head6 grey1"}
                  title="Create Cohort"
                  onClick={() => handleSideMenu()}
                  style={{ maxWidth: "560px", marginBottom: "40px" }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Cohorts;
