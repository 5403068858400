import React, { useRef, useState } from "react";
import PopperDropdown from "../../DropDowns/PopperDropdown";
import Field from "../../sub-components/Field";
import { DateRangePicker } from "../../DatePickers/DateRangePicker";
import dayjs from "dayjs";
import Button from "../../sub-components/Button";
import { ReactComponent as CalendarBlank } from "../../../assets/icons/CalendarBlank.svg";
import { ReactComponent as MaskGroup } from "../../../assets/icons/MaskGroup.svg";
import LinearProgressLoader from "../../sub-components/LinearProgress";

const SidebarObjectives = () => {
  const [objectiveTypeSelected, setObjectiveTypeSelected] =
    useState("All Objectives");
  const cumulativeGraphAnchorRef = useRef(null);
  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);
  const [filterInputValue, setFilterInputValue] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [isCurrentSurvey, setIsCurrentSurvey] = useState({});

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };
  const handleOptionSelected = (e) => {
    console.log(e.target.id);
    setObjectiveTypeSelected(e.target.id);
    setCumulativeGraphDropDownOpen(false);
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  return (
    <>
      {Object.keys(isCurrentSurvey).length === 0 &&
      isCurrentSurvey.constructor === Object ? (
        <>
          <div
            className="row"
            style={{
              background: "#F4E6FFCC",
              padding: "20px 24px",
              marginTop: "32px",
            }}
          >
            <div className="d-flex w-100 justify-content-between">
              <span className="head6-medium">John Doe’s Objectives</span>
              {/* <span>New Survey</span> */}
              <Button
                buttonAddtoTeam
                title="New Survey"
                // onClick={() => handleCreateTeamButton()}
                style={{
                  boxShadow: "none",
                  // width: "100%",
                  padding: "0 30px",
                  cursor: "pointer",
                }}
              />
            </div>

            <div
              className="w-100 row justify-content-between"
              style={{
                padding: "0 8px",
                marginTop: "32px",
                paddingBottom: "28px",
              }}
            >
              <div
                className="col-lg-3"
                style={{
                  borderRight: "4px solid #9300FF",
                  // margin: "0 48px",
                  height: "100%",
                  width: "4px",
                }}
              >
                <span className="d-flex head1-medium mb-3">7</span>
                <span className="d-flex body1-medium grey6">Active Goals</span>
              </div>

              <div
                className="col-lg-3"
                style={{
                  borderRight: "4px solid #9300FF",
                  // margin: "0 48px",
                  height: "100%",
                  width: "4px",
                }}
              >
                <div className="ml-5">
                  <span className="d-flex head1-medium mb-3">20%</span>
                  <span className="d-flex body1-medium grey6">Progress</span>
                </div>
              </div>
              <div
                className="col-lg-3"
                style={{
                  borderRight: "4px solid #9300FF",
                  // margin: "0 48px",
                  height: "100%",
                  width: "4px",
                }}
              >
                <div className="ml-5">
                  <div className="d-flex head1-medium mb-3 ">2</div>
                  <span className="d-flex body1-medium grey6">Completed</span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="ml-5">
                  <span className="d-flex head1-medium mb-3">1</span>
                  <span className="d-flex body1-medium grey6">
                    Due in 30 days
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row d-flex justify-content-between"
            style={{ marginTop: "32px" }}
          >
            <div className="d-flex align-items-center flex-row">
              <PopperDropdown
                anchorRef={cumulativeGraphAnchorRef}
                isDropdownOpen={isCumulativeGraphDropDownOpen}
                handleToggle={() =>
                  handleGenericMenuOpen(setCumulativeGraphDropDownOpen)
                }
                handleClose={() =>
                  handleGenericMenuClose(setCumulativeGraphDropDownOpen)
                }
                dropdownDisplayText={objectiveTypeSelected}
                menuItems={[
                  "All Objectives",
                  "Performance Objectives",
                  "Wellness Objectives",
                ]}
                handleOptionSelected={handleOptionSelected}
                activeMenuItem={objectiveTypeSelected}
              />
              <div style={{ marginLeft: "16px" }} />
              <Field
                inputFieldFullSearchIcon
                placeholder={`Search`}
                value={filterInputValue}
                onChange={(e) => setFilterInputValue(e.target.value)}
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "52px",
                  borderRight: "1px solid #e9ecef",
                  borderRadius: "10px",
                  background: "white",
                }}
              />
            </div>

            <div
              className="d-flex align-items-center"
              style={{
                height: "40px",
                background: "#FFF",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                border: "1px solid #E9ECEF",
                borderRadius: "10px",
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              <DateRangePicker
                value={{ from: startDate, to: endDate }}
                fetchDatesData={fetchDatesData}
                setIsDateMenuOpen={setIsDateMenuOpen}
                isDateMenuOpen={isDateMenuOpen}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>

          <div className="container-fluid">
            <div style={{ marginTop: "24px" }} className="row p-0">
              {[1, 2, 3, 4].map((_, index) => {
                return (
                  <div
                    className="col-6 mb-4"
                    style={{
                      border: "1px solid #DEDEDE",
                      borderRadius: "10px",
                      padding: "16px 22px",
                    }}
                    key={index}
                    onClick={() => setIsCurrentSurvey(1)}
                  >
                    <div style={{ paddingBottom: "19px", paddingTop: "12px" }}>
                      <span
                        style={{
                          background: "#EBEBEB",
                          color: "#757575",
                          padding: "10px",
                          borderRadius: "6px",
                        }}
                      >
                        Company
                      </span>
                    </div>
                    <div className="head6-medium">
                      Hire And Retain The Best Employee
                    </div>
                    <span className="caption2">
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id es. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id es. As a company we need
                      to structure and make moves to capitalize on...........
                    </span>

                    <div style={{ padding: "24px 0" }}>
                      <LinearProgressLoader variant="determinate" value={40} />
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <span className="d-flex align-items-center">
                        <CalendarBlank
                          className="mr-1"
                          stroke={"#56585A"}
                          stroke-width="3"
                          style={{ width: "16px", height: "16px" }}
                        />
                        Q2/Q4
                      </span>
                      <span>Members</span>
                      <span>40% complete</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="col-12 mb-4"
            style={{
              borderRadius: "10px",
              padding: "16px 22px",
              paddingTop: "0",
            }}
          >
            <div style={{ paddingBottom: "19px" }}></div>
            <div className="head6-medium">
              Hire And Retain The Best Employee
            </div>
            <span className="caption2">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id es. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id es. As a company we need to structure and make moves to
              capitalize on...........
            </span>

            <div style={{ padding: "24px 0" }}>
              <LinearProgressLoader variant="determinate" value={40} />
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <span className="d-flex align-items-center">
                <CalendarBlank
                  className="mr-1"
                  stroke={"#56585A"}
                  stroke-width="3"
                  style={{ width: "16px", height: "16px" }}
                />
                Q2/Q4
              </span>
              <span>Members</span>
              <span>40% complete</span>
            </div>

            <div className="d-flex justify-content-between">
              <div>Key Results</div>
              <div>Key Result</div>
            </div>
            <div
              // className="d-flex flex-column align-item-center"
              style={{ background: "#F4E6FF80" }}
            >
              <div
                style={{ padding: "25px 0 20px 0" }}
                className="d-flex justify-content-between align-item-center"
              >
                <div className="d-flex">Improve Collaboration 10%</div>
                <div className="d-flex">
                  <MaskGroup style={{ width: "32px", height: "32px" }} />{" "}
                </div>
                <div
                  className="d-flex justify-content-center align-item-center"
                  style={{ minWidth: "176px", width: "30%" }}
                >
                  <LinearProgressLoader
                    className="d-flex justify-content-center align-item-center"
                    variant="determinate"
                    value={40}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SidebarObjectives;
