import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertUnixTimeStampWithMonthName } from "../../utilities/dateConversion";
import Button from "../sub-components/Button";
import { setIsChangePlanView } from "../../redux/slices/billingSlice";

const PlanDetails = (props) => {
  const { getScreenWidth, handleCancelSubscription } = props;

  const dispatch = useDispatch();

  const { customerInvoices, subscription } = useSelector(
    (state) => state.billingsReducer,
  );

  return (
    <>
      <div className="col-lg-12 col-sm-6">
        <span className="head6 grey8">Plan Details</span>
        <div>
          <div
            style={{
              // height: mediaQuery.width < "992" ? "356px" : "312px",
              marginTop: "11px",
              borderRadius: "10px",
              background: "#f8f8f8",
              padding: "24px",
              minWidth: "220px",
            }}
          >
            <div className="d-flex flex-column">
              <span className="head5 grey8">{subscription?.name}</span>

              <span className="b2 grey6">
                Billed{" "}
                {subscription.interval === "month"
                  ? "Monthly"
                  : subscription.interval === "year" && "Yearly"}
              </span>
            </div>

            <div className="d-flex flex-column" style={{ marginTop: "31px" }}>
              <span className="b1 grey8">
                Unlimited Analytics. Advanced Reporting. Unlimited Tool Access.
              </span>
              <span className="b2 grey6">
                {`Next Payment: ${
                  customerInvoices[0]?.lines.data[0]?.plan.currency === "usd"
                    ? `$${customerInvoices[0]?.lines.data[0]?.plan.amount}
									${customerInvoices[0]?.lines.data[0]?.plan.currency.toUpperCase()}`
                    : customerInvoices[0]?.lines.data[0]?.plan.amount
                } on ${convertUnixTimeStampWithMonthName(
                  subscription.expiresAt,
                )}`}
              </span>
            </div>

            <div
              className="d-flex justify-content-end align-items-center"
              style={{
                flexDirection:
                  getScreenWidth() === "Desktop" ||
                  getScreenWidth() === "Tablet"
                    ? "column"
                    : "row",
                marginTop:
                  getScreenWidth() === "Desktop"
                    ? "3rem"
                    : getScreenWidth() === "Tablet"
                      ? "2rem"
                      : "5rem",
              }}
            >
              <Button
                title="Cancel Subscription"
                typography="b1 grey7"
                buttonHollowLg
                style={{
                  minWidth: "190px",
                  maxWidth: "210px",
                  background: "none",
                  border: "none",
                  marginTop: "5px",
                  marginRight:
                    getScreenWidth() === "DesktopFull" ? "28px" : "0",
                }}
                onClick={handleCancelSubscription}
              />

              <Button
                title="Change Plan"
                typography="s1 grey1"
                buttonFilledFull
                style={{
                  minWidth: "165px",
                  height: "40px",
                  marginTop: "5px",
                  fontWeight: "400",
                  maxWidth:
                    getScreenWidth() === "DesktopFull" ? "135px" : "100%",
                }}
                onClick={() => dispatch(setIsChangePlanView(true))}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanDetails;
