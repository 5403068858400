import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "./authSlice";

const initialState = {
  objectivesData: [],
  currentRowToEdit: {},
  loading: false,
  sideMenu: {
    isSideMenuOpen: false,
    currentProgressDisabledStates: false,
    loading: false,
    tableData: {
      loading: false,
      selected: [],
    },
    objectiveForm: {
      objective: "",
      description: "",
      owner: "",
      objectiveFor: "",
      updateFrequency: "",
      timeFrame: "",
      start_date: null,
      end_date: null,
    },
  },
};

export const getObjectiveData = createAsyncThunk(
  "objectives/getObjectiveData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "GET",
        url: "v1/objectives/",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getObjectivesDrawerTableData = createAsyncThunk(
  "objectives/getObjectivesDrawerTableData",
  async (type, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "GET",
        url: `v1/${type}/`,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createNewObjective = createAsyncThunk(
  "objectives/createNewObjective",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "POST",
        url: "v1/objectives/",
        data,
      });

      dispatch(getObjectiveData());

      // Success alert for creating a new objective
      dispatch(
        showSnackbar({
          message: "Objective created successfully",
          severity: "success",
        }),
      );

      return response.data;
    } catch (error) {
      // Failure alert
      dispatch(
        showSnackbar({
          message: "Failed to create objective",
          severity: "error",
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const editObjective = createAsyncThunk(
  "objectives/editObjective",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "PATCH",
        url: `v1/objectives/${data.id}`,
        data,
      });
      dispatch(getObjectiveData());
      dispatch(
        showSnackbar({
          message: "Objective edited successfully",
          severity: "success",
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        showSnackbar({
          message: "Failed to edit objective",
          severity: "error",
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteObjetive = createAsyncThunk(
  "objectives/deleteObjetive",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "DELETE",
        url: `v1/objectives/${id}`,
      });

      dispatch(getObjectiveData());

      // Success alert for deleting an objective
      dispatch(
        showSnackbar({
          message: "Objective deleted successfully",
          severity: "success",
        }),
      );

      return response.data;
    } catch (error) {
      // Failure alert
      dispatch(
        showSnackbar({
          message: "Failed to delete objective",
          severity: "error",
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

const objectivesSlice = createSlice({
  name: "objectives",
  initialState,
  reducers: {
    toggleSideMenu(state) {
      state.sideMenu.isSideMenuOpen = !state.sideMenu.isSideMenuOpen;
    },
    handleCurrentRowToEdit(state, action) {
      state.currentRowToEdit = action.payload;
    },
    handleCurrentProgress(state, action) {
      state.currentRowToEdit = action.payload;
      state.sideMenu.currentProgressDisabledStates = true;
    },
    falseCurrentProgressDisabledStates(state) {
      state.sideMenu.currentProgressDisabledStates = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewObjective.pending, (state) => {
      state.sideMenu.loading = true;
    });
    builder.addCase(createNewObjective.fulfilled, (state) => {
      state.sideMenu.loading = false;
      state.sideMenu.isSideMenuOpen = false;
    });
    builder.addCase(createNewObjective.rejected, (state) => {
      state.sideMenu.loading = false;
    });
    builder.addCase(editObjective.pending, (state) => {
      state.sideMenu.loading = true;
    });
    builder.addCase(editObjective.fulfilled, (state) => {
      state.sideMenu.loading = false;
      state.sideMenu.isSideMenuOpen = false;
    });
    builder.addCase(editObjective.rejected, (state) => {
      state.sideMenu.loading = false;
    });
    builder.addCase(getObjectiveData.pending, (state) => {
      state.sideMenu.tableData.loading = true;
    });
    builder.addCase(getObjectiveData.fulfilled, (state, action) => {
      state.sideMenu.tableData.loading = false;
      state.objectivesData = action.payload;
    });
    builder.addCase(getObjectiveData.rejected, (state) => {
      state.sideMenu.tableData.loading = false;
    });
    builder.addCase(deleteObjetive.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteObjetive.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const {
  toggleSideMenu,
  handleCurrentRowToEdit,
  handleCurrentProgress,
  falseCurrentProgressDisabledStates,
} = objectivesSlice.actions;

export default objectivesSlice.reducer;
