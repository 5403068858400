let baseURL = "";

if (window && window._env_ && window._env_.REACT_APP_GW_URL) {
  baseURL = window._env_.REACT_APP_GW_URL;
} else {
  baseURL = "http://localhost:8080";
  // baseURL = "https://pr267-api.crewnetics.net";
  // baseURL = "https://staging-api.crewnetics.net";
}

export default baseURL;
