import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../../components/Tables/Table";
import { setSideMenuTablePage } from "../../../../redux/slices/departmentSlice";
import { compareIds } from "../../../../utilities/Utils";
import Field from "../../../../components/sub-components/Field";
import CustomSelect from "../../../../components/sub-components/Select";
import { getSurveysDrawerTableData } from "../../../../redux/slices/surveySlice";

const TeamMembers = ({
  addedTeams,
  setAddedTeams,
  selectedTab,
  isConfirmationModal,
  currentRowToEdit,
  isRowClicked,
  setIsRowClicked,
  isEditableState,
  objectiveType,
  setObjectiveType,
  setAddPeopleToObjectiveSelected,
  tableColumnsCheck,
  addedUsersSelected,
  setAddedUsersSelected,
}) => {
  const { page, sideMenuTableLoading } = useSelector(
    (state) => state.departmentsReducer.sideMenu.tableData,
  );
  const { data: teamsData } = useSelector(
    (state) => state.teamsReducer.tableData,
  );

  const dispatch = useDispatch();

  const [usersData, setUsersData] = useState([]); // users to show inside dropdown
  const [teamsToRemoveSelected, setTeamsToRemoveSelected] = useState([]); // teams which have been added, and selected to remove
  const [searchValue, setSearchValue] = useState("");
  const [usersToDeleteConfirmed, setUsersToDeleteConfirmed] = useState([]);
  const [teamsFromCurrentRow, setTeamsFromCurrentRow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const surveyTypes = {
    1: "departments/",
    2: "teams/",
    3: "user_profile/?type=active",
  };

  const handleSetSideMenuTablePage = (data) => {
    dispatch(setSideMenuTablePage(data));
  };

  const handleToolbarAction = () => {
    const filteredUsersData = teamsData.filter(
      (data) => !addedUsersSelected.includes(data),
    );
    setUsersData(filteredUsersData);

    const revertDeletedTeams = usersToDeleteConfirmed.filter(
      (data) => !addedUsersSelected.includes(data),
    );
    setUsersToDeleteConfirmed(revertDeletedTeams);
    setAddedTeams([...addedTeams, ...addedUsersSelected]);
    setAddedUsersSelected([]);
  };

  const handleRemoveAddedTeams = () => {
    const filteredTeams = addedTeams.filter(
      (user) => !teamsToRemoveSelected.some((team) => compareIds(team, user)),
    );
    setAddedTeams(filteredTeams);
    setTeamsToRemoveSelected([]);
    setUsersToDeleteConfirmed([
      ...usersToDeleteConfirmed,
      ...teamsToRemoveSelected,
    ]);
    setUsersData([...usersData, ...teamsToRemoveSelected]);
  };

  const setSideMenuRowsPerPage = (data) => {
    // dispatch(teamsActions.setSideMenuRowsPerPage(data));
  };

  const newAddedTeamsInDepartment = isRowClicked
    ? addedTeams?.filter((user) => !teamsFromCurrentRow.includes(user))
    : addedTeams;

  const newDeletedTeamsInDepartment = teamsFromCurrentRow.filter((data) =>
    usersToDeleteConfirmed.includes(data),
  );

  const handleObjectiveType = (e) => {
    const selectedItem = e.target;
    setObjectiveType(selectedItem);
    setAddPeopleToObjectiveSelected([]);
  };

  useEffect(async () => {
    if (Object.keys(currentRowToEdit).length > 0) {
      const idsOfPreAddedTeams = currentRowToEdit.teams.map((team) => team.id);
      const preAddedTeams = teamsData.filter((team) =>
        idsOfPreAddedTeams.includes(team.id),
      );
      setTeamsFromCurrentRow(preAddedTeams);
    } else {
      setIsRowClicked(false);
    }
  }, [currentRowToEdit]);

  useEffect(() => {
    if (objectiveType && objectiveType?.value) {
      const fetchData = async () => {
        try {
          setIsTableLoading(true);
          const result = await dispatch(
            getSurveysDrawerTableData(surveyTypes[objectiveType?.value]),
          ).unwrap();
          setTableData(result);
          setIsTableLoading(false);
        } catch (err) {
          setIsTableLoading(false);
          console.log("error ", err);
        }
      };

      fetchData();
    }
  }, [objectiveType]);

  return (
    <>
      {selectedTab.id === 1 && !isConfirmationModal && (
        <>
          <div
            className="head6-medium grey9"
            style={{ paddingTop: "48px", paddingBottom: "20px" }}
          >
            Assign Survey
          </div>
          <div
            style={{
              marginBottom: "20px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <CustomSelect
              items={[
                {
                  value: 1,
                  label: "Departments",
                },
                {
                  value: 2,
                  label: "Teams",
                },
                {
                  value: 3,
                  label: "Users",
                },
              ]}
              value={objectiveType?.value}
              onChange={handleObjectiveType}
              menuMaxHeight={"400px"}
            />
          </div>

          <Paper
            sx={{
              width: "100%",
              minWidth: "260px",
              position: "relative",
              boxShadow: "none",
            }}
          >
            <Table
              backgroundColor={
                isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF"
              }
              headerBackgroundColor={
                isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF"
              }
              data={tableData.filter(
                (data) => !addedTeams.some((team) => compareIds(team, data)),
              )}
              columns={tableColumnsCheck(
                surveyTypes[objectiveType?.value],
                tableData,
              )}
              loading={isTableLoading}
              page={page}
              inputValue={searchValue}
              selected={addedUsersSelected}
              setSelected={setAddedUsersSelected}
              checkboxSize="24px"
              checkboxRequired={tableData.length > 0}
              emptyStateText="Teams not found"
              toolbarText={["Add Selected Teams", "Add All Teams"]}
              displayAvatar
              setTablePage={handleSetSideMenuTablePage}
              setRowsPerPage={setSideMenuRowsPerPage}
              handleToolbarAction={handleToolbarAction}
            />
          </Paper>
        </>
      )}

      {selectedTab.id === 2 && !isConfirmationModal && (
        <div
          className={isRowClicked && !isEditableState ? "disableEvents" : ""}
        >
          <div className="head6 grey9" style={{ margin: "24px 0" }}>
            Remove Assignee(s)
          </div>
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid #E9ECEF",
              borderRadius: "10px",
              background: "#FFFFFF",
            }}
          >
            <Field
              inputFieldFullExpand
              placeholder="Search"
              detectedUserIcon
              style={{
                background:
                  isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF",
                height: "48px",
                borderRadius: "10px",
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              inputStyle={{
                color: "#6C757D",
                border: "none",
                height: "48px",
                background:
                  isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF",
              }}
            />
          </div>
          <Table
            backgroundColor={
              isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF"
            }
            headerBackgroundColor={
              isRowClicked && !isEditableState ? "#F8F9FA" : "#FFFFFF"
            }
            data={addedTeams.length > 0 ? addedTeams : []}
            inputValue={searchValue}
            loading={sideMenuTableLoading}
            columns={tableColumnsCheck(
              surveyTypes[objectiveType?.value],
              tableData,
            )}
            selected={teamsToRemoveSelected}
            page={page}
            setSelected={setTeamsToRemoveSelected}
            setTablePage={handleSetSideMenuTablePage}
            toolbarText={["Remove Selected Teams", "Remove All Teams"]}
            handleToolbarAction={handleRemoveAddedTeams}
            checkboxSize="24px"
            emptyStateText="Team not found"
            checkboxRequired={true}
            displayAvatar={true}
          />
        </div>
      )}

      {(newAddedTeamsInDepartment.length > 0 ||
        newDeletedTeamsInDepartment.length > 0) &&
        isConfirmationModal && (
          <div
            style={{
              borderBottom: "2px solid #DEE2E6",
              width: "100%",
              marginBottom: "24px",
              marginTop: "24px",
            }}
          />
        )}
      <>
        {newAddedTeamsInDepartment.length > 0 && isConfirmationModal && (
          <>
            <div className="head6 grey8" style={{ marginBottom: "20px" }}>
              Added Teams
            </div>
            <Paper
              sx={{
                width: "100%",
                minWidth: "260px",
                position: "relative",
                boxShadow: "none",
              }}
            >
              <>
                <Table
                  backgroundColor={"#F8F9FA"}
                  headerBackgroundColor={"#F8F9FA"}
                  customRowPerPage={newAddedTeamsInDepartment.length}
                  hidePagination={true}
                  data={newAddedTeamsInDepartment}
                  columns={tableColumnsCheck(
                    surveyTypes[objectiveType?.value],
                    tableData,
                  )} //tableColumnsCheck(objectiveType, tableData)
                  page={page}
                  displayAvatar={true}
                />
              </>
            </Paper>
          </>
        )}

        {newDeletedTeamsInDepartment.length > 0 && isConfirmationModal && (
          <>
            <div className="head6 grey8" style={{ marginBottom: "20px" }}>
              Deleted Users
            </div>
            <Paper
              sx={{
                width: "100%",
                minWidth: "260px",
                position: "relative",
                boxShadow: "none",
              }}
            >
              <>
                <Table
                  backgroundColor={"#F8F9FA"}
                  headerBackgroundColor={"#F8F9FA"}
                  customRowPerPage={newDeletedTeamsInDepartment.length}
                  hidePagination
                  data={newDeletedTeamsInDepartment}
                  columns={tableColumnsCheck(
                    surveyTypes[objectiveType?.value],
                    tableData,
                  )}
                  page={page}
                  displayAvatar
                />
              </>
            </Paper>
          </>
        )}
      </>
    </>
  );
};

export default TeamMembers;
