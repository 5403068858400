export const surveyQuestionDummy = [
  {
    category: "Performance Surveys",
    surveys: [
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "A",
        pecentage: "100%",
      },
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "B",
        pecentage: "70%",
      },
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "C",
        pecentage: "40%",
      },
    ],
    response: [
      {
        name: "Savannah Nguyen",
        Response: 1,
        JobTitle: "Senior Engineer",
        ResponseSubmitted: "5/27/15",
      },
    ],
  },
  {
    category: "Wellness Surveys",
    surveys: [
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "A",
        pecentage: "100%",
      },
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "B",
        pecentage: "70%",
      },
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "C",
        pecentage: "40%",
      },
    ],
    response: [
      {
        name: "Savannah Nguyen",
        Response: 1,
        JobTitle: "Senior Engineer",
        ResponseSubmitted: "5/27/15",
      },
    ],
  },
];

export const mySurveyDummy = [
  {
    category: "My Surveys",
    surveys: [
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "A",
        pecentage: "100%",
      },
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "B",
        pecentage: "70%",
      },
      {
        surveyQuestion: "Are you satisfied with HR?",
        AskedBy: "Manager",
        CreatedAt: "12/05/2021",
        grade: "C",
        pecentage: "40%",
      },
    ],
    response: [
      {
        name: "Savannah Nguyen",
        Response: 1,
        JobTitle: "Senior Engineer",
        ResponseSubmitted: "5/27/15",
      },
    ],
  },
];

export const mySurveyResponseTable = [
  {
    full_name: "Ronald Richards",
    response: "😍",
    job_title: "Full Stack engineer",
    response_submitted: "2022-12-01T00:00:00.000Z",
  },
  {
    full_name: "Wade Warren",
    response: "😊",
    job_title: "Front end Engineer",
    response_submitted: "2023-10-01T00:00:00.000Z",
  },
  {
    full_name: "Albert Flores",
    response: "🤔",
    job_title: "UX Designer",
    response_submitted: "2023-12-01T00:00:00.000Z",
  },
  {
    full_name: "Dianne Russell",
    response: "😕",
    job_title: "Quality Assurance",
    response_submitted: "2023-12-01T00:00:00.000Z",
  },
  {
    full_name: "Bessie Cooper",
    response: "😡",
    job_title: "Product manager",
    response_submitted: "2024-02-02T00:00:00.000Z",
  },
];
