import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { showSnackbar } from "./authSlice";

const initialState = {
  currentTab: "1",
  usersMovedToActiveSuccess: false,
  usersMovedToArchiveSuccess: false,
  activeUsers: [],
  archiveUsers: [],
  managerData: [],
  loading: false,
  isSideMenu: false,
  tableData: {
    data: [],
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
    tableLoading: false,
  },
  sideMenuTableData: {
    data: [],
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    toggleTableLoading(state) {
      state.tableData.tableLoading = !state.tableData.tableLoading;
    },
    setManagerData(state, action) {
      state.managerData = action.payload;
    },
    setUsersCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    getUsersPlatformData(state, action) {
      state.sideMenuTableData.data = action.payload;
    },
    updateDetectedUsers(state, action) {
      state.tableData.data = state.tableData.data.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    setGroupsData(state, action) {
      state.tableData.data = action.payload;
    },
    setRowsData(state, action) {
      state.tableData.rowsData = action.payload;
    },
    setUsersTablePage(state, action) {
      state.tableData.page = action.payload;
    },
    setSidemenuTablePage(state, action) {
      state.sideMenuTableData.page = action.payload;
    },
    setTableSelectedRows(state, action) {
      state.tableData.selected = action.payload;
    },
    removeFromActiveUsers(state, action) {
      state.tableData.data = action.payload;
    },
    removeFromArchiveUsers(state, action) {
      state.tableData.data = action.payload;
    },
    // usersMovedToActiveSuccess(state) {
    //   state.usersMovedToActiveSuccess = !state.usersMovedToActiveSuccess;
    // },
    // usersMovedToArchiveSuccess(state) {
    //   state.usersMovedToArchiveSuccess = !state.usersMovedToArchiveSuccess;
    // },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    toggleSideMenu(state) {
      state.isSideMenu = !state.isSideMenu;
    },
    closeSideMenu(state) {
      state.isSideMenu = false;
    },
    usersMovedToActiveSuccessAlert(state, action) {
      state.usersMovedToActiveSuccess = action.payload;
    },
    usersMovedToArchiveSuccessAlert(state, action) {
      state.usersMovedToArchiveSuccess = action.payload;
    },
    setActiveUsers(state, action) {
      state.activeUsers = action.payload;
    },
    handleRemoveDetectedUser(state, action) {
      state.tableData.data = state.tableData.data.filter(
        (user) => user.id !== action.payload,
      );
    },
    undoRemovedFromArchiveUsers(state, action) {
      state.tableData.data = [...state.tableData.data, ...action.payload];
    },
  },
});

export const getUsersList = () => {
  return async (dispatch) => {
    try {
      dispatch(usersSlice.actions.toggleTableLoading());
      const request = await secure_instance.request({
        url: `v1/user_profile/?type=active`,
        method: "GET",
      });

      const newData = await Promise.all(
        request.data.map(async (obj) => {
          obj["full_name"] = `${obj.first_name} ${obj.last_name}`;
          obj["primary_email"] = obj.identifiers?.map(
            (identifier) => identifier.is_primary && identifier.email,
          );
          obj["manager_name"] = await getManagerName(obj.manager_id);
          return obj;
        }),
      );
      dispatch(usersSlice.actions.setManagerData(newData));
      dispatch(usersSlice.actions.setGroupsData(newData));
      dispatch(usersSlice.actions.setActiveUsers(newData));
      dispatch(usersSlice.actions.toggleTableLoading());
    } catch (e) {
      console.log(e);
    }
  };
};

// Manager Data
const getManagerName = async (id) => {
  try {
    if (id == null) {
      return "";
    }
    const request1 = await secure_instance.request({
      url: `/v1/user_profile/${id}`,
      method: "GET",
    });

    const managerName = `${request1.data.first_name} ${request1.data.last_name}`;
    return managerName;
  } catch (e) {
    throw new Error("Failed to get UserProfile", e);
    // console.log("error", e);
  }
};

export const handleArchiveUsersSlice = (
  userIds,
  filteredActiveUsers,
  isMultipleRowsSelected,
) => {
  return async (dispatch) => {
    try {
      if (!isMultipleRowsSelected) {
        dispatch(usersSlice.actions.toggleLoading());
      }

      await secure_instance.request({
        url: "v1/user_profile/archive/users",
        method: "Post",
        data: userIds,
      });

      dispatch(usersSlice.actions.removeFromActiveUsers(filteredActiveUsers));
      // dispatch(usersSlice.actions.usersMovedToArchiveSuccessAlert());
      dispatch(
        showSnackbar({
          message: "User archived successfully!",
          severity: "success",
        }),
      );
      if (!isMultipleRowsSelected) {
        dispatch(usersSlice.actions.toggleLoading());
        dispatch(usersSlice.actions.toggleSideMenu());
      }
      // setIntegrationsData(response);
    } catch (e) {
      dispatch(
        showSnackbar({
          message: "Failed to archive user",
          severity: "error",
        }),
      );
      if (!isMultipleRowsSelected) {
        dispatch(usersSlice.actions.toggleLoading());
        dispatch(usersSlice.actions.toggleSideMenu());
      }
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const handleUpdateDetectedUsers = (data) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: "v1/detected_users/",
        method: "PATCH",
        data: data,
      });
      dispatch(usersSlice.actions.updateDetectedUsers(data[1]));
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const getDetectedUsers = (data) => {
  return async (dispatch) => {
    try {
      dispatch(usersSlice.actions.toggleLoading());
      const request = await secure_instance.request({
        url: "v1/detected_users/?type=detected",
        method: "GET",
        data: data,
      });
      dispatch(usersSlice.actions.setGroupsData(request.data));
      dispatch(usersSlice.actions.toggleLoading());
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const getDetectedUsersPlatform = (tool) => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: `v1/user_profile/?identifier=${tool}&search=true`,
        method: "GET",
      });
      dispatch(usersSlice.actions.getUsersPlatformData(request.data));
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

// export const handleCreateIdentifier = (data) => {
//   return async () => {
//     try {
//       await secure_instance.request({
//         url: "v1/identifier/",
//         method: "POST",
//         data: data,
//       });
//     } catch (e) {
//       // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
//       console.log("error", e);
//     }
//   };
// };

export const handleMoveToActiveUsers = (
  userIds,
  filteredArchiveUsers,
  isMultipleRowsSelected,
) => {
  return async (dispatch) => {
    try {
      if (!isMultipleRowsSelected) {
        dispatch(usersSlice.actions.toggleLoading());
      }
      await secure_instance.request({
        url: "v1/user_profile/unarchive/users",
        method: "Post",
        data: userIds,
      });
      dispatch(usersSlice.actions.removeFromArchiveUsers(filteredArchiveUsers));
      // dispatch(usersSlice.actions.usersMovedToActiveSuccessAlert());
      dispatch(
        showSnackbar({
          message: "User successfully moved to Active",
          severity: "success",
        }),
      );
      if (!isMultipleRowsSelected) {
        dispatch(usersSlice.actions.toggleLoading());
        dispatch(usersSlice.actions.toggleSideMenu());
      }
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      dispatch(
        showSnackbar({
          message: "Failed to move user to Active",
          severity: "error",
        }),
      );
      console.log("error", e);
    }
  };
};

export const handleUndoArchiveUsersSlice = (userIds, filteredArchiveUsers) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: "v1/user_profile/archive/users",
        method: "Post",
        data: userIds,
      });
      dispatch(
        usersSlice.actions.undoRemovedFromArchiveUsers(filteredArchiveUsers),
      );
      // dispatch(usersSlice.actions.usersMovedToActiveSuccessAlert());
      dispatch(
        showSnackbar({
          message: "User successfully moved to Active",
          severity: "success",
        }),
      );
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      dispatch(
        showSnackbar({
          message: "Failed to move user to Active",
          severity: "error",
        }),
      );
      console.log("error", e);
    }
  };
};

export const {
  toggleTableLoading,
  setManagerData,
  setUsersCurrentTab,
  getUsersPlatformData,
  updateDetectedUsers,
  setGroupsData,
  setRowsData,
  setUsersTablePage,
  setSidemenuTablePage,
  setTableSelectedRows,
  removeFromActiveUsers,
  removeFromArchiveUsers,
  // usersMovedToActiveSuccess,
  // usersMovedToArchiveSuccess,
  toggleLoading,
  toggleSideMenu,
  closeSideMenu,
  usersMovedToActiveSuccessAlert,
  usersMovedToArchiveSuccessAlert,
  setActiveUsers,
  handleRemoveDetectedUser,
  undoRemovedFromArchiveUsers,
} = usersSlice.actions;

export default usersSlice.reducer;
