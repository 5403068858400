/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import plus from "../../assets/icons/Plus.svg";
import tick from "../../assets/icons/Tick.svg";
import dots from "../../assets/icons/DotsThree.svg";
import FilterIcon from "../../assets/icons/filter_list.svg";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";

export default function Button(props) {
  const [showAction, setShowAction] = useState(false);

  () => {
    if (showAction === true) {
      setShowAction(!showAction);
      return (
        <div className="action-button">
          <span>Manage Preference</span>
          <span>Dissconnect</span>
        </div>
      );
    }
  };

  if (props.buttonFilledSm) {
    return (
      <div
        className="button-filled-sm"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledMd) {
    return (
      <div
        className="button-filled-md"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonPlanFilledMd) {
    return (
      <div
        className="button-plan-filled-md"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span className="s1 grey0">{props.title || "Button"}</span>
        <img src={tick} style={{ marginLeft: "13px" }} />
      </div>
    );
  } else if (props.buttonAddtoTeam) {
    return (
      <div
        className="button-plan-filled-md"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span className="b0-medium grey0">{props.title || "Button"}</span>
        <Plus
          stroke="#FFFFFF"
          onClick={(e) => {
            // handleTeamsPopup(row, e);
          }}
          className="ml-2"
          style={{ cursor: "pointer", width: "24px", height: "24px" }}
        />
      </div>
    );
  } else if (props.buttonPlanFilledMdIcon) {
    return (
      <div
        className="button-plan-filled-md"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        {/* <Plus stroke="black" width="black"/> */}
        <Plus
          fill="#F8F9FA"
          stroke="#F8F9FA"
          style={{ width: "20px", height: "20px", marginRight: "10px" }}
        />
        <span className="b0-medium grey0">{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledLg) {
    return (
      <button
        className={`button-filled-lg ${props.typography}`}
        style={{ ...props.style }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <span>{props.title || "Button"}</span>
      </button>
    );
  } else if (props.buttonFilledFull) {
    return (
      <button
        className={
          props.className
            ? `${props.className} button-filled-full`
            : "button-filled-full"
        }
        style={{ ...props.style, pointerEvents: props.loading && "none" }}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        {!props.loading && (
          <span
            className={
              props.loading ? "loading-opacity" : `${props.typography}`
            }
            style={{ color: "white", pointerEvents: props.loading && "none" }}
          >
            {props.title || "Button"}
          </span>
        )}
        {props.loading && (
          <div className={props.loading ? "dots-5" : "dots-5"} />
        )}
      </button>
    );
  } else if (props.buttonHollowSm) {
    return (
      <div
        className={`button-hollow-sm ${props.typography}`}
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonHollowSmFilter) {
    return (
      <div
        className="button-hollow-sm"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <img src={FilterIcon} />
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonHollowMd) {
    return (
      <div
        className={`button-hollow-md ${props.typography}`}
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonHollowLg) {
    return (
      <div
        className={
          props.className
            ? `${props.className} button-hollow-lg`
            : "button-hollow-lg"
        }
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span className={props.typography}>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledIconSm) {
    return (
      <div
        className="button-filled-icon-sm"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span style={{ color: "white" }}>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledIconMd) {
    return (
      <div
        className="button-filled-icon-md"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span style={{ color: "white" }}>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledIconLg) {
    return (
      <div
        className="button-filled-icon-lg"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span style={{ color: "white" }}>{props.title || "Button"}</span>
        <img src={plus} style={{ marginLeft: "5px", width: "20%" }} />
      </div>
    );
  } else if (props.buttonHollowIconSm) {
    return (
      <div
        className="button-hollow-icon-sm"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonHollowIconMd) {
    return (
      <div
        className="button-hollow-icon-md"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonHollowIconLg) {
    return (
      <div
        className="button-hollow-icon-lg"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.actionButton) {
    return (
      <div style={{ position: "relative" }}>
        <img
          src={dots}
          style={{ cursor: "pointer" }}
          onClick={setShowAction(!showAction)}
        />
      </div>
    );
  } else if (props.filterListIconButton) {
    return (
      <div
        className={`button-hollow-sm ${props.className}`}
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.icon}</span>
        <span>{props.title || ""}</span>
        {props.count > 0 && (
          <div
            className="ml-2 d-flex justify-content-center align-items-center"
            style={{
              background: "#7B2CBF",
              width: "23px",
              height: "23px",
              borderRadius: "50%",
              position: "absolute",
              right: "-9px",
              top: "-9px",
            }}
          >
            <span className="b2" style={{ color: "white", fontSize: "14px" }}>
              {props.count}
            </span>
          </div>
        )}
        {/* <span className="s1 ml-1">{props.title || "Button"}</span> */}
      </div>
    );
  } else if (props.buttonHollowDestination) {
    return (
      <div
        className="integration-destination-btn"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledIntegrate) {
    return (
      <div
        className="button-integrate-filled"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonHollowIntegrate) {
    return (
      <div
        className="button-integrate-hollow"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  } else if (props.buttonFilledFullFilter) {
    return (
      <button
        className="button-filled-full head6 grey1"
        style={{ ...props.style, pointerEvents: props.loading && "none" }}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <span
          className={props.loading ? "loading-opacity" : ""}
          style={{ color: "white", pointerEvents: props.loading && "none" }}
        >
          {props.title || "Button"}
        </span>
        <div className="d-flex align-items-center">
          <div
            className="ml-2 d-flex justify-content-center"
            style={{
              background: "#FFFFFF",
              width: "19px",
              height: "19px",
              borderRadius: "50%",
            }}
          >
            <span className="b2 primary-color">{props.count}</span>
          </div>

          <div className="ml-2">
            {/* <Close fill="white" stroke-width="3" stroke="white" style={{width:"14px",height:"14px"}} 
							onClick={(e) => props.handleRemoveIndividualFilter(e,props.title)}
						/> */}
            {/* OLD CHANGE */}
            <Close
              fill="white"
              stroke="white"
              style={{ width: "14px", height: "14px" }}
              onClick={(e) =>
                props.handleRemoveIndividualFilter(e, props.clickToRemove)
              }
            />
          </div>
        </div>
      </button>
    );
  } else if (props.buttonHollowLgFilter) {
    return (
      <div
        className="button-hollow-lg b1 primary-color"
        style={{ ...props.style }}
        onClick={props.onClick}
      >
        <span>{props.title || "Button"}</span>
      </div>
    );
  }
}
