import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { secure_instance } from "../../../axios/axios-config";
import CustomModal from "../../../components/Custom/Modal/Modal";
import EditUser from "../../../components/SideMenus/EditUser";
import BackgroundBlur from "../../../components/sub-components/Blur";
import { Table } from "../../../components/Tables/Table";
import {
  handleMoveToActiveUsers,
  handleUndoArchiveUsersSlice,
  setGroupsData,
  setTableSelectedRows,
  setUsersTablePage,
  toggleLoading,
  toggleSideMenu,
  usersMovedToActiveSuccessAlert,
} from "../../../redux/slices/usersSlice";
import { USERS_COLUMNS } from "../../../utilities/Constants";

function ArchiveUsers(props) {
  const { inputValue, filters } = props;
  const { data, page, selected } = useSelector(
    (state) => state.usersReducer.tableData,
  );
  const { isSideMenu } = useSelector((state) => state.usersReducer);
  const { usersMovedToActiveSuccess } = useSelector(
    (state) => state.usersReducer,
  );
  const [usersData, setUsersData] = useState([]);
  const [currentrow, setCurrentrow] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [lastAddedUser, setLastAddedUser] = useState({});
  // const [isSideMenu, setIsSideMenu] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMultipleUsersSelected, setIsMultipleUsersSelected] = useState(false);

  const dispatch = useDispatch();

  const getUsersList = async () => {
    try {
      setLoading(true);
      const request = await secure_instance.request({
        url: `v1/user_profile/?type=archive`,
        method: "GET",
      });

      const newData = request.data.map((obj) => {
        obj["full_name"] = `${obj.first_name} ${obj.last_name}`;
        obj["primary_email"] = obj.identifiers?.map(
          (identifier) => identifier.is_primary && identifier.email,
        );
        return obj;
      });

      setUsersData(newData);
      dispatch(setGroupsData(newData));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCurrentRow = (row) => {
    setCurrentrow(row);
  };

  const handleArchiveSideMenu = () => {
    // dispatch(usersActions.setMultipleSelected(false));
    setIsModal(!isModal);
    dispatch(toggleSideMenu());
  };

  const handleSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  const handleToolbarAction = () => {
    // dispatch(usersActions.setMultipleSelected(true));
    setIsModal(!isModal);
  };

  const handleModalClose = () => {
    setIsModal(!isModal);
  };

  const handleModalConfirmation = () => {
    setIsModal(!isModal);
    handleArchiveUsers();
  };

  const handleArchiveUsers = () => {
    let selectedUsersId = [];
    if (selected.length > 0 && !isSideMenu) {
      setIsMultipleUsersSelected(true);
      setLastSelected(selected);
      selectedUsersId = selected.map((users) => users.user_id);
    }
    // if user is selected from side menu OR multiple users are selected and side menu is open
    if (
      (selected.length === 0 && isSideMenu) ||
      (selected.length > 0 && isSideMenu)
    ) {
      setIsMultipleUsersSelected(false);
      setLastAddedUser(currentrow);
      selectedUsersId = [currentrow.user_id];
      dispatch(toggleLoading());
    }
    const filteredArchiveUsers = data.filter(
      (user) => !selectedUsersId.includes(user.user_id),
    );

    const isMultipleRowsSelected = selected.length > 0 && !isSideMenu;
    dispatch(
      handleMoveToActiveUsers(
        selectedUsersId,
        filteredArchiveUsers,
        isMultipleRowsSelected,
      ),
    );
    dispatch(setUsersTablePage(1));
    dispatch(setTableSelectedRows([]));
  };

  const setSelected = (data) => {
    dispatch(setTableSelectedRows(data));
  };

  const setTablePage = (page) => {
    dispatch(setUsersTablePage(page));
  };

  const handleTableRowClick = (row) => {
    handleCurrentRow(row);
    handleSideMenu();
  };

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    if (usersMovedToActiveSuccess) {
      setTimeout(() => {
        dispatch(usersMovedToActiveSuccessAlert());
        // dispatch(usersActions.setMultipleSelected(false));
      }, 2000);
    }
    // clearTimeout here
  }, [usersMovedToActiveSuccess]);

  return (
    <>
      <CustomModal
        style={{ width: "500px", height: "209px" }}
        open={isModal}
        setIsModal={setIsModal}
        handleModalClose={handleModalClose}
        handleModalConfirmation={handleModalConfirmation}
        users
        archiveUsers
      />

      {isSideMenu && <BackgroundBlur closeMenu={handleSideMenu} />}
      <EditUser
        isSideMenuOpen={isSideMenu}
        currentrow={currentrow}
        handleSideMenu={handleSideMenu}
        handleArchiveSideMenu={handleArchiveSideMenu}
        handleArchiveUsers={handleArchiveUsers}
        archiveUser
      />

      <Table
        data={data}
        headerBackgroundColor="#F7FBFF"
        columns={USERS_COLUMNS}
        page={page}
        loading={loading}
        inputValue={inputValue}
        filters={filters}
        setTablePage={setTablePage}
        selected={selected}
        setSelected={setSelected}
        handleTableRowClick={handleTableRowClick}
        toolbarText={[
          "Move Selected Users To Active",
          "Move All Users To Active",
        ]}
        handleToolbarAction={handleToolbarAction}
        checkboxSize="24px"
        // displayAvatar
        checkboxRequired
      />
    </>
  );
}

export default ArchiveUsers;
