import React from "react";
import Sidebar from "../components/SideBar/Sidebar";

function SidebarLayout(props) {
  return (
    <div className="flex">
      <>
        <div className="d-flex" style={{ height: "100vh", overflow: "hidden" }}>
          <Sidebar />
          <main
            className="d-flex flex-column flex-grow-1"
            style={{
              flex: 1,
              overflow: "hidden",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <div style={{ overflowY: "auto" }}>{props.children}</div>
          </main>
        </div>
      </>
    </div>
  );
}

export default SidebarLayout;
