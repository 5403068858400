import React, { useMemo, useRef, useState } from "react";
import PopperDropdown from "../DropDowns/PopperDropdown";
import LineGraph from "./LineGraph";
import { lineGraphDataDouble } from "../../utilities/dummyGraphs/LineGraphData";
import { secure_instance } from "../../axios/axios-config";
import {
  generateGraphQuery,
  generateQueryForLineGraph,
} from "../../utilities/CubeUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstAndRemoveLastTwoLetters,
  getGraphGranularityMappings,
} from "../../utilities/Utils";
// import LineGraphTimeSeries from "./LineGraphTimeSeries";
import { MyResponsiveLineLoader } from "../../utilities/Loaders";
import FullPageLoading from "../Loading/FullPageLoading";

const CumulativeDashboardGraph = ({ title }) => {
  // Line graph props
  // Popper props
  const dispatch = useDispatch();

  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );

  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [graphData, setGraphData] = useState([]);
  // const [isGraphLoading, setIsGraphLoading] = useState(false);

  const groupByAnchorEl = React.useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = React.useState(false);

  const [selectedGranularity, setSelectedGranularity] = useState("monthly");
  const cumulativeGraphAnchorRef = useRef(null);

  const handleToggleCumulativeGraphDropDown = () => {
    setCumulativeGraphDropDownOpen((prevOpen) => !prevOpen);
  };
  // const handleCloseCumulativeGraphDropDown = () => {
  //   setCumulativeGraphDropDownOpen(false);
  // };

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const handleOptionSelected = async (e) => {
    console.log(e.target.id);
    setSelectedGranularity(e.target.id);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQueryForLineGraph(
      dashboardGlobalFilters,
      getGraphGranularityMappings(e.target.id),
    );

    const { graph } = await getGraphDataWithChMaestro(query);
    console.log("graph trigger", graph);
    const transformedGraph = graph
      .filter((item) => item.data.length > 0)
      .map((item) => ({
        status: item.status,
        data: item.data,
        id: item.key, // You can use a different logic to generate unique IDs if needed
        color: getRandomColor(),
      }));
    setGraphData(transformedGraph);
  };

  // function capitalizeFirstAndRemoveLastTwoLetters(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1, -2);
  // }

  const getGraphDataWithChMaestro = async (query) => {
    // const { measures, dimensions, timeDimensions, filters, order, limit } =
    //   query;
    try {
      // if (graphData.length === 0) {
      setIsGraphLoading(true);
      // }
      const request = await secure_instance.request({
        url: "v1/graph/multiline",
        method: "Post",
        data: query,
      });
      setIsGraphLoading(false);
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
  useMemo(async () => {
    console.log("dashboardGlobalFilters", dashboardGlobalFilters);
    const query = generateQueryForLineGraph(
      // graphType,
      // selectedGranularity,
      dashboardGlobalFilters,
      getGraphGranularityMappings(selectedGranularity),
    );
    console.log("query", query);
    setIsGraphLoading(true);
    const { graph } = await getGraphDataWithChMaestro(query);

    const transformedGraph = graph
      .filter((item) => item.data.length > 0)
      .map((item) => ({
        status: item.status,
        data: item.data,
        id: item.key, // You can use a different logic to generate unique IDs if needed
        color: getRandomColor(),
      }));
    setGraphData(transformedGraph);
    setIsGraphLoading(false);

    // rearrange Data For eventTypeGraph i.e stacking
    // if (graphType === "eventTypeGraph") {
    //   const { uniqueKeys, groups } = await rearrangeGraphDataForGroupBy(
    //     data,
    //     selectedGroupByItem,
    //     "DumpTableMain.userId"
    //   );
    //   console.log("uniqueKeys", uniqueKeys);
    //   console.log("groups", groups);
    //   setGroupByGraphKeys(uniqueKeys);
    //   setGraphData(groups);
    // } else {
    //   setGraphData(data);
    // }
  }, []);
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  useMemo(async () => {
    if (isTriggerRefetch) {
      const query = generateQueryForLineGraph(
        dashboardGlobalFilters,
        getGraphGranularityMappings(selectedGranularity),
      );

      const { graph } = await getGraphDataWithChMaestro(query);
      console.log("graph trigger", graph);
      const transformedGraph = graph
        .filter((item) => item.data.length > 0)
        .map((item) => ({
          status: item.status,
          data: item.data,
          id: item.key, // You can use a different logic to generate unique IDs if needed
          color: getRandomColor(),
        }));
      setGraphData(transformedGraph);
    }
  }, [isTriggerRefetch]);

  console.log("transformedGraphtransformedGraph", graphData);
  return (
    <div className="key-result-cards-container">
      <div className="d-flex justify-content-between">
        {" "}
        {/* <div className="d-flex">{title} </div> */}
        <div className="s1 d-flex align-items-center">{title}</div>
        <div className="d-flex align-items-center">
          <PopperDropdown
            anchorRef={groupByAnchorEl}
            isDropdownOpen={isGroupByMenuOpen}
            handleToggle={() => handleGenericMenuOpen(setIsGroupByMenuOpen)}
            handleClose={() => handleGenericMenuClose(setIsGroupByMenuOpen)}
            dropdownDisplayText={"Group By"}
            menuItems={["Users", "Teams", "Cohorts"]}
            handleOptionSelected={handleOptionSelected}
            activeMenuItem={selectedGranularity}
          />
          <div className="mr-3" />
          <PopperDropdown
            anchorRef={cumulativeGraphAnchorRef}
            isDropdownOpen={isCumulativeGraphDropDownOpen}
            handleToggle={() =>
              handleGenericMenuOpen(setCumulativeGraphDropDownOpen)
            }
            handleClose={() =>
              handleGenericMenuClose(setCumulativeGraphDropDownOpen)
            }
            dropdownDisplayText={capitalizeFirstAndRemoveLastTwoLetters(
              selectedGranularity === "daily" ? "Dayly" : selectedGranularity,
            )}
            menuItems={["Hourly", "Daily", "Weekly", "Monthly", "Yearly"]}
            handleOptionSelected={handleOptionSelected}
            activeMenuItem={selectedGranularity}
          />
        </div>
      </div>
      <div className="row">
        {/* %H:%M:%S */}

        {isGraphLoading ? (
          <div
            style={{
              minWidth: "95%",
              paddingLeft: "30px",
              // height: "398px",
              marginTop: "34px",
              maxHeight: "fit-content",
              justifyContent: "center",
            }}
          >
            <div
              className="d-flex justify-content-between"
              style={{
                position: "relative",
                margin: "0 auto",
                width: "100%",
                height: "100%",
              }}
            >
              {MyResponsiveLineLoader()}
              <div style={{ position: "absolute", left: "39%", top: "35%" }}>
                {/* <FullPageLoading height="50%" /> */}
                <div className="h5 grey9 mt-3">Loading Data</div>
              </div>
            </div>
          </div>
        ) : (
          <></>
          // <LineGraphTimeSeries
          //   graphData={graphData}
          //   margin={{ top: 0, right: 0, bottom: 1, left: 0 }}
          //   // %Y-%m-%dT%H:%M:%S
          //   axisBottom={{ format: "%Y-%m-%d", tickRotation: 47 }}
          //   // xFormatCustom={"time:%Y-%m-%d"}
          //   // graphContainerHeight={"106px"}
          //   curve={"natural"}
          // />
        )}
      </div>
    </div>
  );
};

export default CumulativeDashboardGraph;
