import React, { useState } from "react";
import { Table } from "../../../../components/Tables/Table";
import {
  getSurveyGradeBackgroundColors,
  getSurveyGradeTextColors,
} from "../../../../utilities/Utils";
import { mySurveyResponseTable } from "../../../../utilities/dummyGraphs/surveyDummy";
import { useDispatch, useSelector } from "react-redux";
import { setSideMenuTablePage } from "../../../../redux/slices/surveySlice";

const columns = [
  {
    key: "full_name",
    name: "Full Name",
    // minWidth: 210,
  },
  {
    key: "response",
    name: "Response",
    accessor: (row) => <div style={{ fontSize: "26px" }}>{row.response}</div>,
    align: "center",
    minWidth: 210,
  },
  {
    key: "job_title",
    name: "Job Title",
    align: "left",
    // minWidth: 210,
  },
  {
    key: "response_submitted",
    name: "Response Submitted",
    align: "center",
    // minWidth: 210,
  },
];

const SurveyResponse = ({
  currentrow,
  surveyResponseData,
  searchInputValue,
}) => {
  const dispatch = useDispatch();
  const { page } = useSelector(
    (state) => state.surveysReducer.sideMenu.tableData,
  );

  // const [filteredData, setFilteredData] = useState(mySurveyResponseTable);
  // const [filterInputValue, setFilterInputValue] = useState("");
  const [selected, setSelected] = useState(false);

  const setTablePage = (page) => {
    dispatch(setSideMenuTablePage(page));
  };

  return (
    <div>
      <div
        style={{
          border: "3px solid #F5F5F5",
          boxShadow: "0px 4px 4px 0px #FAFAFA",
          borderRadius: "10px",
          padding: "20px 34px",
        }}
      >
        <Table
          data={surveyResponseData}
          columns={columns}
          loading={false}
          page={page}
          inputValue={searchInputValue}
          filters={""}
          setTablePage={setTablePage}
          selected={selected}
          setSelected={setSelected}
          // handleToolbarAction={handleToolbarAction}
        />
      </div>
    </div>
  );
};

export default SurveyResponse;
