/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "../../../components/sub-components/Button";
import close from "../../../assets/icons/X.svg";
import BillingCard from "../../../components/Cards/BillingCard";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../../redux/slices/authSlice";

export default function Packages(props) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("Starter");

  const handlePlanConfirmed = () => {
    props.onChange(selected);
    dispatch(toggleModal());
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="packages-module"
      dialogClassName="plan-modal"
      centered
    >
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex flex-row-reverse w-100">
          {props.mediaQuery.width > 768 && (
            <img
              src={close}
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(toggleModal());
              }}
            />
          )}
        </div>
        <div className="head4 grey9" style={{ paddingBottom: "50px" }}>
          Change Plan
        </div>
        <div
          className="d-flex"
          style={{
            flexDirection: props.mediaQuery.width <= 425 && "column",
            marginBottom: "70px",
          }}
        >
          <BillingCard
            starter
            className="billing-cards"
            style={
              props.mediaQuery.width > 768
                ? { marginRight: "80px" }
                : props.mediaQuery.width < 768 && { marginBottom: "50px" }
            }
            currentPlan={props?.plan?.split(" ")[0] === "Starter"}
            selected={selected === "Starter"}
            price={
              props.monthly === "true"
                ? props.packages[0]?.stripe_pricings[0]?.price_per_unit
                : props.packages[0]?.stripe_pricings[1]?.price_per_unit
            }
            package={props.packages[0]}
            time={props.monthly ? "month" : "year"}
            onClick={() => setSelected("Starter")}
          />
          <BillingCard
            professional
            className="billing-cards"
            style={
              props.mediaQuery.width > 768
                ? { marginRight: "80px" }
                : props.mediaQuery.width < 768 && { marginBottom: "50px" }
            }
            currentPlan={props?.plan?.split(" ")[0] === "Professional"}
            selected={selected === "Professional"}
            price={
              props.monthly === "true"
                ? props.packages[1]?.stripe_pricings[0]?.price_per_unit
                : props.packages[1]?.stripe_pricings[1]?.price_per_unit
            }
            package={props.packages[1]}
            time={props.monthly ? "month" : "year"}
            onClick={() => setSelected("Professional")}
          />
          <BillingCard enterprise />
        </div>
        <div
          style={{
            width: "248px",
          }}
        >
          <Button
            buttonFilledFull
            title="Confiirm Plan"
            onClick={handlePlanConfirmed}
          />
          {props.mediaQuery.width < 425 && (
            <Button
              style={{ marginTop: "15px" }}
              buttonHollowLg
              title="Cancel"
              onClick={() => {
                dispatch(toggleModal());
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}
