import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { showSnackbar } from "./authSlice";

const initialState = {
  loading: false,
  isSideMenu: false,
  formData: {},
  data: [],
};

export const getCompanyData = createAsyncThunk(
  "company/getCompanyData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: `/v1/tenant_profile/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editFromUsers = createAsyncThunk(
  "company/editFromUsers",
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    console.log("in async thunk edit users", id, formData);
    try {
      await secure_instance.request({
        url: `/v1/tenant_profile/${id}`,
        method: "PATCH",
        data: {
          tenant_name: formData.tenant_name,
          address: formData.address,
          state: formData.state,
          country: formData.country,
          phone: formData.phone,
          zip_code: formData.zip_code,
          web_url: formData.web_url,
          image_url: formData.image_url,
        },
      });

      // Success alert
      dispatch(
        showSnackbar({
          message: "User profile updated successfully",
          severity: "success",
        }),
      );
    } catch (error) {
      // Error alert
      dispatch(
        showSnackbar({
          message: "Failed to update user profile",
          severity: "error",
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyFormData: (state, action) => {
      const data = action.payload;
      state.formData = {
        ...state.formData,
        ...data,
      };
    },
    setLoadingStart: (state) => {
      state.loading = true;
    },
    setLoadingEnd: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyData.fulfilled, (state, action) => {
      state.loading = false;
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    });
    builder.addCase(getCompanyData.rejected, (state, action) => {
      state.loading = false;
      console.log("Failed to fetch company data:", action.payload);
    }),
      builder.addCase(editFromUsers.pending, (state) => {
        state.loading = true;
      });
    builder.addCase(editFromUsers.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(editFromUsers.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setCompanyFormData,
  setLoadingStart,
  setLoadingEnd,
  setLoadingFailed,
} = companySlice.actions;

export default companySlice.reducer;
