import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/sub-components/Button";
import LinkExpired from "./LinkExpired";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import Header from "../Login/Header";
import Footer from "../../../components/sub-components/Footer";
import { activateAccountSliceCall } from "../../../redux/slices/authSlice";

export default function AccountActivated(props) {
  const dispatch = useDispatch();

  const isAccountActivated = useSelector(
    (state) => state.authReducer.isAccountActivated,
  );

  useEffect(() => {
    dispatch(activateAccountSliceCall(props.match.params.token));
  }, [dispatch, props.match.params.token]);

  if (isAccountActivated === "false") {
    return <LinkExpired />;
  }
  const mediaQuery = useWindowDimensions();

  return (
    <>
      <div className="account-activated">
        <Header />
        {mediaQuery.width <= 425 && (
          <div
            className="row mt-5"
            style={{ minWidth: "280px", width: "100%" }}
          >
            <div style={{ borderTop: "1px dashed #CED4DA", width: "100%" }} />
          </div>
        )}
        {isAccountActivated ? (
          <div
            className="d-flex flex-column align-items-center"
            style={{ textAlign: "center" }}
          >
            <div className="signup-flow-headers head3 primary-color">
              Account Activated
            </div>
            <div className="head5 grey8 account-activated-message">
              This account has been activated. You may click on the button below
              to continue.
            </div>

            <div className="row">
              <div className="d-flex flex-column align-items-center col-lg-12">
                <Button
                  style={{ width: "100%", minWidth: "300px" }}
                  buttonFilledFull
                  title="Continue"
                  onClick={() => window.location.replace("/confirm-plan")}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex flex-column align-items-center"
            style={{ textAlign: "center" }}
          >
            <div
              className="head3"
              style={{ paddingTop: "230px", color: "#7B2CBF" }}
            >
              Activating Your Account ...
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
