import React from "react";
export default function BackgroundBlur({
  popup,
  closeMenu,
  loader,
  backgroundColor,
  showAddMenu,
}) {
  return (
    <div
      onClick={() => closeMenu && closeMenu()}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: backgroundColor || "rgba(108, 117, 125, 0.25)",
        // opacity: 0.85,
        backdropFilter: "blur(1px)",
        // filter: "blur(8px)",
        // WebkitFilter: "blur(8px)",
        zIndex: popup ? 55 : loader ? "-1" : 5,
      }}
    ></div>
  );
}
