import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as ArrowsLeftRight } from "../../assets/icons/ArrowsLeftRight.svg";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import { ReactComponent as TreeStructure } from "../../assets/icons/TreeStructure.svg";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";

import Field from "../sub-components/Field";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../Tables/Table";
import {
  getDetectedUsersPlatform,
  handleUpdateDetectedUsers,
  setSidemenuTablePage,
} from "../../redux/slices/usersSlice";

export default function DetectedUsersMenu(props) {
  const {
    handleConnect,
    sideMenu,
    refreshState,
    currentRow,
    setIsRadioButton,
  } = props;
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [menuRow, setMenuRow] = useState("");
  const [menuToggle, setMenuToggle] = useState(false);
  const [currentRowId, setCurrentRowId] = React.useState(null);
  const { data, page, selected, rowsData } = useSelector(
    (state) => state.usersReducer.sideMenuTableData,
  );

  const columns = [
    {
      key: "name",
      // numeric: false,
      // align: "left",
      name: "Name",
      accessor: (row) => `${row.first_name}  ${row.last_name}`,
      padding: "0 0 0 32px",
    },
    {
      key: "email",
      // align: "left",
      name: "Email",
      // padding: "16px 32px",
      minWidth: 150,
      accessor: (row) =>
        row.identifiers.length > 0 &&
        row.identifiers?.map(
          (identifier) => identifier.is_primary && identifier.email,
        ),
      // textAlign: "left",
    },
    {
      key: "_",
      // align: "left",
      name: "Department",
      // padding: "16px 32px",
      minWidth: 150,
    },
    {
      key: "icon",
      align: "right",
      name: "",
      // padding: "16px 32px",
      minWidth: 150,
      accessor: (row) => (
        <span
          className="d-flex justify-content-end b1 mr-2 grey8"
          style={{ gap: "21px" }}
        >
          {currentRowId === row.user_id && <TreeStructure />}
        </span>
      ),
    },
  ];

  const dispatch = useDispatch();

  const handleChangeConnect = (data) => {
    setLoading(true);
    handleConnect(data);
  };

  const setTablePage = (page) => {
    dispatch(setSidemenuTablePage(page));
  };

  const setSideMenuRow = (row) => {
    setMenuRow(row);
    setMenuToggle(true);
  };

  const handleIconShow = (id) => {
    if (currentRowId === id) {
      return;
    }
    setCurrentRowId(id);
  };

  useEffect(() => {
    if (sideMenu === true) {
      setMenuToggle(false);
      setTablePage(1);
    }
  }, [sideMenu]);

  useMemo(() => {
    if (currentRow?.identifier) {
      dispatch(getDetectedUsersPlatform(currentRow?.identifier));
    }
  }, [dispatch, currentRow]);

  const handleMapUser = async () => {
    // const selectedUser = data.find((user) => Number(user.user_id === menuRow.user_id));

    setMenuToggle(false);

    const primaryIdentifier = menuRow.identifiers?.find(
      (identifier) => identifier.is_primary,
    );

    const mapUser = [
      {
        user_id: menuRow.user_id,
        type: currentRow.integration?.category,
        identifier: currentRow.identifier,
        identifier_id: currentRow.identifier_id,
        email:
          currentRow.identifier.toLowerCase() === "github"
            ? menuRow.first_name + " " + menuRow.last_name
            : primaryIdentifier?.email,
        is_primary: false,
      },
      {
        id: currentRow.id,
        tenant_id: currentRow.tenant_id,
        identifier: currentRow.identifier,
        identifier_id: currentRow.identifier_id,
        username: currentRow.username,
        is_mapped: true,
      },
    ];

    dispatch(handleUpdateDetectedUsers(mapUser));
    refreshState();
  };

  return (
    <div
      className={`side-menu-box ${
        sideMenu === true ? "open border" : "side-menu-box border"
      }`}
      style={{ paddingBottom: "0" }}
    >
      <div className="d-flex align-items-center justify-content-between mt-3">
        <span className="head5">
          {menuToggle ? (
            <Arrow_left
              stroke={"#343A40"}
              style={{
                margin: "0px 20px 0px 0",
                width: "24px",
                cursor: "pointer",
              }}
              onClick={() => setMenuToggle(false)}
            />
          ) : (
            <Close
              stroke="#343A40"
              strokeWidth="2"
              style={{
                margin: "0px 20px 3px 0",
                width: "24px",
                cursor: "pointer",
              }}
              onClick={() => {
                refreshState(), setMenuToggle(false);
              }}
            />
          )}
          {menuToggle ? "Map Detected User" : "Detected Users"}
        </span>
        {menuToggle ? (
          <div
            className="d-flex align-items-center flex-row-reverse"
            style={{ width: "fit-content" }}
          >
            <div className="integration-menu-buttons">
              <Button
                buttonFilledFull
                title="Map User"
                className="s1 grey1"
                style={{ height: "40px", marginTop: "5px", fontWeight: "400" }}
                onClick={() => handleMapUser()}
              />
            </div>
            <div
              style={{ paddingRight: "15px" }}
              className="integration-menu-buttons"
            >
              <Button
                buttonHollowLg
                title="Cancel"
                className="s1 grey6"
                style={{ height: "40px", marginTop: "5px" }}
                onClick={() => setMenuToggle(false)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {menuToggle ? (
        <>
          <span className="head6 grey8" style={{ marginTop: "34px" }}>
            Map Detected User
          </span>
          <span className="s1 grey8" style={{ marginTop: "16px" }}>
            {`You are about to map ${currentRow.username} from ${currentRow.identifier}
						to ${menuRow?.first_name}. Are you sure you want to continue?`}
          </span>
        </>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "16px" }}
        >
          <div style={{ display: "inline-block" }}>
            <div className="s2 grey8">Name</div>
            <div style={{ marginTop: "12px" }}>
              <Field
                className="b1 grey7"
                inputFieldFullReadOnly
                noDropDown
                readOnly
                value={currentRow?.username ?? ""}
              />
            </div>
          </div>

          <div
            className=""
            style={{ marginLeft: "32px", display: "inline-block" }}
          >
            <div className="s2 grey8">Platform</div>

            <div style={{ marginTop: "12px" }}>
              <img
                src={currentRow?.integration?.image_url}
                width="32"
                height="32"
              />
              <span className="b1 grey7" style={{ marginLeft: "16px" }}>
                {currentRow?.identifier}
              </span>
            </div>
          </div>
        </div>
      )}

      {menuToggle ? (
        <div
          style={{
            borderBottom: "2px solid #E9ECEF",
            width: "100%",
            marginTop: "24px",
          }}
        />
      ) : (
        <div
          style={{
            borderBottom: "2px solid #E9ECEF",
            width: "100%",
            marginTop: "40px",
          }}
        />
      )}

      <div className="head6 grey8" style={{ marginTop: "24px" }}>
        {menuToggle ? "User's Details" : "Map with active user"}
        {menuToggle ? (
          ""
        ) : (
          <p className="s2 grey8">
            Map the detected user to any of your active users to help us
            identify their account
          </p>
        )}
      </div>
      {menuToggle ? (
        <div>
          <div
            className={`d-flex align-items-center ${
              menuToggle && "justify-content-between"
            } w-100`}
            style={{ marginTop: "16px" }}
          >
            <div style={{ display: "inline-block", width: "50%" }}>
              <div className="s2 grey8">
                {menuToggle ? "Detected User" : "Name"}
              </div>
              <div style={{ marginTop: "12px" }}>
                <Field
                  className="b1 grey7"
                  inputFieldFullReadOnly
                  noDropDown
                  readOnly
                  value={currentRow?.username}
                />
              </div>
            </div>

            <div
              className=""
              style={{
                marginLeft: "32px",
                display: "inline-block",
                whiteSpace: "nowrap",
              }}
            >
              <div className="s2 grey8">Platform</div>

              <div style={{ marginTop: "12px" }}>
                <img
                  src={currentRow?.integration?.image_url}
                  width="30"
                  height="30"
                />
                <span className="b1 grey7" style={{ marginLeft: "16px" }}>
                  {currentRow?.identifier}
                </span>
              </div>
            </div>

            {menuToggle && (
              <>
                <ArrowsLeftRight
                  style={{
                    minWidth: "24px",
                    minHeight: "24px",
                    margin: "0 32px",
                    marginTop: "10px",
                  }}
                />

                <div
                  className=""
                  style={{
                    marginLeft: "32px",
                    display: "inline-block",
                    whiteSpace: "nowrap",
                  }}
                >
                  <div className="s2 grey8">Active User</div>
                  <div style={{ marginTop: "12px" }}>
                    <div
                      style={{
                        display: "flex",
                        margin: 0,
                        alignItems: "center",
                        pointerEvents: "none",
                      }}
                    >
                      <span
                        className="s1"
                        style={{
                          marginRight: "16px",
                          minWidth: "37px",
                          background: "#495057",
                          color: "#F8F9FA",
                          minHeight: "37px",
                          borderRadius: "100px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {menuRow?.first_name.substring(0, 1)}
                        {menuRow?.last_name.substring(0, 1)}
                      </span>
                      <span className="b1 grey8" style={{ display: "flex" }}>
                        {menuRow?.first_name + " " + menuRow?.last_name}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div style={{ marginBottom: "16px" }}>
          <Field
            inputFieldFullExpand
            placeholder="Search active user"
            value={inputValue}
            onChange={(e) => {
              setTablePage(1);
              setInputValue(e.target.value);
            }}
            detectedUserIcon
            style={{
              margin: "48px 0 20px 0",
              border: "1px solid #E9ECEF",
              borderRadius: "10px",
              background: "#FFFFFF",
            }}
            inputStyle={{
              color: "#6C757D",
              border: "none",
              height: "48px",
              background: "#FFFFFF",
            }}
          />
        </div>
      )}

      {menuToggle ? (
        ""
      ) : (
        <Table
          data={data}
          inputValue={inputValue}
          columns={columns}
          setTablePage={setTablePage}
          page={page}
          setSideMenuRow={setSideMenuRow}
          setMenuToggle={setMenuToggle}
          handleTableRowClick={setSideMenuRow}
          onMouseOver={(row) => handleIconShow(row.user_id)}
          onMouseLeave={() => setCurrentRowId(null)}
          displayAvatar
        />
      )}

      <div className="integration-menu-buttons-container-mobile">
        <div
          className="integration-menu-buttons-mobile"
          style={{ width: "100%" }}
        >
          <Button
            buttonFilledFull
            title="Connect"
            style={{
              width: "100%",
              color: "white",
              height: "40px",
              fontWeight: "400",
            }}
            loading={loading}
            onClick={() => handleChangeConnect(props.data)}
          />
        </div>
        <div
          style={{ width: "100%" }}
          className="integration-menu-buttons-mobile"
        >
          <Button
            buttonHollowLg
            title="Cancel"
            style={{ width: "100%", height: "40px", marginTop: "15px" }}
            onClick={() => props.handleSideMenuClose()}
          />
        </div>
      </div>
    </div>
  );
}
