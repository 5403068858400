import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  rowsData: [],
  rowsPerPage: 5,
  page: 1,
  selected: [],
  isMultipleRowsSelected: false,
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableData(state, action) {
      state.data = action.payload;
    },
    setRowsData(state, action) {
      state.rowsData = action.payload;
    },
    setTablePageAction(state, action) {
      state.page = action.payload;
    },
    setTableSelectedRows(state, action) {
      state.selected = action.payload;
    },
    setMultipleSelected(state, action) {
      state.isMultipleRowsSelected = action.payload;
    },
  },
});

export const {
  setTableData,
  setRowsData,
  setTablePageAction,
  setTableSelectedRows,
  setMultipleSelected,
} = tableSlice.actions;

export default tableSlice.reducer;
