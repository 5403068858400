import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import Button from "../../components/sub-components/Button";
import { useDispatch, useSelector } from "react-redux";
import Field from "../sub-components/Field";
import {
  handleCouponCodeChangeInStore,
  handleUpgradePlan,
  removeCoupon,
  setStripePricingIdInStore,
  toggleConfirmPlanSideMenu,
  upgradePlanErrorStateRefresh,
} from "../../redux/slices/billingSlice";
import { checkCouponDetails } from "../../redux/slices/authSlice";

export default function ConfirmPlan(props) {
  // const { tool } = props.data;
  const { sideMenu, applyCouponConfirmPlan, setApplyCouponConfirmPlan } = props;

  const dispatch = useDispatch();

  const [price, setPrice] = useState();
  const [stripePricingId, setStripePricingId] = useState();

  const {
    stripePricings,
    currentStripePriceId,
    loading,
    couponCode,
    couponResult,
  } = useSelector((state) => state.billingsReducer);

  const { isMonthly, isYearly, plan } = useSelector(
    (state) => state.billingsReducer.paymentPlans,
  );

  const { isError, errorMessage } = useSelector(
    (state) => state.billingsReducer.upgradePlanError,
  );

  const handleCouponCodeChange = (target) => {
    dispatch(handleCouponCodeChangeInStore(target));
  };

  const handleConfirmPlan = () => {
    dispatch(handleUpgradePlan({ stripePriceId: currentStripePriceId }));
    // props.setIsChangePlan(false);
  };

  const handleCheckCouponDetails = () => {
    if (couponResult.isCouponCodeApplied) {
      dispatch(removeCoupon());
    } else {
      dispatch(checkCouponDetails(couponCode));
    }
  };

  const handleCloseMenu = () => {
    dispatch(toggleConfirmPlanSideMenu());
    // Refresh States
    dispatch(upgradePlanErrorStateRefresh());
    setApplyCouponConfirmPlan(false);
  };

  useEffect(() => {
    const stripePricing =
      stripePricings[
        plan === "Starter" || plan === "Base" ? 0 : plan === "Professional" && 1
      ]?.stripe_pricings;

    isMonthly
      ? setPrice(stripePricing[0]?.price_per_unit)
      : setPrice(stripePricing[1]?.price_per_unit);

    isMonthly
      ? setStripePricingId(stripePricing[0].id)
      : setStripePricingId(stripePricing[1].id);
  }, [plan, isMonthly, isYearly]);

  useEffect(() => {
    dispatch(setStripePricingIdInStore(stripePricingId));
  }, [stripePricingId]);

  return (
    <div
      className={`side-menu-box ${
        sideMenu === true ? "open border" : "side-menu-box border"
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <span className="head5 grey9">
          <Close
            style={{ margin: "2px 10px 5px 0", width: "24px" }}
            stroke={"#343A40"}
            strokeWidth={"2"}
            onClick={() => handleCloseMenu()}
          />
          Confirm Plan
        </span>

        <div
          className="d-flex align-items-center flex-row-reverse"
          style={{ width: "fit-content" }}
        >
          <div className="integration-menu-buttons">
            <Button
              typography={"b0-medium grey0"}
              buttonFilledFull
              title="Continue"
              style={{
                color: "white",
                height: "40px",
                marginTop: "5px",
                fontWeight: "400",
              }}
              loading={loading}
              onClick={() => handleConfirmPlan()}
            />
          </div>
          <div
            style={{ paddingRight: "15px" }}
            className="integration-menu-buttons"
          >
            <Button
              typography={"s1 grey6"}
              buttonHollowLg
              title="Back"
              style={{ height: "40px" }}
              onClick={() => handleCloseMenu()}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "38px",
        }}
      >
        <div>
          <span className="head5 grey8" style={{ paddingTop: "30px" }}>
            {/* Connect {tool} to your Crewnetics Account */}
            {`${plan === "Base" ? "Starter" : plan} Plan `}
            <span className="s1 grey8">
              ({isMonthly ? "Monthly" : "Yearly"})
            </span>
          </span>
        </div>

        <div className="head5 grey8">
          {`$`}
          {price}
          <span className="s1 grey8">{`/${isMonthly ? "month" : "year"}`}</span>
        </div>
      </div>

      <span className="b1 primary-color" style={{ paddingTop: "10px" }}>
        Features
      </span>

      <div style={{ paddingTop: "10px" }}>
        <div className="b1 grey7">
          {`${stripePricings[0].allowed_users} users`}
        </div>
        <div
          className="b1 b1 grey7"
          style={{ margin: "0px", padding: "12px 0" }}
        >
          {`${stripePricings[0].allowed_integrations} integrations`}
        </div>
        <div className="b1 b1 grey7">Destinations</div>
      </div>

      <span className="b1 grey6" style={{ paddingTop: "22px" }}>
        Note: In case of exceeding limit, you will have to upgrade your plan.
      </span>

      <div
        className="w-100"
        style={{ borderTop: "2px solid #E9ECEF", margin: "24px 0" }}
      />

      <div style={{ position: "relative" }}>
        <div className="d-flex align-items-center justify-content-between">
          <span className="head6 grey6">Total Payment</span>

          <span className="head6 grey6">
            {`$`}
            {price}
          </span>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
          <span className="head6 grey6">Payment Due Today</span>

          <span className="head6 grey6">
            {`$`}
            {price}
          </span>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          {/* <span className="head6 grey6">
                    Apply Coupon Code
					</span> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="d-flex flex-column s1 primary-color"
              onClick={() => setApplyCouponConfirmPlan(!applyCouponConfirmPlan)}
              style={{
                paddingBottom: "10px",
                cursor: "pointer",
              }}
            >
              Apply Coupon Code
            </div>
          </div>
          <span className="s1 grey6">Next Payment: $29.99 on 18/09/2021</span>
        </div>

        <div
          className="d-flex align-items-center justify-content-end"
          style={{
            opacity: isError ? 1 : 0,
            transition: "200ms",
            paddingTop: "46px",
          }}
        >
          <span
            className="s1 d-flex align-items-center"
            style={{
              color: "#FFFFFF",
              background: "#EE6055",
              padding: "16px 24px",
              borderRadius: "10px",
              maxWidth: "650px",
              minWidth: "215px",
              minHeight: "56px",
              maxHeight: "70px",
            }}
          >
            <Close
              style={{
                marginRight: "12px",
                minWidth: "22px",
                maxWidth: "22px",
                minHeight: "22px",
                maxHeight: "22px",
              }}
              stroke={"#FFFFFF"}
              strokeWidth={"3"}
            />
            {"Error: "}
            {isError &&
              (errorMessage?.message.length > 20
                ? `${errorMessage?.message.substring(0, 10)}...`
                : errorMessage?.message)}
          </span>
        </div>

        {applyCouponConfirmPlan && (
          <Field
            style={{
              position: "absolute",
              top: "120px",
              marginBottom: "30px",
              width: "400px",
            }}
            inputStyle={{ width: "400px" }}
            inputFieldApplyCoupon
            placeholder="Coupon Code"
            disabled={!couponCode}
            name="couponCode"
            value={couponCode}
            applied={couponResult.isCouponCodeApplied}
            errorText={couponResult.couponErrorText}
            onChange={(e) => handleCouponCodeChange(e.target)}
            onClick={handleCheckCouponDetails}
          />
        )}
      </div>

      {/* <div
				className="integration-menu-buttons-container-mobile"
			>
				<div className="integration-menu-buttons-mobile" style={{ width:"100%"}}>
					<Button
						buttonFilledFull
						title='Connect'
						style={{ width:"100%",color: "white", height: "40px",fontWeight:"400" }}
						// loading={loading}
						// onClick={() => handleChangeConnect(props.data)}
					/>
                    
				</div>
				<div style={{width:"100%" }} className="integration-menu-buttons-mobile">
					<Button
						buttonHollowLg
						title="Cancel"
						style={{ width:"100%", height: "40px",marginTop:"15px" }}
						onClick={() => props.handleSideMenuClose()}
					/>
				</div>
			</div> */}
    </div>
  );
}
