import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Error404 from "./pages/Error404";
import { ownerRoutes } from "../utilities/Constants";
import FullPageLoading from "../components/Loading/FullPageLoading";
import { refreshTokenSliceCall } from "../redux/slices/authSlice";
import SidebarLayout from "./SidebarLayout";
import { HeaderNew } from "./Layout";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation(); // Get current route
  const { user, isRefreshed, isRefreshing } = useSelector(
    (state) => state.authReducer,
  );

  useEffect(() => {
    if (!isRefreshed && !isRefreshing) {
      dispatch(refreshTokenSliceCall());
    }
  }, [dispatch, isRefreshed, isRefreshing]);

  if (isRefreshing || !isRefreshed) {
    return (
      <Route
        {...rest}
        render={() =>
          location.pathname === "/join-organization" ? (
            <FullPageLoading height="65vh" />
          ) : (
            <SidebarLayout>
              <HeaderNew />
              <FullPageLoading height="65vh" />
            </SidebarLayout>
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={() =>
        user?.tenantId ? (
          ownerRoutes.find((p) => p.path === rest.path) &&
          user.roleId !== 11 ? (
            <Error404 />
          ) : location.pathname === "/join-organization" ? (
            <Component />
          ) : (
            <SidebarLayout>
              <HeaderNew />
              <Component />
            </SidebarLayout>
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default ProtectedRoute;
