import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "../sub-components/Button";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import CustomBox from "./CustomBox";

export default function BillingsModal(props) {
  const {
    isSubscriptionCancelModal,
    setIsSubscriptionCancelModal,
    subscription,
  } = props;
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsSubscriptionCancelModal(false);
    props.setIsSubscriptionCancelConfirm(false);
  };

  if (props.subscriptionCancelModal) {
    return (
      <div>
        <Modal
          open={isSubscriptionCancelModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CustomBox>
            <div style={{ ...props.style }}>
              <Close
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 18,
                  right: 15,
                  margin: "2px 10px 5px 0",
                  width: "24px",
                }}
                stroke="#343A40"
                stroke-width="3"
                onClick={handleClose}
              />
              {!props.isSubscriptionCancelConfirm ? (
                <>
                  <span
                    className="head5 grey9"
                    style={{ marginBottom: "16px" }}
                  >
                    Confirm Cancellation
                  </span>

                  <span className="s1 grey7" style={{ marginBottom: "8px" }}>
                    Are you sure you want to cancel your subscription?
                  </span>

                  <span
                    className="s1 grey7"
                    style={{ marginBottom: "27px", flexWrap: "wrap" }}
                  >
                    If you would like to proceed with canceling your
                    subscription, please select “Continue” below.
                  </span>

                  <Button
                    title="Continue"
                    className="b2 grey0"
                    buttonFilledFull
                    style={{
                      minWidth: " 135px",
                      height: "40px",
                      marginTop: "5px",
                      fontWeight: "400",
                      maxWidth: "135px",
                    }}
                    onClick={() => props.setIsSubscriptionCancelConfirm(true)}
                  />

                  <div
                    className="s1 primary-color"
                    style={{ cursor: "pointer", marginTop: "34px" }}
                    onClick={handleClose}
                  >
                    I don’t want to cancel my subscription
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0 -50px",
                  }}
                >
                  <span
                    className="head5 grey9"
                    style={{ marginBottom: "16px" }}
                  >
                    We’re sorry to see you go!
                  </span>

                  <span className="s1 grey7" style={{ marginBottom: "8px" }}>
                    Your{" "}
                    {subscription.interval === "month" ? "monthly" : "yearly"}
                    subscription is paid until{" "}
                    {props.convertUnixTimeStamp(subscription.expiresAt)}. We
                    would appreciate if you could tell us your reason leaving
                    below.
                  </span>

                  <span
                    className="s1 grey7"
                    style={{ marginBottom: "27px", flexWrap: "wrap" }}
                  >
                    Reason for leaving (What options ?)
                  </span>

                  <Button
                    title="Submit"
                    className="b2 grey0"
                    buttonFilledFull
                    style={{
                      minWidth: "88px",
                      height: "40px",
                      marginTop: "5px",
                      fontWeight: "400",
                      maxWidth: "88px",
                    }}
                    // onClick={() => props.setIsChangePlan(true)}
                  />

                  {/* <div className="s1 primary-color" style={{cursor:"pointer",marginTop:"34px"}} onClick={handleClose}>
                                I don’t want to cancel my subscription
                            </div> */}
                </div>
              )}
            </div>
          </CustomBox>
        </Modal>
      </div>
    );
  }
}
