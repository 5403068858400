import React, { useEffect, useState } from "react";
import Field from "../../../components/sub-components/Field";
import Button from "../../../components/sub-components/Button";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../components/sub-components/ImageUploader";
import {
  editFromUsers,
  getCompanyData,
  setCompanyFormData,
} from "../../../redux/slices/companySlice";

function Company() {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const { formData, loading } = useSelector((state) => state.companyReducer);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrlToUpload, setImageUrlToUpload] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentRowImage, setCurrentRowImage] = useState(null);
  const { user } = useSelector((state) => state.authReducer);

  const handleChange = (event) => {
    setDisabled(false);
    dispatch(
      setCompanyFormData({
        [event.target.name]: event.target.value,
      }),
    );
  };

  const handleSubmit = () => {
    setDisabled(true);
    const cloneFormData = { ...formData };
    cloneFormData["image_url"] =
      formData?.image_url && imageUrlToUpload === null
        ? formData?.image_url
        : imageUrlToUpload;
    dispatch(editFromUsers({ id: user?.tenantId, formData: cloneFormData }));
  };

  useEffect(() => {
    dispatch(getCompanyData(user?.tenantId));
  }, []);

  useEffect(() => {
    if (imageUrlToUpload !== null) {
      setLoadingImage(false);
      setDisabled(false);
    }
  }, [imageUrlToUpload]);

  return (
    <div>
      <div
        className="page-body-new d-flex justify-content-between"
        style={{ padding: "0px 40px 0px 29px" }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ paddingLeft: "8px" }}>
            <h1 className="head6 grey8" style={{ lineHeight: "30px" }}>
              Basic Information
            </h1>
            <p className="b2 grey6" style={{ lineHeight: "21px" }}>
              Information that is necessory to your profile and might be synced
              through your identity provider
            </p>
          </div>
          <div className="row" style={{ marginTop: "24px" }}>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Company Name
              </div>
              <Field
                style={{ background: "#FFFFFF" }}
                value={formData?.tenant_name}
                name="tenant_name"
                onChange={(e) => handleChange(e)}
                inputFieldFull
              />
            </div>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Address
              </div>
              <Field
                style={{ background: "#FFFFFF" }}
                value={formData?.address}
                name="address"
                onChange={(e) => handleChange(e)}
                inputFieldFull
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                State
              </div>
              <Field
                style={{ background: "#FFFFFF" }}
                value={formData?.state}
                name="state"
                onChange={(e) => handleChange(e)}
                inputFieldFull
              />
            </div>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Country
              </div>
              <div style={{ width: "100%" }}>
                <Field
                  style={{ background: "#FFFFFF" }}
                  value={formData?.country}
                  name="country"
                  onChange={(e) => handleChange(e)}
                  inputFieldFull
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-lg-6">
              <div className="s1" style={{ paddingBottom: "10px" }}>
                Phone
              </div>
              <Field
                style={{ background: "#FFFFFF" }}
                value={formData?.phone}
                name="phone"
                onChange={(e) => handleChange(e)}
                inputFieldFull
              />
            </div>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Zip Code
              </div>
              <Field
                style={{ background: "#FFFFFF" }}
                value={formData?.zip_code}
                name="zip_code"
                onChange={(e) => handleChange(e)}
                inputFieldFull
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-lg-6">
              <div className="s1 grey8" style={{ paddingBottom: "10px" }}>
                Website URL
              </div>
              <Field
                style={{ background: "#FFFFFF" }}
                value={formData?.web_url}
                name="web_url"
                onChange={(e) => handleChange(e)}
                inputFieldFull
              />
            </div>
          </div>

          <div className="s1" style={{ paddingTop: "25px", width: "160px" }}>
            <Button
              disabled={disabled}
              loading={loading}
              onClick={handleSubmit}
              buttonFilledFull
              title="Update Profile"
            />
          </div>
        </div>
        <div
          className="d-flex flex-column"
          style={{
            marginTop: "90px",
            width: "300px",
            height: "100%",
            textAlign: "left",
          }}
        >
          <div
            className="s1"
            style={{ marginLeft: "24px", paddingBottom: "10px" }}
          >
            Company Logo
          </div>
          <ImageUploader
            loadingImage={loadingImage}
            setImageUrlToUpload={setImageUrlToUpload}
            setLoadingImage={setLoadingImage}
            postImageURL={"v1/teams/upload"}
            data={formData?.image_url}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            currentRowImage={currentRowImage}
            setCurrentRowImage={setCurrentRowImage}
          />
        </div>
      </div>
    </div>
  );
}

export default Company;
