import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardGraph from "./DashboardGraph";
import {
  // generateQueryForLineGraph,
  generateQueryForSynchrounousCommunicationGraph,
} from "../../utilities/CubeUtils";
import { getGraphGranularityMappings } from "../../utilities/Utils";
import { secure_instance } from "../../axios/axios-config";

const CommunicationTeam = () => {
  // const dispatch = useDispatch();
  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );

  // const [dashboardUserFilters, setDashboardUserFilters] = useState(
  //   dashboardGlobalFilters,
  // );
  const [selectedGranularity, setSelectedGranularity] = useState("monthly");
  const [selectedGroupByOption, setSelectedGroupByOption] = useState("teams");

  const cumulativeGraphAnchorRef = useRef(null);
  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);

  const groupByAnchorEl = useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = useState(false);
  const [teamDataForMapping, setTeamDataForMapping] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  // const handleOptionSelected = async (e) => {
  //   setSelectedGranularity(e.target.id);
  //   setCumulativeGraphDropDownOpen(false);
  // };
  const generateQuery = (selectedGranularity) => {
    const query = generateQueryForSynchrounousCommunicationGraph(
      dashboardGlobalFilters,
      // organizationGlobalFilter,
      getGraphGranularityMappings(selectedGranularity),
    );
    return query;
  };

  const getGraphAPIData = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/graph/line",
        method: "Post",
        data: query,
      });
      return request.data;
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };

  // ------------  SHOULD BE HITTING TWO DIFFERENT API'S WITH FILTERS OF SYNC AND ASYNC ------------

  const getGraphData = async (query) => {
    setIsGraphLoading(true);
    const response = await getGraphAPIData(query);

    if (!response?.graph) return [];

    const result = response.graph?.reduce(
      (acc, obj) => {
        if (obj.event_name === "communication_call_misc") {
          acc.SynchrounousCommunicationGraphData.push(obj);
        } else {
          acc.AsynchrounousCommunicationGraphData.push(obj);
        }
        return acc;
      },
      {
        SynchrounousCommunicationGraphData: [],
        AsynchrounousCommunicationGraphData: [],
      },
    );

    // Message: Asyncronous,
    // Call: Synchronous

    const SynchrounousCommunicationGraphData =
      result.SynchrounousCommunicationGraphData;

    const AsynchrounousCommunicationGraphData =
      result.AsynchrounousCommunicationGraphData;

    const transformedCommunicationData = [
      {
        id: "sync",
        data: SynchrounousCommunicationGraphData.map((item) => ({
          x: item.x,
          y: item.y,
        })),
      },
      {
        id: "async",
        data: AsynchrounousCommunicationGraphData.map((item) => ({
          x: item.x,
          y: item.y,
        })),
      },
    ];
    setIsGraphLoading(false);

    return transformedCommunicationData;
  };

  const fetchGraphData = async () => {
    // setIsGraphLoading(true);
    const query = generateQuery(selectedGranularity);
    const graphData = await getGraphData(query);
    setGraphData(graphData ?? []);
    // setIsGraphLoading(false);
  };

  const handleOptionSelected = async (value) => {
    setSelectedGranularity(value);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(value);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  // Fetch data when component first loads
  useEffect(() => {
    fetchGraphData();
  }, []);

  // Fetch data on trigger refetch
  useEffect(() => {
    if (isTriggerRefetch) {
      fetchGraphData();
    }
  }, [isTriggerRefetch, selectedGranularity]);

  return (
    <DashboardGraph
      title={"Synchrounous Vs Asynchronous Communication"}
      isGraphLoading={isGraphLoading}
      graphData={graphData}
      setGraphData={setGraphData}
      isTriggerRefetch={isTriggerRefetch}
      dashboardGlobalFilters={dashboardGlobalFilters}
      getGraphData={getGraphData}
      generateQuery={generateQuery}
      selectedGranularity={selectedGranularity}
      setSelectedGranularity={setSelectedGranularity}
      teamDataForMapping={teamDataForMapping}
      cumulativeGraphAnchorRef={cumulativeGraphAnchorRef}
      handleGenericMenuOpen={handleGenericMenuOpen}
      handleGenericMenuClose={handleGenericMenuClose}
      handleOptionSelected={handleOptionSelected}
      groupByAnchorEl={groupByAnchorEl}
      isGroupByMenuOpen={isGroupByMenuOpen}
      setIsGroupByMenuOpen={setIsGroupByMenuOpen}
      isCumulativeGraphDropDownOpen={isCumulativeGraphDropDownOpen}
      setCumulativeGraphDropDownOpen={setCumulativeGraphDropDownOpen}
      groupByMenuItems={["Users", "Teams"]}
      selectedGroupByOption={selectedGroupByOption}
      // these two funcs need to be defined --------------------------?
      handleGroupByOptionSelected={() => console.log("")}
      handleGranularityOptionSelected={() => console.log("")}
      communicationGraph
    />
  );
};

export default CommunicationTeam;
