import React, { useState } from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/Info.svg";
import Field from "../sub-components/Field";

const PopperDropdown = ({
  anchorRef,
  isDropdownOpen,
  handleToggle,
  handleClose,
  dropdownDisplayText,
  menuItems,
  handleOptionSelected,
  activeMenuItem,
  customRenderFieldChildren,
  dropdownWithSearchAndCheckbox,
  dropdownData,
  dropdownItemsSelected,
  handleClickCheckbox,
  handleApply,
  keyToAccess,
  // searchTerm,
  searchBy,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const getRenderField = () => {
    if (customRenderFieldChildren) {
      return customRenderFieldChildren;
    } else {
      return (
        <div
          className="d-flex align-items-center"
          style={{
            background: "#FFFFFF",
            position: "relative",
            border: "1px solid #7B2CBF",
            borderRadius: "10px",
            padding: "8px 16px",
            width: "100%",
            height: "37px",
            cursor: "pointer",
          }}
        >
          <span
            className="primary-color s2 mr-2"
            style={{ whiteSpace: "nowrap" }}
          >
            {dropdownDisplayText}
          </span>

          <CaretDown stroke="#7B2CBF" stroke-width="1.5" />
        </div>
      );
    }
  };

  const getRenderDropdown = () => {
    if (dropdownWithSearchAndCheckbox) {
      // const isSelected = (name) => dropdownItemsSelected?.indexOf(name) !== -1;
      // const searchTermLowerCase = searchTerm.toLowerCase();

      const searchByNameIfNoProps = searchBy || "name";

      const filteredData = dropdownData.filter((item) => {
        // Convert the value of the specified searchBy property to lowercase
        const searchValue = item[searchByNameIfNoProps]
          ? item[searchByNameIfNoProps].toLowerCase()
          : "";

        // Check if the search term is found in the searchBy property
        return searchValue.includes(searchTerm.toLowerCase());
      });

      const isSelected = (name) => {
        if (
          Object.hasOwn(dropdownItemsSelected, "id") &&
          Object.hasOwn(name, "id")
        ) {
          return dropdownItemsSelected.id === name.id;
        }

        return dropdownItemsSelected === name;
      };

      return (
        <div style={{ position: "relative" }}>
          <div
            style={{
              height: "280px",
              overflowY: "scroll",
              position: "relative",
              margin: "4px 0",
            }}
          >
            <div style={{ margin: "13px 15px 15px 15px" }}>
              <Field
                inputFieldFullSearchIcon
                placeholder={`Search`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "52px",
                  borderRight: "1px solid #e9ecef",
                  borderRadius: "10px",
                }}
              />
            </div>

            <div style={{ height: "auto" }}>
              {filteredData?.map((obj, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                  // onClick={() => props.handleClickCheckbox(team)}
                >
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      // handleClickCheckbox(obj);
                      setSearchTerm("");
                      handleApply(obj);
                    }}
                    style={{
                      padding: "6px 12px",
                      paddingLeft: "20px",
                      margin: "2px 0",
                      background: isSelected(obj) && "#F4E6FF",
                    }}
                    className="d-flex justify-content-between align-items-center w-100"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="s2 grey8"
                        style={{
                          display: "flex",
                          padding: "0",
                          marginLeft: "0px",
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                      >
                        {/* <BpCheckbox
                          checked={isSelected(obj)}
                          // checked={false}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                          }}
                          width={24}
                          height={24}
                        /> */}
                        {keyToAccess === "none"
                          ? obj
                          : obj[keyToAccess]
                            ? obj[keyToAccess]
                            : obj["first_name"] + " " + obj["last_name"]}
                      </span>
                    </div>

                    <InfoIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      stroke="#ADB5BD"
                      stroke-width="1.5"
                    />
                  </MenuItem>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="w-100">
            <div
              style={{
                alignItems: "flex-end",
                flexDirection: "column",
                borderTop: "1px solid #CED4DA",
                maxHeight: "55px",
                minHeight: "55px",
                height: "55px",
                borderRadius: "10px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="d-flex" style={{ margin: "24px" }}>
                <span
                  className="s2 grey8"
                  style={{
                    marginRight: "17px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </span>
                <span
                  className="s2 primary-color"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleApply();
                    setSearchTerm("");
                  }}
                >
                  Apply
                </span>
              </div>
            </div>
          </div> */}
        </div>
      );
    } else {
      return (
        <MenuList
          autoFocusItem={isDropdownOpen}
          id="composition-menu"
          aria-labelledby="composition-button"
          // onKeyDown={handleListKeyDown}
        >
          {menuItems.map((menuItem, index) => (
            <MenuItem
              onClick={handleOptionSelected}
              key={index}
              sx={{
                "&.Mui-selected": {
                  background: "#F4E6FF",
                  color: "#7B2CBF",
                },
              }}
              id={menuItem.toLowerCase()}
              selected={activeMenuItem.toLowerCase() === menuItem.toLowerCase()}
            >
              {menuItem}
            </MenuItem>
          ))}
        </MenuList>
      );
    }
  };

  return (
    <div>
      <div
        ref={anchorRef}
        id="composition-button"
        aria-controls={isDropdownOpen ? "composition-menu" : undefined}
        aria-expanded={isDropdownOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {getRenderField()}
      </div>

      <Popper
        open={isDropdownOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: "5" }}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "top right",
            }}
          >
            <Paper sx={{ border: "1px solid #BFBFBF", borderRadius: "10px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                {getRenderDropdown()}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default PopperDropdown;
