import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import TeamsMenu from "../../../components/SideMenus/TeamsMenu";
import { Table } from "../../../components/Tables/Table";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/mi_delete.svg";
import DeleteModal from "../../../components/Modals/DeleteModal";
import {
  getTeamsData,
  toggleAddMenu,
  deleteTeamCall,
  updateTeamsData,
  setTeamsTablePage,
  setTableSelectedRows,
  handleCreateTeamButtonClick,
} from "../../../redux/slices/teamsSlice";
import { TEAMS_COLUMNS } from "../../../utilities/Constants";

export default function Teams(props) {
  const { inputValue, filters, userApplied, teamAppliedFilter } = props;
  const dispatch = useDispatch();

  const { showEditMenu, showAddMenu, isTeamCreatedButtonClick } = useSelector(
    (state) => state.teamsReducer,
  );

  const { selected, data, page, tableLoading } = useSelector(
    (state) => state.teamsReducer.tableData,
  );

  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentrow, setCurrentrow] = React.useState({});

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleCurrentRow = (row) => {
    editMenu();
    setCurrentrow(row);
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const editMenu = () => {
    dispatch(toggleAddMenu());
    handleActionMenuClose();
  };

  const deleteTeam = () => {
    const teamsDataCloned = data.map((a) => ({ ...a }));

    if (showAddMenu) {
      const map = { teams: [{ id: currentrow.id }] };
      dispatch(deleteTeamCall(map));
      handleCloseModal();
      const filteredTeams = teamsDataCloned.filter(
        (obj) => obj.id !== currentrow.id,
      );
      dispatch(updateTeamsData(filteredTeams));
      dispatch(toggleAddMenu());
    }
    if (!showAddMenu) {
      const map = { teams: selected.map((obj) => ({ id: obj.id })) };
      dispatch(deleteTeamCall(map));
      handleCloseModal();
      const filteredTeams = teamsDataCloned.filter(
        (obj) => !selected.some((sel) => sel.id === obj.id),
      );
      dispatch(updateTeamsData(filteredTeams));
    }
    setSelected([]);
  };

  const setTablePage = (data) => {
    dispatch(setTeamsTablePage(data));
  };

  const setSelected = (data) => {
    dispatch(setTableSelectedRows(data));
  };

  useEffect(() => {
    dispatch(getTeamsData()); //todo
  }, []);

  useEffect(() => {
    if (isTeamCreatedButtonClick) {
      dispatch(handleCreateTeamButtonClick());
    }
  }, [isTeamCreatedButtonClick]);

  useEffect(() => {
    if (showEditMenu === true || showAddMenu === true) {
      document.body.style.overflow = "hidden";
    } else if (showEditMenu === false) {
      document.body.style.overflow = "unset";
    }
  }, [showEditMenu, showAddMenu]);

  return (
    <div style={{ textAlign: "left" }}>
      <DeleteModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        deleteEvent={deleteTeam}
        text={"Delete Team"}
      />
      <div className="flex-column">
        <TeamsMenu
          currentRowToEdit={currentrow}
          deleteTeam={handleOpenModal}
          setCurrentRowToEdit={setCurrentrow}
        />

        <div className="teams-table">
          <Box sx={{ width: "100%", mb: 2 }} style={{ borderRadius: "10px" }}>
            <Table
              data={
                teamAppliedFilter.length === 0
                  ? data
                  : data.filter((data) => data === teamAppliedFilter)
              }
              // userApplied={userApplied}
              headerBackgroundColor="#F7FBFF"
              columns={TEAMS_COLUMNS}
              loading={tableLoading && !showAddMenu && !showEditMenu}
              page={page}
              inputValue={inputValue}
              filters={filters}
              setTablePage={setTablePage}
              selected={selected}
              setSelected={setSelected}
              sortBy="id"
              handleTableRowClick={handleCurrentRow}
              handleToolbarAction={handleOpenModal}
              toolbarText={["Delete", "Delete"]}
              toolbarImage={<DeleteIcon />}
              checkboxSize="24px"
              checkboxRequired
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
