import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFlashNotificationsMenuOpen: false,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    toggleFlashNotificationsMenu(state, action) {
      state.isFlashNotificationsMenuOpen = action.payload;
    },
  },
});

export const { toggleFlashNotificationsMenu } = menuSlice.actions;

export default menuSlice.reducer;
