import React, { useState } from "react";

export default function Tab(props) {
  var tabs = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  function handleTabChange(index) {
    setSelectedTab(tabs[index]);
    props.setActiveTab(index + 1);
  }

  if (props.tab1) {
    return (
      <div className="d-flex" style={{ width: "300px" }}>
        <div
          className="tab"
          style={{ ...props.style, marginRight: "20px" }}
          onClick={props.onClick}
        >
          <span>{props.title || "Explore"}</span>
        </div>
        <div
          className="tab-sm"
          style={{ ...props.style, borderBottom: "4px #7b2cbf solid" }}
          onClick={props.onClick}
        >
          <span>{props.tab1 || "Tab"}</span>
        </div>
      </div>
    );
  } else if (props.tab2) {
    return (
      <div className="d-flex" style={{ width: "400px" }}>
        <div className="tab" style={{ ...props.style, marginRight: "20px" }}>
          <span>{props.title || "Explore"}</span>
        </div>
        {tabs.slice(0, tabs.length - 2).map((tab, index) => {
          if (tab.id === selectedTab.id) {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(index)}
                className="tab-sm"
                style={{ borderBottom: "4px #7b2cbf solid" }}
              >
                <span>{props.title || "Tab"}</span>
              </div>
            );
          } else {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(index)}
                className="tab-sm"
              >
                <span>{props.title || "Tab"}</span>
              </div>
            );
          }
        })}
      </div>
    );
  } else if (props.tab3) {
    return (
      <div className="d-flex" style={{ width: "550px" }}>
        <div
          className="tab"
          style={{ ...props.style, marginRight: "20px" }}
          onClick={props.onClick}
        >
          <span>{props.title || "Explore"}</span>
        </div>
        {tabs.slice(0, tabs.length - 1).map((tab, index) => {
          if (tab.id === selectedTab.id) {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(index)}
                className="tab-sm"
                style={{ borderBottom: "4px #7b2cbf solid" }}
              >
                <span>{props.title || "Tab"}</span>
              </div>
            );
          } else {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(index)}
                className="tab-sm"
              >
                <span>{props.title || "Tab"}</span>
              </div>
            );
          }
        })}
      </div>
    );
  } else if (props.tab4) {
    return (
      <div className="d-flex" style={{ width: "700px" }}>
        {/* <div
          className="tab"
          style={{ ...props.style, marginRight: "20px" }}
          onClick={props.onClick}
        >
          <span>{props.title || "Explore"}</span>
        </div> */}
        {tabs.slice(0, tabs.length).map((tab, index) => {
          if (tab.id === selectedTab.id) {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(index)}
                className="tab-sm b0-medium grey8"
                style={{ borderBottom: "4px solid #7B2CBF" }}
              >
                <span>{props.title || "Tab"}</span>
              </div>
            );
          } else {
            return (
              <div
                key={index}
                onClick={() => handleTabChange(index)}
                className="tab-sm b1 grey6"
              >
                <span>{props.title || "Tab"}</span>
              </div>
            );
          }
        })}
      </div>
    );
  } else if (props.tabSm) {
    return (
      <div
        className="tab-sm"
        style={{ ...props.style, borderBottom: "4px #7b2cbf solid" }}
        onClick={props.onClick}
      >
        <span>{props.title || "Tab"}</span>
      </div>
    );
  }
}
