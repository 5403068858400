import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Close } from "../../../assets/icons/X.svg";
import { ReactComponent as Arrow_left } from "../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as DotsThreeHorizontal } from "../../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as Fi_trash } from "../../../assets/icons/fi_trash.svg";
import { ReactComponent as PencilSimple } from "../../../assets/icons/PencilSimple.svg";
import Field from "../../sub-components/Field";
import Button from "../../sub-components/Button";
import { Table } from "../../Tables/Table";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { secure_instance } from "../../../axios/axios-config";
import { Menu, MenuItem } from "@mui/material";
import { StyledMenu } from "../../Menus/Menu";
import dayjs from "dayjs";
import Tabv2 from "../../sub-components/Tabv2";
import {
  decrementView,
  deleteSingleLifeEvents,
  getAllCategoriesData,
  handleCreateIdentifier,
  resetEventCategory,
  resetEventType,
  resetFormData,
  selectEventCategory,
  selectEventType,
  setCurrentRowEventsSlice,
  setEventsSideMenuTablePage,
  setSideMenuTableRowsPerPage,
  toggleEventMenuClose,
  toggleSideMenuTableLoading,
  updateCurrentScreen,
  updateLifeEvents,
} from "../../../redux/slices/eventsSlice";

const usersColumns = [
  {
    key: "name",
    name: "Full Name",
    accessor: (row) => `${row.first_name}  ${row.last_name}`,
    // minWidth: 300,
  },
  {
    key: "emai",
    name: "Email",
    accessor: (row) =>
      row.identifiers?.map(
        (identifier) => identifier.is_primary && identifier.email,
      ),
    // minWidth: 200,
  },
  {
    key: "_",
    name: "Job Title",
    // minWidth: 200,
  },
];

function CreateEventsNew() {
  const dispatch = useDispatch();

  const {
    eventType,
    events,
    eventCategory,
    formData,
    isEventSideMenuOpen,
    isEditEvents,
  } = useSelector((state) => state.eventsReducer);
  const { page, sideMenuTableLoading } = useSelector(
    (state) => state.eventsReducer.sideMenu.tableData,
  );

  // const { eventType } = useSelector((state) => state.eventsReducer);

  // const {
  //   isEventSideMenuOpen,
  //   showEditMenu,
  //   showAddMenu,
  //   eventCreationSuccess,
  //   eventCreationFailure,
  //   updateEventSuccess,
  //   updateEventFailure,
  //   formData,
  // } = useSelector((state) => state.eventsReducer);
  const tabs = [
    { id: 1, title: "Details" },
    { id: 2, title: "Users" },
    // Add more tabs as needed
  ];

  const [selectedTab, setSelectedTab] = useState({ id: 1 });
  const [activeTab, setActiveTab] = useState("details");

  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrlToUpload, setImageUrlToUpload] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentRowImage, setCurrentRowImage] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isTableRow, setIsTableRow] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [addedUsersSelected, setAddedUsersSelected] = useState([]); // users which have been added from dropdown and selected
  const [addedUsers, setAddedUsers] = useState([]); // users which have been added
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [usersToRemoveSelected, setUsersToRemoveSelected] = useState([]);
  const [usersToAddConfirmed, setUsersToAddConfirmed] = useState([]);
  const [usersToDeleteConfirmed, setUsersToDeleteConfirmed] = useState([]);
  const [addedUsersFromTeam, setAddedUsersFromTeam] = useState([]);
  const [isRowClicked, setIsRowClicked] = useState(false);
  // const [currentRow, setCurrentRow] = React.useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [editConfirmModal, setEditConfirmModal] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(false);
  const [show, setShow] = useState(false);
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [isEditEventCategory, setIsEditEventCategory] = useState(false);

  // console.log("addedUsers", addedUsers);

  const EditUsersData = () => {
    // if (
    //   Object.keys(formData).length > 0 &&
    //   formData.categories?.event_type === "shared"
    // ) {
    //   const currentUserProfiles = formData.life_event_mappings.map(
    //     (data) => data.user_profiles.user_id,
    //   );
    //   return usersData.filter(
    //     (data) => !currentUserProfiles.includes(data.user_id),
    //   );
    // } else {
    //   return usersData;
    // }
    // const filteredUsersData = usersData.filter(
    //   (data) => !addedUsers.includes(data.user_id),
    // );
    // const result = usersData.filter((c) =>
    //   addedUsers.some((s) => s.user_id !== c.user_id),
    // );

    // const filtereee = usersData?.filter((user) => !addedUsers.includes(user));
    // const filteredActiveUsers = usersData.filter(
    //   (user) => !addedUsers.includes(user.user_id),
    // );

    const secondUserIds = new Set(addedUsers.map((user) => user.user_id));
    const uniqueUsers = usersData.filter(
      (user) => !secondUserIds.has(user.user_id),
    );

    // console.log("usersData", usersData);
    // console.log("addedUsers", addedUsers);
    // console.log("filtereee", uniqueUsers);

    // const newArray = usersData.filter(
    //   (array22) =>
    //     !addedUsers.some((array11) => array11.user_id === array22.user_id),
    // );
    return uniqueUsers;
  };

  // const RowClickData = () => {
  //   return addedUsers.length > 0 && addedUsers;
  // };

  const isButtonActionMenu = Boolean(anchorEl);
  // console.log({ isButtonActionMenu });

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  // const isTabActive = (tab) => {
  //   return activeTab === tab;
  // };

  const handlePrevClickTest = () => {
    dispatch(decrementView());
    dispatch(resetEventCategory());
  };

  const getUsersList = async () => {
    try {
      dispatch(toggleSideMenuTableLoading());
      const request = await secure_instance.request({
        url: `v1/user_profile/?type=all`,
        method: "GET",
      });

      if (isRowClicked) {
        const newArray = request.data.filter(
          (array22) =>
            !addedUsers.some((array11) => array11.user_id === array22.user_id),
        );
        setUsersData(newArray);
      } else {
        setUsersData(request.data);
      }
      dispatch(toggleSideMenuTableLoading());
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteEvent = () => {
    handleThreeDotsClose();
    dispatch(deleteSingleLifeEvents(formData.id));
    dispatch(toggleEventMenuClose());
    dispatch(updateCurrentScreen(1));
  };

  const handleThreeDotsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  const handlePopUpMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal = () => setOpenModal(true);

  const eventSideMenuClosed = () => {
    dispatch(toggleEventMenuClose());
    dispatch(updateCurrentScreen(1));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    dispatch(setCurrentRowEventsSlice({ [name]: value }));
  };

  const handleDateChange = (name, date) => {
    dispatch(setCurrentRowEventsSlice({ [name]: date.toISOString() }));
  };

  const updateFormData = () => {
    const cloneFormData = {
      ...formData,
      img_url:
        formData?.img_url && imageUrlToUpload === null
          ? formData?.img_url
          : imageUrlToUpload,
    };
    // cloneFormData["life_event_mappings"] = userIds;
    setSideMenuTablePage(0);
    return cloneFormData;
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const cloneFormData = updateFormData();
    cloneFormData.category_id = formData?.category_id.id;
    // cloneFormData.categories = formData?.category_id;
    delete cloneFormData["categories"];
    if (!cloneFormData.img_url) {
      cloneFormData.img_url = ""; // Set img_url to an empty string if not already set
    }

    dispatch(
      updateLifeEvents(
        formData.id,
        cloneFormData,
        addedUsers.map((data) => ({ user_id: data.user_id })),
      ),
    );
    resetForm();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cloneFormData = updateFormData();
    cloneFormData.category_id = formData?.category_id.id;

    if (!cloneFormData.img_url) {
      cloneFormData.img_url = ""; // Set img_url to an empty string if not already set
    }

    dispatch(
      handleCreateIdentifier(
        cloneFormData,
        addedUsers.map((data) => ({ user_id: data.user_id })),
      ),
    );
    resetForm();
  };

  const resetForm = () => {
    dispatch(toggleEventMenuClose());
    dispatch(resetEventType());
    dispatch(resetEventCategory());
    dispatch(updateCurrentScreen(1));
    dispatch(resetFormData());
    setIsEditEventCategory(false);
  };

  const setSideMenuTablePage = (data) => {
    dispatch(setEventsSideMenuTablePage(data));
  };

  const setSideMenuRowsPerPage = (data) => {
    dispatch(setSideMenuTableRowsPerPage(data));
  };

  const handleToolbarAction = () => {
    // {
    //   showConfirmationModal
    //     ? setDisabled(addedUsers.length > 0 && addedUsers)
    //     : !isEditableState
    //     ? setDisabled(addedUsers.length > 0)
    //     : setDisabled(addedUsers.length === 0);
    // }

    const filteredUsersData = usersData.filter(
      (data) => !addedUsersSelected.includes(data),
    );
    setUsersData(filteredUsersData);
    setAddedUsers([...addedUsers, ...addedUsersSelected]);
    setAddedUsersSelected([]);
  };

  const handleClickAddedUsers = (user) => {
    const selectedIndex = addedUsersSelected.findIndex(
      (x) => x.user_id === user.user_id,
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(addedUsersSelected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(addedUsersSelected.slice(1));
    } else if (selectedIndex === addedUsersSelected.length - 1) {
      newSelected = newSelected.concat(addedUsersSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        addedUsersSelected.slice(0, selectedIndex),
        addedUsersSelected.slice(selectedIndex + 1),
      );
    }

    setAddedUsersSelected(newSelected);
  };

  const handleClickCheckbox = (user) => {
    const selectedIndex = usersToRemoveSelected.findIndex(
      (x) => x.user_id === user.user_id,
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(usersToRemoveSelected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(usersToRemoveSelected.slice(1));
    } else if (selectedIndex === usersToRemoveSelected.length - 1) {
      newSelected = newSelected.concat(usersToRemoveSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        usersToRemoveSelected.slice(0, selectedIndex),
        usersToRemoveSelected.slice(selectedIndex + 1),
      );
    }

    setUsersToRemoveSelected(newSelected);
  };

  const handleRemoveAddedUsers = () => {
    const filteredUsers = addedUsers.filter(
      (user) => !usersToRemoveSelected.includes(user),
    );
    setAddedUsers(filteredUsers);
    setUsersToRemoveSelected([]);
    setUsersData([...usersData, ...usersToRemoveSelected]);
  };

  // console.log({ usersData });
  // console.log(
  //   "currentUserProfiles",
  //   formData.life_event_mappings.map((data) => data.user_profiles.user_id),
  // );
  // console.log("addedUsers", addedUsers);
  // console.log("usersToRemoveSelected", usersToRemoveSelected);

  const handleRemoveFromTeam = () => {
    handleActionMenuClose();
    handleOpenModal();
  };

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };

  const getInputFieldsStyles = () => {
    return {
      background:
        showConfirmationModal || (!isEditAllowed && isEditEvents)
          ? "#F8F9FA"
          : "#FFFFFF",
      // border: "1px solid #DEE2E6",
      color:
        (showConfirmationModal || (!isEditAllowed && isEditEvents)) &&
        "#6C757D",
      pointerEvents:
        (showConfirmationModal || (!isEditAllowed && isEditEvents)) && "none",
    };
  };

  const getInputFieldHeadingTextColor = () => {
    if (showConfirmationModal || (!isEditAllowed && isEditEvents)) {
      return "grey6";
    } else {
      return "grey8";
    }
  };

  const RowClickData = () => {
    return addedUsers.length > 0 && addedUsers;
  };

  // function renderActionButtons() {
  //   if (usersToRemoveSelected.length > 0 && activeTab === "user") {
  //     return (
  //       <>
  //         <Button
  //           buttonFilledFull
  //           title="Remove"
  //           typography="b0-medium grey1"
  //           style={{ width: "107px", height: "40px", marginTop: "5px" }}
  //           onClick={handleRemoveAddedUsers}
  //         />
  //         <Button
  //           buttonHollowLg
  //           typography="s1 grey6"
  //           title="Cancel"
  //           onClick={() => setUsersToRemoveSelected([])}
  //         />
  //       </>
  //     );
  //   } else if (formData) {
  //     return (
  //       <>
  //         <Button
  //           buttonFilledFull
  //           typography="b1 grey1"
  //           title={showConfirmationModal ? "Confirm" : "Next"}
  //           disabled={disabled}
  //           style={{ width: "96px", height: "40px" }}
  //           onClick={handleNextButton}
  //         />
  //         <Button
  //           buttonHollowLg
  //           typography="s1 grey6"
  //           title="Cancel"
  //           onClick={handlePrevClickTest}
  //         />
  //       </>
  //     );
  //   } else if (editConfirmModal) {
  //     return (
  //       <>
  //         <Button
  //           buttonFilledFull
  //           typography="b1 grey1"
  //           title="Save Changes"
  //           disabled={disabled}
  //           style={{ width: "155px", height: "40px" }}
  //           onClick={handleEdit}
  //         />
  //         <Button
  //           buttonHollowLg
  //           typography="s1 grey6"
  //           title="Cancel"
  //           onClick={() => setEditConfirmModal(false)}
  //         />
  //       </>
  //     );
  //   }
  // }

  // function ThreeDotsMenu({ handleDelete }) {
  //   return (
  //     <div style={{ cursor: "pointer", width: "40px", height: "40px" }}>
  //       <DotsThreeHorizontal onClick={() => handleThreeDotsOpen()} />
  //       <StyledMenu
  //         id="demo-customized-menu"
  //         MenuListProps={{
  //           "aria-labelledby": "demo-customized-button",
  //         }}
  //         anchorEl={anchorEl}
  //         open={isButtonActionMenu}
  //         onClose={handleThreeDotsClose}
  //       >
  //         <MenuItem disableRipple onClick={handleDelete}>
  //           <Fi_trash
  //             stroke="#343A40"
  //             style={{ width: "16px", height: "16px" }}
  //           />
  //           <span
  //             className="s2 grey8 d-flex align-items-center"
  //             style={{ marginLeft: "8px" }}
  //           >
  //             Delete
  //           </span>
  //         </MenuItem>
  //       </StyledMenu>
  //     </div>
  //   );
  // }

  function PencilEditButton({ handleClickEdit }) {
    return (
      <div
        style={{ marginRight: "16px" }}
        onClick={() => setIsEditAllowed(!isEditAllowed)}
      >
        <PencilSimple
          style={{ width: "32px", height: "32px", cursor: "pointer" }}
          // onClick={console.log("PENCIL CLICK")}
        />
      </div>
    );
  }

  function renderActionButtons() {
    if (usersToRemoveSelected.length > 0 && activeTab === "user") {
      return (
        <>
          <Button
            buttonFilledFull
            title="Remove"
            typography="b0-medium grey1"
            style={{ width: "107px", height: "40px", marginTop: "5px" }}
            onClick={handleRemoveAddedUsers}
          />
          <Button
            buttonHollowLg
            typography="s1 grey6"
            title="Cancel"
            onClick={() => setUsersToRemoveSelected([])}
          />
        </>
      );
    } else if (isEditAllowed && showConfirmationModal) {
      return (
        <>
          <Button
            // buttonFilledFull
            // typography="b1 grey1"
            // title="Save Changes"
            // disabled={disabled}
            // style={{
            //   width: "100%",
            //   height: "40px",
            //   whiteSpace: "noWrap",
            //   padding: "0 50px",
            // }}
            buttonFilledLg
            typography="body1-medium grey0"
            title="Save Changes"
            // loading={loading}
            style={{
              backgroundColor: "#9300FF",
              whiteSpace: "noWrap",
            }}
            onClick={handleEdit}
          />
          <Button
            buttonHollowLg
            typography="s1 grey6"
            title="Cancel"
            onClick={() => setIsEditAllowed(false)}
          />
        </>
      );
    } else if (isEditAllowed) {
      return (
        <>
          <Button
            // buttonFilledFull
            // typography="b1 grey1"
            // title="Confirm"
            // disabled={disabled}
            // style={{ width: "155px", height: "40px" }}
            buttonFilledLg
            typography="body1-medium grey0"
            title="Confirm"
            // loading={loading}
            style={{
              backgroundColor: "#9300FF",
            }}
            onClick={() => setShowConfirmationModal(true)}
          />
          <Button
            buttonHollowLg
            typography="s1 grey6"
            title="Cancel"
            onClick={() => setIsEditAllowed(false)}
          />
        </>
      );
    } else if (isEditEvents) {
      return (
        <>
          <div style={{ cursor: "pointer", width: "40px", height: "40px" }}>
            <DotsThreeHorizontal onClick={handleThreeDotsOpen} />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={isButtonActionMenu}
              onClose={handleThreeDotsClose}
            >
              <MenuItem disableRipple onClick={() => setDeleteModal(true)}>
                <Fi_trash
                  stroke="#343A40"
                  style={{ width: "16px", height: "16px" }}
                />
                <span
                  className="s2 grey8 d-flex align-items-center"
                  style={{ marginLeft: "8px" }}
                >
                  Delete
                </span>
              </MenuItem>
            </StyledMenu>
          </div>
          <div
            style={{ marginRight: "16px" }}
            onClick={() => setIsEditAllowed(!isEditAllowed)}
          >
            <PencilSimple
              style={{ width: "32px", height: "32px", cursor: "pointer" }}
            />
          </div>
          {/* <PencilEditButton
          // handleClickEdit={() => setIsEditAllowed(!isEditAllowed)}
          /> */}
        </>
      );
    } else if (showConfirmationModal) {
      return (
        <>
          <Button
            // buttonFilledFull
            // typography="b1 grey1"
            // title="Confirm"
            // disabled={disabled}
            // style={{ width: "96px", height: "40px" }}
            buttonFilledLg
            typography="body1-medium grey0"
            title="Confirm"
            // loading={loading}
            style={{
              backgroundColor: "#9300FF",
            }}
            disabled={disabled}
            onClick={handleSubmit}
          />
          <Button
            buttonHollowLg
            typography="s1 grey6"
            title="Cancel"
            onClick={() => setShowConfirmationModal(false)}
          />
        </>
      );
    } else if (formData) {
      return (
        <>
          <Button
            // buttonFilledFull
            // typography="b1 grey1"
            // title="Next"
            // disabled={disabled}
            // style={{ width: "96px", height: "40px" }}
            buttonFilledLg
            typography="body1-medium grey0"
            title="Next"
            // loading={loading}
            style={{
              backgroundColor: "#9300FF",
            }}
            disabled={disabled}
            onClick={() => setShowConfirmationModal(true)}
          />
          <Button
            buttonHollowLg
            typography="s1 grey6"
            title="Cancel"
            onClick={handlePrevClickTest}
          />
        </>
      );
    } else if (!showConfirmationModal) {
      return (
        <>
          <div style={{ cursor: "pointer", width: "40px", height: "40px" }}>
            <DotsThreeHorizontal onClick={handleThreeDotsOpen} />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={isButtonActionMenu}
              onClose={handleThreeDotsClose}
            >
              <MenuItem disableRipple onClick={() => setDeleteModal(true)}>
                <Fi_trash
                  stroke="#343A40"
                  style={{ width: "16px", height: "16px" }}
                />
                <span
                  className="s2 grey8 d-flex align-items-center"
                  style={{ marginLeft: "8px" }}
                >
                  Delete
                </span>
              </MenuItem>
            </StyledMenu>
          </div>
          <PencilEditButton />
        </>
      );
    }
    // else if (showConfirmationModal && isEditEvents) {
    //   return (
    //     <>
    //       <Button
    //         buttonFilledFull
    //         typography="b1 grey1"
    //         title="Save Changes"
    //         disabled={disabled}
    //         style={{ width: "155px", height: "40px" }}
    //         onClick={handleEdit}
    //       />
    //       <Button
    //         buttonHollowLg
    //         typography="s1 grey6"
    //         title="Cancel"
    //         onClick={() => setEditConfirmModal(false)}
    //       />
    //     </>
    //   );
    // }
    else {
      return (
        <>
          <Button
            // buttonFilledFull
            // typography="b1 grey1"
            // title="Confirm"
            // disabled={disabled}
            // style={{ width: "155px", height: "40px" }}
            buttonFilledLg
            typography="body1-medium grey0"
            title="Confirm"
            // loading={loading}
            style={{
              backgroundColor: "#9300FF",
            }}
            onClick={() => setEditConfirmModal(true)}
          />
          <Button
            buttonHollowLg
            typography="s1 grey6"
            title="Cancel"
            // onClick={handleEditCancel}
          />
        </>
      );
    }
  }

  const renderIcon = () => {
    const commonIconProps = {
      stroke: "#343A40",
      strokeWidth: "2",
      style: {
        margin: "0px 20px 3px 0",
        width: "24px",
        cursor: "pointer",
      },
    };

    if (showConfirmationModal) {
      return (
        <Arrow_left
          {...commonIconProps}
          onClick={() => setShowConfirmationModal(false)}
        />
      );
    }

    if (formData && !isEditEvents) {
      return <Arrow_left {...commonIconProps} onClick={handlePrevClickTest} />;
    }

    if (deleteModal) {
      return (
        <Arrow_left
          {...commonIconProps}
          onClick={() => {
            setDeleteModal(false);
            handleThreeDotsClose();
          }}
        />
      );
    }

    return <Close {...commonIconProps} onClick={eventSideMenuClosed} />;
  };

  const handleSelectEvent = (event) => {
    dispatch(selectEventType(event));
    // setEventButtonDisabled(false);
  };

  const handleSelectEventCategory = (category) => {
    // dispatch(eventsActions.selectEventType(event));
    setIsEditEventCategory(true);
    dispatch(selectEventCategory(category));
    // setEventButtonDisabled(false);
  };

  const handleTeamTypeMenu = () => {
    setShow(!show);
  };
  const handleEventCategoryMenu = () => {
    setShowCategoryMenu(!showCategoryMenu);
  };
  const handleResetDropdowns = () => {
    if (show || showCategoryMenu) {
      setShow(false);
      setShowCategoryMenu(false);
    }
  };

  const handleTabChange = (index) => {
    // console.log(first)
    setSideMenuTablePage(1);
    setSelectedTab(tabs[index]);
    // Your tab change logic here
  };
  const hanldeResetStates = () => {
    setActiveTab("details");
    setLoadingImage(false);
    setImageUrlToUpload(null);
    setSelectedImage(null);
    setCurrentRowImage(null);
    setSearchValue("");
    setDisabled(true);
    setIsTableRow({});
    setAnchorEl(null);
    // setAddedUsersSelected([]); // users which have been added from dropdown and selected
    setAddedUsers([]); // users which have been added
    setShowConfirmationModal(false);
    setOpenModal(false);
    // setUsersData([]);
    // setUsersToRemoveSelected([]);
    // setUsersToAddConfirmed([]);
    // setUsersToDeleteConfirmed([]);
    // setAddedUsersFromTeam([]);
    setIsRowClicked(false);
    setDeleteModal(false);
    setEditConfirmModal(false);
    setIsEditAllowed(false);
  };

  // useEffect(() => {
  //   if (imageUrlToUpload !== null) {
  //     setLoadingImage(false);
  //     setDisabled(false);
  //   }
  // }, [imageUrlToUpload]);

  useEffect(() => {
    getUsersList();
    console.count("first------------------------");
    return () => {
      dispatch(resetFormData());
    };
  }, []);

  useEffect(() => {
    if (showConfirmationModal) {
      setUsersToAddConfirmed(
        addedUsers?.filter((user) => !addedUsersFromTeam.includes(user)),
      );
      setUsersToDeleteConfirmed(
        usersData.length > 0 &&
          usersData?.filter((user) => addedUsersFromTeam.includes(user)),
      );
    }
  }, [showConfirmationModal]);

  useEffect(() => {
    if (isEditEvents) {
      // setformData(formData);
      dispatch(setCurrentRowEventsSlice(formData));
      dispatch(selectEventType(formData.categories.event_type));
      setEditConfirmModal(true);
    } else {
      dispatch(setCurrentRowEventsSlice({}));
      // setformData({});
    }
  }, [isEditEvents]);

  // console.log("formDataformData", formData);
  // console.log("isEditAllowed", isEditAllowed);
  // console.log("showConfirmationModal", showConfirmationModal);

  useEffect(() => {
    if (formData && formData.life_event_mappings && addedUsers.length === 0) {
      // setIsEditView(true);
      setAddedUsers(
        formData.life_event_mappings.map((data) => data.user_profiles),
      );
    } else {
      addedUsers.length > 0 && addedUsers;
    }
  }, [formData]);

  useEffect(() => {
    if (!isEventSideMenuOpen) {
      hanldeResetStates();
    }
  }, [isEventSideMenuOpen]);

  useEffect(() => {
    dispatch(getAllCategoriesData());
  }, []);

  // console.log("isEditEvents", isEditEvents);
  // console.log("formData", formData);

  const personalEvents = events.filter(
    (event) => event.event_type === "personal",
  );
  const sharedEvents = events.filter((event) => event.event_type === "shared");

  return (
    <>
      <div onClick={() => handleResetDropdowns()}>
        {/* {deleteModal && (
            <>
              <div style={{ marginTop: "30px" }}>
                <p className="head6 grey8">
                  Delete the event{" "}
                  {formData.categories.event_type.charAt(0).toUpperCase() +
                    formData.categories.event_type.slice(1)}
                  ?
                </p>
              </div>
            </>
          )} */}

        {!deleteModal ? (
          <div>
            <div className="d-flex justify-content-between">
              <span
                className="head4 grey8 d-flex align-items-center"
                style={{ fontWeight: "400" }}
              >
                {renderIcon()}
                {isEditEvents && !deleteModal
                  ? "Edit Events"
                  : deleteModal
                    ? "Delete Event"
                    : "Create new Event"}
              </span>

              <div
                className="d-flex align-items-center flex-row-reverse"
                style={{ width: "fit-content" }}
              >
                {renderActionButtons()}
                {/* {formData &&
                (showConfirmationModal ? (
                  <>
                    <div>
                      <Button
                        buttonFilledFull
                        typography="b1 grey1"
                        title="Confirm"
                        disabled={disabled}
                        loading={loading}
                        style={{ width: "96px", height: "40px" }}
                        onClick={handleSubmit}
                      />
                    </div>
                    <div style={{ paddingRight: "15px" }}>
                      <Button
                        buttonHollowLg
                        typography="s1 grey6"
                        title="Cancel"
                        onClick={() => setShowConfirmationModal(false)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <Button
                        buttonFilledFull
                        typography="b1 grey1"
                        title="Next"
                        disabled={disabled}
                        style={{ width: "96px", height: "40px" }}
                        onClick={() => setShowConfirmationModal(true)}
                      />
                    </div>
                    <div>
                      <div style={{ paddingRight: "15px" }}>
                        <Button
                          buttonHollowLg
                          typography="s1 grey6"
                          title="Cancel"
                          onClick={handlePrevClickTest}
                        />
                      </div>
                    </div>
                  </>
                ))} */}
              </div>
            </div>

            {console.log("eventTypeeventType", eventType)}

            {/* {!showConfirmationModal && eventType === "shared" && (
              <div className="d-flex">
                <div
                  className="b0-medium d-flex justify-content-center"
                  style={{
                    borderBottom: isTabActive("details")
                      ? "4px solid #7B2CBF"
                      : "4px solid #E9ECEF",
                    padding: "8px",
                    width: "279px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => handleTabClick("details")}
                >
                  Details
                </div>
                <div
                  className="b0-medium d-flex justify-content-center"
                  style={{
                    borderBottom: isTabActive("user")
                      ? "4px solid #7B2CBF"
                      : "4px solid #E9ECEF",
                    padding: "8px",
                    width: "279px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => handleTabClick("user")}
                >
                  <div style={{ position: "relative" }}>
                    <span>User</span>
                    {addedUsers.length > 0 && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          // background: showConfirmationModal ? "#7B2CBF" : "#CED4DA",
                          background: showConfirmationModal
                            ? "#CED4DA"
                            : "#7B2CBF",
                          width: "23px",
                          height: "23px",
                          borderRadius: "50%",
                          position: "absolute",
                          right: "-22px",
                          top: "-10px",
                        }}
                      >
                        <span
                          className="overline"
                          style={{
                            color: "white",
                            position: "relative",
                            top: "1px",
                            left: "1px",
                          }}
                        >
                          {addedUsers.length}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )} */}

            {showConfirmationModal && (
              <>
                <div
                  style={{
                    borderTop: "1px solid #BFBFBF",
                    // width: "100%",
                    // marginLeft: "-48px",
                    // marginRight: "-48px",
                    margin: "32px -48px",
                  }}
                />
                <div className="head6-medium grey6"> Events Details</div>
              </>
            )}
            {eventType === "shared" && !showConfirmationModal && (
              <div
                className="mt-2"
                style={{
                  pointerEvents:
                    showConfirmationModal ||
                    (!isEditAllowed && isEditEvents && "none"),
                }}
              >
                <Tabv2
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onTabChange={handleTabChange}
                />
              </div>
            )}

            {/* {showConfirmationModal && formData && eventType === "personal" ? (
            <>
              <div style={{ marginTop: "30px" }}>
                <p className="head6 grey8">Confirm Event Creation</p>
                <p className="s1 grey8">Are you sure you want to continue?</p>
              </div>
            </>
          ) : deleteModal ? (
            <>
              <div style={{ marginTop: "30px" }}>
                <p className="head6 grey8">
                  Delete the event{" "}
                  {formData.categories.event_type.charAt(0).toUpperCase() +
                    formData.categories.event_type.slice(1)}
                  ?
                </p>
              </div>
            </>
          ) : formData && eventType === "personal" ? (
            <>
              <div style={{ marginTop: "30px" }}>
                <p className="head6 grey8">Event Details</p>
              </div>
            </>
          ) : editConfirmModal ? (
            <>
              <div style={{ marginTop: "30px" }}>
                <p className="head6 grey8">Confirm Changes</p>
                <p className="s1 grey8">Are you sure you want to continue?</p>
              </div>
            </>
          ) : (
            <>
              <div style={{ marginTop: "30px" }}>
                <p className="head6 grey8">Event Details</p>
              </div>
              </>
            )} */}

            {/* --------------------------------------------------------------------------------------------------------------------- */}
            {selectedTab.id === 1 && (
              // {activeTab === "details" && (
              <>
                <div style={{ marginTop: "20px" }}>
                  {/* <p className="head6 grey8">
                    {showConfirmationModal
                      ? "Confirm event creation"
                      : "Event Details"}
                  </p> */}
                  {/* <p className="s1 grey8">
                    {showConfirmationModal &&
                      "Are you sure you want to continue?"}
                  </p> */}
                </div>
                <div style={{ flexWrap: "nowrap" }}>
                  <div>
                    <div style={{ paddingBottom: "32px" }}>
                      <div>
                        <div
                          className={`head7-medium ${getInputFieldHeadingTextColor()}`}
                          style={{ marginBottom: "12px" }}
                        >
                          Title
                        </div>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Field
                            style={getInputFieldsStyles()}
                            // value={updateUser?.event_name ? formData?.event_name : formData.event_name}
                            // value={
                            //   eventType === "personal"
                            //     ? formData.event_name
                            //     : formData.event_name
                            // }
                            value={formData.event_name}
                            name="event_name"
                            onChange={handleChange}
                            disabled={showConfirmationModal}
                            // disabled={
                            //   editConfirmModal
                            //     ? true
                            //     : formData && eventType === "personal"
                            //     ? showConfirmationModal
                            //       ? true
                            //       : false
                            //     : !isEditableState
                            //     ? true
                            //     : false
                            // }
                            inputFieldFull
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ paddingBottom: "32px" }}>
                      <div>
                        <div
                          className={`head7-medium ${getInputFieldHeadingTextColor()}`}
                          style={{ marginBottom: "12px" }}
                        >
                          Event Type
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTeamTypeMenu();
                          }}
                          style={{
                            pointerEvents:
                              (showConfirmationModal ||
                                (!isEditAllowed && isEditEvents)) &&
                              "none",
                          }}
                          // onClick={(e) => e.stopPropagation()}
                        >
                          <Field
                            className="s1"
                            placeholder="Select Event Type"
                            inputFieldFullReadOnly={isRowClicked}
                            teamTypeDropdown={!isRowClicked}
                            value={eventType}
                            onClick={(value) =>
                              handleSelectEvent(value.toLowerCase())
                            }
                            // handleTeamTypeMenu={handleTeamTypeMenu}
                            disabled={showConfirmationModal}
                            show={show}
                            options={["Personal", "Shared"]}
                            // disabled={!isEditableState && isRowClicked}
                            style={{
                              ...getInputFieldsStyles(),
                              cursor: "pointer",
                            }}
                            readOnly
                            // style={{
                            //   color: isRowClicked
                            //     ? "rgba(73, 80, 87, 0.5)"
                            //     : "#495057",
                            //   cursor: "pointer",
                            //   background:
                            //     isEditEvents || !showConfirmationModal
                            //       ? "#FFFFFF"
                            //       : "#F8F9FA",
                            // }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ paddingBottom: "32px" }}>
                      <div>
                        <div
                          className={`head7-medium ${getInputFieldHeadingTextColor()}`}
                          style={{
                            marginBottom: "12px",
                            pointerEvents:
                              (showConfirmationModal ||
                                (!isEditAllowed && isEditEvents)) &&
                              "none",
                          }}
                        >
                          Event Category
                        </div>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEventCategoryMenu();
                          }}
                          // onClick={(e) => e.stopPropagation()}
                        >
                          {/* <Field
                            className="s1"
                            placeholder="Select Event Category"
                            inputFieldFullReadOnly={isRowClicked}
                            teamTypeDropdown={!isRowClicked}
                            value={eventCategory}
                            onClick={(value) =>
                              handleSelectEventCategory(value)
                            }
                            // handleTeamTypeMenu={handleTeamTypeMenu}
                            show={showCategoryMenu}
                            options={
                              eventType === "personal"
                                ? personalEvents.map((event) => event.category)
                                : eventType === "shared"
                                  ? sharedEvents.map((event) => event.category)
                                  : []
                            }
                            // disabled={!isEditableState && isRowClicked}
                            style={{
                              color: isRowClicked
                                ? "rgba(73, 80, 87, 0.5)"
                                : "#495057",
                              cursor: "pointer",
                              background:
                                !isEditAllowed && !isEditEvents
                                  ? "#FFFFFF"
                                  : "#F8F9FA",
                            }}
                          /> */}
                          <Field
                            // inputBackground={
                            //   !isEditAllowed && !isEditEvents
                            //     ? "#FFFFFF"
                            //     : "#F8F9FA"
                            // }
                            inputBackground={
                              showConfirmationModal ||
                              (!isEditAllowed && isEditEvents)
                                ? "#F8F9FA"
                                : "#FFFFFF"
                              // (!isEditAllowed && !isEditEvents) ||
                              // !showConfirmationModal
                              //   ? "#FFFFFF"
                              //   : "#F8F9FA"
                            }
                            fieldDropdownFull
                            placeholder="Select Event Category"
                            typography={`s1 ${
                              showConfirmationModal ||
                              (!isEditAllowed && isEditEvents)
                                ? "grey6-placeHolderColor"
                                : "grey9-placeHolderColor"
                            } ${
                              !isEditAllowed &&
                              !isEditEvents &&
                              !showConfirmationModal
                                ? "grey8"
                                : "grey6"
                            }`}
                            disabled={showConfirmationModal}
                            id="attribute"
                            objectValueToRender={"category"}
                            // rowId={rowId}
                            // style={{ pointerEvents: !isEditAllowed && "none" }}
                            // options={isEditAllowed ? Object.keys(attributeSchema) : []}
                            // options={["one", "two"]}
                            options={
                              eventType === "personal"
                                ? personalEvents.map((event) => event)
                                : eventType === "shared"
                                  ? sharedEvents.map((event) => event)
                                  : []
                            }
                            style={getInputFieldsStyles()}
                            readOnly
                            value={
                              isEditEvents && !isEditEventCategory
                                ? formData?.categories?.category
                                : eventCategory?.category
                            }
                            // handleDropDownMenu={handleDropDownMenu}
                            // showMultipleDropDown
                            showDropDown={showCategoryMenu}
                            onClick={(value) =>
                              handleSelectEventCategory(value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className={`head7-medium ${getInputFieldHeadingTextColor()}`}
                      >
                        Event Description
                      </div>
                    </div>
                    <div style={{ paddingBottom: "32px", paddingTop: "12px" }}>
                      <div>
                        <Field
                          // style={{
                          //   background: "#FFFFFF",
                          //   border: "1px solid #DEE2E6",
                          // }}
                          style={getInputFieldsStyles()}
                          name="description"
                          value={
                            eventType === "personal"
                              ? formData.description
                              : formData.description
                          }
                          // value={formData?.description ? formData && eventType === "personal" ?  formData.description : formData.description : formData.description}
                          onChange={handleChange}
                          disabled={showConfirmationModal}
                          // disabled={
                          //   editConfirmModal
                          //     ? true
                          //     : formData && eventType === "personal"
                          //     ? showConfirmationModal
                          //       ? true
                          //       : false
                          //     : !isEditableState
                          //     ? true
                          //     : false
                          // }
                          inputFieldFull
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "2px solid #E9ECEF",
                    borderColor: "#E9ECEF",
                  }}
                >
                  <div className="w-100 mr-2">
                    <div
                      className={`head7-medium ${getInputFieldHeadingTextColor()}`}
                      style={{ paddingBottom: "10px" }}
                    >
                      Start Date
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Start Date"
                          name="start_date"
                          value={
                            formData && formData?.start_date === null
                              ? null
                              : dayjs(formData?.start_date)
                            // eventType === "personal"
                            //   ? dayjs(formData?.start_date)
                            //   : dayjs(formData?.start_date)
                          }
                          onChange={(date) =>
                            handleDateChange("start_date", date)
                          }
                          disabled={
                            showConfirmationModal ||
                            (!isEditAllowed && isEditEvents)
                          }
                          // defaultValue={}
                          sx={{ width: "90%" }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div className="w-100 mb-4">
                    <div
                      className={`head7-medium ${getInputFieldHeadingTextColor()}`}
                      style={{ paddingBottom: "10px" }}
                    >
                      End Date
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="End Date"
                          name="end_date"
                          value={
                            formData && formData?.end_date === null
                              ? null
                              : dayjs(formData?.end_date)
                            // formData && eventType === "personal"
                            //   ? dayjs(formData?.end_date)
                            //   : dayjs(formData?.end_date)
                          }
                          onChange={(date) =>
                            handleDateChange("end_date", date)
                          }
                          disabled={
                            showConfirmationModal ||
                            (!isEditAllowed && isEditEvents)
                          }
                          sx={{ width: "90%" }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>

                {eventType === "shared" && (
                  <div
                    style={{
                      pointerEvents:
                        (showConfirmationModal ||
                          (!isEditAllowed && isEditEvents)) &&
                        "none",
                    }}
                  >
                    <div
                      className={`head7-medium mt-3 ${getInputFieldHeadingTextColor()}`}
                    >
                      {`${
                        showConfirmationModal
                          ? "Users"
                          : "Add Users to the Team"
                      }`}
                    </div>
                    <div
                      // className="events-menu-container"
                      style={{
                        marginBottom: "20px",
                        marginTop: "20px",
                        pointerEvents:
                          (showConfirmationModal ||
                            (!isEditAllowed && isEditEvents)) &&
                          "none",
                      }}
                    >
                      <Field
                        inputFieldFullExpand
                        placeholder="Search"
                        detectedUserIcon
                        // style={{
                        //   background: "#FFFFFF",
                        //   height: "48px",
                        //   borderRadius: "10px",
                        //   border: "1px solid #e9ecef",
                        // }}
                        style={{
                          border: "1px solid #DEE2E6",
                          background: "#FFFFFF",
                          // background:
                          //   showConfirmationModal ||
                          //   (!isEditAllowed && isEditEvents)
                          //     ? "#F8F9FA"
                          //     : "#FFFFFF",
                          borderRadius: "10px",
                        }}
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        inputStyle={{
                          color: "#6C757D",
                          height: "48px",
                          background: "#FFFFFF",
                          // showConfirmationModal ||
                          // (!isEditAllowed && isEditEvents)
                          //   ? "#F8F9FA"
                          //   : "#FFFFFF",
                        }}
                        disabled={showConfirmationModal}
                      />
                    </div>
                    {showConfirmationModal ? (
                      <Table
                        backgroundColor="#FFFFFF"
                        customRowPerPage={usersData.length}
                        hidePagination
                        data={usersToAddConfirmed}
                        columns={usersColumns}
                        page={page}
                        setTablePage={setSideMenuTablePage}
                        displayAvatar
                        headingColor="grey6"
                        tableTextColor="grey6"
                        // checkboxRequired={usersData.length > 0}
                        // checkboxSize="24px"
                      />
                    ) : (
                      <Table
                        // backgroundColor={
                        //   !isEditAllowed && isEditEvents ? "#F8F9FA" : "#FFFFFF"
                        // }
                        backgroundColor={"#FFFFFF"}
                        headingColor={
                          !isEditAllowed && isEditEvents ? "grey6" : "grey9"
                        }
                        tableTextColor={
                          !isEditAllowed && isEditEvents ? "grey6" : "grey9"
                        }
                        data={EditUsersData()}
                        columns={usersColumns}
                        loading={sideMenuTableLoading}
                        page={page}
                        inputValue={searchValue}
                        setTablePage={setSideMenuTablePage}
                        selected={addedUsersSelected}
                        setSelected={setAddedUsersSelected}
                        toolbarText={["Add Selected Users", "Add All Users"]}
                        handleToolbarAction={handleToolbarAction}
                        checkboxSize="24px"
                        setIsTableRow={setIsTableRow}
                        isTableRow={isTableRow}
                        handleOpen={handleOpenModal}
                        handleClickAddedUsers={handleClickAddedUsers}
                        handleRemoveFromTeam={handleRemoveFromTeam}
                        handlePopUpMenu={handlePopUpMenu}
                        setRowsPerPage={setSideMenuRowsPerPage}
                        anchorEl={anchorEl}
                        checkboxRequired={usersData.length > 0}
                        columnIndexToRenderIcon={1}
                        displayAvatar
                      />
                    )}
                  </div>
                )}
              </>
            )}

            {selectedTab.id === 2 && (
              // {activeTab === "user" && (
              <div
                style={{
                  pointerEvents:
                    (showConfirmationModal ||
                      (!isEditAllowed && isEditEvents)) &&
                    "none",
                }}
              >
                {/* {showConfirmationModal ? (
                        <div style={{ marginTop: "30px" }}>
                          <p className="head6 grey8">Added Users</p>
                        </div>
                      ) : ( */}
                <div style={{ marginTop: "30px" }}>
                  <p className="head6 grey8">Remove Users from event</p>
                </div>
                {/* )} */}
                <div
                  className="teams-menu-container"
                  style={{
                    position: "relative",
                    marginBottom: "20px",
                    background: "#F8F9FA",
                  }}
                >
                  {/* {!showConfirmationModal ? (
                          <Field
                            inputFieldFullExpand
                            placeholder="Search"
                            detectedUserIcon
                            style={{
                              background: "#FFFFFF",
                              height: "48px",
                              borderRadius: "10px",
                            }}
                            value={searchValue}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                            }}
                            inputStyle={{
                              color: "#6C757D",
                              border: "none",
                              height: "48px",
                              background: "#FFFFFF",
                            }}
                          />
                        ) : ( */}
                  <Field
                    inputFieldFullExpand
                    placeholder="Search"
                    detectedUserIcon
                    style={{
                      background: "#FFFFFF",
                      // showConfirmationModal ||
                      // (!isEditAllowed && isEditEvents)
                      //   ? "#F8F9FA"

                      height: "48px",
                      borderRadius: "10px",
                    }}
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    inputStyle={{
                      color: "#6C757D",
                      border: "none",
                      height: "48px",
                      background: "#FFFFFF",
                      // background:
                      // showConfirmationModal ||
                      // (!isEditAllowed && isEditEvents)
                      //   ? "#F8F9FA"
                      //   : "#FFFFFF",
                    }}
                  />
                  {/* )} */}
                </div>

                <Table
                  // backgroundColor={
                  //   !isEditAllowed && isEditEvents ? "#F8F9FA" : "#FFFFFF"
                  // }
                  backgroundColor={"#FFFFFF"}
                  data={RowClickData()}
                  inputValue={searchValue}
                  loading={sideMenuTableLoading}
                  columns={usersColumns}
                  selected={usersToRemoveSelected}
                  page={page}
                  handleToolbarAction={handleRemoveAddedUsers}
                  setSelected={setUsersToRemoveSelected}
                  handleClickAddedUsers={handleClickCheckbox}
                  setTablePage={setSideMenuTablePage}
                  addTeamsManual
                  checkboxSize="24px"
                  toolbarText={["Remove Selected Users", "Remove All Users"]}
                  emptyState="User not found"
                  // disableToolbar
                  checkboxRequired
                  displayAvatar
                />
              </div>
            )}
          </div>
        ) : (
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <span className="head5 grey8" style={{ fontWeight: "400" }}>
                {renderIcon()}
                {"Delete Event"}
              </span>

              <div
                className="d-flex align-items-center flex-row-reverse"
                style={{ width: "fit-content" }}
              >
                <>
                  <Button
                    // buttonFilledFull
                    // typography="b1 grey1"
                    // title="Confirm"
                    // loading={loading}
                    // style={{ width: "96px", height: "40px" }}
                    buttonFilledLg
                    typography="body1-medium grey0"
                    title="Confirm"
                    // loading={loading}
                    style={{
                      backgroundColor: "#9300FF",
                    }}
                    onClick={handleDeleteEvent}
                  />
                  <Button
                    buttonHollowLg
                    typography="s1 grey6"
                    title="Cancel"
                    // onClick={handleDeleteCancel}
                  />
                </>{" "}
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <p className="head6 grey8">
                Delete the event{" "}
                {formData.categories.category.charAt(0).toUpperCase() +
                  formData.categories.category.slice(1)}
                ?
              </p>
            </div>

            <div>
              <p className="s1 grey8">
                {`You are about to delete ${formData.event_name} permanently . Are you sure you want to continue?`}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CreateEventsNew;
