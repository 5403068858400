import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../../../components/Tables/Table";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/mi_delete.svg";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { capitalizeFirstLetter } from "../../../../utilities/Utils";
import {
  fetchDepartmentsData,
  toggleDepartmentsSideMenu,
  deleteDepartmentCall,
  saveFetchDepartmentsData,
  setTablePage,
} from "../../../../redux/slices/departmentSlice";
import DepartmentsMenu from "./DepartmentsMenu";

export default function Departments({ inputValue }) {
  const dispatch = useDispatch();
  const { showEditMenu } = useSelector((state) => state.eventsReducer);
  const { isMultipleRowsSelected, data, tableLoading } = useSelector(
    (state) => state.eventsReducer.tableData,
  );

  const { isSideMenuOpen } = useSelector(
    (state) => state.departmentsReducer.sideMenu,
  );
  const { departmentsData } = useSelector((state) => state.departmentsReducer);
  const { page } = useSelector((state) => state.departmentsReducer.tableData);

  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentrow, setCurrentrow] = useState({});
  const [selected, setSelected] = useState([]);

  const columns = [
    {
      key: "name",
      name: "Title",
      // minWidth: 210,
    },
    {
      key: "department_email",
      name: "Email",
      // minWidth: 261,
    },
    {
      key: "hod",
      name: "Head of Department",
      accessor: (row) => (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginLeft: "16px" }}
        >
          {row?.hod_profile?.first_name
            ? capitalizeFirstLetter(row.hod_profile.first_name)
            : "No HOD"}
        </div>
      ),
      // minWidth: 241,
    },
    {
      key: "members",
      name: "Members",
      accessor: (row) => {
        // Gather users from all teams
        const allUsers = row.teams.reduce((acc, team) => {
          if (team.users && team.users.length > 0) {
            return acc.concat(team.users);
          }
          return acc;
        }, []);

        // Calculate the total number of users
        const totalUsers = allUsers.length;

        // Get the first three users to display
        const usersToDisplay = allUsers.slice(0, 3);
        return (
          <>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ marginLeft: "16px" }}
            >
              {usersToDisplay.length > 0 ? (
                usersToDisplay.map((member, index) => {
                  return member.image_url !== "" ? (
                    <img
                      className="event-avatar-image"
                      style={{ marginLeft: index !== 0 ? "-16px" : "0" }}
                      src={member.image_url}
                      alt={`${member.first_name} ${member.last_name}`}
                      key={member.id}
                    />
                  ) : (
                    <div
                      className="event-avatar"
                      style={{ marginLeft: index !== 0 ? "-16px" : "0" }}
                      key={member.id}
                    >
                      {member.first_name.charAt(0).toUpperCase()}
                      {member.last_name.charAt(0).toUpperCase()}
                    </div>
                  );
                })
              ) : (
                <span className="body1 grey6">0</span>
              )}
            </div>
            {totalUsers > 3 && (
              <span className="ml-2 body1 grey6">
                {"+"}
                {totalUsers - 3}
              </span>
            )}
          </>
        );
      },
      // minWidth: 220,
    },
  ];

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCurrentRow = (row) => {
    setCurrentrow(row);
    dispatch(toggleDepartmentsSideMenu());
  };

  const handleTablePage = (data) => {
    dispatch(setTablePage(data));
  };

  const deleteDepartment = () => {
    const teamsDataCloned = departmentsData.map((a) => ({ ...a }));
    const toBeDeleted = isSideMenuOpen
      ? { departments: [{ id: currentrow.id }] }
      : { departments: selected.map((obj) => ({ id: obj.id })) };

    dispatch(deleteDepartmentCall(toBeDeleted));
    // .unwrap()
    // .then((payload) => console.log("fulfilled", payload))
    // .catch((error) => console.error("rejected", error));
    handleCloseModal();

    const filteredDepartments = isSideMenuOpen
      ? departmentsData.filter((obj) => obj.id !== currentrow.id)
      : teamsDataCloned.filter(
          (obj) => !selected.some((sel) => sel.id === obj.id),
        );

    dispatch(saveFetchDepartmentsData(filteredDepartments));
    isSideMenuOpen ? dispatch(toggleDepartmentsSideMenu()) : null;
    setSelected([]);
  };

  useEffect(() => {
    dispatch(fetchDepartmentsData());
  }, []);

  console.log("currentrow -> ", currentrow);

  return (
    <>
      <DeleteModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        deleteEvent={deleteDepartment}
        text={"Delete Department"}
      />

      <DepartmentsMenu
        currentRowToEdit={currentrow}
        deleteDepartment={handleOpenModal}
        setCurrentRowToEdit={setCurrentrow}
      />

      <Table
        data={departmentsData}
        headerBackgroundColor="#F7FBFF"
        columns={columns}
        loading={tableLoading && !isSideMenuOpen && !showEditMenu}
        page={page}
        inputValue={inputValue}
        setTablePage={handleTablePage}
        selected={selected}
        setSelected={setSelected}
        sortBy="id"
        handleTableRowClick={handleCurrentRow}
        anchorEl={anchorEl}
        handleToolbarAction={handleOpenModal}
        isMultipleRowsSelected={isMultipleRowsSelected}
        toolbarText={["Delete", "Delete"]}
        toolbarImage={<DeleteIcon />}
        checkboxSize="24px"
        checkboxRequired
      />
    </>
  );
}
