import React from "react";
import { useHistory } from "react-router";
import Button from "../../components/sub-components/Button";
import Footer from "../../components/sub-components/Footer";
import Header from "./Login/Header";

export default function Error404() {
  const history = useHistory();

  const handleContinue = () => {
    history.goBack();
  };

  return (
    <>
      <div className="signup">
        <Header />

        <div
          className="d-flex flex-column align-items-center"
          style={{ textAlign: "center" }}
        >
          <div className="head3 not-found-header" style={{ color: "#7B2CBF" }}>
            Error 404
          </div>
          <div
            className="head6"
            style={{ paddingTop: "30px", paddingBottom: "40px" }}
          >
            Oops! We can’t find the page you’re looking for.
          </div>

          <div className="col-lg-5">
            <Button
              buttonFilledFull
              title="Continue"
              onClick={handleContinue}
            />
          </div>
        </div>
      </div>
      <div
        className="col-lg-12 d-flex flex-row justify-content-center align-items-center"
        style={{ gap: "43.5px", marginTop: "180px" }}
      >
        <Footer />
      </div>
    </>
  );
}
