import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/sub-components/Button";
import Footer from "../../../components/sub-components/Footer";
import useWindowDimensions from "../../../components/WindowSize/WindowDimensions";
import Header from "../Login/Header";
import { getCookie } from "../../../utilities/Utils";
import { resendActivationLink } from "../../../redux/slices/authSlice";

export default function ActivationPending() {
  const dispatch = useDispatch();

  const handleResendLink = () => {
    dispatch(resendActivationLink(getCookie("email")));
  };

  const mediaQuery = useWindowDimensions();

  return (
    <>
      <div className="signup">
        <Header />

        {mediaQuery.width <= 425 && (
          <div
            className="row mt-5"
            style={{ minWidth: "280px", width: "100%" }}
          >
            <div style={{ borderTop: "1px dashed #CED4DA", width: "100%" }} />
          </div>
        )}

        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ textAlign: "center" }}
        >
          <div className="head3 primary-color signup-flow-headers">
            Activation Pending
          </div>
          <div
            className="head6 grey8"
            style={{ paddingTop: "15px", paddingBottom: "60px" }}
          >
            Please check your email and click the link in it to activate your
            account.
          </div>

          <div className="row w-100">
            <div className="col-lg-12">
              <Button
                style={{ maxWidth: "560px", margin: "0 auto" }}
                buttonFilledFull
                title="Resend Link"
                onClick={handleResendLink}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-lg-12 d-flex flex-row justify-content-center align-items-center"
        style={{ gap: "43.5px", marginTop: "220px" }}
      >
        <Footer />
      </div>
    </>
  );
}
