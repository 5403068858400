import React from "react";
import BarGraph from "./BarGraph";
import { capitalizeFirstLetter } from "../../utilities/Utils";

const dummyBarData = [
  {
    country: "AD",
    "hot dog": 164,
    "hot dogColor": "hsl(127, 70%, 50%)",
    burger: 166,
    burgerColor: "hsl(101, 70%, 50%)",
    sandwich: 37,
    sandwichColor: "hsl(245, 70%, 50%)",
    kebab: 71,
    kebabColor: "hsl(258, 70%, 50%)",
    fries: 11,
    friesColor: "hsl(112, 70%, 50%)",
    donut: 150,
    donutColor: "hsl(317, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 79,
    "hot dogColor": "hsl(24, 70%, 50%)",
    burger: 198,
    burgerColor: "hsl(247, 70%, 50%)",
    sandwich: 14,
    sandwichColor: "hsl(305, 70%, 50%)",
    kebab: 101,
    kebabColor: "hsl(265, 70%, 50%)",
    fries: 153,
    friesColor: "hsl(271, 70%, 50%)",
    donut: 130,
    donutColor: "hsl(338, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 163,
    "hot dogColor": "hsl(10, 70%, 50%)",
    burger: 0,
    burgerColor: "hsl(330, 70%, 50%)",
    sandwich: 45,
    sandwichColor: "hsl(168, 70%, 50%)",
    kebab: 122,
    kebabColor: "hsl(106, 70%, 50%)",
    fries: 61,
    friesColor: "hsl(87, 70%, 50%)",
    donut: 24,
    donutColor: "hsl(77, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 11,
    "hot dogColor": "hsl(324, 70%, 50%)",
    burger: 116,
    burgerColor: "hsl(308, 70%, 50%)",
    sandwich: 133,
    sandwichColor: "hsl(68, 70%, 50%)",
    kebab: 97,
    kebabColor: "hsl(24, 70%, 50%)",
    fries: 149,
    friesColor: "hsl(107, 70%, 50%)",
    donut: 94,
    donutColor: "hsl(294, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 183,
    "hot dogColor": "hsl(61, 70%, 50%)",
    burger: 5,
    burgerColor: "hsl(207, 70%, 50%)",
    sandwich: 135,
    sandwichColor: "hsl(331, 70%, 50%)",
    kebab: 57,
    kebabColor: "hsl(242, 70%, 50%)",
    fries: 60,
    friesColor: "hsl(168, 70%, 50%)",
    donut: 142,
    donutColor: "hsl(56, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 84,
    "hot dogColor": "hsl(84, 70%, 50%)",
    burger: 142,
    burgerColor: "hsl(233, 70%, 50%)",
    sandwich: 54,
    sandwichColor: "hsl(299, 70%, 50%)",
    kebab: 83,
    kebabColor: "hsl(233, 70%, 50%)",
    fries: 82,
    friesColor: "hsl(157, 70%, 50%)",
    donut: 146,
    donutColor: "hsl(86, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 29,
    "hot dogColor": "hsl(186, 70%, 50%)",
    burger: 9,
    burgerColor: "hsl(35, 70%, 50%)",
    sandwich: 44,
    sandwichColor: "hsl(348, 70%, 50%)",
    kebab: 148,
    kebabColor: "hsl(107, 70%, 50%)",
    fries: 97,
    friesColor: "hsl(107, 70%, 50%)",
    donut: 11,
    donutColor: "hsl(74, 70%, 50%)",
  },
];

const DashboardOverviewGraphs = ({
  icon,
  graphType,
  quantifyText,
  time,
  bottomText,
  graph,
  pieChart,
}) => {
  return (
    <div className="key-result-cards-container">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          {icon}

          <div className="d-flex flex-column justify-content-between ml-3">
            <span className="d-flex head6-medium">{graphType}</span>
            <span
              className="d-flex caption1 grey6"
              style={{ whiteSpace: "pre" }}
            >
              {quantifyText}
            </span>
          </div>
        </div>

        <div className="caption1 grey6">{time}</div>
      </div>
      <div className="row">
        <div className="body2 grey8 col-lg-6 d-flex align-items-end">
          {pieChart ? (
            // justify-content-between
            // grid-gap:10px for longer text
            <div className="ml-1 d-flex flex-wrap">
              {bottomText.map((text, index) => {
                return (
                  <div
                    //
                    className="d-flex align-items-center"
                    key={index}
                    style={{ minWidth: "60px" }}
                  >
                    <span
                      className="d-flex align-items-center"
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "20px",
                        background: "black",
                        marginRight: "6px",
                      }}
                    />
                    {capitalizeFirstLetter(text)}
                  </div>
                );
              })}
            </div>
          ) : (
            bottomText
          )}
        </div>
        {/* justify-content-end */}
        <div className="col-lg-6">
          {/* <BarGraph /> */}
          {/* {console.log(graph)} */}
          {graph}
        </div>
      </div>
    </div>
  );
};

export default DashboardOverviewGraphs;
