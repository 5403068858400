import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function CustomizedSnackbars({ open, message, onClose, severity }) {
  // "success" | "error" | "warning" | "info"
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  // const handleClick = (newState) => () => {
  //   setState({ ...newState, open: true });
  // };

  // const handleClose = () => {
  //   setState({ ...state, open: false });
  // };
  // const handleClick = () => {
  //   setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };

  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={onClose}
          // severity={type}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {/* {title} */}
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export { CustomizedSnackbars as Snackbar };
