import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isNewNotificationsReceived: false,
  isFlashMenuOpen: false,
  data: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    toggleNewNotifications(state, action) {
      state.isNewNotificationsReceived = action.payload;
    },
    setFormData(state, action) {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
  },
});

export const { toggleNewNotifications, setFormData } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
