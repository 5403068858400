import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { ReactComponent as CaretDown } from "../../assets/icons/CaretDown.svg";

const CustomSelect = ({
  items,
  value,
  onChange,
  label,
  error,
  helperText,
  disabled,
  multiple,
  menuMaxHeight,
  displayEmpty,
  iconComponent,
  customMenuStyles = {},
  useCustomMenu,
  customSelectPlaceholder,
  mapItemValueAsObject,
}) => {
  const DefaultIcon = (props) => (
    <CaretDown
      {...props}
      style={{
        position: "absolute",
        right: "8px",
        top: "50%",
        transform: "translateY(-50%)",
      }}
      stroke="#7B2CBF"
      strokeWidth="1.5"
    />
  );

  // Helper function to find the current selected item
  const findSelectedItem = (currentValue) => {
    return items.find((item) => item.value === currentValue);
  };
  console.log("valuevalue>>>>", value);
  return (
    <FormControl error={error} disabled={disabled} fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        displayEmpty={displayEmpty}
        value={value ?? ""}
        onChange={(e) => {
          // Pass the raw value to maintain consistency
          onChange(e);
        }}
        label={label}
        multiple={multiple}
        renderValue={(selected) => {
          if (customSelectPlaceholder) {
            return (
              <div className="d-flex mr-2"> {customSelectPlaceholder}</div>
            );
          }
          if (multiple) {
            if (selected?.length === 0) {
              return <em>Search</em>;
            }
            return selected
              .map((selectedValue) => {
                console.log("selectedValue", selectedValue);
                const selectedItem = items.find(
                  (item) => item.value === selectedValue.value,
                );
                return selectedItem ? selectedItem.label : selectedValue;
              })
              .join(", ");
          }
          if (selected === "" && displayEmpty) {
            return <em>Search</em>;
          }
          const selectedItem = findSelectedItem(selected);
          return selectedItem ? <div>{selectedItem.label}</div> : "";
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: menuMaxHeight || null,
              ...customMenuStyles,
            },
            sx: {
              "& .MuiMenuItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#F4E6FF",
                  color: "#7B2CBF",
                },
                "&:hover": {
                  backgroundColor: "#eedcfc",
                  color: "#7B2CBF",
                },
              },
            },
          },
          getContentAnchorEl: null,
        }}
        IconComponent={iconComponent || DefaultIcon}
        sx={{
          ...(useCustomMenu
            ? {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#7B2CBF",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#7B2CBF",
                  borderWidth: "0.15rem",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#7B2CBF",
                },

                paddingRight: "16px",
                background: "#FFFFFF",
                borderRadius: "10px",
                height: "37px",
                color: "#7B2CBF",
              }
            : {
                borderRadius: "10px",
                height: "48px",
              }),
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomSelect;
