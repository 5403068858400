import React from "react";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Visa } from "../../assets/icons/Visa.svg";
import { ReactComponent as Mastercard } from "../../assets/icons/Mastercard.svg";
import { ReactComponent as Bullets } from "../../assets/icons/Bullets.svg";
import { ReactComponent as Trash } from "../../assets/icons/fi_trash.svg";
import { Table } from "../Tables/Table";
import {
  IsAddNewCardInStore,
  detachPaymentMethodInStore,
  handleSideMenu,
  makeDefaultPaymentMethod,
  setTablePageInBillingStore,
} from "../../redux/slices/billingSlice";

const PaymentCards = () => {
  const { isSideMenu, stripeCardsList, defaultPaymentMethod } = useSelector(
    (state) => state.billingsReducer,
  );
  const { page } = useSelector((state) => state.billingsReducer.tableData);

  const dispatch = useDispatch();

  const generateBullets = (index) => {
    return (
      // THIS NEEDS TO BE RESPONSIVE
      <div style={{ marginRight: "3px" }} key={index}>
        <Bullets style={{ width: "6px", height: "6px", marginRight: "2px" }} />
        <Bullets style={{ width: "6px", height: "6px", marginRight: "2px" }} />
        <Bullets style={{ width: "6px", height: "6px", marginRight: "2px" }} />
        <Bullets style={{ width: "6px", height: "6px", marginRight: "2px" }} />
      </div>
    );
  };

  const makeDefault = (row) => {
    dispatch(makeDefaultPaymentMethod(row));
  };

  const detachPaymentMethod = (row) => {
    if (defaultPaymentMethod.id === row.id) {
      const newDefaultCard = stripeCardsList?.find(
        (card) => card.id !== defaultPaymentMethod.id,
      );
      if (!newDefaultCard) {
        alert("Atleast two payment methods must exist to delete");
        return;
      }

      dispatch(makeDefaultPaymentMethod(newDefaultCard));
      dispatch(detachPaymentMethodInStore(row));
    } else {
      dispatch(detachPaymentMethodInStore(row));
    }
  };

  const columns = [
    {
      key: "tool",
      name: "Cardholder Name",
      minWidth: 170,
      accessor: (row) => (
        <span className="b1"> {row.billing_details.name} </span>
      ),
    },
    {
      key: "category",
      name: "Card Number",
      minWidth: 220,
      accessor: (row) => (
        <div className="d-flex align-items-center">
          <span style={{ marginRight: "8px" }}>
            {row.card.brand === "visa" ? (
              <Visa style={{ width: "27px", height: "19px" }} />
            ) : (
              row.card.brand === "mastercard" && (
                <Mastercard style={{ width: "27px", height: "19px" }} />
              )
            )}
          </span>
          <span className="head6" style={{ display: "flex", color: "#10182B" }}>
            {[1, 2, 3].map((index) => generateBullets(index))}
          </span>
          <span className="b1" style={{ display: "flex", color: "#10182B" }}>
            {row.card.last4}
          </span>
        </div>
      ),
    },
    {
      key: "expiry",
      name: "Expiry",
      minWidth: 100,
      align: "left",
      accessor: (row) => (
        <span className="b1 grey8">
          {`${row.card.exp_month} / ${
            row?.card && row?.card?.exp_year?.toString()?.slice(2, 4)
          }`}
        </span>
      ),
    },
    {
      key: "status",
      name: "Status",
      minWidth: 100,
      align: "center",
      accessor: (row) => (
        <span className="b1">
          {row?.id === defaultPaymentMethod?.id ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "132px",
                height: "33px",
                background: "#7B2CBF",
                borderRadius: "48px",
                padding: "5px 10px",
              }}
            >
              <span className="b1 grey0">Default</span>
            </div>
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "142px",
                height: "33px",
                background: "#ADB5BD",
                borderRadius: "48px",
                padding: "5px 15px",
                cursor: "pointer",
              }}
              onClick={() => makeDefault(row)}
            >
              <span className="b1 grey0">Make Default</span>
            </div>
          )}
        </span>
      ),
    },
    {
      key: "_",
      name: "Action",
      minWidth: 100,
      align: "center",
      accessor: (row) => (
        <Trash
          stroke="black"
          style={{ cursor: "pointer" }}
          onClick={() => detachPaymentMethod(row)}
        />
      ),
    },
  ];

  const setTablePage = (data) => {
    dispatch(setTablePageInBillingStore(data));
  };

  return (
    <div
      className={`side-menu-box ${
        isSideMenu === true ? "open border" : "side-menu-box border"
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Close
            fill="#7B2CBF"
            stroke="black"
            strokeWidth="2"
            style={{ marginRight: "20px", width: "26px", height: "25px" }}
            onClick={() => dispatch(handleSideMenu())}
          />
          <span className="head5 grey8">Payment Cards</span>
        </div>

        <div
          className="d-flex align-items-center flex-row-reverse"
          style={{ width: "fit-content" }}
        >
          <div className="integration-menu-buttons">
            <Button
              buttonFilledFull
              title="Add New Card"
              style={{
                color: "white",
                minWidth: "136px",
                height: "40px",
                marginTop: "5px",
                fontWeight: "400",
              }}
              typography="s1 grey1"
              // loading={loading}
              onClick={() => dispatch(IsAddNewCardInStore())}
            />
          </div>
          <div
            style={{ paddingRight: "15px" }}
            className="integration-menu-buttons"
          >
            <Button
              buttonHollowLg
              title="Cancel"
              typography="s1 grey6"
              style={{ height: "40px" }}
              onClick={() => dispatch(handleSideMenu())}
            />
          </div>
        </div>
      </div>
      {/* <div className="top-border" /> */}

      <span className="head6 grey9" style={{ margin: "38px 0 23px 0" }}>
        Cards List
      </span>

      <Table
        // paymentCardsList
        data={stripeCardsList}
        columns={columns}
        defaultCard={defaultPaymentMethod}
        inputValue={""}
        page={page}
        setTablePage={setTablePage}
      />
    </div>
  );
};

export default PaymentCards;
