import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export const SurveyComponent = ({
  survey,
  surveyCount,
  index,
  onSubmit,
  style,
  color,
}) => {
  const emojis = [
    { emoji: "😍", label: "Amazing" },
    { emoji: "😊", label: "Good" },
    { emoji: "🤔", label: "Average" },
    { emoji: "😟", label: "Poor" },
    { emoji: "😡", label: "Worst" },
  ];

  dayjs.extend(relativeTime);
  return (
    <div className="survey" style={{ ...style, backgroundColor: color }}>
      <div className="survey-header">
        <span>{`Survey ( ${index} / ${surveyCount} )`}</span>
        <span>{dayjs(survey.created_at).fromNow()}</span>
      </div>
      <div className="survey-question">
        {survey.properties.survey_details.result.data[0].question}?
      </div>
      <div className="survey-options">
        {emojis.map((item, index) => (
          <div
            key={index}
            className="emoji-reaction d-flex flex-column align-items-center"
            onClick={() =>
              onSubmit(
                survey.properties.survey_details.result.data[0]
                  .triggered_suveys[0].id,
                item.label,
              )
            }
            style={{
              cursor: "pointer",
              padding: "10px",
            }}
          >
            <span
              role="img"
              aria-label={item.label}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FFFFFF",
                width: "55px",
                height: "55px",
                borderRadius: "50%",
                boxShadow:
                  "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.08)",
                marginBottom: "10px",
              }}
            >
              {item.emoji}
            </span>
            <span
              className="d-flex align-items-center justify-content-center s2"
              style={{ color: "#7E57C2", fontWeight: "bold", fontSize: "14px" }}
            >
              {item.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
