import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";

const initialState = {
  integrationsData: [],
  tenentData: [],
  toolsStatus: {},
  loader: false,
  tableLoader: false,
  sideMenu: {
    tableData: {
      data: [],
      rowsPerPage: 5,
      page: 1,
    },
  },
  disconnectionLoader: false,
  disconnectSuccess: false,
  isLoading: false,
  isUserMapped: false,
};

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setTenentData(state, action) {
      state.tenentData = action.payload;
    },
    setIntegrationsData(state, action) {
      state.integrationsData = action.payload;
    },
    setToolsStatus(state, action) {
      state.toolsStatus = { ...state.toolsStatus, ...action.payload };
    },
    setMeetWebhookRegistration(state, action) {
      state.toolsStatus = action.payload;
    },
    toggleLoader(state) {
      state.loader = !state.loader;
    },
    toggleTableLoader(state) {
      state.tableLoader = !state.tableLoader;
    },
    setSideMenuTablePage(state, action) {
      state.sideMenu.tableData.page = action.payload;
    },
    toggleDisconnectionLoader(state) {
      state.disconnectionLoader = !state.disconnectionLoader;
    },
    toggleDisconnectSuccess(state) {
      state.disconnectSuccess = !state.disconnectSuccess;
    },
    removeIntegrationConnection(state, action) {
      delete state.toolsStatus[action.payload];
    },
    setConnectedUsers(state, action) {
      state.sideMenu.tableData.data = action.payload;
    },
    setDetectedUsers(state, action) {
      state.sideMenu.tableData.data = action.payload;
    },
    setActiveUsers(state, action) {
      state.sideMenu.tableData.data = action.payload;
    },
    toggleIsLoading(state) {
      state.isLoading = !state.isLoading;
    },
    toggleIsUserMapped(state) {
      state.isUserMapped = !state.isUserMapped;
    },
  },
});

export const getTenantIntegrations = () => {
  return async (dispatch) => {
    try {
      const request = await secure_instance.request({
        url: "v1/tenant_integrations/",
        method: "Get",
      });

      dispatch(setTenentData(request.data));
      if (request.data !== null && request.data.length > 1) {
        return 1;
      } else {
        return 0;
      }
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

//purpose?
export const getTeamsSlice = (history) => {
  return async () => {
    try {
      const request = await secure_instance.request({
        url: "v1/teams/",
        method: "GET",
      });

      if (request.status === 200 && request.data.length > 0) {
        return 1;
      } else {
        return 0;
      }
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const getIntegrationsDataSlice = () => {
  return async (dispatch) => {
    try {
      dispatch(toggleIsLoading());
      const request = await secure_instance.request({
        url: "v1/integrations/",
        method: "Get",
      });
      // const modifiedData = request.data.map((obj) => {
      // 	obj["status"] = "Disconnected";
      // 	// obj["primary_email"] = obj.identifiers?.map(identifier => identifier.is_primary && identifier.email);

      // 	return obj;
      // });
      dispatch(setIntegrationsData(request.data));
      dispatch(toggleIsLoading());
      // setIntegrationsData(response);
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const connections = (name) => {
  return async (dispatch) => {
    try {
      dispatch(toggleIsLoading());
      const request = await secure_instance.request({
        url: `v1/${name}_auth`,
        method: "GET",
      });
      window.location = request.data.redirect_url;
      dispatch(toggleIsLoading());
    } catch (e) {
      dispatch(toggleIsLoading());
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const handleDisconnectSlice = (tool) => {
  return async (dispatch) => {
    try {
      dispatch(toggleDisconnectionLoader());
      await secure_instance.request({
        url: `v1/${tool}_auth`,
        method: "DELETE",
      });
      // window.location = request.data.redirect_url;
      dispatch(removeIntegrationConnection(tool));
      setTimeout(() => {
        dispatch(toggleDisconnectionLoader());
        dispatch(toggleDisconnectSuccess());
      }, 1000);
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

// export const getAllIntegrationsStatus = (tools) => {
//   return async (dispatch) => {
//     try {
//       const newObj = {};
//       tools.forEach(async (tool) => {
//         const res = await secure_instance.request({
//           url: `v1/${tool}_auth/connection`,
//           method: "Get",
//         });
//         if (res.data) {
//           newObj[tool] = true;
//           dispatch({
//             type: HANDLE_INTEGRATIONS_STATUS,
//             payload: newObj,
//           });
//         }
//       });
//     } catch (e) {
//       // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
//       console.error("error", e);
//     }
//   };
// };

export const getAllIntegrationsStatusSlice = (tools) => {
  return async (dispatch) => {
    try {
      const promises = tools.map(async (tool) => {
        const res = await secure_instance.request({
          url: `v1/${tool}_auth/connection`,
          method: "Get",
        });
        return { tool, status: res.data };
      });
      const results = await Promise.allSettled(promises);
      const newObj = {};
      results.forEach(({ value, status }) => {
        if (value?.tool && status === "fulfilled") {
          newObj[value.tool] = true;
        }
      });
      dispatch(setToolsStatus(newObj));
    } catch (e) {
      console.error("error", e);
    }
  };
};

export const getConnectedUsers = (tool) => {
  return async (dispatch) => {
    try {
      dispatch(toggleTableLoader());
      const request = await secure_instance.request({
        url: `v1/user_profile/?identifier=${tool}&search=false`,
        method: "Get",
      });
      dispatch(toggleTableLoader());
      dispatch(setConnectedUsers(request.data));
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const getDetectedUsers = (tool) => {
  return async (dispatch) => {
    try {
      dispatch(toggleTableLoader());
      const request = await secure_instance.request({
        url: `v1/detected_users/?identifier=${tool}&search=true`,
        method: "GET",
      });
      dispatch(toggleTableLoader());
      dispatch(setDetectedUsers(request.data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const getActiveUsers = (tool) => {
  return async (dispatch) => {
    try {
      dispatch(toggleTableLoader());
      const request = await secure_instance.request({
        url: `v1/user_profile/?identifier=${tool}&search=true`,
        method: "GET",
      });
      dispatch(toggleTableLoader());
      dispatch(setActiveUsers(request.data));
    } catch (e) {
      console.log(e);
    }
  };
};

export const handleCreateIdentifier = (data) => {
  return async (dispatch) => {
    try {
      dispatch(toggleIsLoading());

      await secure_instance.request({
        url: "v1/identifier/",
        method: "POST",
        data: data,
      });

      dispatch(toggleIsLoading());
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const handleUpdateDetectedUsers = (data) => {
  return async (dispatch) => {
    try {
      await secure_instance.request({
        url: "v1/detected_users/",
        method: "PATCH",
        data: data,
      });
      dispatch(toggleIsUserMapped());
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

// <------------------- Google Meet Connection ------------------------->

export const meetWebhookRegistraion = (type) => {
  return async (dispatch) => {
    try {
      dispatch(toggleIsLoading());
      const request = await secure_instance.request({
        url: `/v1/gauth_app/${type}/watch`,
        method: "GET",
      });
      const newObj = {};
      if (request.data) {
        const requestData = await secure_instance.request({
          url: `/v1/gauth_app/${type}/connection`,
          method: "GET",
        });
        if (requestData.data) {
          newObj[type] = true;
        }
        dispatch(toggleIsLoading());
        setTimeout(() => {
          dispatch(setToolsStatus(newObj));
        }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const meetConnectionSlice = (tools) => {
  return async (dispatch) => {
    try {
      const promises = tools.map(async (tool) => {
        const res = await secure_instance.request({
          url: `/v1/gauth_app/${tool}/connection`,
          method: "Get",
        });
        return { tool, status: res.data };
      });
      const results = await Promise.allSettled(promises);
      const newObj = {};
      results.forEach(({ value, status }) => {
        if (value?.tool && status === "fulfilled") {
          newObj[value.tool] = true;
        }
      });

      dispatch(setToolsStatus(newObj));
    } catch (e) {
      console.error("error", e);
    }
  };
};

export const meetDisconnect = (type) => {
  return async (dispatch) => {
    try {
      dispatch(toggleDisconnectionLoader());
      await secure_instance.request({
        url: `/v1/gauth_app/${type}/connection`,
        method: "DELETE",
      });
      // window.location = request.data.redirect_url;
      dispatch(removeIntegrationConnection(type));
      setTimeout(() => {
        dispatch(toggleDisconnectionLoader());
        dispatch(toggleDisconnectSuccess());
      }, 1000);
    } catch (e) {
      // --------- WILL ROUTE ON SOME PAGE ON FAILURE ---------
      console.log("error", e);
    }
  };
};

export const {
  setTenentData,
  setIntegrationsData,
  setToolsStatus,
  setMeetWebhookRegistration,
  toggleLoader,
  toggleTableLoader,
  setSideMenuTablePage,
  toggleDisconnectionLoader,
  toggleDisconnectSuccess,
  removeIntegrationConnection,
  setConnectedUsers,
  setDetectedUsers,
  setActiveUsers,
  toggleIsLoading,
  toggleIsUserMapped,
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
